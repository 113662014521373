import { ActionTree, ActionContext } from 'vuex'

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/library/store'

import { State } from './state'
import { Mutations } from './mutations'
import { MapsMutationTypes } from './mutation-types'
import { MapsActionTypes, StorageActionTypes } from './action-types'
import { recursiveDeleteLayers, debounceCall, getFlatList, isGroup, isLayer } from '@/library/helpers'
import api from '@/library/api'
import apiNoToken from '@/library/apINoToken'
import {
  GeoMap,
  GeoMapLayer,
  GeoMapLayerFull,
  GeoMapLayergroup,
  GeoMapLayerCreate,
  GeoMapLayerExport,
  DataPayload,
  DataWithParamsPayload,
  ExportTask,
  GeoMapListItem,
  GroupRenamePayload,
  GroupRefreshInfoPayload,
  ImportTask,
  StorageDataPayload,
  StorageMovePayload,
  StorageRenamePayload,
  FeaturePatchInfo,
  MapRenamePayload,
  GetSessionData,
  ColumnSchema,
  LayerColumnsInfo,
  ActionLayerPayload,
  LayerAttributesPayload,
  LayerAttributeUniqueValues,
  StorageData,
  PaginationParams,
  DownloadPath,
  MapLayerFeaturePayload,
  MapLayerFeatureUpdatePayload,
  MapLayerFeatureCreatePayload,
  MapLayerFeatureDeletePayload,
  ModelFeature,
  BookmarSchema,
  LightweightGroupStructure,
  MapSearchModel,
  MapSearchResult,
  UpdateSessionPayload,
  AttributiveLayerResponce,
  LayerColumnsDeletePayload,
  LayerColumnsPatchPayload,
  AttributiveDataPayload,
  AttributiveParamsPayload,
  LayerRefreshInfoPayload,
  MultipleDeleteLayerFeaturesPayload,
  MapLayerFeaturesBatchUpdatePayload,
  MapGroupInfo,
  TasksResponse,
  StyleCopyPayload,
  StyleCopyResult,
  LayerConvertTo3dModel,
  LayerTransform3dModel,
  LayerLegendImagePayload,
  LayerStyleIcon,
  MapSearchBufferModel,
  FeatureGeometry,
  BookmarksPayload,
  BookmarksResponse,
  DeleteUserAccessPayload,
  DeleteUserAccessResponce,
  PatchUserAccessResponce,
  PatchUserAccessPayload,
  UserAccessInfo,
  TextSearchModel,
  TextSearchResult
} from '@/library/types'
import { BaseMapType } from '@/library/types/maps/enums'
import { migrationMboxToSld } from '@/library/helpers/migrations'
import auth from '@/library/auth'
import { WindowVariant } from '@/library/types/base/enums'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Buffer: any
type AugmentedActionContext = {
  commit<K extends keyof Mutations> (
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [MapsActionTypes.FETCH_MAP] ({ dispatch, commit }: AugmentedActionContext, payload: number): Promise<GeoMap>,
  [MapsActionTypes.FETCH_UNAUTHORISED_MAP] ({ dispatch, commit }: AugmentedActionContext, payload: number): Promise<GeoMap>,
  [MapsActionTypes.FETCH_MAP_CONFIG] ({ dispatch, commit }: AugmentedActionContext, payload: number): Promise<GeoMap>,
  [MapsActionTypes.MAP_REFRESH] ({ dispatch, commit }: AugmentedActionContext, payload: number): Promise<GeoMap>,
  [MapsActionTypes.FETCH_MAPS] ({ commit, state }: AugmentedActionContext, params: PaginationParams): Promise<GeoMapListItem[]>,
  [MapsActionTypes.GET_SESSION_DATA] ({ commit }: AugmentedActionContext, mapId: number): Promise<GetSessionData | undefined>,
  [MapsActionTypes.PUT_SESSION_DATA] ({ commit }: AugmentedActionContext, payload: UpdateSessionPayload): Promise<void>,
  [MapsActionTypes.FETCH_TASKS] ({ commit, state }: AugmentedActionContext, params: PaginationParams): Promise<TasksResponse>,
  [MapsActionTypes.FETCH_TASK] ({ commit, state }: AugmentedActionContext, payload: number): Promise<ImportTask | ExportTask>,
  [MapsActionTypes.FETCH_LAYER] ({ commit, state }: AugmentedActionContext, payload: number): Promise<GeoMapLayer>,
  [MapsActionTypes.FETCH_LAYER_FEATURE] ({ dispatch, commit, state }: AugmentedActionContext, payload: MapLayerFeaturePayload): Promise<ModelFeature>,
  [MapsActionTypes.LAYER_FEATURES_BATCH_UPDATE] ({ dispatch, commit, state }: AugmentedActionContext, payload: MapLayerFeaturesBatchUpdatePayload): Promise<ModelFeature>,
  [MapsActionTypes.LAYER_FEATURE_UPDATE] ({ dispatch, commit, state }: AugmentedActionContext, payload: MapLayerFeatureUpdatePayload): Promise<ModelFeature>,
  [MapsActionTypes.LAYER_FEATURE_CREATE] ({ dispatch, commit, state }: AugmentedActionContext, payload: MapLayerFeatureCreatePayload): Promise<ModelFeature>,
  [MapsActionTypes.LAYER_FEATURE_DELETE] ({ dispatch, commit, state }: AugmentedActionContext, payload: MapLayerFeatureDeletePayload): Promise<ModelFeature>,
  [MapsActionTypes.LAYER_REFRESH] ({ commit, state }: AugmentedActionContext, payload: number): Promise<GeoMapLayerFull>,
  [MapsActionTypes.MAP_CREATE] ({ commit, state }: AugmentedActionContext, payload: boolean): Promise<GeoMap>
  [MapsActionTypes.MAP_DELETE] ({ commit, state }: AugmentedActionContext, payload: number): Promise<void>
  [MapsActionTypes.MOVE_MAP_TO_TRASH] ({ commit, state }: AugmentedActionContext, payload: number): Promise<void>
  [MapsActionTypes.MAP_RESTORE] ({ commit, state }: AugmentedActionContext, payload: number): Promise<void>
  [MapsActionTypes.LAYER_IMPORT] ({ commit, state }: AugmentedActionContext, payload: FormData | StorageData): Promise<void>,
  [MapsActionTypes.CREATE_GROUP] ({ commit, state }: AugmentedActionContext, payload: number): Promise<GeoMapLayergroup>,
  [MapsActionTypes.DELETE_GROUP] ({ commit, state }: AugmentedActionContext, payload: number): Promise<void>,
  [MapsActionTypes.DELETE_GROUPS] ({ commit, state }: AugmentedActionContext, payload: number[]): Promise<void>,
  [MapsActionTypes.CREATE_LAYER] ({ commit, state }: AugmentedActionContext, payload: GeoMapLayerCreate): Promise<void>,
  [MapsActionTypes.DELETE_LAYER] ({ commit, state }: AugmentedActionContext, layerId: number): Promise<void>,
  [MapsActionTypes.DELETE_LAYERS] ({ state }: AugmentedActionContext, payload: number[]): Promise<void>,
  [MapsActionTypes.UPDATE_ATTRIBUTES] ({ commit }: AugmentedActionContext, payload: FeaturePatchInfo): Promise<void>
  [MapsActionTypes.UPDATE_GROUP_INFO] ({ commit, state }: AugmentedActionContext, payload: GroupRefreshInfoPayload): Promise<void>,
  [MapsActionTypes.UPDATE_LAYER_INFO] ({ commit }: AugmentedActionContext, payload: GroupRenamePayload): Promise<void>,
  [MapsActionTypes.MAP_UPDATE_GROUP_ROOT] ({ commit, state }: AugmentedActionContext, payload: LightweightGroupStructure): Promise<void>
  [MapsActionTypes.MAP_RENAME] ({ commit }: AugmentedActionContext, payload: MapRenamePayload): Promise<void>
  [MapsActionTypes.GET_LAYER_COLUMNS] ({ commit }: AugmentedActionContext, payload: number): Promise<ColumnSchema[]>
  [MapsActionTypes.PATCH_LAYER] ({ commit }: AugmentedActionContext, payload: DataPayload<ActionLayerPayload>): Promise<GeoMapLayer>
  [MapsActionTypes.PATCH_LAYER_STYLE] ({ dispatch, state, commit }: AugmentedActionContext, payload: DataPayload<ActionLayerPayload>): Promise<boolean>
  [MapsActionTypes.LAYER_EXPORT_TASK] ({ state, commit }: AugmentedActionContext, data: GeoMapLayerExport): Promise<ExportTask>
  [MapsActionTypes.FILE_DOWNLOAD] ({ state }: AugmentedActionContext, payload: DownloadPath): Promise<string>
  [MapsActionTypes.PATCH_MAP] ({ commit, state }: AugmentedActionContext, payload: GeoMap): Promise<void>
  [MapsActionTypes.PATCH_USER_ACCESS_LEVEL] ({ commit, state }: AugmentedActionContext, payload: PatchUserAccessPayload): Promise<PatchUserAccessResponce>
  [MapsActionTypes.DELETE_USER_ACCESS_LEVEL] ({ commit, state }: AugmentedActionContext, payload: DeleteUserAccessPayload): Promise<DeleteUserAccessResponce>
  [MapsActionTypes.GET_USER_ACCESS_LEVEL] ({ commit, state }: AugmentedActionContext, mapId: number): Promise<UserAccessInfo[]>
  [MapsActionTypes.GET_BOOKMARKS] ({ commit }: AugmentedActionContext, payload: BookmarksPayload): Promise<BookmarksResponse>
  [MapsActionTypes.PATCH_BOOKMARK] ({ commit }: AugmentedActionContext, payload: BookmarSchema): Promise<BookmarSchema>
  [MapsActionTypes.CREATE_BOOKMARK] ({ commit }: AugmentedActionContext, payload: BookmarSchema): Promise<BookmarSchema>
  [MapsActionTypes.DELETE_BOOKMARK] ({ commit }: AugmentedActionContext, bookmarkId: number): Promise<void>
  [MapsActionTypes.MAP_FEATURES_SEARCH] ({ commit }: AugmentedActionContext, payload: MapSearchModel): Promise<MapSearchResult[]>
  [MapsActionTypes.MAP_FEATURES_SEARCH_BUFFER] ({ commit }: AugmentedActionContext, payload: MapSearchBufferModel): Promise<FeatureGeometry>
  [MapsActionTypes.MAP_TEXT_SEARCH] ({ commit }: AugmentedActionContext, payload: TextSearchModel): Promise<TextSearchResult>
  [MapsActionTypes.LAYER_COLUMNS_DELETE] ({ commit }: AugmentedActionContext, payload: DataPayload<LayerColumnsDeletePayload>): Promise<void>
  [MapsActionTypes.LAYER_COLUMNS_UPDATE] ({ commit }: AugmentedActionContext, payload: DataPayload<LayerColumnsPatchPayload>): Promise<void>
  [MapsActionTypes.LAYER_COLUMNS_CREATE] ({ commit }: AugmentedActionContext, payload: DataPayload<LayerColumnsPatchPayload>): Promise<void>
  [MapsActionTypes.RESET_TREE_STRUCTURE] ({ state }: AugmentedActionContext): void
  [MapsActionTypes.FETCH_FILTERED_LAYER_FEATURES] ({ state }: AugmentedActionContext, payload: DataWithParamsPayload<AttributiveDataPayload, AttributiveParamsPayload>): Promise<AttributiveLayerResponce>
  [MapsActionTypes.FETCH_LAYER_FEATURE_ATTRIBUTES] ({ state }: AugmentedActionContext, layerId: number): Promise<LayerColumnsInfo[]>
  [MapsActionTypes.GET_GROUPS_INFO] ({ state }: AugmentedActionContext, groupId: number): Promise<MapGroupInfo>
  [MapsActionTypes.DELETE_LAYER_FEATURES] ({ state }: AugmentedActionContext, payload: MultipleDeleteLayerFeaturesPayload): void
  [MapsActionTypes.LAYER_STYLE_COPY] ({ state }: AugmentedActionContext, payload: StyleCopyPayload): Promise<StyleCopyResult>
  [MapsActionTypes.LAYER_CONVERT_TO_3D] ({ state }: AugmentedActionContext, payload: LayerConvertTo3dModel): Promise<void>
  [MapsActionTypes.LAYER_TRANSFORM_3D] ({ state }: AugmentedActionContext, payload: LayerTransform3dModel): Promise<void>
  [MapsActionTypes.GET_LAYER_ATTRIBUTE_VALUES] ({ state }: AugmentedActionContext, payload: LayerAttributesPayload): Promise<LayerAttributeUniqueValues>
  [MapsActionTypes.LAYER_LEGEND_IMAGE] ({ state }: AugmentedActionContext, payload: LayerLegendImagePayload): Promise<string>
  [MapsActionTypes.GET_LAYER_STYLE_ICONS] ({ state }: AugmentedActionContext): Promise<void>

  [StorageActionTypes.COPY_ITEM] ({ state }: AugmentedActionContext, payload: StorageDataPayload): Promise<void>
  [StorageActionTypes.MOVE_ITEM] ({ state }: AugmentedActionContext, payload: StorageMovePayload): Promise<void>
  [StorageActionTypes.DELETE_ITEM] ({ state }: AugmentedActionContext, payload: StorageDataPayload): Promise<void>
  [StorageActionTypes.RENAME_ITEM] ({ state }: AugmentedActionContext, payload: StorageRenamePayload): Promise<void>
}

const MAP_DEFAULT = {
  name: 'Новая карта',
  preview: '',
  description: 'Новая карта',
  is3d: false,
  lat: 0,
  lng: 0,
  zoom: 0,
  maxZoom: 0,
  minZoom: 0
}

const putSessionData = (newSession: GetSessionData) => {
  if (auth.keycloak.token) {
    api.put('/session', newSession)
  }
}

const UPDATE_SESSION_INTERVAL = 1000
const lazyUpdateSession = debounceCall(putSessionData, UPDATE_SESSION_INTERVAL)

export const actions: ActionTree<State, RootState> & Actions = {
  async [MapsActionTypes.LAYER_IMPORT] ({ state, commit }, payload: FormData | StorageData) {
    if (auth.keycloak.token) {
      await auth.keycloakUpdateToken()
    }
    const { data } = await api.post(`/maps/${state.map?.id}/tasks/layerImport`, payload)
    commit(MapsMutationTypes.ADD_EXPORT_TASK_ID, data.id)
    commit(MapsMutationTypes.SET_WINDOW_OPEN_STATE, {
      id: WindowVariant.OPERATIONS_HISTORY,
      state: true
    })
  },

  async [MapsActionTypes.UPDATE_ATTRIBUTES] (_, payload: FeaturePatchInfo) {
    return api.patch(`/layers/${payload.layerId}/features/${payload.featureId}`, payload)
      .then(({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.FETCH_MAP] ({ commit }, mapId: number) {
    commit(MapsMutationTypes.SET_MAP_BASEMAP, BaseMapType.BBM)
    commit(MapsMutationTypes.SET_MAP, null)
    commit(MapsMutationTypes.SET_TASKS, [])
    commit(MapsMutationTypes.SET_MAP_STATE_DEFAULT, null)
    return api.get(`/maps/${mapId}`)
      .then(async (resp) => {
        try {
          await migrationMboxToSld(resp.data)
        } finally {
          //
        }

        commit(MapsMutationTypes.SET_MAP, resp.data)
        return resp.data
      })
      .catch((e) => {
        throw e
      })
  },

  async [MapsActionTypes.FETCH_UNAUTHORISED_MAP] ({ commit }, mapId: number) {
    commit(MapsMutationTypes.SET_MAP_BASEMAP, BaseMapType.BBM)
    commit(MapsMutationTypes.SET_MAP, null)
    commit(MapsMutationTypes.SET_TASKS, [])
    commit(MapsMutationTypes.SET_MAP_STATE_DEFAULT, null)
    return apiNoToken.get(`/maps/${mapId}`)
      .then(async (resp) => {
        try {
          /* TODO check after backend is ready */
          /* war only resp */
          await migrationMboxToSld(resp.data)
        } finally {
          commit(MapsMutationTypes.SET_MAP, resp.data)
        }
        return resp.data
      })
  },

  async [MapsActionTypes.FETCH_MAP_CONFIG] (_, mapId: number) {
    return apiNoToken.get(`/maps/${mapId}/config`)
      .then(async ({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.MAP_REFRESH] ({ commit }, mapId: number) {
    return api.get<GeoMap>(`/maps/${mapId}`)
      .then(({ data }) => {
        commit(MapsMutationTypes.SET_MAP, data)
        return data
      })
  },

  async [MapsActionTypes.CREATE_LAYER] ({ dispatch }, layer: GeoMapLayerCreate) {
    return api.post('/layers', layer).then(({ data }) => {
      dispatch(MapsActionTypes.LAYER_REFRESH, data.id)
    })
  },

  async [MapsActionTypes.DELETE_LAYER] ({ state, commit }, layerId: number) {
    await api.delete(`/layers/${layerId}`)
    if (state.map) {
      recursiveDeleteLayers(state.map.groups, [layerId])
      const newSelectedLayers = state.selectedLayers.filter(item => item !== layerId)
      commit(MapsMutationTypes.SET_LAYERS_SELECTED, newSelectedLayers)
    }
  },

  async [MapsActionTypes.DELETE_LAYERS] ({ state }, payload: number[]): Promise<void> {
    await api.delete('/layers', {
      data: {
        layerIds: Array.from(new Set(payload))
      }
    })
    if (state.map) {
      recursiveDeleteLayers(state.map.groups, payload)
    }
  },

  async [MapsActionTypes.MAP_UPDATE_GROUP_ROOT] ({ commit, state }, payload: LightweightGroupStructure) {
    const { data } = await api.put(`/maps/${state.map?.id}/groups`, payload)
    commit(MapsMutationTypes.SET_ROOT_GROUP, data)
    return data
  },

  async [MapsActionTypes.MAP_RENAME] ({ commit }, payload: MapRenamePayload) {
    const { data } = await api.patch(`/maps/${payload.mapId}`, { name: payload.newMapName })
    if (payload.withoutReload === undefined) {
      commit(MapsMutationTypes.SET_MAP, data)
    }
  },

  async [MapsActionTypes.CREATE_GROUP] ({ commit, state }): Promise<GeoMapLayergroup> {
    return api.post<GeoMapLayergroup>(`/maps/${state.map?.id}/groups`, { name: 'Новая группа' })
      .then(({ data }) => {
        commit(MapsMutationTypes.ADD_GROUP, data)
        commit(MapsMutationTypes.SET_MAP_GROUPS_VISIBLE, [...state.visibleGroups, data.id])
        return data
      })
  },

  async [MapsActionTypes.UPDATE_LAYER_INFO] ({ commit }, payload: LayerRefreshInfoPayload): Promise<void> {
    return api.patch(`/layers/${payload.itemId}`, {
      comment: payload.comment,
      name: payload.newName,
      url: payload.url,
      config3d: payload.config3d
    }).then(({ data }) => {
      commit(MapsMutationTypes.SET_MAP_LAYER_NAME, payload)
      return data
    })
  },

  async [MapsActionTypes.UPDATE_GROUP_INFO] ({ commit, state }, payload: GroupRefreshInfoPayload): Promise<void> {
    return api.patch(`/maps/${state.map?.id}/groups/${payload.itemId}`, {
      colorCode: payload.colorCode,
      name: payload.newName
    })
      .then(({ data }) => {
        commit(MapsMutationTypes.SET_MAP_GROUP_INFO, payload)
        return data
      })
  },

  async [MapsActionTypes.DELETE_GROUP] ({ commit, state }, groupId: number): Promise<void> {
    return api.delete(`/maps/${state.map?.id}/groups/${groupId}`).then(() => {
      commit(MapsMutationTypes.MAP_GROUP_DELETE, groupId)
      const newSelectedGroups = state.selectedGroups.filter(item => item !== groupId)
      commit(MapsMutationTypes.SET_GROUPS_SELECTED, newSelectedGroups)
    })
  },

  async [MapsActionTypes.DELETE_GROUPS] ({ commit, state }, payload: number[]): Promise<void> {
    await api.delete(`/maps/${state.map?.id}/groups`, {
      data: {
        groupsIds: Array.from(new Set(payload))
      }
    })
    payload.forEach(groupId => {
      commit(MapsMutationTypes.MAP_GROUP_DELETE, groupId)
    })
  },

  async [MapsActionTypes.FETCH_MAPS] (_, params: PaginationParams) {
    return api.get('/maps', {
      params: params
    }).then(({ data }) => {
      return data
    })
  },

  async [MapsActionTypes.GET_SESSION_DATA] ({ commit }, mapId: number): Promise<GetSessionData | undefined> {
    const { data } = await api.get('/session', {
      params: {
        map_id: mapId
      }
    })
    commit(MapsMutationTypes.SET_MAP_SESSION_DATA, data)
    return data
  },

  async [MapsActionTypes.PUT_SESSION_DATA] ({ state }, payload: UpdateSessionPayload): Promise<void> {
    const allItems = state.map ? getFlatList(state.map?.groups) : []
    const groupsIds = allItems.filter(item => isGroup(item)).map(item => item.id)
    const layersIds = allItems.filter(item => isLayer(item)).map(item => item.id)
    const actualVisibleLayers = state.visibleLayers.filter(layerInfo => {
      return layersIds.includes(layerInfo.layerId)
    })
    const actualVisibleGroupsIds = state.visibleGroups.filter(groupId => {
      return groupsIds.includes(groupId)
    })
    const newSession: GetSessionData = {
      extent: (payload?.is2dProcessing ? payload?.extent2d : state.savedSession?.extent) ?? [],
      mapId: 3,
      baseMap: (payload?.is2dProcessing ? state.basemap : state.savedSession?.baseMap) ?? BaseMapType.BBM,
      visibleLayers: (payload?.is2dProcessing ? actualVisibleLayers : state.savedSession?.visibleLayers) ?? [],
      visibleGroupsIds: (payload?.is2dProcessing ? actualVisibleGroupsIds : state.savedSession?.visibleGroupsIds) ?? [],
      extent3d: payload?.extent3d,
      visible3dLayers: (!payload?.is2dProcessing ? actualVisibleLayers : state.savedSession?.visible3dLayers) ?? [],
      visible3dGroupsIds: (!payload?.is2dProcessing ? actualVisibleGroupsIds : state.savedSession?.visible3dGroupsIds) ?? []
    }
    state.savedSession = newSession
    lazyUpdateSession(newSession)
  },

  async [MapsActionTypes.FETCH_LAYER] (_, layerId: number): Promise<GeoMapLayer> {
    const { data } = await api.get<GeoMapLayer>(`/layers/${layerId}`)
    return data
  },

  async [MapsActionTypes.FETCH_LAYER_FEATURE] (_, payload: MapLayerFeaturePayload): Promise<ModelFeature> {
    return api.get<ModelFeature>(`/layers/${payload.layerId}/features/${payload.featureId}`)
      .then(({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.LAYER_FEATURES_BATCH_UPDATE] ({ dispatch }, payload: MapLayerFeaturesBatchUpdatePayload): Promise<ModelFeature> {
    return api.post<ModelFeature>(`/layers/${payload.layerId}/features:batchUpdate`, payload.model)
      .then(({ data }) => {
        dispatch(MapsActionTypes.LAYER_REFRESH, payload.layerId)
        return data
      })
  },

  async [MapsActionTypes.LAYER_FEATURE_UPDATE] ({ dispatch }, payload: MapLayerFeatureUpdatePayload): Promise<ModelFeature> {
    return api.patch<ModelFeature>(`/layers/${payload.layerId}/features/${payload.featureId}`, payload.model)
      .then(({ data }) => {
        dispatch(MapsActionTypes.LAYER_REFRESH, payload.layerId)

        return data
      })
  },

  async [MapsActionTypes.LAYER_FEATURE_CREATE] ({ dispatch }, payload: MapLayerFeatureCreatePayload): Promise<ModelFeature> {
    return api.post<ModelFeature>(`/layers/${payload.layerId}/features`, payload.model)
      .then(({ data }) => {
        dispatch(MapsActionTypes.LAYER_REFRESH, payload.layerId)
        return data
      })
  },

  async [MapsActionTypes.LAYER_FEATURE_DELETE] ({ dispatch }, payload: MapLayerFeatureDeletePayload): Promise<ModelFeature> {
    return api.delete<ModelFeature>(`/layers/${payload.layerId}/features/${payload.featureId}`)
      .then(({ data }) => {
        dispatch(MapsActionTypes.LAYER_REFRESH, payload.layerId)
        return data
      })
  },

  async [MapsActionTypes.LAYER_REFRESH] ({ commit }, layerId: number): Promise<GeoMapLayerFull> {
    const { data } = await api.get<GeoMapLayerFull>(`/layers/${layerId}`)
    commit(MapsMutationTypes.SET_LAYER, data)
    return data
  },

  async [MapsActionTypes.FETCH_TASKS] ({ commit, state }, params: PaginationParams): Promise<TasksResponse> {
    const { data } = await api.get<TasksResponse>(`/maps/${state.map?.id}/tasks`, {
      params: {
        'page[number]': 1,
        'page[size]': params.limit,
        'order[created]': 'desc'
      }
    })
    commit(MapsMutationTypes.SET_TASKS, data.items)
    return data
  },

  async [MapsActionTypes.FETCH_TASK] ({ commit, state }, taskId: number): Promise<ImportTask | ExportTask> {
    return api.get<ImportTask>(`/maps/${state.map?.id}/tasks/${taskId}`)
      .then(({ data }) => {
        commit(MapsMutationTypes.UPDATE_TASK, data)
        return data
      })
  },

  async [MapsActionTypes.MAP_CREATE] ({ commit }, payload: boolean): Promise<GeoMap> {
    const mapSample = MAP_DEFAULT
    mapSample.is3d = payload
    return api.post('/maps', mapSample)
      .then(({ data }) => {
        commit(MapsMutationTypes.ADD_MAP, data)
        return data
      })
  },

  async [MapsActionTypes.MAP_DELETE] ({ commit, state }, payload: number): Promise<void> {
    return api.delete(`/maps/${payload}`)
      .then(() => {
        let newMapList: GeoMap[]
        if (state.list) {
          newMapList = state.list.filter(x => x.id !== payload)
        } else {
          newMapList = []
        }
        commit(MapsMutationTypes.SET_LIST, newMapList)
      })
  },

  [MapsActionTypes.MOVE_MAP_TO_TRASH] (_, payload: number): Promise<void> {
    return api.delete(`/maps/${payload}/trash`)
  },

  [MapsActionTypes.MAP_RESTORE] (_, payload: number): Promise<void> {
    return api.post(`/maps/${payload}/restore`)
  },

  async [MapsActionTypes.LAYER_EXPORT_TASK] ({ state, commit }, data: GeoMapLayerExport) {
    return api.post(`/maps/${state.map?.id}/tasks/layerExport`, data)
      .then(({ data }) => {
        commit(MapsMutationTypes.ADD_EXPORT_TASK_ID, data.id)
        return data
      })
  },

  async [MapsActionTypes.GET_LAYER_COLUMNS] (_, layerId: number): Promise<ColumnSchema[]> {
    const response = await api.get(`/layers/${layerId}/columns`)
    return response.data
  },

  async [MapsActionTypes.PATCH_LAYER] ({ commit }, payload: DataPayload<ActionLayerPayload>): Promise<GeoMapLayer> {
    const { data } = await api.patch<GeoMapLayer>(`/layers/${payload.id}`, payload.data)
    commit(MapsMutationTypes.SET_LAYER, data)
    return data
  },

  async [MapsActionTypes.PATCH_LAYER_STYLE] ({ dispatch, state, commit }, payload: DataPayload<ActionLayerPayload>): Promise<boolean> {
    await api.patch(`/layers/${payload.id}/style`, payload.data)
    const existLayer = state.visibleLayers.find(item => item.layerId === payload.id)
    if (existLayer) {
      existLayer.filters = []
      commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, state.visibleLayers)
    }
    dispatch(MapsActionTypes.LAYER_REFRESH, payload.id)
    return true
  },

  async [MapsActionTypes.FILE_DOWNLOAD] (_, payload: DownloadPath): Promise<string> {
    const response = await api.get(`/download/${payload.dirname}/${payload.filename}`, {
      responseType: 'blob'
    })
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    return window.URL.createObjectURL(blob)
  },

  async [MapsActionTypes.PATCH_MAP] (_, payload: GeoMap): Promise<void> {
    const data = {} as GeoMap
    if (payload.allAccess != null) {
      data.allAccess = payload.allAccess
    }
    return api.patch(`/maps/${payload.id}`, data)
  },

  async [MapsActionTypes.DELETE_USER_ACCESS_LEVEL] (_, payload: DeleteUserAccessPayload): Promise<DeleteUserAccessResponce> {
    const { data } = await api.post<DeleteUserAccessResponce>(`/maps/${payload.id}/users:remove`, payload.data.items)
    return data
  },

  async [MapsActionTypes.PATCH_USER_ACCESS_LEVEL] (_, payload: PatchUserAccessPayload): Promise<PatchUserAccessResponce> {
    const { data } = await api.patch<PatchUserAccessResponce>(`/maps/${payload.id}/users`, payload.data.items)
    return data
  },

  async [MapsActionTypes.GET_USER_ACCESS_LEVEL] (_, mapId: number): Promise<UserAccessInfo[]> {
    const { data } = await api.get(`/maps/${mapId}/users`)
    return data
  },

  async [MapsActionTypes.GET_BOOKMARKS] (_, payload: BookmarksPayload): Promise<BookmarksResponse> {
    const { data } = await api.get(`/maps/${payload.mapId}/bookmarks`, {
      params: {
        'page[number]': payload.pageNumber,
        'page[size]': payload.pageSize
      }
    })
    return data
  },

  async [MapsActionTypes.PATCH_BOOKMARK] ({ commit }: AugmentedActionContext, payload: BookmarSchema): Promise<BookmarSchema> {
    const { data } = await api.patch(`/bookmarks/${payload.id}`, {
      name: payload.name,
      position2d: payload.position2d,
      position3d: payload.position3d
    })
    return data
  },

  async [MapsActionTypes.CREATE_BOOKMARK] ({ commit }: AugmentedActionContext, payload: BookmarSchema): Promise<BookmarSchema> {
    const { data } = await api.post('/bookmarks', payload)
    return data
  },

  async [MapsActionTypes.DELETE_BOOKMARK] ({ commit }: AugmentedActionContext, bookmarkId: number): Promise<void> {
    await api.delete(`/bookmarks/${bookmarkId}`)
  },

  async [MapsActionTypes.MAP_FEATURES_SEARCH] (_: AugmentedActionContext, payload: MapSearchModel): Promise<MapSearchResult[]> {
    return api.post<MapSearchResult[]>('/search', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.MAP_FEATURES_SEARCH_BUFFER] (_: AugmentedActionContext, payload: MapSearchBufferModel): Promise<FeatureGeometry> {
    return api.post<FeatureGeometry>('/search:buffer', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.MAP_TEXT_SEARCH] (_: AugmentedActionContext, payload: TextSearchModel): Promise<TextSearchResult> {
    return api.post<TextSearchResult>('/full_text_search', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.LAYER_COLUMNS_DELETE] (_: AugmentedActionContext, payload: DataPayload<LayerColumnsDeletePayload>): Promise<void> {
    return api.post(`/layers/${payload.id}/columns:batchDelete`, payload.data)
      .then(({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.LAYER_COLUMNS_UPDATE] (_: AugmentedActionContext, payload: DataPayload<LayerColumnsPatchPayload>): Promise<void> {
    return api.post(`/layers/${payload.id}/columns:batchUpdate`, payload.data)
      .then(({ data }) => {
        return data
      })
  },

  async [MapsActionTypes.LAYER_COLUMNS_CREATE] (_: AugmentedActionContext, payload: DataPayload<LayerColumnsPatchPayload>): Promise<void> {
    return api.post(`/layers/${payload.id}/columns:batchCreate`, payload.data)
      .then(({ data }) => {
        return data
      })
  },

  [MapsActionTypes.RESET_TREE_STRUCTURE] ({ state }: AugmentedActionContext) {
    state.failedDragging = true
    if (state.map && state.cachedRootStructure) {
      state.map.groups = state.cachedRootStructure
    }
  },

  async [MapsActionTypes.FETCH_FILTERED_LAYER_FEATURES] (_: AugmentedActionContext, payload: DataWithParamsPayload<AttributiveDataPayload, AttributiveParamsPayload>): Promise<AttributiveLayerResponce> {
    const { data } = await api.post<AttributiveLayerResponce>(`/layers/${payload.id}/features:filterQuery`, payload.data, {
      params: {
        'page[number]': payload.params.pageNumber,
        'page[size]': payload.params.pageSize,
        'include[geometry]': payload.params.includeGeomerty,
        'include[properties]': payload.params.includeProperties,
        'include[measures]': payload.params.includeMeasures
      }
    })
    return data
  },

  async [MapsActionTypes.FETCH_LAYER_FEATURE_ATTRIBUTES] (_: AugmentedActionContext, layerId: number): Promise<LayerColumnsInfo[]> {
    const { data } = await api.get(`/layers/${layerId}/attributes`)
    return data
  },

  async [MapsActionTypes.GET_LAYER_ATTRIBUTE_VALUES] (_: AugmentedActionContext, payload: LayerAttributesPayload): Promise<LayerAttributeUniqueValues> {
    const { data } = await api.get(`/layers/${payload.layerId}/attributes/${payload.attributeName}`, {
      params: {
        'page[number]': payload.pageNumber,
        'page[size]': payload.pageSize,
        search: ''
      }
    })
    return data
  },

  async [MapsActionTypes.GET_GROUPS_INFO] (_: AugmentedActionContext, groupId: number): Promise<MapGroupInfo> {
    const { data } = await api.get<MapGroupInfo>(`/groups/${groupId}`)
    return data
  },

  async [MapsActionTypes.DELETE_LAYER_FEATURES] (_: AugmentedActionContext, payload: MultipleDeleteLayerFeaturesPayload) {
    await api.post(`/layers/${payload.layerId}/features:bulkDelete`, {
      featuresIds: Array.from(new Set(payload.featureIds))
    })
  },

  async [MapsActionTypes.LAYER_STYLE_COPY] (_: AugmentedActionContext, payload: StyleCopyPayload): Promise<StyleCopyResult> {
    const { data } = await api.patch<StyleCopyResult>('/styles/copy', payload)
    return data
  },

  async [MapsActionTypes.LAYER_CONVERT_TO_3D] ({ state, commit }: AugmentedActionContext, payload: LayerConvertTo3dModel): Promise<void> {
    const { data } = await api.post(`/maps/${state.map?.id}/tasks/layerConvertIn3d`, payload)
    commit(MapsMutationTypes.ADD_EXPORT_TASK_ID, data.id)
    commit(MapsMutationTypes.SET_WINDOW_OPEN_STATE, {
      id: WindowVariant.OPERATIONS_HISTORY,
      state: true
    })
  },

  async [MapsActionTypes.LAYER_TRANSFORM_3D] ({ state, commit }: AugmentedActionContext, payload: LayerTransform3dModel): Promise<void> {
    const { data } = await api.post(`/maps/${state.map?.id}/tasks/layerTransformTileset`, payload)
    commit(MapsMutationTypes.ADD_EXPORT_TASK_ID, data.id)
    commit(MapsMutationTypes.SET_WINDOW_OPEN_STATE, {
      id: WindowVariant.OPERATIONS_HISTORY,
      state: true
    })
  },

  async [MapsActionTypes.LAYER_LEGEND_IMAGE] ({ state, commit }: AugmentedActionContext, payload: LayerLegendImagePayload): Promise<string> {
    const resp = await api.post(`/geodata/${payload.layerId}/legend`, {
      legendOptions: payload.legendOptions,
      width: payload.width ?? 20,
      height: payload.height ?? 20
    },
    { responseType: 'arraybuffer' }
    )

    if (resp.data === undefined) {
      return ''
    }

    const prefix = 'data:' + resp.headers['content-type'] + ';base64,'
    const img = Buffer.from(resp.data, 'binary').toString('base64')
    return prefix + img
  },

  async [MapsActionTypes.GET_LAYER_STYLE_ICONS] ({ state }): Promise<void> {
    const { data } = await api.get<LayerStyleIcon[]>('/icons')
    state.cachedStyleIcons = data
  },

  async [StorageActionTypes.COPY_ITEM] (_: AugmentedActionContext, payload: StorageDataPayload): Promise<void> {
    await api.put('/storage/copy', payload)
  },

  async [StorageActionTypes.MOVE_ITEM] (_: AugmentedActionContext, payload: StorageMovePayload): Promise<void> {
    await api.put('/storage/move', payload)
  },

  async [StorageActionTypes.DELETE_ITEM] (_: AugmentedActionContext, payload: StorageDataPayload): Promise<void> {
    await api.delete('/storage', { params: payload })
  },

  async [StorageActionTypes.RENAME_ITEM] (_: AugmentedActionContext, payload: StorageRenamePayload): Promise<void> {
    await api.patch('/storage', payload)
  }
}
