
import i18n from '@/i18n'
import { defineComponent } from 'vue'

import BaseEmptyMessage from '@/library/base/BaseEmptyMessage.vue'
import imgDocument from '@/library/assets/img/icDoc.svg'

const $t = i18n.global.t

export default defineComponent({
  components: { BaseEmptyMessage },

  props: {
    title: { type: String, default: $t('base.dropZone.defaultTitle') },
    icon: { type: String, default: imgDocument },
    fullScreen: { type: Boolean, default: false },
    limitedZone: { type: Boolean, default: true }
  },

  emits: ['droped'],

  data: () => ({
    isDragging: false,
    isZoneActive: false,
    dragCounter: 0
  }),

  mounted () {
    window.addEventListener('dragenter', this.enterHandler)
    window.addEventListener('dragover', this.defaultEvent)
    window.addEventListener('dragleave', this.leaveHandler)
    window.addEventListener('drop', this.closeDragging)
  },

  beforeUnmount () {
    window.removeEventListener('dragenter', this.enterHandler)
    window.removeEventListener('dragover', this.defaultEvent)
    window.removeEventListener('dragleave', this.leaveHandler)
    window.removeEventListener('drop', this.closeDragging)
  },

  methods: {
    defaultEvent (event: DragEvent) {
      event.stopPropagation()
      event.preventDefault()
    },

    enterHandler (event: DragEvent) {
      this.defaultEvent(event)
      this.dragCounter += 1
      if (event.dataTransfer !== null) {
        const items = Array.from(event.dataTransfer.items)
        const itemFiles = items.filter(item => {
          return item.kind === 'file'
        })
        if (event.dataTransfer.files.length > 0 || itemFiles.length > 0) {
          this.isDragging = true
        }

        const isSafari = navigator.userAgent.match(/AppleWebKit/) && !navigator.userAgent.match(/Chrome/)
        if (isSafari && !items.length) {
          this.isDragging = true
        }
      }
    },

    leaveHandler (event: DragEvent) {
      event.preventDefault()
      this.dragCounter -= 1
      if (!this.isZoneActive && this.dragCounter === 0) {
        this.isDragging = false
      }
    },

    deactivateZone () {
      this.isZoneActive = false
    },

    closeDragging (event: DragEvent) {
      this.defaultEvent(event)
      this.dragCounter = 0
      this.isDragging = false
    },

    dropHandler (event: DragEvent) {
      const files: File[] = []
      const dataTransfer = event.dataTransfer
      if (dataTransfer !== null) {
        if (dataTransfer.items && dataTransfer.items.length) {
          Array.from(dataTransfer.items).forEach(item => {
            if (item.kind === 'file') {
              const file = item.getAsFile()
              if (file instanceof File) {
                files.push(file)
              }
            }
          })
        } else {
          Array.from(dataTransfer.files).forEach(file => {
            files.push(file)
          })
        }
      }
      this.$emit('droped', files)
      this.deactivateZone()
      this.isDragging = false
    }
  }
})
