
import { defineComponent, computed, ref, provide } from 'vue'
import { ActionBottomRightKey } from './symbol'

export default defineComponent({
  setup () {
    const activeAction = ref<string | undefined>(undefined)
    const setState = (value: string | undefined) => {
      activeAction.value = value
    }

    provide(ActionBottomRightKey, ref({
      activateAction: setState,
      activeAction: computed(() => activeAction.value)
    }))
  }
})
