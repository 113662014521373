import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-711e9162"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-color-picker", { disabled: _ctx.disabled }]),
    style: _normalizeStyle({ backgroundColor: _ctx.color }),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openPicker && _ctx.openPicker(...args)))
  }, [
    _createElementVNode("input", {
      ref: "picker",
      type: "color",
      value: _ctx.color,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerInput && _ctx.handlerInput(...args)))
    }, null, 40, _hoisted_1)
  ], 6))
}