import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50e2ea3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "symbol-polygon-multi" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_icon, {
      type: _ctx.IconType.STATIC
    }, {
      default: _withCtx(() => [
        (['Polygon', 'MultiPolygon'].includes(_ctx.type))
          ? (_openBlock(), _createBlock(_component_inline_svg, {
              key: 0,
              class: "symbol-polygon-multi",
              src: _ctx.imgPoly
            }, null, 8, ["src"]))
          : (['Line', 'LineString', 'MultiLineString'].includes(_ctx.type))
            ? (_openBlock(), _createBlock(_component_inline_svg, {
                key: 1,
                class: "symbol-polygon-multi",
                src: _ctx.imgLine
              }, null, 8, ["src"]))
            : (['Point', 'MultiPoint'].includes(_ctx.type))
              ? (_openBlock(), _createBlock(_component_inline_svg, {
                  key: 2,
                  class: "symbol-polygon-multi",
                  src: _ctx.imgPoint
                }, null, 8, ["src"]))
              : (_ctx.type === 'Raster')
                ? (_openBlock(), _createBlock(_component_inline_svg, {
                    key: 3,
                    class: "symbol-polygon-multi",
                    src: _ctx.imgRaster
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["type"])
  ]))
}