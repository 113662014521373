
import i18n from '@/i18n'
import { defineComponent, inject } from 'vue'

import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseWindow from '@/library/base/BaseWindow.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { OlMapKey } from '../../olMap'
import { ActionTopRightKey } from '../../../actions/symbol'
import { useStore } from '@/library/store'
import { IconType, ButtonType, TextType, SizeType } from '@/library/types/base/enums'
import { MapActiveToolType } from '@/library/types/maps/enums'
import imgLink from '@/library/assets/img/icLink.svg'
import BaseScrollbar from '@/library/base/BaseScrollbar.vue'
import BaseCopy from '@/library/base/BaseCopy.vue'
import toast from '@/library/toast'
import NotificationComponent from '@/library/toast/NotificationComponent.vue'
import { WINDOW_BAR_HEIGHT } from '@/library/helpers'

const store = useStore()

export default defineComponent({
  components: {
    BaseHeader,
    BaseWindow,
    BaseIcon,
    BaseScrollbar,
    BaseCopy
  },

  setup () {
    const ActionTopRight = inject(ActionTopRightKey)
    return {
      ActionTopRight,
      imgLink,
      SizeType,
      IconType,
      TextType,
      ButtonType
    }
  },

  data () {
    return {
      OlMap: inject(OlMapKey),
      link: '',
      contentHeight: 0
    }
  },

  computed: {
    isActive (): boolean {
      if (this.ActionTopRight) {
        return this.ActionTopRight.activeAction === MapActiveToolType.MAP_EXTENT_LINK_CREATOR
      }
      return false
    },

    visibleLayers () {
      return store.state.maps.visibleLayers
    },

    iframe (): string {
      const _iframe = document.createElement('iframe')
      _iframe.src = this.link
      _iframe.width = '100%'
      _iframe.height = '100%'
      return _iframe.outerHTML
    },

    infoBoxHeight (): number {
      return this.contentHeight + WINDOW_BAR_HEIGHT
    }
  },

  methods: {
    $t: i18n.global.t,

    closePanel () {
      if (this.ActionTopRight) {
        this.ActionTopRight.activateAction(null)
      }
    },

    prepareCustomToastComponent (type: string) {
      let headerText = ''
      let subheaderText = ''
      switch (type) {
        case 'extent':
          headerText = this.$t('maps.extent.copyExtentNotificationHeader')
          subheaderText = ''
          break
        case 'iframe':
          headerText = this.$t('maps.extent.copyIframeNotificationHeader')
          subheaderText = ''
          break
      }
      return {
        component: NotificationComponent,
        props: {
          header: headerText,
          subheader: subheaderText
        }
      }
    },

    showNotification (type: string) {
      toast.success(this.prepareCustomToastComponent(type))
    },

    recalcLink () {
      // const mapId = Number(this.$route.params.id)
      if (this.OlMap) {
        const prefix = `${document.location.origin}/viewer?`
        const currentView = this.OlMap.map.getView()
        const center = currentView.getCenter()
        const preparedLayers = this.visibleLayers.map(item => {
          return JSON.stringify(item)
        })
        this.link = prefix + [
          `center=${center ? center.join('~') : ''}`,
          `visibleLayers=${encodeURI(preparedLayers.join('~'))}`,
          `zoom=${currentView.getZoom()}`
        ].join('&')
      }
    },

    handleClick () {
      if (!this.ActionTopRight) {
        return
      }
      if (this.isActive) {
        this.ActionTopRight.activateAction(null)
      } else {
        this.ActionTopRight.activateAction(MapActiveToolType.MAP_EXTENT_LINK_CREATOR)
        this.recalcLink()
        this.$nextTick(() => {
          this.contentHeight = (this.$refs.basePanelRef as HTMLElement)
            .getBoundingClientRect()
            .height
        })
      }
    }
  }
})
