
import { defineComponent } from 'vue'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  components: { InlineSvg },
  props: {
    modelValue: { type: [Array, String, Number, Boolean], required: true },
    value: { type: [Array, String, Boolean, Number], default: true },
    title: { type: String, required: false, default: null },
    trueValue: { type: [String, Number, Boolean], default: true },
    falseValue: { type: [String, Number, Boolean], default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  computed: {
    checked () {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) !== -1
      } else {
        return this.modelValue === this.trueValue
      }
    }
  },
  methods: {
    updateModel () {
      if (this.disabled) {
        return
      }

      if (this.checked) {
        this.$emit('update:modelValue', Array.isArray(this.modelValue) ? this.modelValue.filter(x => x !== this.value) : this.falseValue)
      } else {
        this.$emit('update:modelValue', Array.isArray(this.modelValue) ? [...this.modelValue, this.value] : this.trueValue)
      }
    }
  }
})
