import { MutationTree } from 'vuex'

import {
  GeoMap,
  GeoMapLayer,
  GeoMapLayergroup,
  GroupRenamePayload,
  GroupRefreshInfoPayload,
  MapLayerAlpha,
  ImportTask,
  ExportTask,
  GetSessionData,
  WindowOpenPayload,
  LayerRefreshInfoPayload,
  VisibleLayer,
  MultipleTreeSelect
} from '@/library/types'

import { State } from './state'
import { MapsMutationTypes } from './mutation-types'
import { BaseMapType } from '@/library/types/maps/enums'
import { WindowVariant } from '@/library/types/base/enums'
import { getInitParams, getSortedUniqueLayers } from '@/library/helpers'
import {
  getLayersList,
  findGroup,
  findLayer,
  findParentGroup,
  recursiveRefreshLayer
} from '@/library/helpers/layers'

export type Mutations<S = State> = {
  [MapsMutationTypes.SET_MAP] (state: S, payload: GeoMap | null): void;
  [MapsMutationTypes.SET_MAP_STATE_DEFAULT] (state: S, payload: null | undefined): void;
  [MapsMutationTypes.ADD_MAP] (state: S, payload: GeoMap | null): void;
  [MapsMutationTypes.SET_LIST] (state: S, payload: GeoMap[]): void;
  [MapsMutationTypes.SET_GROUPS_SELECTED] (state: S, payload: number[]): void;
  [MapsMutationTypes.SET_LAYERS_SELECTED] (state: S, payload: number[]): void;
  [MapsMutationTypes.SET_MAP_LAYERS_VISIBLE] (state: S, payload: VisibleLayer[]): void;
  [MapsMutationTypes.SET_MAP_GROUPS_VISIBLE] (state: S, payload: number[]): void;
  [MapsMutationTypes.SET_MAP_GROUPS_ORDER] (state: S, payload: unknown[]): void;
  [MapsMutationTypes.SET_MAP_BASEMAP] (state: S, payload: BaseMapType): void;
  [MapsMutationTypes.ADD_LAYERS] (state: S, payload: GeoMapLayer[]): void;
  [MapsMutationTypes.SET_LAYERS_ALPHA] (state: S, payload: MapLayerAlpha): void;
  [MapsMutationTypes.SET_TASKS] (state: S, payload: (ImportTask | ExportTask)[]): void;
  [MapsMutationTypes.ADD_TASK] (state: S, payload: ImportTask | ExportTask): void;
  [MapsMutationTypes.UPDATE_TASK] (state: S, payload: ImportTask | ExportTask): void;
  [MapsMutationTypes.ADD_EXPORT_TASK_ID] (state: S, payload: number): void;
  [MapsMutationTypes.ADD_GROUP] (state: S, payload: GeoMapLayergroup): void;
  [MapsMutationTypes.SET_ROOT_GROUP] (state: S, payload: GeoMapLayergroup): void;
  [MapsMutationTypes.SET_ROOT_LAYERS] (state: S, payload: GeoMapLayer[]): void;
  [MapsMutationTypes.SET_ROOT_GROUP_ORDER] (state: S, payload: GeoMapLayergroup[]): void;
  [MapsMutationTypes.SET_MAP_SESSION_DATA] (state: S, payload: GetSessionData): void;
  [MapsMutationTypes.UPDATE_MAP_GROUP] (state: S, payload: GeoMapLayergroup): void;
  [MapsMutationTypes.SET_MAP_GROUP_INFO] (state: S, payload: GroupRefreshInfoPayload): void;
  [MapsMutationTypes.SET_MAP_LAYER_NAME] (state: S, payload: GroupRenamePayload): void;
  [MapsMutationTypes.MAP_GROUP_DELETE] (state: S, payload: number): void;
  [MapsMutationTypes.SET_LAYER] (state: S, payload: GeoMapLayer): void;
  [MapsMutationTypes.SET_MAP_LAYER_EXPANDED] (state: S, payload: number[]): void;
  [MapsMutationTypes.SET_WINDOW_OPEN_STATE] (state: S, payload: WindowOpenPayload): void;
  [MapsMutationTypes.SET_MULTIPLE_TREE_SELECT] (state: S, payload: MultipleTreeSelect): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MapsMutationTypes.SET_MAP] (state: State, map: GeoMap | null) {
    state.map = map
    if (map?.groups) {
      state.cachedRootStructure = Object.assign({}, map.groups)
    }
  },
  [MapsMutationTypes.SET_MAP_STATE_DEFAULT] (state: State) {
    state.multipleTreeSelect.isEnable = false
    state.selectedGroups = []
    state.selectedLayers = []
    state.visibleGroups = []
    state.visibleLayers = []
    state.layersOpacity = {}
    state.savedSession = undefined
    state.exportTasksIds = []
    state.groupEditId = undefined
    for (const windowKey in state.openedWindows) {
      state.openedWindows[windowKey as WindowVariant] = false
    }
  },
  [MapsMutationTypes.ADD_MAP] (state: State, map: GeoMap | null) {
    if (map) {
      if (state.list) {
        state.list.push(map)
      } else {
        state.list = [map]
      }
    }
  },
  [MapsMutationTypes.SET_LIST] (state: State, maps: GeoMap[]) {
    state.list = maps
  },
  [MapsMutationTypes.SET_MAP_LAYERS_VISIBLE] (state: State, layers: VisibleLayer[]) {
    state.visibleLayers = getSortedUniqueLayers(layers, state.map?.groups)
  },
  [MapsMutationTypes.SET_TASKS] (state: State, tasks: (ImportTask | ExportTask)[]) {
    state.tasks = tasks
  },
  [MapsMutationTypes.ADD_TASK] (state: State, task: ImportTask | ExportTask) {
    state.tasks = [task, ...state.tasks]
  },
  [MapsMutationTypes.UPDATE_TASK] (state: State, task: ImportTask | ExportTask) {
    const taskIndex = state.tasks.findIndex(x => x.id === task.id)
    if (taskIndex === -1) {
      return
    }
    state.tasks.splice(taskIndex, 1, task)
  },
  [MapsMutationTypes.ADD_EXPORT_TASK_ID] (state: State, task: number) {
    state.exportTasksIds.push(task)
  },
  [MapsMutationTypes.SET_MAP_GROUPS_VISIBLE] (state: State, ids: number[]) {
    state.visibleGroups = Array.from(new Set(ids))
  },
  [MapsMutationTypes.SET_MAP_GROUPS_ORDER] (state: State, groups: GeoMapLayergroup[]) {
    if (state.map) {
      state.map.groups = groups[0]
    }
  },

  [MapsMutationTypes.SET_LAYERS_ALPHA] (state: State, payload: MapLayerAlpha) {
    state.layersOpacity[payload.layerId] = payload.layerAlpha
  },
  [MapsMutationTypes.SET_MAP_BASEMAP] (state: State, basemap: BaseMapType) {
    state.basemap = basemap
  },
  [MapsMutationTypes.ADD_LAYERS] (state: State, layers: GeoMapLayer[]) {
    if (state.map) {
      const loadedLayers = state.map.groups.layers.map(layer => layer.id)
      layers.forEach(newLayer => {
        if (!loadedLayers.includes(newLayer.id)) {
          (state.map as GeoMap).groups.layers.push(newLayer)
        }
      })
    }
  },
  [MapsMutationTypes.ADD_GROUP] (state: State, newGroup: GeoMapLayergroup) {
    if (state.map) {
      if (!Array.isArray(newGroup.groups)) {
        newGroup.groups = []
      }
      if (!Array.isArray(newGroup.layers)) {
        newGroup.layers = []
      }
      state.map.groups.groups.push(newGroup)
      state.groupEditId = newGroup.id
    }
  },
  [MapsMutationTypes.SET_ROOT_GROUP] (state: State, rootGroup: GeoMapLayergroup) {
    if (state.map) {
      state.map.groups = rootGroup
      state.cachedRootStructure = Object.assign({}, rootGroup)
    }
  },
  [MapsMutationTypes.SET_ROOT_LAYERS] (state: State, rootLayers: GeoMapLayer[]) {
    if (state.map) {
      state.map.groups.layers = rootLayers
    }
  },
  [MapsMutationTypes.SET_ROOT_GROUP_ORDER] (state: State, rootGroup: GeoMapLayergroup[]) {
    if (state.map) {
      state.map.groups.groups = rootGroup
    }
  },
  [MapsMutationTypes.SET_MAP_SESSION_DATA] (state: State, data: GetSessionData) {
    if (!state.map) {
      return
    }
    state.savedSession = data
    const initParams = getInitParams()
    if (Array.isArray(initParams.visibleLayers)) {
      state.visibleLayers = initParams.visibleLayers
    } /* else {
      Moved to Map2D/Map3D cause 2way session
      state.visibleLayers = data.visibleLayers
      state.visibleGroups = Array.from(new Set(data.visibleGroupsIds))
    } */
  },
  [MapsMutationTypes.UPDATE_MAP_GROUP] (state: State, group: GeoMapLayergroup) {
    if (!state.map) {
      return
    }

    if (state.map.groups.id === group.id) {
      state.map.groups = group
      return
    }

    const sGroup = findGroup(state.map.groups, group.id)

    if (!sGroup) {
      return
    }

    sGroup.groups = group.groups
    sGroup.layers = group.layers
  },
  [MapsMutationTypes.SET_MAP_GROUP_INFO] (state: State, payload: GroupRefreshInfoPayload) {
    state.groupEditId = undefined

    if (!state.map) {
      return
    }
    const sGroup = findGroup(state.map.groups, payload.itemId)

    if (!sGroup) {
      return
    }

    if (payload.newName) {
      sGroup.name = payload.newName
    }
    if (payload.colorCode) {
      sGroup.colorCode = payload.colorCode
    }
  },
  [MapsMutationTypes.SET_MAP_LAYER_NAME] (state: State, payload: LayerRefreshInfoPayload) {
    if (!state.map) {
      return
    }
    const layers = getLayersList(state.map.groups)

    const sLayer = findLayer(layers, payload.itemId)

    if (!sLayer) {
      return
    }

    if (payload.newName) {
      sLayer.name = payload.newName
    }
    if (payload.url) {
      sLayer.url = payload.url
    }
  },
  [MapsMutationTypes.MAP_GROUP_DELETE] (state: State, groupId: number) {
    if (!state.map) {
      return
    }
    const sGroup = findParentGroup(state.map.groups, groupId)
    if (!sGroup) {
      return
    }

    sGroup.groups = sGroup.groups.filter(x => x.id !== groupId)
  },

  [MapsMutationTypes.SET_LAYER] (state: State, newLayer: GeoMapLayer) {
    if (state.map) {
      recursiveRefreshLayer(state.map.groups, newLayer)
    }
  },

  [MapsMutationTypes.SET_MAP_LAYER_EXPANDED] (state: State, expandedLayers: number[]) {
    state.expandedLayers = expandedLayers
  },

  [MapsMutationTypes.SET_WINDOW_OPEN_STATE] (state: State, payload: WindowOpenPayload) {
    state.openedWindows[payload.id] = payload.state
  },

  [MapsMutationTypes.SET_GROUPS_SELECTED] (state: State, newItems: number[]) {
    state.selectedGroups = Array.from(new Set(newItems))
  },

  [MapsMutationTypes.SET_LAYERS_SELECTED] (state: State, newItems: number[]) {
    state.selectedLayers = Array.from(new Set(newItems))
  },

  [MapsMutationTypes.SET_MULTIPLE_TREE_SELECT] (state: State, data: MultipleTreeSelect) {
    state.multipleTreeSelect = data
    state.selectedLayers = Array.from(new Set([]))
    state.selectedGroups = Array.from(new Set([]))
    if (data.isEnable) {
      state.openedWindows[WindowVariant.OPERATIONS_HISTORY] = false
    }
  }
}
