import { Ref } from 'vue'

export function useInput (
  filter: RegExp,
  maxlength: number,
  inputRef: Ref<HTMLElement | undefined>,
  emit: {
    (e: 'update:modelValue', value: string | number): void,
    (e: 'overloadedInput'): void,
    (e: 'enterWrongCharacter'): void,
  }) {
  const input = (e: Event) => {
    const value = (e.target as HTMLInputElement).value
    let clearValue = value.replace(filter, '')
    if (value.length !== clearValue.length) {
      emit('enterWrongCharacter')
    }

    if (inputRef.value && clearValue.length > maxlength) {
      clearValue = clearValue.slice(0, maxlength)
      emit('overloadedInput')
    }

    (inputRef.value as HTMLInputElement).value = clearValue
    emit('update:modelValue', clearValue)
  }

  return input
}
