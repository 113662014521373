
import { WmsLegendRuleSymbolizerLine } from '@/library/types/maps/wmsLegends'
import { defineComponent, PropType } from 'vue'
import InlineSvg from 'vue-inline-svg'
import imgLine from '@/library/assets/img/maps/symbols/line.svg'

const __default__ = defineComponent({
  components: { InlineSvg },
  props: {
    line: { type: Object as PropType<WmsLegendRuleSymbolizerLine>, required: true }
  },
  data () {
    return {
      imgLine
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "755b4b09": (_ctx.line.stroke),
  "3606c560": (_ctx.line["stroke-width"]),
  "11ce0295": (_ctx.line["stroke-opacity"]),
  "2cea1c5e": (_ctx.line["stroke-dasharray"])
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__