import { vModelText as _vModelText, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c79b36d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-input" }
const _hoisted_2 = ["placeholder", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "elInput",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      placeholder: _ctx.placeholder,
      class: "search-input__input search",
      readonly: _ctx.disabled
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.innerValue],
      [_directive_tooltip, _ctx.innerValue]
    ]),
    (!_ctx.innerValue.length)
      ? (_openBlock(), _createBlock(_component_base_icon, {
          key: 0,
          class: "search-input__icon",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.innerValue = ''), ["stop"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_inline_svg, {
              src: require('@/assets/svg/search.svg')
            }, null, 8, ["src"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_base_icon, {
          key: 1,
          class: "search-input__icon",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.innerValue = ''), ["stop"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_inline_svg, {
              src: require('@/assets/svg/cross.svg')
            }, null, 8, ["src"])
          ]),
          _: 1
        }))
  ]))
}