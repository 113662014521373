import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebeee0ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["base-button", [_ctx.type, _ctx.size]]),
    disabled: _ctx.disabled
  }, [
    (_ctx.submit)
      ? (_openBlock(), _createBlock(_component_base_loading, {
          key: 0,
          class: "base-button__submit"
        }))
      : _createCommentVNode("", true),
    (!_ctx.submit)
      ? _renderSlot(_ctx.$slots, "icon", { key: 1 }, undefined, true)
      : _createCommentVNode("", true),
    (!_ctx.submit)
      ? _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}