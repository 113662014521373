import {
  GeometryStyleOptions,
  Symbolizer,
  MapBoxExpression,
  MapBoxStyle,
  LayerFilterItem,
  ExpressionCondition,
  AttributeInfo
} from '@/library/types'
import { FeatureType } from '../types/maps/enums'
import { MapBoxLayerType, ComplexStyleType } from '../types/styles_editor/enums'

const _prepareStrokeWidth = (value?: number | MapBoxExpression, maxSize = 20): number => {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    const countOfSteps = maxSize / 5
    return Math.min(Math.ceil(value / countOfSteps), 5)
  }
  return 1
}

const _prepareStrokeDasharray = (dasharray?: string, width?: number | MapBoxExpression): string => {
  let parsedWidth: number | undefined
  if (width !== undefined) {
    parsedWidth = _prepareStrokeWidth(width)
  }
  switch (dasharray) {
    case '0 2':
      return parsedWidth ? `0 ${Math.max(4, parsedWidth * 1.1)}` : '0 4'
    case '2 1':
      return parsedWidth ? `4 ${Math.max(4, parsedWidth * 1.25)}` : '3 3'
  }
  return dasharray || ''
}

const _prepareOpacity = (value?: number): string => {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return String(value)
  }
  return '1'
}

const _prepareRadius = (value?: number): string => {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    const koef = 7 / 20
    return String(value * koef)
  }
  return '1'
}

export const prepareGeometryStyle = (
  geometryType: FeatureType, options: GeometryStyleOptions
): Symbolizer | undefined => {
  let style: Symbolizer | undefined
  switch (geometryType) {
    case FeatureType.POLYGON:
    case FeatureType.MULTI_POLYGON:
      style = {
        Polygon: {
          fill: options.fill ? String(options.fill) : '',
          stroke: options.stroke ? String(options.stroke) : '',
          'fill-opacity': _prepareOpacity(options.fillOpacity),
          'stroke-opacity': _prepareOpacity(options.strokeOpacity),
          'stroke-dasharray': _prepareStrokeDasharray(options.strokeDasharray),
          'stroke-width': _prepareStrokeWidth(options.strokeWidth) + 'px'
        }
      }
      break
    case FeatureType.POINT:
    case FeatureType.MULTI_POINT:
      style = {
        Point: {
          graphics: [{
            fill: options.fill ? String(options.fill) : '',
            stroke: options.stroke ? String(options.stroke) : '',
            radius: _prepareRadius(options.radius),
            icon: options.icon ? String(options.icon) : '',
            'fill-opacity': _prepareOpacity(options.fillOpacity),
            'stroke-opacity': _prepareOpacity(options.strokeOpacity),
            'stroke-width': _prepareStrokeWidth(options.strokeWidth) + 'px'
          }]
        }
      }
      break
    case FeatureType.LINE_STRING:
    case FeatureType.MULTI_LINE_STRING:
      style = {
        Line: {
          stroke: options.stroke ? String(options.stroke) : '',
          'stroke-opacity': _prepareOpacity(options.strokeOpacity),
          'stroke-dasharray': _prepareStrokeDasharray(options.strokeDasharray, options.strokeWidth),
          'stroke-width': _prepareStrokeWidth(options.strokeWidth) + 'px'
        }
      }
      break
  }
  return style
}

export const getGeometrySymbolOptions = (style?: MapBoxStyle): GeometryStyleOptions => {
  const options: GeometryStyleOptions = {}
  if (style && Array.isArray(style.layers) && style.layers.length) {
    style.layers.forEach(styleLayer => {
      if (styleLayer.paint) {
        switch (styleLayer.type) {
          case MapBoxLayerType.FILL:
            options.fill = styleLayer.paint['fill-color']
            options.fillOpacity = styleLayer.paint['fill-opacity']
            break
          case MapBoxLayerType.CIRCLE:
            options.fill = styleLayer.paint['circle-color']
            options.radius = styleLayer.paint['circle-radius']
            options.stroke = styleLayer.paint['circle-stroke-color']
            options.fillOpacity = styleLayer.paint['circle-opacity']
            options.strokeOpacity = styleLayer.paint['circle-stroke-opacity']
            options.strokeWidth = styleLayer.paint['circle-stroke-width']
            break
          case MapBoxLayerType.LINE:
            options.stroke = styleLayer.paint['line-color']
            options.strokeOpacity = styleLayer.paint['line-opacity']
            options.strokeWidth = styleLayer.paint['line-width']
            options.strokeDasharray = styleLayer.paint['line-dasharray']?.join(' ') || ''
            break
        }
      }
      if (
        styleLayer.layout &&
        styleLayer.type === MapBoxLayerType.SYMBOL &&
        styleLayer.id === MapBoxLayerType.SYMBOL &&
        styleLayer.layout['icon-image'] &&
        String(styleLayer.layout['icon-image']) !== 'undefined'
      ) {
        options.icon = styleLayer.layout['icon-image']
        const radius = styleLayer.__custom__?.iconSize
        if (radius && !Number.isNaN(radius)) {
          options.radius = radius as number
        }
      }
    })
  }
  return options
}

const getVisibleState = (attributeValue: string, needAttributesParse: boolean, filter?: LayerFilterItem[]) => {
  if (!filter) return true
  if (needAttributesParse) {
    return filter.some(queryObject => String(queryObject.value) === String(attributeValue))
  }
  return false
}

export const applyComplexStyle = (
  type: ComplexStyleType, isLegend: boolean, featureType: FeatureType,
  options: GeometryStyleOptions, attributes: Record<string, AttributeInfo> | Record<string, Symbolizer>,
  filter?: LayerFilterItem[], isFullEnabled = false
): void => {
  let values: MapBoxExpression = []
  switch (type) {
    case ComplexStyleType.FILL:
      values = options.fill as MapBoxExpression
      break
    case ComplexStyleType.STROKE:
      values = options.stroke as MapBoxExpression
      break
    case ComplexStyleType.ICON:
      values = options.icon as MapBoxExpression
      break
  }
  const startPosition = values[0] === 'case' ? 1 : 2
  const stepMode = values[0] === 'step'
  const needAttributesParse = Array.isArray(filter) && isFullEnabled
  for (let i = startPosition; i < values.length - 2; i += 2) {
    const colorValue = stepMode ? String(values[i + 2]) : String(values[i + 1])
    let attributeValue: string
    const condition = (stepMode ? values[i + 1] : values[i]) as ExpressionCondition
    if (Array.isArray(condition) && condition.length > 1 && condition[1].length === 3) {
      attributeValue = String(condition[1][2])
    } else {
      attributeValue = String(stepMode ? values[i + 1] : values[i])
    }
    let preparedOptions: GeometryStyleOptions = {}
    switch (type) {
      case ComplexStyleType.FILL:
        preparedOptions = {
          fill: colorValue,
          stroke: options.stroke,
          fillOpacity: options.fillOpacity,
          radius: options.radius,
          strokeOpacity: options.strokeOpacity,
          strokeDasharray: options.strokeDasharray,
          strokeWidth: options.strokeWidth
        }
        break
      case ComplexStyleType.STROKE:
        preparedOptions = {
          stroke: colorValue,
          radius: options.radius,
          strokeOpacity: options.strokeOpacity,
          strokeDasharray: options.strokeDasharray,
          strokeWidth: options.strokeWidth
        }
        break
      case ComplexStyleType.ICON:
        preparedOptions = {
          icon: colorValue
        }
        break
    }
    const style = prepareGeometryStyle(featureType, preparedOptions)
    if (isLegend && filter?.length && !getVisibleState(attributeValue, true, filter)) {
      continue
    } else if (!attributes[attributeValue]) {
      const visibleState = getVisibleState(attributeValue, needAttributesParse, filter)
      attributes[attributeValue] = {
        style: {},
        visible: isFullEnabled && visibleState
      }
    }
    if (style) {
      if (isLegend) {
        attributes[attributeValue] = style
      } else {
        attributes[attributeValue].style = style
      }
    }
  }
}

export const sortLegend = (items: [string, Symbolizer][] | AttributeInfo[]): void => {
  items.sort((left: [string, Symbolizer] | AttributeInfo, right: [string, Symbolizer] | AttributeInfo) => {
    const leftNumber = Array.isArray(left) ? Number(left[0]) : Number(left.attribute)
    const rightNumber = Array.isArray(right) ? Number(right[0]) : Number(right.attribute)
    if (!Number.isNaN(leftNumber) && !Number.isNaN(rightNumber)) {
      return leftNumber - rightNumber
    } else {
      const [leftAttr, rightAttr] = [
        Array.isArray(left) ? String(left[0]) : String(left.attribute),
        Array.isArray(right) ? String(right[0]) : String(right.attribute)
      ]
      if (leftAttr > rightAttr) {
        return 1
      } else if (leftAttr < rightAttr) {
        return -1
      }
      return 0
    }
  })
}
