
import { defineComponent } from 'vue'
import InlineSvg from 'vue-inline-svg'
import imgCheck from '@/library/assets/img/check.svg'
import RadioStill from '@/assets/svg/radio_still.svg'
import RadioFull from '@/assets/svg/radio_full.svg'

export default defineComponent({
  components: { InlineSvg },
  props: {
    modelValue: { type: [String, Boolean, Number], required: true },
    value: { type: [String, Boolean, Number], required: true },
    label: { type: String, required: true }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      imgCheck,
      RadioStill,
      RadioFull
    }
  },
  computed: {
    checked () {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateModel () {
      this.$emit('update:modelValue', this.value)
    }
  }
})
