import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "base-single-select-list" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PerfectScrollbar = _resolveComponent("PerfectScrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PerfectScrollbar, { options: {wheelSpeed: 0.5, swipeEasing: true, wheelPropagation: false, minScrollbarLength: 20 } }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["base-single-select-list__item", {'active': _ctx.modelValue === item.value }]),
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
          }, _toDisplayString(item.name), 11, _hoisted_2))
        }), 128))
      ]),
      _: 1
    }, 8, ["options"])
  ]))
}