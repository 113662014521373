import chroma from 'chroma-js'

import {
  MapBoxStyleSymbolLayout,
  MapBoxStyleSymbolPaint,
  MapBoxStyleCirclePaint,
  MapBoxStyleFillPaint,
  MapBoxStyleLinePaint,
  ExpressionCondition,
  MapBoxStyleLayer,
  MapBoxExpression,
  StyleCacheInfo,
  MapBoxStyle,
  ColorField
} from '@/library/types'
import {
  isStrictTransparent,
  MIN_ALLOWABLE_ZOOM,
  MAX_ALLOWABLE_ZOOM
} from '@/library/helpers'
import { fontFamilyOptions, fontStyleOptions } from './options'
import { NON_STRICT_COMPARISON } from './main'
import { MapBoxLayerType, PointSymbolType } from '@/library/types/styles_editor/enums'

type ParserOptions = {
  figureType?: 'line' | 'polygon' | 'point'
  attributes: string[]
}

type ParserResult = {
  styles: StyleCacheInfo,
  signatures: Record<string, StyleCacheInfo>
  parsedAttributeValues: string[]
}

export class MapBoxStyleParser {
  private figureType: 'line' | 'polygon' | 'point' | undefined = undefined
  private styles: StyleCacheInfo = {}
  private attributeOptions: string[] = []
  private parsedAttributeValues: string[] = []
  private signatures: Record<string, StyleCacheInfo> = {}

  private _clearCache () {
    this.figureType = undefined
    this.styles = {}
    this.attributeOptions = []
    this.parsedAttributeValues = []
    this.signatures = {}
  }

  private _parseSymbolTranslate (signature: StyleCacheInfo, translateValue?: [number, number]) {
    if (Array.isArray(translateValue) && translateValue.length === 2) {
      signature.signatureRelativeX = Number(translateValue[0])
      signature.signatureRelativeY = Number(translateValue[1])
    }
  }

  private _parseStringProperty (signature: StyleCacheInfo, property: string, stringValue?: string) {
    if (typeof stringValue === 'string') {
      signature[property] = stringValue
    }
  }

  private _parseColorProperty (signature: StyleCacheInfo, property: string, colorValue?: string | ColorField) {
    if (typeof colorValue === 'string' && chroma.valid(colorValue)) {
      signature[property] = colorValue
    }
  }

  private _parseSymbolColor (signature: StyleCacheInfo, colorValue?: string) {
    if (typeof colorValue === 'string' && chroma.valid(colorValue)) {
      const parsedAlpha = Number(chroma(colorValue).alpha())
      const parsedColor = String(chroma(colorValue).hex())
      signature.signatureOutlineColor = parsedColor
      signature.signatureOutlineOpacity = !Number.isNaN(parsedAlpha) ? (parsedAlpha * 100) : 100
    }
  }

  private _parseNumberProperty (signature: StyleCacheInfo, property: string, numberValue?: number | MapBoxExpression) {
    numberValue = Number(numberValue)
    if (!Number.isNaN(numberValue)) {
      signature[property] = numberValue
    }
  }

  private _parseSignaturePaint (paint: MapBoxStyleSymbolPaint, signature: StyleCacheInfo) {
    this._parseSymbolColor(signature, paint['text-halo-color'])
    this._parseNumberProperty(signature, 'signatureOutlineSize', paint['text-halo-width'])
    this._parseStringProperty(signature, 'signatureFontColor', paint['text-color'])
    this._parseOpacity(signature, 'signatureFontColorOpacity', paint['text-opacity'])
    this._parseSymbolTranslate(signature, paint['text-translate'])
    this._parseStringProperty(signature, 'signatureFontColor', paint['icon-color'])
    this._parseOpacity(signature, 'signatureFontColorOpacity', paint['icon-opacity'])
    this._parseSymbolTranslate(signature, paint['icon-translate'])
  }

  private _parseOpacity (info: StyleCacheInfo, property: string, value?: number) {
    value = Number(value)
    if (!Number.isNaN(value) && value > 0 && value < 1) {
      info[property] = Math.round(value * 100)
    } else {
      info[property] = 100
    }
  }

  private _parseSymbolTextFont (signature: StyleCacheInfo, fontValue?: string | string[]) {
    if (Array.isArray(fontValue) && fontValue.length) {
      const fontParts = fontValue[0].split(' ')
      if (fontParts.length > 1) {
        const parsedStyle = fontParts.pop() as string
        const parsedFamily = fontParts.join(' ')
        const existFamily = fontFamilyOptions.find(item => {
          return item.value.toLowerCase() === parsedFamily?.toLowerCase()
        })
        const existStyle = fontStyleOptions.find(item => {
          return item.key.toLowerCase() === parsedStyle?.toLowerCase()
        })
        if (existFamily && existStyle) {
          signature.signatureFontFamily = existFamily?.key
          signature.signatureFontStyle = existStyle?.key
        }
      }
    }
  }

  private _parseSymbolTextField (signature: StyleCacheInfo, fieldValue?: string | Array<string| string[]>) {
    if (fieldValue) {
      let existAttribute: string | undefined = this.attributeOptions[0]
      if (Array.isArray(fieldValue) && fieldValue.length > 1) {
        existAttribute = this.attributeOptions.find(option => {
          return option === (fieldValue as string[])[1]
        })
      } else if (typeof fieldValue === 'string') {
        const parsedValue = fieldValue.split('{')[1].split('}')[0]
        existAttribute = this.attributeOptions.find(option => {
          return option === parsedValue
        })
      }
      if (existAttribute) {
        signature.signatureAttribute = existAttribute
      }
    }
  }

  private _parseSignatureLayout (layout: MapBoxStyleSymbolLayout, signature: StyleCacheInfo) {
    this._parseSymbolTextFont(signature, layout['text-font'])
    this._parseSymbolTextField(signature, layout['text-field'])
    this._parseNumberProperty(signature, 'signatureFontSize', layout['text-size'])
    this._parseNumberProperty(signature, 'signatureRotateAngle', layout['text-rotate'])
    this._parseStringProperty(signature, 'signatureAlignment', layout['text-anchor'])
    if (layout['text-max-width'] && !Number.isNaN(layout['text-max-width'])) {
      signature.signatureSymbolsCount = Math.round(layout['text-max-width'] * 16)
    }
    this._parseStringProperty(signature, 'signatureAlignment', layout['icon-anchor'])
    this._parseNumberProperty(signature, 'signatureRotateAngle', layout['icon-rotate'])
    this.styles.showSignature = layout.visibility === 'visible'
  }

  private _parseSignature (key: string, layer: MapBoxStyleLayer) {
    const paint = layer.paint as MapBoxStyleSymbolPaint
    const layout = layer.layout as MapBoxStyleSymbolLayout
    this.signatures[key] = {}
    if (paint) {
      this._parseSignaturePaint(paint, this.signatures[key])
    }
    if (layout) {
      this._parseSignatureLayout(layout, this.signatures[key])
    }
    if (layer.__custom__) {
      this._parseStringProperty(this.signatures[key], 'signatureLinePosition', layer.__custom__.linePosition as string)
      this._parseNumberProperty(this.signatures[key], 'signatureLineOffset', layer.__custom__.lineOffset as number)
      this._parseNumberProperty(this.signatures[key], 'signatureLineRepeatInterval', layer.__custom__.lineRepeatInterval as number)
      this.signatures[key].signatureNeedRepeat = Boolean(layer.__custom__.needRepeat)
      this.signatures[key].signatureConflictResolution = Boolean(layer.__custom__.conflictResolution)
    }
  }

  private _setExpressionAttribute (item: MapBoxExpression) {
    let attributeName: string
    const condition = (item[1] as ExpressionCondition)
    if (Array.isArray(condition) && condition.length > 1 && condition[1].length === 2) {
      attributeName = String(condition[1][1])
    } else {
      attributeName = String(condition)
    }
    const filteredAttribute = this.attributeOptions.find(option => {
      return option === attributeName
    })
    if (filteredAttribute) {
      this.styles.attribute = filteredAttribute
    } else if (this.attributeOptions.length) {
      this.styles.attribute = this.attributeOptions[0]
    }
  }

  private _parseStopColors (
    items: MapBoxExpression, gradientProperty: string, stopColors?: string[]
  ) {
    const preparedColorStops: string[] = []
    items.forEach(item => {
      if (typeof item === 'string' && chroma.valid(item)) {
        preparedColorStops.push(item)
      } else if (Array.isArray(item) && item.length === 2) {
        this._setExpressionAttribute(item as MapBoxExpression)
      }
    })
    const colors = preparedColorStops.slice()
    if (String(items[0]) === 'step') {
      this.parsedAttributeValues = [...colors.slice(1), colors[0]]
    } else {
      this.parsedAttributeValues = colors
    }
    if (Array.isArray(stopColors) && stopColors.length > 1) {
      this.styles[gradientProperty] = stopColors
    } else {
      this.styles[gradientProperty] = chroma.scale(this.parsedAttributeValues).colors(12)
    }
  }

  private _parseFillLayer (layer: MapBoxStyleLayer) {
    const paint = layer.paint as MapBoxStyleFillPaint
    if (paint && paint['fill-color']) {
      const fillOpacity = Number(paint['fill-opacity'])
      this._parseOpacity(this.styles, 'polygonFillOpacity', fillOpacity)
      if (Array.isArray(paint['fill-color'])) {
        this._parseStopColors(
          paint['fill-color'], 'fillGradient'
        )
        this.styles.styleFormat = 'editable'
      } else if (isStrictTransparent(paint['fill-color'], fillOpacity)) {
        this.styles.polygonFillOpacity = 100
        this.styles.fillColor = 'transparent'
      } else {
        this.styles.styleFormat = 'simple'
        this._parseColorProperty(this.styles, 'fillColor', paint['fill-color'])
      }
    }
  }

  private _parseStopIcons (items: MapBoxExpression) {
    const preparedIcons: string[] = []
    items.forEach(item => {
      if (typeof item === 'string' && item.startsWith('https://')) {
        preparedIcons.push(item)
      } else if (Array.isArray(item) && item.length === 2) {
        this._setExpressionAttribute(item as MapBoxExpression)
      }
    })
    const icons = Array.from(preparedIcons)
    if (String(items[0]) === 'step') {
      this.parsedAttributeValues = [...icons.slice(1), icons[0]]
    } else {
      this.parsedAttributeValues = icons
    }
  }

  private _parseSymbolLayer (layer: MapBoxStyleLayer) {
    if (layer.id.startsWith('signature')) {
      const order = Number(layer.id[layer.id.length - 1])
      if (!Number.isNaN(order)) {
        this._parseSignature(String(order + 1), layer)
      }
    } else {
      if (layer?.__custom__?.iconSize) {
        this.styles.pointSize = layer.__custom__.iconSize
      }
      const layout = layer.layout as MapBoxStyleSymbolLayout
      if (layout && layout['icon-image'] && String(layout['icon-image']) !== 'undefined') {
        if (Array.isArray(layout['icon-image']) && layout['icon-image'].length) {
          this._parseStopIcons(layout['icon-image'])
          this.styles.styleFormat = 'editable'
        }
        this.styles.pointSvgIcon = layout['icon-image']
        this.styles.pointSymbolType = PointSymbolType.SVG
      }
    }
  }

  private _parseStopSizes (items: MapBoxExpression, gradientProperty: string) {
    const preparedSizeStops: Set<number> = new Set()
    items.forEach((item, index) => {
      const convertedItem = Number(item)
      if (!Number.isNaN(convertedItem) && index % 2 !== 0) {
        preparedSizeStops.add(convertedItem)
      } else if (Array.isArray(item) && item.length === 2) {
        this._setExpressionAttribute(item as MapBoxExpression)
      }
    })
    const resultSizeList = Array.from(preparedSizeStops)
    if (resultSizeList.length) {
      this.styles[gradientProperty] = {
        startValue: Math.floor(Math.min(...resultSizeList)),
        endValue: Math.ceil(Math.max(...resultSizeList))
      }
    }
  }

  private _parseLineLayer (layer: MapBoxStyleLayer) {
    const paint = layer.paint as MapBoxStyleLinePaint
    if (paint) {
      const lineDasharray = paint['line-dasharray']
      const lineOpacity = Number(paint['line-opacity'])
      if (this.figureType === 'polygon') {
        const lineWidth = Number(paint['line-width'])
        if (Array.isArray(lineDasharray) && lineDasharray.length > 1) {
          this.styles.polygonStrokeType = 'dashed'
        } else {
          this.styles.polygonStrokeType = 'solid'
        }
        this._parseColorProperty(this.styles, 'polygonStrokeColor', paint['line-color'])
        this._parseOpacity(this.styles, 'polygonStrokeOpacity', lineOpacity)
        this._parseNumberProperty(this.styles, 'polygonStrokeWidth', lineWidth)
      } else if (this.figureType === 'line') {
        if (Array.isArray(lineDasharray)) {
          if (lineDasharray[0] === 0) {
            this.styles.lineType = 'dotted'
          } else {
            this.styles.lineType = 'dashed'
          }
        } else {
          this.styles.strokeType = 'solid'
        }
        if (paint['line-color']) {
          if (Array.isArray(paint['line-color'])) {
            this._parseStopColors(
              paint['line-color'], 'fillGradient'
            )
            this.styles.styleFormat = 'editable'
          } else {
            this._parseColorProperty(this.styles, 'fillColor', paint['line-color'])
          }
        }
        this._parseOpacity(this.styles, 'lineOpacity', lineOpacity)
        if (paint['line-width']) {
          if (Array.isArray(paint['line-width'])) {
            this._parseStopSizes(paint['line-width'], 'lineWeight')
            this.styles.styleFormat = 'editable'
            this.styles.lineWeightType = 'byValue'
          } else {
            this._parseNumberProperty(this.styles, 'lineWeight', Number(paint['line-width']))
          }
        }
      }
    }
  }

  private _parseCircleLayer (layer: MapBoxStyleLayer) {
    const paint = layer.paint as MapBoxStyleCirclePaint
    if (paint) {
      this._parseNumberProperty(this.styles, 'pointStrokeWidth', paint['circle-stroke-width'])
      this._parseNumberProperty(this.styles, 'pointSize', paint['circle-radius'] ? (paint['circle-radius'] * 2) : undefined)
      this._parseOpacity(this.styles, 'pointStrokeOpacity', paint['circle-stroke-opacity'])
      this._parseStringProperty(this.styles, 'pointStrokeColor', paint['circle-stroke-color'])
      if (paint['circle-color']) {
        const fillOpacity = Number(paint['circle-opacity'])
        this._parseOpacity(this.styles, 'pointFillOpacity', fillOpacity)
        if (Array.isArray(paint['circle-color'])) {
          this._parseStopColors(
            paint['circle-color'], 'fillGradient'
          )
          this.styles.styleFormat = 'editable'
        } else if (isStrictTransparent(paint['circle-color'], fillOpacity)) {
          this.styles.pointFillOpacity = 100
          this.styles.fillColor = 'transparent'
        } else {
          this.styles.styleFormat = 'simple'
          this.styles.fillColor = paint['circle-color']
        }
      }
    }
  }

  private _updateZoom (layer: MapBoxStyleLayer) {
    const minzoom = Number(layer.minzoom)
    const maxzoom = Number(layer.maxzoom) - NON_STRICT_COMPARISON
    if (!Number.isNaN(minzoom) && !Number.isNaN(maxzoom)) {
      const scaleValue = {
        startValue: (minzoom !== MIN_ALLOWABLE_ZOOM && minzoom < MAX_ALLOWABLE_ZOOM - 1) ? minzoom + 1 : minzoom,
        endValue: maxzoom < MAX_ALLOWABLE_ZOOM ? maxzoom + 1 : maxzoom
      }
      if (layer.id.startsWith('signature')) {
        const order = Number(layer.id[layer.id.length - 1])
        if (!Number.isNaN(order)) {
          const signature = this.signatures[String(order + 1)]
          signature.signatureScale = scaleValue
        }
      } else {
        if (scaleValue.endValue > MAX_ALLOWABLE_ZOOM) scaleValue.endValue = MAX_ALLOWABLE_ZOOM
        this.styles.scale = scaleValue
      }
    }
  }

  private _parseMapboxLayer (layer: MapBoxStyleLayer) {
    switch (layer.type) {
      case MapBoxLayerType.FILL:
        this._parseFillLayer(layer)
        break
      case MapBoxLayerType.LINE:
        this._parseLineLayer(layer)
        break
      case MapBoxLayerType.CIRCLE:
        this._parseCircleLayer(layer)
        break
      case MapBoxLayerType.SYMBOL:
        this._parseSymbolLayer(layer)
        break
    }
    this._updateZoom(layer)
  }

  public parseStyle (style: MapBoxStyle, options: ParserOptions): ParserResult {
    this._clearCache()
    if (options.figureType) {
      this.figureType = options.figureType
    }
    if (options.attributes) {
      this.attributeOptions = options.attributes
    }
    style.layers.forEach(layer => {
      this._parseMapboxLayer(layer)
    })
    return {
      styles: this.styles,
      signatures: this.signatures,
      parsedAttributeValues: this.parsedAttributeValues
    }
  }
}
