
import { defineComponent, ref } from 'vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  components: {
    BaseIcon,
    InlineSvg
  },
  props: {
    modelValue: { type: String, required: true },
    placeholder: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    autofocus: { type: Boolean, required: false, default: false }
  },
  emits: [
    'update:modelValue'
  ],
  setup () {
    var elInput = ref<HTMLInputElement>()
    return {
      elInput
    }
  },
  data () {
    return {
      innerValue: this.modelValue
    }
  },
  watch: {
    innerValue: function (val) {
      this.$emit('update:modelValue', val)
    }
  },
  methods: {
    focusInput () {
      if (this.elInput) {
        this.elInput.focus()
      }
    }
  },
  mounted () {
    if (this.autofocus) this.focusInput()
  }
})
