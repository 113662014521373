import i18n from '@/i18n'

import { ErrorCategory } from '@/library/types/base/enums'

const $t = i18n.global.t

const UNKNOWN_ERROR = $t('maps.layers.downloadManager.errors.unknown')

const IMPORT_WARNINGS = {
  INVALID_GEOMETRY: $t('maps.layers.downloadManager.warnings.invalidObjects'),
  ATTRIBUTES_TRIMMED: $t('maps.layers.downloadManager.warnings.attributesTrimmed')
}

const EXPORT_WARNINGS = {
  ATTRIBUTES_CHANGED: $t('maps.layers.downloadManager.warnings.attributesChanged'),
  INVALID_SLD: $t('maps.layers.downloadManager.warnings.invalidSld')
}

const IMPORT_ERRORS = {
  importer_connection_error: $t('maps.layers.downloadManager.errors.connection_error'),
  importer_publication_failed: $t('maps.layers.downloadManager.errors.publication_failed'),
  geoserver_publication_failed: $t('maps.layers.downloadManager.errors.geoserver_publication_failed'),
  invalid_file_format: $t('maps.layers.downloadManager.errors.invalid_file_format'),
  time_limit_exceeded: $t('maps.layers.downloadManager.errors.time_limit_exceeded')
}

const EXPORT_ERRORS = {
  exporter_connection_error: $t('maps.layers.downloadManager.errors.connection_error'),
  exporter_publication_failed: $t('maps.layers.downloadManager.errors.publication_failed'),
  time_limit_exceeded: $t('maps.layers.downloadManager.errors.time_limit_exceeded')
}

export const formatError = (category: ErrorCategory, code: string): string => {
  let result: string | undefined
  switch (category) {
    case ErrorCategory.EXPORT:
      result = EXPORT_ERRORS[code as keyof typeof EXPORT_ERRORS]
      break
    case ErrorCategory.IMPORT:
      result = IMPORT_ERRORS[code as keyof typeof IMPORT_ERRORS]
      break
  }
  return result || UNKNOWN_ERROR
}

export const formatWarning = (category: ErrorCategory, code: string): string | undefined => {
  let result: string | undefined
  switch (category) {
    case ErrorCategory.EXPORT:
      result = EXPORT_WARNINGS[code as keyof typeof EXPORT_WARNINGS]
      break
    case ErrorCategory.IMPORT:
      result = IMPORT_WARNINGS[code as keyof typeof IMPORT_WARNINGS]
      break
  }
  return result
}

export const GEOMETRY_ERRORS = {
  topology_validation_error: $t('errors.geometry.topology_validation_error'),
  repeated_point: $t('errors.geometry.repeated_point'),
  hole_lies_outside_shell: $t('errors.geometry.hole_lies_outside_shell'),
  holes_are_nested: $t('errors.geometry.holes_are_nested'),
  interior_disconnected: $t('errors.geometry.interior_disconnected'),
  self_intersection: $t('errors.geometry.self_intersection'),
  ring_self_intersection: $t('errors.geometry.ring_self_intersection'),
  nested_shells: $t('errors.geometry.nested_shells'),
  duplicate_rings: $t('errors.geometry.duplicate_rings'),
  few_points_in_geometry: $t('errors.geometry.few_points_in_geometry'),
  invalid_coordinate: $t('errors.geometry.invalid_coordinate'),
  ring_not_closed: $t('errors.geometry.ring_not_closed'),
  empty_geometry: $t('errors.geometry.empty_geometry'),
  invalid_geometry: $t('errors.geometry.invalid_geometry')
}
