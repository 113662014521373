
import { defineComponent, PropType, StyleValue } from 'vue'
import BaseButton from '../BaseButton.vue'
import BaseHeader from '../BaseHeader.vue'
import BaseScrollbar from '../BaseScrollbar.vue'
import BaseLoading from '@/library/base/BaseLoading.vue'

export default defineComponent({
  components: { BaseButton, BaseHeader, BaseScrollbar, BaseLoading },
  props: {
    title: { type: String, required: true },
    bodyCustomStyles: { type: Object as PropType<StyleValue>, default: () => ({}) },
    withTopSeparator: { type: Boolean, default: true },
    withBottomSeparator: { type: Boolean, default: true },
    disableCloseOnClickOutside: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  emits: ['close']
})
