
import { defineComponent } from 'vue'
import GeocodeMap from '@/library/maps/viewer/GeocodeMap.vue'

export default defineComponent({
  components: { GeocodeMap },
  props: {
    id: { type: Number, required: false, default: 3 }
  }
})
