import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3681f8fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-menu" }
const _hoisted_2 = {
  ref: "containerRef",
  class: "base-menu__list-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_menu_group = _resolveComponent("base-menu-group")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "menuIcon",
      class: _normalizeClass(["base-menu__icon", { disabled: _ctx.disabled }])
    }, [
      _withDirectives((_openBlock(), _createBlock(_component_base_icon, {
        modelValue: _ctx.isOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
        rounded: _ctx.iconRounded,
        disabled: _ctx.disabled,
        "rotate-angle": _ctx.iconRotateAngle,
        size: _ctx.iconSize,
        type: _ctx.iconType,
        toggled: ""
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "icon", {}, () => [
            _createVNode(_component_inline_svg, { src: _ctx.iconSrc }, null, 8, ["src"])
          ], true)
        ]),
        _: 3
      }, 8, ["modelValue", "rounded", "disabled", "rotate-angle", "size", "type"])), [
        [_directive_tooltip, _ctx.iconTip]
      ])
    ], 2),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "base-menu__list",
              style: _normalizeStyle({ top: `${_ctx.topPosition}px`, left: `${_ctx.leftPosition}px` })
            }, [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "list", { close: _ctx.close }, () => [
                  (Array.isArray(_ctx.items) && _ctx.items.length)
                    ? (_openBlock(), _createBlock(_component_base_menu_group, {
                        key: 0,
                        items: _ctx.items,
                        level: 0,
                        onUpdateSequence: _ctx.updateSequence
                      }, null, 8, ["items", "onUpdateSequence"]))
                    : _createCommentVNode("", true)
                ], true)
              ], 512)
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}