
import BaseInput from '@/library/base/BaseInput.vue'
import BaseRadio from '@/library/base/BaseRadio.vue'
import BaseButton from '@/library/base/BaseButton.vue'
import BasePanel from '@/library/base/BasePanel.vue'
// import { RadioType } from '@/library/types/base/enums'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { BasePanel, BaseInput, BaseButton, BaseRadio },
  emits: ['close', 'downloadMap'],
  data () {
    return {
      fileName: '',
      exportFormats: ''
    }
  },

  methods: {
    cancelFormFill () {
      this.fileName = ''
      this.exportFormats = ''
      this.$emit('close')
    },
    downloadMap () {
      this.$emit('downloadMap', this.fileName, this.exportFormats)
    }
  }
})
