<template>
  <div class="exit">
    Выход
    <!-- This component used as a plug in route /exit -->
    <!-- route /exit used to handle log out action and check unsaved changes in personal area -->
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="less" scoped>
  .exit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
