import auth from '@/library/auth'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

// const isAuthDisable = process.env.VUE_APP_IS_AUTH_DISABLE === 'true'

const cpsApi = axios.create({
  baseURL: process.env.VUE_APP_CPS_API_URL
})

const authToken = (config: AxiosRequestConfig) => {
  config.headers = config.headers || {}
  config.headers.authorization = 'Bearer ' + auth.keycloak.token
  return config
}

const onResponseSuccess = (response: AxiosResponse) => {
  return response
}

const onResponseError = (error: Error | AxiosError) => {
  if (!axios.isAxiosError(error)) {
    throw error
  }

  if (!error.response) {
    throw error
  }

  switch (error.response.status) {
    default:
      throw error.response.data
  }
}

cpsApi.interceptors.request.use(authToken)
cpsApi.interceptors.response.use(onResponseSuccess, onResponseError)

export default cpsApi
