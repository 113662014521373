import Keycloak, { KeycloakConfig, KeycloakError, KeycloakPromise } from 'keycloak-js'
import access from './access'

const initOptions : KeycloakConfig = {
  url: process.env.VUE_APP_KEYCLOAK_URL ?? '',
  realm: process.env.VUE_APP_KEYCLOAK_REALM ?? '',
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID ?? ''
}

const keycloak = new Keycloak(initOptions)

const REFRESH_INTERVAL = process.env.VUE_APP_KEYCLOAK_REFRESH_SECONDS_INTERVAL

keycloak.onTokenExpired = () => {
  keycloakUpdateToken()
}

const keycloakUpdateToken = async () : Promise<void | boolean> => {
  return keycloak.updateToken(REFRESH_INTERVAL ? Number(REFRESH_INTERVAL) : 60)
    .catch(() => {
      keycloak.login()
    })
}

keycloak.onAuthLogout = () => {
  keycloak.login()
}

const init = (): KeycloakPromise<boolean, KeycloakError> => {
  return keycloak.init({ onLoad: 'check-sso', flow: 'standard', silentCheckSsoFallback: false })
}

/* Baykalsk usage  */
const redirectUri = `${window.location.protocol}//${window.location.host}/viewer`
const login = (): void => {
  keycloak.login({ redirectUri })
}

const auth = {
  keycloak,
  keycloakUpdateToken,
  init,
  access,
  login
}

export default auth
