
import { defineComponent, inject, computed } from 'vue'
import {
  ImageryLayer,
  WebMapServiceImageryProvider,
  WebMercatorTilingScheme
} from 'cesium'

import { CViewerKey } from './../symbol'

export default defineComponent({
  name: 'CGoogleProvider',

  setup () {
    const viewer = inject(CViewerKey)
    return {
      viewer: computed(() => viewer?.value)
    }
  },

  data: () => ({
    layer1: null as ImageryLayer | null/* ,
    layer2: null as ImageryLayer | null */
  }),

  watch: {
    viewer () {
      this.reloadLayer()
    }
  },

  beforeUnmount () {
    if (this.viewer && this.layer1 && !this.viewer.isDestroyed()) {
      this.viewer.scene.globe.imageryLayers.remove(this.layer1 as ImageryLayer)
    }

    /* if (this.viewer && this.layer2 && !this.viewer.isDestroyed()) {
      this.viewer.scene.globe.imageryLayers.remove(this.layer2 as ImageryLayer)
    } */
  },

  mounted () {
    this.reloadLayer()
  },

  methods: {
    reloadLayer () {
      if (this.viewer) {
        /* this.layer2 = this.viewer.scene.globe.imageryLayers.addImageryProvider(
          new WebMapServiceImageryProvider({
            url: 'https://mt1.google.com/vt?lyrs=h&x={x}&y={y}&z={z}',
            layers: '',
            tilingScheme: new WebMercatorTilingScheme()
          }),
          1
        ) */

        this.layer1 = this.viewer.scene.globe.imageryLayers.addImageryProvider(
          new WebMapServiceImageryProvider({
            url: 'https://mt1.google.com/vt?lyrs=s&x={x}&y={y}&z={z}',
            layers: '',
            tilingScheme: new WebMercatorTilingScheme()
          }),
          1
        )
      }
    }
  }
})
