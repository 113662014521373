import { ThemeMode } from '@/library/types/theme/enum'
import { State } from './state'

export type Getters = {
  currentTheme(state: State): Record<string, string>,
  currentThemeMode(state: State): ThemeMode
}

export const getters: Getters = {
  currentTheme: (state) => state.themes[state.currentThemeMode],
  currentThemeMode: (state: State) => state.currentThemeMode
}
