import SLDParser from 'geostyler-sld-parser'
import MapboxParser from 'geostyler-mapbox-parser'
import { Style, ReadStyleResult } from 'geostyler-style'
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser'
import chroma from 'chroma-js'

import {
  MapBoxStyle,
  MapBoxStyleLayer,
  MapBoxStyleFillPaint,
  MapBoxStyleLinePaint,
  MapBoxStyleCirclePaint,
  StyleCacheInfo,
  MapBoxExpression,
  ExpressionCondition,
  ColorField,
  ColumnSchema,
  AllPaintFields,
  SldStyleRule,
  SldParsedObject,
  SldCssParameter,
  MapBoxStyleSymbolPaint,
  MapBoxStyleSymbolLayout,
  GeometrySldSymbolizer,
  TextVendorOption
} from '@/library/types'
import { LayerColumnDbType } from '@/library/types/maps/enums'
import {
  MIN_ALLOWABLE_ZOOM,
  MAX_ALLOWABLE_ZOOM,
  getScaleByZoom,
  getZoomByScale,
  parseExpressionAttributeName
} from '@/library/helpers'
import { LinePositionType, MapBoxLayerType } from '@/library/types/styles_editor/enums'
import { DEFAULT_ICON } from './main'

const ELSE_FILTER_FLAG = 'NEED_ELSE_FILTER'

const xmlParserOptions = {
  ignoreDeclaration: false,
  ignoreAttributes: false,
  allowBooleanAttributes: true,
  attributeNamePrefix: '@_'
}

export class StyleConverter {
  private columns: ColumnSchema[] = []
  private figureType: 'point' | 'line' | 'polygon' = 'polygon'
  private styleCache: Record<string, StyleCacheInfo> = {}

  private _parseColorField (value: ColorField): ColorField {
    if (typeof value === 'string' && chroma.valid(value)) {
      return chroma(value).hex()
    }
    return value
  }

  private _resetStyleCache (): void {
    setTimeout(() => {
      this.styleCache = {}
    })
  }

  private _parseFillLayer (layer: MapBoxStyleLayer): void {
    const paint = layer.paint as MapBoxStyleFillPaint
    if (paint) {
      this.styleCache[layer.id].fillColor = paint['fill-color']
      this.styleCache[layer.id].fillOpacity = paint['fill-opacity']
      if (Array.isArray(paint['fill-color'])) {
        paint['fill-color'] = 'transparent'
      }
    }
  }

  private _parseDasharray = (value?: string) => {
    if (value) {
      switch (value) {
        case '0 2':
          return '2 2'
        case '2 1':
          return '5 5'
      }
    }
    return value
  }

  private _parseLineLayer (layer: MapBoxStyleLayer): void {
    const paint = layer.paint as MapBoxStyleLinePaint
    if (paint) {
      this.styleCache[layer.id].lineColor = paint['line-color']
      this.styleCache[layer.id].lineDasharray = this._parseDasharray(paint['line-dasharray']?.join(' '))
      this.styleCache[layer.id].lineWidth = paint['line-width']
      this.styleCache[layer.id].lineOpacity = paint['line-opacity']
      if (Array.isArray(paint['line-color'])) {
        paint['line-color'] = 'transparent'
      }
      if (Array.isArray(paint['line-width'])) {
        delete paint['line-width']
      }
    }
  }

  private _parseCircleLayer (layer: MapBoxStyleLayer): void {
    const paint = layer.paint as MapBoxStyleCirclePaint
    if (paint) {
      this.styleCache[layer.id].circleColor = paint['circle-color']
      this.styleCache[layer.id].circleOpacity = paint['circle-opacity']
      this.styleCache[layer.id].circleStrokeOpacity = paint['circle-stroke-opacity']
      this.styleCache[layer.id].circleStrokeColor = paint['circle-stroke-color']
      this.styleCache[layer.id].circleStrokeWidth = paint['circle-stroke-width']
      if (Array.isArray(paint['circle-color'])) {
        paint['circle-color'] = 'transparent'
      }
    }
  }

  private _parseSymbolLayer (layer: MapBoxStyleLayer): void {
    const paint = layer.paint as MapBoxStyleSymbolPaint
    const layout = layer.layout as MapBoxStyleSymbolLayout
    if (paint) {
      this.styleCache[layer.id]['text-translate'] = paint['text-translate']
      if (paint['text-color'] && typeof paint['text-color'] === 'string') {
        paint['text-color'] = String(this._parseColorField(paint['text-color']))
      }
      for (const propertyName in paint) {
        if (propertyName.startsWith('icon-')) {
          delete paint[propertyName as keyof MapBoxStyleSymbolPaint]
        }
      }
    }
    if (layout) {
      this.styleCache[layer.id]['text-anchor'] = layout['text-anchor']
      for (const propertyName in layout) {
        if (propertyName.startsWith('icon-')) {
          this.styleCache[layer.id][propertyName] = layout[propertyName as keyof MapBoxStyleSymbolLayout]
        }
      }
    }
  }

  private _clearZoom (layer: MapBoxStyleLayer): void {
    if (layer.maxzoom) {
      this.styleCache[layer.id].maxzoom = layer.maxzoom
      delete layer.maxzoom
    }
    if (layer.minzoom) {
      this.styleCache[layer.id].minzoom = layer.minzoom
      delete layer.minzoom
    }
  }

  private _parseMaxboxLayer (layer: MapBoxStyleLayer): void {
    this.styleCache[layer.id] = {}
    if (layer.type === 'fill') {
      this._parseFillLayer(layer)
    } else if (layer.type === 'line') {
      this._parseLineLayer(layer)
    } else if (layer.type === 'circle') {
      this._parseCircleLayer(layer)
    } else if (layer.type === 'symbol') {
      this._parseSymbolLayer(layer)
    }
    if (layer.__custom__) {
      for (const property in layer.__custom__) {
        this.styleCache[layer.id][property] = layer.__custom__[property]
      }
    }
    this._clearZoom(layer)
  }

  private _prepareMapboxStyle (mapboxStyle: string): string | undefined {
    const parsedStructure = JSON.parse(mapboxStyle) as MapBoxStyle
    if (parsedStructure) {
      if (Array.isArray(parsedStructure.layers)) {
        parsedStructure.layers.forEach(layer => {
          this._parseMaxboxLayer(layer)
        })
      }
    }
    return JSON.stringify(parsedStructure)
  }

  private _correctGeoStyler (geoStyler: Style): void {
    geoStyler.rules.forEach((rule, index) => {
      if (index < geoStyler.rules.length - 1) {
        const nextRule = geoStyler.rules[index + 1]
        if (rule.name === nextRule.name) {
          geoStyler.rules.splice(index, 1)
        }
      }
    })
    geoStyler.rules.forEach(rule => {
      const [minzoom, maxzoom] = [
        Number(this.styleCache[rule.name]?.minzoom),
        Number(this.styleCache[rule.name]?.maxzoom)
      ]
      if (!Number.isNaN(minzoom) && !Number.isNaN(maxzoom)) {
        rule.scaleDenominator = {
          max: getScaleByZoom(minzoom + 1),
          min: getScaleByZoom(maxzoom + 1)
        }
      }
    })
  }

  private _parseZoomRules (geoStyler: Style): void {
    geoStyler.rules.forEach(rule => {
      const scale = rule.scaleDenominator
      if (scale && scale.min && scale.max) {
        if (!this.styleCache[rule.name]) {
          this.styleCache[rule.name] = {}
        }
        this.styleCache[rule.name].minzoom = Math.min(
          Number(scale.min),
          this.styleCache[rule.name].minzoom || MAX_ALLOWABLE_ZOOM
        )
        this.styleCache[rule.name].maxzoom = Math.max(
          Number(scale.max),
          this.styleCache[rule.name].maxzoom || MIN_ALLOWABLE_ZOOM
        )
      }
    })
  }

  private _prepareSldExpressionLiteral (
    color: MapBoxExpression, property: string, functionType: 'Categorize' | 'Recode'
  ): string[] {
    const result: string[] = []
    const startPosition = color[0] === 'case' ? 1 : 2
    const stepMode = color[0] === 'step'
    const propertyInfo = this.columns.find(item => item.name === property)
    for (let i = startPosition; i < color.length - 2; i += 2) {
      const colorValue = stepMode ? String(color[i + 2]) : String(color[i + 1])
      let attributeValue: string
      const condition = (stepMode ? color[i + 1] : color[i]) as ExpressionCondition
      if (Array.isArray(condition) && condition.length > 1 && condition[1].length === 3) {
        attributeValue = String(condition[1][2])
      } else {
        attributeValue = String(stepMode ? color[i + 1] : color[i])
      }
      switch (propertyInfo?.dbType) {
        case LayerColumnDbType.FLOAT8:
          if (Number.isInteger(parseFloat(attributeValue))) {
            attributeValue = parseFloat(attributeValue).toFixed(1)
          }
          break
        case LayerColumnDbType.BIGINT:
        case LayerColumnDbType.SMALLINT:
        case LayerColumnDbType.INT:
          attributeValue = parseInt(attributeValue).toString()
          break
      }
      result.push(attributeValue)
      result.push(colorValue)
    }
    if (functionType === 'Categorize') {
      return [String(color[color.length - 1]), ...result]
    }
    return result
  }

  private _generateExpression (
    type: 'fill' | 'stroke' | 'stroke-width', property: string, expression: MapBoxExpression
  ): SldCssParameter {
    const functionType = expression[0] === 'step' ? 'Categorize' : 'Recode'
    return {
      '@_name': type,
      'ogc:Function': {
        '@_name': functionType,
        'ogc:Function': {
          '@_name': 'strDefaultIfBlank',
          'ogc:PropertyName': property,
          'ogc:Literal': ''
        },
        'ogc:Literal': this._prepareSldExpressionLiteral(expression, property, functionType)
      }
    }
  }

  private _correctPolygonSymbolizer (rule: SldStyleRule) {
    const fillColor = this.styleCache[rule.Name]?.fillColor
    const fillOpacity = this.styleCache[rule.Name]?.fillOpacity
    const propertyName = parseExpressionAttributeName(fillColor)
    const fillParameters = []
    if (Array.isArray(fillColor) && propertyName) {
      const fillColorRule = this._generateExpression('fill', propertyName, fillColor)
      fillParameters.push(fillColorRule)
    } else if (typeof fillColor === 'string') {
      fillParameters.push({
        '@_name': 'fill',
        '#text': String(this._parseColorField(fillColor))
      })
    }
    if (typeof fillOpacity === 'number') {
      fillParameters.push({
        '@_name': 'fill-opacity',
        '#text': fillOpacity
      })
    }
    rule.PolygonSymbolizer = {
      Fill: {
        CssParameter: fillParameters
      }
    }
  }

  private _correctPointSymbolizer (rule: SldStyleRule) {
    const circleColor = this.styleCache[rule.Name]?.circleColor
    const circleOpacity = this.styleCache[rule.Name]?.circleOpacity
    const circleStrokeOpacity = this.styleCache[rule.Name]?.circleStrokeOpacity
    const circleStrokeColor = this.styleCache[rule.Name]?.circleStrokeColor
    const circleStrokeWidth = this.styleCache[rule.Name]?.circleStrokeWidth
    const propertyName = parseExpressionAttributeName(circleColor)
    const fillParameters = []
    const strokeParameters = []
    if (Array.isArray(circleColor) && propertyName) {
      const fillColorRule = this._generateExpression('fill', propertyName, circleColor)
      fillParameters.push(fillColorRule)
    } else if (typeof circleColor === 'string') {
      fillParameters.push({
        '@_name': 'fill',
        '#text': String(this._parseColorField(circleColor))
      })
    }
    if (typeof circleOpacity === 'number') {
      fillParameters.push({
        '@_name': 'fill-opacity',
        '#text': circleOpacity
      })
    }
    if (typeof circleStrokeColor === 'string') {
      strokeParameters.push({
        '@_name': 'stroke',
        '#text': circleStrokeColor
      })
    }
    if (typeof circleStrokeWidth === 'number' && circleStrokeWidth > 0) {
      strokeParameters.push({
        '@_name': 'stroke-width',
        '#text': circleStrokeWidth
      })
      if (typeof circleStrokeOpacity === 'number') {
        strokeParameters.push({
          '@_name': 'stroke-opacity',
          '#text': circleStrokeOpacity
        })
      }
    } else {
      strokeParameters.push({
        '@_name': 'stroke-opacity',
        '#text': 0
      })
    }
    rule.PointSymbolizer = {
      Graphic: {
        Mark: {
          WellKnownName: 'circle',
          Fill: {
            CssParameter: fillParameters
          },
          Stroke: {
            CssParameter: strokeParameters
          }
        },
        Size: rule.PointSymbolizer?.Graphic.Size ?? 10
      }
    }
  }

  private _correctLineSymbolizer (rule: SldStyleRule) {
    const lineColor = this.styleCache[rule.Name]?.lineColor
    const lineOpacity = this.styleCache[rule.Name]?.lineOpacity
    const lineWidth = this.styleCache[rule.Name]?.lineWidth
    const lineDasharray = this.styleCache[rule.Name]?.lineDasharray
    const propertyName = parseExpressionAttributeName(lineColor)
    const strokeParameters = []
    if (Array.isArray(lineColor) && propertyName) {
      const lineColorRule = this._generateExpression('stroke', propertyName, lineColor)
      strokeParameters.push(lineColorRule)
    } else if (typeof lineColor === 'string') {
      strokeParameters.push({
        '@_name': 'stroke',
        '#text': String(this._parseColorField(lineColor))
      })
    }
    let lineWidthRule: SldCssParameter | undefined
    if (Array.isArray(lineWidth) && propertyName && lineWidth.length) {
      lineWidthRule = this._generateExpression('stroke-width', propertyName, lineWidth)
    } else if (typeof lineWidth === 'number' && lineWidth > 0) {
      lineWidthRule = {
        '@_name': 'stroke-width',
        '#text': lineWidth
      }
    }
    if (lineWidthRule) {
      strokeParameters.push(lineWidthRule)
      if (typeof lineOpacity === 'number') {
        strokeParameters.push({
          '@_name': 'stroke-opacity',
          '#text': lineOpacity
        })
      }
    } else {
      strokeParameters.push({
        '@_name': 'stroke-opacity',
        '#text': 0
      })
    }
    if (typeof lineDasharray === 'string') {
      strokeParameters.push({
        '@_name': 'stroke-dasharray',
        '#text': lineDasharray
      })
    }
    rule.LineSymbolizer = {
      Stroke: {
        CssParameter: strokeParameters
      }
    }
  }

  private _correctTextFont (parameters: SldCssParameter[]) {
    const hasFontStyle = parameters.filter(rule => rule['@_name'] === 'font-style').length > 0
    const hasFontWeight = parameters.filter(rule => rule['@_name'] === 'font-weight').length > 0
    let fontFamily = 'Times'
    let fontStyle = 'normal'
    let fontWeight = 'normal'
    const styleVariants = ['regular', 'italic', 'bold']
    parameters.forEach(rule => {
      if (rule['@_name'] === 'font-family' && rule['#text']) {
        fontFamily = String(rule['#text']).toLowerCase()
        const parsedStyle = ['regular', 'italic'].filter(findedStyle => {
          return fontFamily.includes(findedStyle)
        })
        if (fontFamily.includes('bold')) {
          fontWeight = 'bold'
        }
        styleVariants.forEach(style => {
          fontFamily = fontFamily.split(' ').filter(item => item !== style).join(' ')
        })
        if (parsedStyle.length) {
          fontStyle = parsedStyle[0]
        }
        rule['#text'] = fontFamily[0].toUpperCase() + fontFamily.slice(1).toLowerCase()
      }
    })
    if (!hasFontStyle) {
      parameters.push({
        '@_name': 'font-style',
        '#text': fontStyle
      })
    }
    if (!hasFontWeight) {
      parameters.push({
        '@_name': 'font-weight',
        '#text': fontWeight
      })
    }
  }

  private _correctTextHalo (parameters: SldCssParameter[]) {
    const hasFillOpacity = parameters.filter(rule => rule['@_name'] === 'fill-opacity').length > 0
    let colorAlpha = 1
    parameters.forEach(rule => {
      if (rule['@_name'] === 'fill' && rule['#text']) {
        const parsedColor = String(rule['#text'])
        if (chroma.valid(parsedColor)) {
          colorAlpha = chroma(parsedColor).alpha()
          rule['#text'] = String(chroma(parsedColor).alpha(1))
        }
      }
    })
    if (!hasFillOpacity) {
      parameters.push({
        '@_name': 'fill-opacity',
        '#text': colorAlpha
      })
    }
  }

  private _convertMapboxAlignmentToSld (alignment: string) {
    switch (alignment) {
      case 'top_left':
        return { x: 0, y: 1 }
      case 'top':
        return { x: 0.5, y: 1 }
      case 'top_right':
        return { x: 1, y: 1 }
      case 'left':
        return { x: 0, y: 0.5 }
      case 'center':
        return { x: 0.5, y: 0.5 }
      case 'right':
        return { x: 1, y: 0.5 }
      case 'bottom_left':
        return { x: 0, y: 0 }
      case 'bottom':
        return { x: 0.5, y: 0 }
      case 'bottom_right':
        return { x: 1, y: 0 }
    }
    return { x: 0, y: 0 }
  }

  private _correctTextSymbolizer (rule: SldStyleRule) {
    if (!rule.TextSymbolizer) return
    const cssFontParameters = rule.TextSymbolizer.Font?.CssParameter
    const cssHaloParameters = rule.TextSymbolizer.Halo?.Fill.CssParameter
    if (cssFontParameters && rule.TextSymbolizer.Font?.CssParameter) {
      if (!Array.isArray(cssFontParameters) && cssFontParameters['@_name']) {
        rule.TextSymbolizer.Font.CssParameter = [cssFontParameters]
      }
      if (Array.isArray(rule.TextSymbolizer.Font.CssParameter)) {
        this._correctTextFont(rule.TextSymbolizer.Font.CssParameter)
      }
    }
    if (cssHaloParameters && rule.TextSymbolizer?.Halo?.Fill.CssParameter) {
      if (!Array.isArray(cssHaloParameters) && cssHaloParameters['@_name']) {
        rule.TextSymbolizer.Halo.Fill.CssParameter = [cssHaloParameters]
      }
      if (Array.isArray(rule.TextSymbolizer.Halo.Fill.CssParameter)) {
        this._correctTextHalo(rule.TextSymbolizer.Halo.Fill.CssParameter)
      }
    }
    const mapboxAlignment = this.styleCache[rule.Name]['text-anchor']
    const mapboxTextTranslate = this.styleCache[rule.Name]['text-translate'] || [0, 0]
    const { x, y } = this._convertMapboxAlignmentToSld(mapboxAlignment)
    const vendorOptions: TextVendorOption[] = [{
      '@_name': 'conflictResolution',
      '#text': this.styleCache[rule.Name].conflictResolution !== true
    }]
    let placement
    switch (this.figureType) {
      case 'point':
        placement = {
          PointPlacement: {
            AnchorPoint: {
              AnchorPointX: {
                Literal: x
              },
              AnchorPointY: {
                Literal: y
              }
            },
            Displacement: {
              DisplacementX: {
                Literal: mapboxTextTranslate[0] || 0
              },
              DisplacementY: {
                Literal: mapboxTextTranslate[1] ? (mapboxTextTranslate[1] * (-1)) : 0
              }
            }
          }
        }
        break
      case 'line':
        placement = {
          LinePlacement: {
            PerpendicularOffset: this.styleCache[rule.Name].lineOffset ? this.styleCache[rule.Name].lineOffset * (-1) : 0
          }
        }
        vendorOptions.push({
          '@_name': 'followLine',
          '#text': this.styleCache[rule.Name].linePosition === LinePositionType.FOLLOW_LINE
        })
        vendorOptions.push({
          '@_name': 'spaceAround',
          '#text': -10
        })
        if (this.styleCache[rule.Name].needRepeat) {
          vendorOptions.push({
            '@_name': 'repeat',
            '#text': this.styleCache[rule.Name].lineRepeatInterval
          })
        }
        break
      case 'polygon':
        vendorOptions.push({
          '@_name': 'goodnessOfFit',
          '#text': 0
        })
        break
      default:
        placement = {
          PointPlacement: {
            AnchorPoint: {
              AnchorPointX: {
                Literal: 0.5
              },
              AnchorPointY: {
                Literal: 0.5
              }
            }
          }
        }
        break
    }
    rule.TextSymbolizer.VendorOption = vendorOptions
    rule.TextSymbolizer.LabelPlacement = placement
  }

  private _parseSldStyleRules (sldStyle: string) {
    const parser = new XMLParser(xmlParserOptions)
    const sldObject: SldParsedObject = parser.parse(sldStyle)
    let styleRules = sldObject?.StyledLayerDescriptor?.NamedLayer?.UserStyle?.FeatureTypeStyle?.Rule
    if (!styleRules) {
      styleRules = sldObject?.StyledLayerDescriptor?.UserLayer?.UserStyle?.FeatureTypeStyle?.Rule
    }
    if (styleRules && !Array.isArray(styleRules)) {
      styleRules = [styleRules]
    }
    return styleRules || []
  }

  private _generateIconRule = (ruleId: string, size: number, url: string, property?: string, value?: string) => {
    const newRule: SldStyleRule = {
      Name: 'symbol',
      PointSymbolizer: {
        Graphic: {
          Size: size,
          ExternalGraphic: {
            OnlineResource: {
              '@_xlink:href': url,
              '@_xlink:type': 'simple'
            },
            Format: {
              '#text': 'image/svg+xml'
            }
          }
        }
      }
    }
    const cache = this.styleCache[ruleId]
    const [minzoom, maxzoom] = [
      cache?.minzoom ? Number(cache.minzoom) : MIN_ALLOWABLE_ZOOM,
      cache?.maxzoom ? Number(cache.maxzoom) : MAX_ALLOWABLE_ZOOM
    ]
    if (!Number.isNaN(minzoom) && !Number.isNaN(maxzoom)) {
      newRule.MinScaleDenominator = getScaleByZoom(maxzoom + 1)
      newRule.MaxScaleDenominator = getScaleByZoom(minzoom + 1)
    }
    if (value && property) {
      newRule.Filter = {
        PropertyIsEqualTo: {
          PropertyName: property,
          Literal: value
        }
      }
    } else if (property === ELSE_FILTER_FLAG) {
      newRule.ElseFilter = ''
    }
    return newRule
  }

  private _correctIconRules () {
    const result: SldStyleRule[] = []
    for (const layerId in this.styleCache) {
      const ruleCache = this.styleCache[layerId]
      if (
        {}.hasOwnProperty.call(ruleCache, 'icon-image') &&
        {}.hasOwnProperty.call(ruleCache, 'iconSize')
      ) {
        if (Array.isArray(ruleCache['icon-image']) && ruleCache['icon-image'].length) {
          const values = ruleCache['icon-image']
          const startPosition = values[0] === 'case' ? 1 : 2
          const stepMode = values[0] === 'step'
          const attributeName = parseExpressionAttributeName(values)
          for (let i = startPosition; i < values.length - 2; i += 2) {
            const url = stepMode ? String(values[i + 2]) : String(values[i + 1])
            let attributeValue: string
            const condition = (stepMode ? values[i + 1] : values[i]) as ExpressionCondition
            if (Array.isArray(condition) && condition.length > 1 && condition[1].length === 3) {
              attributeValue = String(condition[1][2])
            } else {
              attributeValue = String(stepMode ? values[i + 1] : values[i])
            }
            const newRule = this._generateIconRule(
              layerId, ruleCache.iconSize, url, attributeName, attributeValue
            )
            result.push(newRule)
          }
          const defaultRule = this._generateIconRule(
            layerId, ruleCache.iconSize, DEFAULT_ICON, ELSE_FILTER_FLAG
          )
          result.push(defaultRule)
        } else {
          const newRule = this._generateIconRule(
            layerId, ruleCache.iconSize, ruleCache['icon-image']
          )
          result.push(newRule)
        }
      }
    }
    return result
  }

  private _correctSldStructure (geoStyler: string): string {
    const parser = new XMLParser(xmlParserOptions)
    const sldObject: SldParsedObject = parser.parse(geoStyler)
    let styleRules = sldObject?.StyledLayerDescriptor?.NamedLayer?.UserStyle?.FeatureTypeStyle?.Rule
    if (!styleRules) {
      styleRules = sldObject?.StyledLayerDescriptor?.UserLayer?.UserStyle?.FeatureTypeStyle?.Rule
    }
    if (styleRules && !Array.isArray(styleRules)) {
      styleRules = [styleRules]
    }
    styleRules = styleRules || []
    styleRules.forEach(rule => {
      if ({}.hasOwnProperty.call(rule, 'LineSymbolizer')) {
        this._correctLineSymbolizer(rule)
      } else if ({}.hasOwnProperty.call(rule, 'PolygonSymbolizer')) {
        this._correctPolygonSymbolizer(rule)
      } else if ({}.hasOwnProperty.call(rule, 'PointSymbolizer')) {
        this._correctPointSymbolizer(rule)
      } else if ({}.hasOwnProperty.call(rule, 'TextSymbolizer')) {
        this._correctTextSymbolizer(rule)
      }
    })
    const lineRule = Object.assign({}, styleRules.find(item => {
      return {}.hasOwnProperty.call(item, 'LineSymbolizer')
    }))
    const pointRule = Object.assign({}, styleRules.find(item => {
      return {}.hasOwnProperty.call(item, 'PointSymbolizer')
    }))
    const polygonRule = Object.assign({}, styleRules.find(item => {
      return {}.hasOwnProperty.call(item, 'PolygonSymbolizer')
    }))
    if (polygonRule.PolygonSymbolizer && lineRule.LineSymbolizer) {
      const stroke = Object.assign({}, lineRule.LineSymbolizer?.Stroke ?? { CssParameter: [] })
      const polygonSymbolizer = (polygonRule.PolygonSymbolizer as GeometrySldSymbolizer)
      polygonSymbolizer.Stroke = stroke
      styleRules.every((item, index) => {
        if (item.LineSymbolizer) {
          (styleRules as SldStyleRule[]).splice(index, 1)
          return false
        }
        return true
      })
    }
    const iconRules = pointRule.PointSymbolizer ? this._correctIconRules() : []
    const sldUserStyle = sldObject?.StyledLayerDescriptor?.NamedLayer?.UserStyle
    if (sldUserStyle) {
      sldUserStyle.FeatureTypeStyle = {
        Rule: styleRules.concat(iconRules)
      }
    }
    const builder = new XMLBuilder(xmlParserOptions)
    return builder.build(sldObject)
  }

  private _correctExpressionData (layer: MapBoxStyleLayer, paintProperty: 'fill-color' | 'circle-color' | 'line-color') {
    if (layer.paint) {
      const fillColor = layer.paint[paintProperty] as ColorField
      if (fillColor && typeof fillColor !== 'string' && !Array.isArray(fillColor)) {
        const incorectStructure = fillColor as Record<string, any>
        const propertyName = incorectStructure.args[0]?.args[0]?.args[0] as string
        const literals = incorectStructure.args.slice(1)
        const sldFunctionType = incorectStructure.name
        if (propertyName && sldFunctionType && literals) {
          const expressionType = sldFunctionType === 'Categorize' ? 'step' : 'match'
          const commonColor = literals[literals.length - 1]
          layer.paint[paintProperty] = [
            expressionType, ['get', propertyName], ...literals, commonColor
          ]
        }
      }
    }
  }

  private _correctSymbolData (layer: MapBoxStyleLayer, nextLayer: MapBoxStyleLayer) {
    layer.paint = Object.assign(layer.paint as AllPaintFields, nextLayer.paint)
    layer.layout = Object.assign(layer.layout as AllPaintFields, nextLayer.layout)
  }

  private _correctMapboxStructure (mapboxStyle: string): string {
    const result: MapBoxStyle = JSON.parse(mapboxStyle)
    if (this.styleCache.sldStroke && !result.layers.find(item => item.type === MapBoxLayerType.LINE)) {
      result.layers.push({
        id: 'line',
        type: MapBoxLayerType.LINE,
        paint: {
          'line-color': this.styleCache.sldStroke.lineColor,
          'line-width': this.styleCache.sldStroke.lineWidth,
          'line-opacity': this.styleCache.sldStroke.lineOpacity
        }
      })
    }
    result.layers.forEach((layer, index) => {
      const [minzoom, maxzoom] = [
        Number(this.styleCache[layer.id]?.minzoom),
        Number(this.styleCache[layer.id]?.maxzoom)
      ]
      layer.minzoom = getZoomByScale(!Number.isNaN(minzoom) ? minzoom : MIN_ALLOWABLE_ZOOM)
      layer.maxzoom = getZoomByScale(!Number.isNaN(maxzoom) ? maxzoom : MAX_ALLOWABLE_ZOOM)
      switch (layer.type) {
        case MapBoxLayerType.FILL:
          this._correctExpressionData(layer, 'fill-color')
          break
        case MapBoxLayerType.LINE:
          this._correctExpressionData(layer, 'circle-color')
          break
        case MapBoxLayerType.CIRCLE:
          this._correctExpressionData(layer, 'line-color')
          break
        case MapBoxLayerType.SYMBOL: {
          const nextLayer = (index < result.layers.length - 1) ? result.layers[index + 1] : null
          if (nextLayer && layer.id === nextLayer.id) {
            this._correctSymbolData(layer, nextLayer)
            result.layers.splice(index, 2, layer)
          }
          break
        }
      }
    })
    return JSON.stringify(result)
  }

  private _parseSldStrokeParameter (params: SldCssParameter) {
    if (!['string', 'number'].includes(typeof params['#text'])) return
    switch (params['@_name']) {
      case 'stroke-width':
        this.styleCache.sldStroke.lineWidth = params['#text']
        break
      case 'stroke-opacity':
        this.styleCache.sldStroke.lineOpacity = params['#text']
        break
      case 'stroke':
        this.styleCache.sldStroke.lineColor = params['#text']
        break
    }
  }

  private _parseSldStroke (params?: SldCssParameter | SldCssParameter[]) {
    if (Array.isArray(params)) {
      params.forEach(paramsItem => {
        this._parseSldStrokeParameter(paramsItem)
      })
    } else if (params) {
      this._parseSldStrokeParameter(params)
    }
  }

  public async convertMapboxToSld (
    mapboxStyle: string, figureType: 'point' | 'line' | 'polygon',
    columns: ColumnSchema[]
  ): Promise<string | undefined> {
    this._resetStyleCache()
    const mapboxParser = new MapboxParser()
    this.columns = columns
    this.figureType = figureType
    const preparedMapboxStyle = this._prepareMapboxStyle(mapboxStyle)
    if (preparedMapboxStyle) {
      const geoStyler: ReadStyleResult = await mapboxParser.readStyle(preparedMapboxStyle)
      if (geoStyler.output) {
        this._correctGeoStyler(geoStyler.output)
        const sldParser = new SLDParser()
        const result = await sldParser.writeStyle(geoStyler.output)
        if (result.output) {
          const modifiedResult = this._correctSldStructure(result.output)
          return modifiedResult.replace(new RegExp('undefined', 'g'), '')
        }
      }
    }
  }

  public async convertSldToMapbox (sldStyle: string): Promise<string | undefined> {
    this._resetStyleCache()
    const sldParser = new SLDParser()
    const geoStyler: ReadStyleResult = await sldParser.readStyle(sldStyle)
    if (geoStyler.output) {
      const strokeRule = geoStyler.output.rules.find(item => item.name === 'stroke')
      if (!strokeRule) {
        this.styleCache.sldStroke = {}
        const styleRules = this._parseSldStyleRules(sldStyle)
        styleRules.forEach(rule => {
          if ({}.hasOwnProperty.call(rule, 'LineSymbolizer')) {
            this._parseSldStroke(rule.LineSymbolizer?.Stroke?.CssParameter)
          }
          if ({}.hasOwnProperty.call(rule, 'PolygonSymbolizer')) {
            this._parseSldStroke(rule.PolygonSymbolizer?.Stroke?.CssParameter)
          }
          if ({}.hasOwnProperty.call(rule, 'PointSymbolizer')) {
            this._parseSldStroke(rule.PointSymbolizer?.Graphic.Mark?.Stroke?.CssParameter)
          }
        })
      }
      this._parseZoomRules(geoStyler.output)
      const mapboxParser = new MapboxParser()
      const result = await mapboxParser.writeStyle(geoStyler.output)
      if (result.output) {
        return this._correctMapboxStructure(result.output)
      }
    }
  }

  public async isValidSld (sldText: string): Promise<boolean> {
    try {
      const isCorrectXML = XMLValidator.validate(sldText)
      if (isCorrectXML !== true) return false
      const parser = new XMLParser(xmlParserOptions)
      const sldObject: SldParsedObject = parser.parse(sldText)
      const descriptor = sldObject?.StyledLayerDescriptor
      if (
        !sldObject['?xml']['@_encoding'] ||
        !sldObject['?xml']['@_version'] ||
        !descriptor['@_version'] ||
        !descriptor['@_xmlns'] ||
        !descriptor['@_xmlns:ogc'] ||
        (
          !descriptor?.NamedLayer &&
          !descriptor?.UserLayer
        )
      ) return false
      return true
    } catch {
      return false
    }
  }
}
