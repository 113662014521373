import { FormField } from '@/library/types'

export const calculateFormErrors = (fields: FormField[]): number => {
  return fields.reduce((accumulator, field) => {
    if (Array.isArray(field.children) && field.children.length) {
      return accumulator + calculateFormErrors(field.children)
    }
    if (field.hasError) {
      return accumulator + 1
    }
    return accumulator
  }, 0)
}
