
import { defineComponent, ref, provide, computed, onBeforeMount, onMounted, watch, nextTick } from 'vue'
import InlineSvg from 'vue-inline-svg'
import {
  Cesium3DTileset,
  GeoJsonDataSource,
  EasingFunction,
  ImageryLayer,
  Cartesian3,
  Rectangle,
  Math as CesiumMath,
  Resource,
  Cartographic,
  JulianDate,
  Matrix4
} from 'cesium'
import { VcReadyObject } from 'vue-cesium/lib/utils/types'
import { VcCesiumObject } from 'vue-cesium/es/utils/types'
import { onBeforeRouteLeave } from 'vue-router'

import { useStore } from '@/library/store'
import { useToast } from 'vue-toastification'
import i18n from '@/i18n'
import { IconType } from '@/library/types/base/enums'
import { BaseMapType, GeoMapLayerType, LayerActiveToolType, MapActiveToolType } from '@/library/types/maps/enums'
import auth from '@/library/auth'
import { GeoMapLayer, GeoMapLayergroup, LayerActiveToolParams, Position3dInfo, Offset3d } from '@/library/types'
import { getInitParams, getLayersList, PRIMARY_LAYER_TOOLS } from '@/library/helpers'
import { Map3DKey } from './symbol'
import ActionsTopRight from './ol-map/control/top_right/ActionsTopRight.vue'
import { MapsActionTypes } from '@/library/store/modules/maps/action-types'
import { MapsMutationTypes } from '@/library/store/modules/maps/mutation-types'
import ActionsBottomRight from './actions/ActionsBottomRight.vue'
import OfpTool from '../../../components/app/OfpTool.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import Layers from './layers/Layers.vue'
import CViewer from './cesium-map/CViewer.vue'
import COsmProvider from './cesium-map/provider/osm.vue'
import COpenTopoMapProvider from './cesium-map/provider/opentopomap.vue'
import CBaseMapProvider from './cesium-map/provider/basemap.vue'
import CGoogleProvider from './cesium-map/provider/google.vue'
import CWmsProvider from './cesium-map/provider/wms.vue'
import CTmsProvider from './cesium-map/provider/tms.vue'
import CDatasourceGeojson from './cesium-map/datasource/geojson.vue'
import CPrimitiveTileset from './cesium-map/primitive/tileset.vue'
import MapSync from './sync/MapSync.vue'
import CesiumControlFullExtent from './cesium-map/control/CesiumControlFullExtent.vue'
import CesiumControlFooter from './cesium-map/control/CesiumControlFooter.vue'
import Bookmarks from './cesium-map/control/top-right/Bookmarks.vue'

import defaultObjectStyle from '@/library/libs/maps/defaults/objectStyle.json'

import ActionLegend from './actions/ActionLegend.vue'
import ActionBaseMap from './actions/ActionBaseMap.vue'
import ActionRelief from './actions/ActionRelief.vue'

import { useStore as usestoreApp } from '@/store'
import { AppActionTypes } from '@/store/modules/baykalsk/action-types'
import { CommonPreenabledLayers } from '@/types/baykalsk/types'
import { CityPresentationAnimation } from '@/types/baykalsk/enums'
import imgRuler from '@/library/assets/img/icRuler.svg'
import imgTools from '@/library/assets/img/icTools.svg'
import imgDownload from '@/library/assets/img/icDownload.svg'
import ExtentLinkCreator from './cesium-map/control/top-right/ExtentLinkCreator.vue'
import Geolocation from './cesium-map/control/top-right/Geolocation.vue'
import MapLocation from './cesium-map/control/top-right/MapLocation.vue'
import LayerConvertTo3d from './tools/3d/LayerConvertTo3d.vue'
import LayerTransform3d from './tools/3d/LayerTransform3d.vue'
import LayerStyle from './layers/styles/LayerStyle.vue'
import SldEditor from './layers/styles/SldEditor.vue'
import LayerSettings from './tools/settings/LayerSettings.vue'
import LayerExternalSettings from './tools/settings/LayerExternalSettings.vue'
import ClickInfo3D from './tools/click/ClickInfo3D.vue'
import AttributionTable from './shared/infobox/AttributionTable.vue'
import { LayerToolParamsMap } from './types'

declare type ITool = {
  horizontal: string,
  area: string
}

declare type IData = {
  borderCanvasId: string,
  borderCanvas: HTMLCanvasElement | null,
  mEditable: boolean,
  editableLayerObjectId: number,
  showLayerObjectWindow: boolean,
  currentLayerObjectID: number,
  drawingActionInstances?: VcCesiumObject | Array<VcCesiumObject>,
  toolName: ITool,
}

const store = useStore()

export default defineComponent({
  components: {
    CViewer,
    COsmProvider,
    CWmsProvider,
    CTmsProvider,
    COpenTopoMapProvider,
    CBaseMapProvider,
    CGoogleProvider,
    CDatasourceGeojson,
    ActionsTopRight,
    ActionsBottomRight,
    OfpTool,
    BaseIcon,
    InlineSvg,
    Layers,
    CPrimitiveTileset,
    ActionLegend,
    ActionBaseMap,
    ActionRelief,
    SldEditor,
    MapSync,
    CesiumControlFullExtent,
    CesiumControlFooter,
    Bookmarks,
    ExtentLinkCreator,
    Geolocation,
    MapLocation,
    LayerConvertTo3d,
    LayerStyle,
    LayerTransform3d,
    LayerSettings,
    LayerExternalSettings,
    ClickInfo3D,
    AttributionTable
  },
  props: {
    withLayers: {
      type: Boolean,
      required: false,
      default: true
    },
    allFunctionalEnable: {
      type: Boolean,
      required: false,
      default: true
    },
    demoMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['new-extent'],
  setup (props, { emit }) {
    const isWithRelief = ref(true)
    const changeIsWithRelief = () => {
      isWithRelief.value = !isWithRelief.value
    }
    watch(
      () => isWithRelief.value,
      () => {
        nextTick(() => {
          setExtent(activeSlideExtent3D.value ?? extent3d.value)
        })
      }
    )
    const cMeasurementsRef = ref()
    const cViewerRef = ref<InstanceType<typeof CViewer> | null>()
    const actionsBottomRightRef = ref()
    const layersRef = ref<{[id: number]: VcCesiumObject | Array<VcCesiumObject>}>({})

    const baykalskExtent3d = [-974073.6699487976, 3853336.29253559, 4973967.929947179]
    const cartesianDestination = Cartesian3.fromArray(baykalskExtent3d)
    const extent3d = ref<Position3dInfo>({
      destination: cartesianDestination,
      orientation: {
        heading: 3.534444574773044,
        pitch: -0.2092278452649059,
        roll: 0.00006420759700276335
      }
    })

    const toast = useToast()
    const $t = i18n.global.t
    const showOFP = ref(false)
    const ofpLayersIds = ref<number[]>([])
    watch(
      () => ofpLayersIds.value,
      (val) => {
        if (val) getOfpLayers()
      }
    )
    const ofpMapLayers = ref<GeoMapLayerType.WMS[]>([])
    const ofpWMS = computed(() => {
      return ofpMapLayers.value.filter(x => x.type === GeoMapLayerType.WMS)
    })
    const ofpTMS = computed(() => {
      return ofpMapLayers.value.filter(x => x.type === GeoMapLayerType.TMS || x.type === GeoMapLayerType.WMTS)
    })
    const prepareOfp = () => {
      store.dispatch(MapsActionTypes.FETCH_MAP_CONFIG, 3)
        .then((result) => {
          if (result.ofpLayerId) {
            ofpLayersIds.value = result.ofpLayerId
          }
        })
        .catch(() => {
          toast.clear()
          toast.error($t('baikalsk.shared.ofp.load-error'))
        })
    }
    const getOfpLayers = () => {
      ofpLayersIds.value.forEach(id => {
        store.dispatch(MapsActionTypes.FETCH_LAYER, id)
          .then((layer) => {
            ofpMapLayers.value.push(layer)
          })
          .catch(() => {
            toast.clear()
            toast.error($t('baikalsk.shared.ofp.load-error'))
          })
      })
    }

    onBeforeMount(() => {
      /* Требование Заказчика по умолчанию базовая подложка */
      store.commit(MapsMutationTypes.SET_MAP_BASEMAP, BaseMapType.BBM)
    })
    onMounted(() => {
      setExtent(activeSlideExtent3D.value ?? extent3d.value)
      getPreenabledLayersIds()
      prepareOfp()
    })
    const currentBasemap = computed(() => {
      return store.state.maps.basemap
    })

    const getExtent2d = () => {
      const viewer = cViewerRef.value?.viewer
      if (viewer) {
        const scratchRectangle = new Rectangle()
        const rect = viewer.camera.computeViewRectangle(
          viewer.scene.globe.ellipsoid, scratchRectangle
        )
        return rect ? [
          CesiumMath.toDegrees(rect.west),
          CesiumMath.toDegrees(rect.south),
          CesiumMath.toDegrees(rect.east),
          CesiumMath.toDegrees(rect.north)
        ] : []
      }
      return []
    }

    const setExtent = (extend3d: Position3dInfo) => {
      const viewer = cViewerRef.value?.viewer
      if (viewer) {
        const duration = activeSlideAnimation.value === CityPresentationAnimation.SLOW ? 1 : 0
        viewer.camera.flyTo({
          orientation: extend3d.orientation,
          destination: extend3d.destination,
          easingFunction: EasingFunction.QUADRATIC_IN_OUT,
          duration: activeSlideAnimation.value ? duration : 2
        })
      }
    }

    const baykalskStore = usestoreApp()
    const isAdmin = computed(() => baykalskStore.state.auth.isAdmin)
    const activeSlideAnimation = computed(() => {
      if (!baykalskStore.state.baykalsk) {
        return CityPresentationAnimation.SLOW
      }
      return baykalskStore.state.baykalsk.currentSlideAnimation
    })
    const activeSlideExtent3D = computed(() => {
      if (!baykalskStore.state.baykalsk) {
        return undefined
      }
      return baykalskStore.state.baykalsk.activeCPSSlideExtent3D
    })
    watch(
      () => activeSlideExtent3D.value,
      (val) => {
        if (val) setExtent(val)
      }
    )

    const estatePreenabledLayersIds = ref<number[]>([])
    watch(
      () => estatePreenabledLayersIds.value,
      (val) => {
        if (val) setEstateLayersToVisible(val)
      }
    )

    const getPreenabledLayersIds = () => {
      if (props.demoMode) return
      baykalskStore.dispatch(AppActionTypes.COMMON_GET_PRE_LAYERS)
        .then((result: CommonPreenabledLayers) => {
          estatePreenabledLayersIds.value = result.id
        })
    }

    const estateMapLayers = ref<GeoMapLayerType.HYBRID[]>([])

    const setEstateLayersToVisible = (val: number[]) => {
      const preparedLayers = val.map(layer => {
        return {
          layerId: layer,
          filters: []
        }
      })
      store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, [...store.state.maps.visibleLayers, ...preparedLayers])
    }

    const emitExtent = () => {
      const viewer = cViewerRef.value?.viewer
      if (viewer) {
        const camera = viewer.scene.camera
        const position = camera.positionCartographic
        const destination = Cartesian3.fromRadians(position.longitude, position.latitude, position.height)
        emit('new-extent', {
          destination: destination,
          orientation: {
            heading: camera.heading,
            pitch: camera.pitch,
            roll: camera.roll
          }
        })
      }
    }

    const lazyUpdateSession = () => {
      if (!baykalskStore.state.auth.authenticated) return
      if (props.demoMode) return
      const viewer = cViewerRef.value?.viewer
      if (viewer) {
        const camera = viewer.scene.camera
        const position = camera.positionCartographic
        const destination = Cartesian3.fromRadians(position.longitude, position.latitude, position.height)
        store.dispatch(MapsActionTypes.PUT_SESSION_DATA, {
          extent2d: getExtent2d(),
          extent3d: {
            destination: destination,
            orientation: {
              heading: camera.heading,
              pitch: camera.pitch,
              roll: camera.roll
            }
          },
          is2dProcessing: false
        })
      }
    }

    const layerActiveTools = ref<LayerToolParamsMap>({
      [LayerActiveToolType.CLICK]: {}
    })

    const deleteLayerToolsByType = (toolType: LayerActiveToolType) => {
      delete layerActiveTools.value[toolType]
    }

    const setLayerActiveTool = (toolType: LayerActiveToolType, params?: LayerActiveToolParams) => {
      const activeKeys = Object.keys(layerActiveTools.value)
      if (PRIMARY_LAYER_TOOLS.includes(toolType)) {
        activeKeys.forEach(toolKey => {
          if (PRIMARY_LAYER_TOOLS.includes(toolKey as LayerActiveToolType)) {
            deleteLayerToolsByType(toolKey as LayerActiveToolType)
          }
        })
      } else {
        activeKeys.forEach(toolKey => {
          if (!PRIMARY_LAYER_TOOLS.includes(toolKey as LayerActiveToolType)) {
            deleteLayerToolsByType(toolKey as LayerActiveToolType)
          }
        })
      }
      layerActiveTools.value[toolType] = params || {}
    }

    const unsetLayerActiveTool = (toolType: LayerActiveToolType) => {
      deleteLayerToolsByType(toolType)
      if (!Object.keys(layerActiveTools.value).length) {
        layerActiveTools.value[LayerActiveToolType.CLICK] = {}
      }
    }

    const mapActiveTool = ref<MapActiveToolType | null>(null)
    const setMapActiveTool = (tool: MapActiveToolType | null) => {
      if (tool) {
        mapActiveTool.value = tool
      } else {
        mapActiveTool.value = null
      }
    }

    const destGivenBearingStartDistance = (bearingDeg: number, distance: number, latRad: number, lonRad: number, radius = 6371e3) => {
      const bearingRad = bearingDeg * Math.PI / 180
      const angDisRad = distance / radius
      const sinLat2 = Math.sin(latRad) * Math.cos(angDisRad) + Math.cos(latRad) * Math.sin(angDisRad) * Math.cos(bearingRad)
      const lat2Rad = Math.asin(sinLat2)
      const y = Math.sin(bearingRad) * Math.sin(angDisRad) * Math.cos(latRad)
      const x = Math.cos(angDisRad) - Math.sin(latRad) * sinLat2
      const lon2Rad = lonRad + Math.atan2(y, x)

      return Cartographic.fromRadians(lon2Rad, lat2Rad)
    }
    const calculatePositionTileset = (id: number, config3d: Offset3d, cesiumObject: VcReadyObject) => {
      const tileset = cesiumObject

      if (tileset && tileset instanceof Cesium3DTileset) {
        const cartographic = Cartographic.fromCartesian(
          tileset.boundingSphere.center
        )

        const d1 = destGivenBearingStartDistance(0, config3d.offsetY, cartographic.latitude, cartographic.longitude)
        const d2 = destGivenBearingStartDistance(90, config3d.offsetX, d1.latitude, d1.longitude)

        const surface = Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          0
        )

        const offset = Cartesian3.fromRadians(
          d2.longitude,
          d2.latitude,
          config3d.offsetZ
        )

        const translation = Cartesian3.subtract(
          offset,
          surface,
          new Cartesian3()
        )

        tileset.modelMatrix = Matrix4.fromTranslation(translation)
      }
    }

    onBeforeRouteLeave(() => {
      lazyUpdateSession()
    })

    provide(Map3DKey, ref({
      geoMap: computed(() => (store.state.maps.map)),
      viewer: computed(() => cViewerRef.value),
      getMapLegendContent: () => actionsBottomRightRef.value.mapLegendRef.mapLegendContentRef,
      currentBasemap: currentBasemap,
      viewerElement: computed(() => cViewerRef.value?.$el),
      lazyUpdateSession,
      layerActiveTools: computed(() => layerActiveTools.value),
      setLayerActiveTool,
      unsetLayerActiveTool,
      mapActiveTool,
      setMapActiveTool,
      setExtent,
      layersRef: computed(() => layersRef.value)
    }))

    return {
      changeIsWithRelief,
      isWithRelief,
      isAdmin,
      estateMapLayers,
      cMeasurementsRef,
      cViewerRef,
      actionsBottomRightRef,
      currentBasemap,
      showOFP,
      ofpWMS,
      ofpTMS,
      BaseMapType,
      setExtent,
      lazyUpdateSession,
      emitExtent,
      imgRuler,
      imgTools,
      imgDownload,
      IconType,
      layersRef,
      calculatePositionTileset
    }
  },
  data (): IData {
    return {
      borderCanvasId: '',
      borderCanvas: null,
      editableLayerObjectId: 0,
      currentLayerObjectID: 0,
      mEditable: false,
      showLayerObjectWindow: true,
      drawingActionInstances: [],
      toolName: {
        horizontal: 'horizontal',
        area: 'area'
      }
    }
  },
  computed: {
    visibleLayersIds () {
      return store.getters.getVisibleLayerIds
    },

    layers (): GeoMapLayer[] {
      if (!store.state.maps.map) {
        return []
      }

      const layers = getLayersList(store.state.maps.map.groups)
      return layers.filter(x => this.visibleLayersIds.includes(x.id))
    },
    imageLayers (): GeoMapLayer[] {
      return this.layers.filter(x => x.type === GeoMapLayerType.WMS)
    },
    tiledLayers (): GeoMapLayer[] {
      return this.layers.filter(x => x.type === GeoMapLayerType.TMS || x.type === GeoMapLayerType.WMTS)
    },
    vectorLayers (): GeoMapLayer[] {
      return this.layers.filter(x => x.type === GeoMapLayerType.WFS || (x.type === GeoMapLayerType.HYBRID && !x.urlTileset?.length))
    },
    layers3dTileset (): GeoMapLayer[] {
      return this.layers.filter(x => x.type === GeoMapLayerType.TILESET_3D || (x.type === GeoMapLayerType.HYBRID && x.urlTileset?.length))
    },
    visibleGroups () {
      return store.state.maps.visibleGroups
    },
    wmsParameters () {
      return {
        format: 'image/png',
        transparent: true
      }
    }
  },
  watch: {
    layers () {
      this.lazyUpdateSession()
    },
    visibleGroups () {
      this.lazyUpdateSession()
    },
    currentBasemap () {
      this.lazyUpdateSession()
    }
  },
  mounted () {
    this.applySession()
  },
  methods: {
    applySession () {
      if (this.$props.demoMode) return
      const session = store.state.maps.savedSession
      const initParams = getInitParams()

      if (session) {
        store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, session.visible3dLayers)
        store.commit(MapsMutationTypes.SET_MAP_GROUPS_VISIBLE, [...session.visible3dGroupsIds])
        /* Disabled cause google is set by default  */
        // store.commit(MapsMutationTypes.SET_MAP_BASEMAP, session.baseMap)
      }

      this.$nextTick(() => {
        if (initParams.position3d) {
          this.setExtent(initParams.position3d)
        }
        /* Disabled, set extent on Baykalsk by default */
        /*  else if (session?.extent3d) {
          this.setExtent(session?.extent3d)
        } */
        if (initParams.visibleLayers) {
          store.commit(MapsMutationTypes.SET_MAP_SESSION_DATA, {
            baseMap: BaseMapType.GOOGLE_SPUTNIKSM,
            mapId: 3,
            visibleGroupsIds: [],
            visibleLayers: initParams.visibleLayers,
            extent: [],
            visible3dGroupsIds: [],
            visible3dLayers: initParams.visibleLayers,
            extent3d: []
          })
        }
      })
    },
    // measurementsReady ({ cesiumObject }: VcReadyObject) {
    //   this.drawingActionInstances = cesiumObject
    // },
    clear () {
      this.cMeasurementsRef.clearAll()
    },
    toggle (name: string) {
      this.cMeasurementsRef.toggleAction(name)
    },
    async flyToGroup (group: GeoMapLayergroup) {
      const asyncQueries : Promise<void>[] = []
      const layers = getLayersList(group)
      const fullBorders = {
        minLat: Number.POSITIVE_INFINITY,
        maxLat: Number.NEGATIVE_INFINITY,
        minLon: Number.POSITIVE_INFINITY,
        maxLon: Number.NEGATIVE_INFINITY
      }
      layers.forEach(layer => {
        asyncQueries.push(this.calculateLayerBorders(layer, fullBorders))
      })
      await Promise.all(asyncQueries)
      const fullRectangle = Rectangle.fromDegrees(
        fullBorders.minLon, fullBorders.minLat,
        fullBorders.maxLon, fullBorders.maxLat
      )
      if (this.cViewerRef?.viewer) {
        this.cViewerRef.viewer.camera.flyTo({
          destination: fullRectangle,
          easingFunction: EasingFunction.QUADRATIC_IN_OUT,
          duration: 2
        })
      }
    },
    updateFullBorders (fullBorders: Record<string, number>, lat: number, lon: number) {
      fullBorders.minLat = Math.min(fullBorders.minLat, lat)
      fullBorders.maxLat = Math.max(fullBorders.maxLat, lat)
      fullBorders.minLon = Math.min(fullBorders.minLon, lon)
      fullBorders.maxLon = Math.max(fullBorders.maxLon, lon)
    },
    calculateLayerBorders (layer: GeoMapLayer, fullBorders: Record<string, number>): Promise<void> {
      return new Promise(resolve => {
        const viewer = this.cViewerRef?.viewer
        if (!layer || !viewer || !this.layersRef[layer.id]) {
          return
        }
        switch (layer.type) {
          case GeoMapLayerType.WMS: {
            resolve()
            break
          }
          case GeoMapLayerType.WFS:
          case GeoMapLayerType.HYBRID: {
            const dataSource = this.layersRef[layer.id] as GeoJsonDataSource
            dataSource.entities.values.forEach(entity => {
              const position = entity.position?.getValue(JulianDate.now())
              if (position) {
                const cartographic = Cartographic.fromCartesian(position)
                const lat = CesiumMath.toDegrees(cartographic.latitude)
                const lon = CesiumMath.toDegrees(cartographic.longitude)
                this.updateFullBorders(fullBorders, lat, lon)
              }
            })
            resolve()
            break
          }
          case GeoMapLayerType.TILESET_3D: {
            const boundingSphere = (this.layersRef[layer.id] as Cesium3DTileset).boundingSphere
            const centerCartographic = Cartographic.fromCartesian(boundingSphere.center)
            const lat = CesiumMath.toDegrees(centerCartographic.latitude)
            const lon = CesiumMath.toDegrees(centerCartographic.longitude)
            this.updateFullBorders(fullBorders, lat, lon)
            resolve()
            break
          }
        }
      })
    },
    flyToLayer (layer: GeoMapLayer) {
      const viewer = this.cViewerRef?.viewer
      if (!layer || !viewer) {
        return
      }
      switch (layer.type) {
        case GeoMapLayerType.WMS:
          (this.layersRef[layer.id] as ImageryLayer).getViewableRectangle().then((rectangle) => {
            if (viewer) {
              viewer.camera.flyTo({
                destination: rectangle,
                easingFunction: EasingFunction.QUADRATIC_IN_OUT,
                duration: 2
              })
            }
          })
          break
        case GeoMapLayerType.WFS:
        case GeoMapLayerType.HYBRID:
          viewer.flyTo(this.layersRef[layer.id] as GeoJsonDataSource, {
            duration: 2
          })
          break
        case GeoMapLayerType.TILESET_3D:
          viewer.flyTo(this.layersRef[layer.id] as Cesium3DTileset, {
            duration: 2
          })
          break
        case GeoMapLayerType.TMS:
          if (layer.extent) {
            const offsetRect = 0.001
            viewer.camera.flyTo({
              destination: Rectangle.fromDegrees(layer.extent?.xMin - offsetRect, layer.extent?.yMin - offsetRect, layer.extent?.xMax + offsetRect, layer.extent?.yMax + offsetRect),
              easingFunction: EasingFunction.QUADRATIC_IN_OUT,
              duration: 2
            })
          }
          break
      }
    },
    setLayerOpacity (id: number, opacity: number) {
      var viewer = this.cViewerRef?.viewer
      var layer = this.layers.find(x => x.id === id)
      if (!viewer || !layer) {
        return
      }
      switch (layer.type) {
        case GeoMapLayerType.WMS:
          (this.layersRef[id] as ImageryLayer).alpha = opacity / 100
          break
      }
    },
    registerLayer (id: number, cesiumObject: VcReadyObject, config3d?: Offset3d) {
      this.layersRef[id] = cesiumObject
      if (config3d) this.calculatePositionTileset(id, config3d, cesiumObject)
    },
    mapLegendManipulation (newValue: boolean) {
      this.actionsBottomRightRef.showLegend = newValue
    },
    getWfsUrl (layer: GeoMapLayer) {
      switch (layer.type) {
        case GeoMapLayerType.HYBRID:
          return new Resource({
            url: (process.env.VUE_APP_API_URL ?? '') + (layer.urlWfs ?? ''),
            headers: {
              authorization: 'Bearer ' + auth.keycloak.token
            }
          })
        default:
          return layer.url
      }
    },
    getMarkerSize () {
      return defaultObjectStyle.markerSize
    },
    getUrlResourse (layer: GeoMapLayer) {
      switch (layer.type) {
        case GeoMapLayerType.HYBRID: {
          return new Resource({
            url: process.env.VUE_APP_API_URL + (layer.urlTileset ?? ''),
            headers: {
              authorization: 'Bearer ' + auth.keycloak.token
            }
          })
        }
        case GeoMapLayerType.TMS:
          if (!layer.isGeo) {
            return new Resource({ url: layer.url })
          } else {
            return new Resource({
              url: process.env.VUE_APP_API_URL + layer.url,
              headers: {
                authorization: 'Bearer ' + auth.keycloak.token
              }
            })
          }
        case GeoMapLayerType.TILESET_3D:
        default: {
          if (!layer.isGeo) {
            return new Resource({ url: layer.url })
          } else {
            return new Resource({
              url: process.env.VUE_APP_API_URL + (layer.urlTileset ?? ''),
              headers: {
                authorization: 'Bearer ' + auth.keycloak.token
              }
            })
          }
        }
      }
    }
  }
})
