import { State } from './state'
import { ThemeMutationTypes } from './mutation-types'
import { MutationTree } from 'vuex'
import { Themes } from '@/library/types/theme/types'
import { ThemeMode } from '@/library/types/theme/enum'

export type Mutations<S = State> = {
  [ThemeMutationTypes.SET_THEMES](state: S, payload: Themes): void;
  [ThemeMutationTypes.SET_MODE](state: S, payload: ThemeMode): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ThemeMutationTypes.SET_THEMES] (state: State, themes: Themes) {
    state.themes = themes
  },
  [ThemeMutationTypes.SET_MODE] (state: State, themeMode: ThemeMode) {
    state.currentThemeMode = themeMode
  }
}
