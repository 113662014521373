import i18n from '@/i18n'

import { roundAfterDecimalPoint } from '@/library/helpers'

const $t = i18n.global.t

export const BYTES_IN_KB = 1024
export const BYTES_IN_MB = BYTES_IN_KB * 1024
export const BYTES_IN_GB = BYTES_IN_MB * 1024
export const BYTES_IN_TB = BYTES_IN_GB * 1024

export const formatFileSize = (size: number, decimalNumbers?: number): string => {
  let formatedSize = ''
  decimalNumbers = decimalNumbers || 2
  if (size < BYTES_IN_KB) {
    formatedSize = `${size} ${$t('base.fileUnits.byte')}`
  } else if (size < BYTES_IN_MB) {
    formatedSize = `${roundAfterDecimalPoint(size / BYTES_IN_KB, decimalNumbers)} ${$t('base.fileUnits.kb')}`
  } else if (size < BYTES_IN_GB) {
    formatedSize = `${roundAfterDecimalPoint(size / BYTES_IN_MB, decimalNumbers)} ${$t('base.fileUnits.mb')}`
  } else if (size < BYTES_IN_TB) {
    formatedSize = `${roundAfterDecimalPoint(size / BYTES_IN_GB, decimalNumbers)} ${$t('base.fileUnits.gb')}`
  } else if (size >= BYTES_IN_TB) {
    formatedSize = `${roundAfterDecimalPoint(size / BYTES_IN_TB, decimalNumbers)} ${$t('base.fileUnits.tb')}`
  }
  return formatedSize
}
