// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum AppMutationTypes {
  SET_ORDERS = 'APP_SET_ORDERS',
  SET_MAP = 'APP_SET_MAP',

  SET_MAP_LAYERS_VISIBLE = 'MAPS_SET_MAP_LAYERS_VISIBLE',
  SET_MAP_GROUPS_VISIBLE = 'MAPS_SET_MAP_GROUPS_VISIBLE',
  SET_MAP_LAYER_EXPANDED = 'SET_MAP_LAYER_EXPANDED',
  SET_MAP_LAYERS_LIMIT = 'SET_MAP_LAYERS_LIMIT',

  SET_LIST_OF_READ = 'SET_LIST_OF_READ',
  SET_LIST_OF_WRITE = 'SET_LIST_OF_WRITE',
  SET_LIST_OF_DENY = 'SET_LIST_OF_DENY',
  RESET_ACCESS_LISTS = 'RESET_ACCESS_LISTS',

  SET_CAMERAS_PERMS = 'SET_CAMERAS_PERMS',
  SET_CAMERA_LIST_OF_READ = 'SET_CAMERA_LIST_OF_READ',
  SET_CAMERA_LIST_OF_DENY = 'SET_CAMERA_LIST_OF_DENY',

  SET_LIST_OF_SERVICE_MARKERS = 'SET_LIST_OF_SERVICE_MARKERS',
  SET_ACTIVE_CPS_MARKER = 'SET_ACTIVE_CPS_MARKER',
  SET_ACTIVE_SLIDE_EXTENT = 'SET_ACTIVE_SLIDE_EXTENT',
  SET_ACTIVE_SLIDE_EXTENT3D = 'SET_ACTIVE_SLIDE_EXTENT3D',
  SET_SLIDE_ANIMATION = 'SET_SLIDE_ANIMATION',
  SET_CPS_ACCESS_LEVEL = 'CPS_SET_CPS_ACCESS_LEVEL',
  SET_SERVICES_ACCESS_LEVELS = 'SET_SERVICES_ACCESS_LEVELS',

  SET_MAP_IS_3D_ENABLED = 'SET_MAP_IS_3D_ENABLED'
}
