
import { defineComponent, PropType, ref } from 'vue'
import i18n from '@/i18n'

import { IconType, SizeType, TextType } from '../types/base/enums'

import BaseIcon from '@/library/base/BaseIcon.vue'
import imgClose from '@/library/assets/img/icCrossedClose.svg'
import imgSearch from '@/library/assets/img/icSearch.svg'
import imgLock from '@/library/assets/img/icLock.svg'

const t = i18n.global.t

type Modificators = {
  lazy?: string
}

declare type IData = {
  innerValue: string | number | null,
  isFocus: boolean
}

export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    modelValue: { type: [String, Number], default: null },
    index: { type: [String, Number], default: undefined },
    modelModifiers: { type: Object as PropType<Modificators>, default: () => ({}) },
    placeholder: { type: String, default: null },
    nativePlaceholder: { type: String, default: null },
    type: { type: String, default: 'text' },
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledWithText: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    hasLock: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
    search: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    minValue: { type: Number, default: undefined },
    maxValue: { type: Number, default: undefined },
    maxWidth: { type: Number, default: undefined },
    showWidthCounter: { type: Boolean, default: false },
    showWidthCounterOnFocus: { type: Boolean, default: false },
    numberStep: { type: Number, default: undefined },
    customStyles: { type: Object, default: undefined },
    searchIconTip: { type: String, default: t('base.input.searchIconTip') },
    hiddenNumberArrows: { type: Boolean, default: true },
    size: {
      type: String as PropType<SizeType>,
      default: SizeType.MD
    }
  },
  emits: [
    'update:modelValue',
    'update:hasError',
    'enterHit',
    'blurEvent',
    'clear',
    'searchClick'
  ],
  setup () {
    var elInput = ref<HTMLInputElement>()
    return {
      elInput,
      imgLock,
      imgClose,
      imgSearch,
      SizeType,
      IconType,
      TextType,
      t
    }
  },
  data (): IData {
    return {
      innerValue: this.modelValue ?? '',
      isFocus: false
    }
  },
  computed: {
    hasValue () {
      return this.innerValue != null && this.innerValue.toString().length > 0
    },

    calculatedClass () {
      const result: string[] = [this.size]
      if (this.disabled) {
        result.push('disabled')
      }
      return result.join(' ')
    },

    isRequiredFieldEmpty () {
      const isEmptyString = this.innerValue && !String(this.innerValue).trim().length
      return this.required && (!this.innerValue || isEmptyString)
    },

    innerErrorMessage () {
      if (this.errorMessage) {
        return this.errorMessage
      }
      if (this.isRequiredFieldEmpty) {
        return t('base.requiredError')
      }
      return undefined
    },

    calculatedHasError () {
      const isCounter = (this.showWidthCounter || this.showWidthCounterOnFocus)
      const errorMaxWidth = this.maxWidth && String(this.modelValue).length > this.maxWidth
      return (
        this.hasError ||
        this.isRequiredFieldEmpty ||
        (isCounter && errorMaxWidth)
      )
    },

    inputType () {
      return this.type === 'number' ? 'text' : this.type
    },

    inputClass () {
      const result = ['base-input__input']
      if (this.clearable) {
        result.push('with-action-right')
      }
      if (this.search) {
        result.push('with-action-left')
      }
      if (this.calculatedHasError) {
        result.push('has-error')
      }
      if (this.hiddenNumberArrows) {
        result.push('hidden-number-arrows')
      }
      if (['date', 'datetime-local'].includes(this.type)) {
        result.push('ignored-white-space')
      }
      return result.join(' ')
    }
  },
  watch: {
    innerValue (newValue: string | number | null, oldValue: string | number | null) {
      if (newValue === oldValue || this.modelModifiers.lazy) {
        return
      }
      this.updateWithValidate(newValue, oldValue, true)
    },

    modelValue (newVal) {
      this.innerValue = newVal ?? ''
    },

    calculatedHasError (state: boolean) {
      this.$nextTick(() => {
        this.$emit('update:hasError', state)
      })
    }
  },
  mounted () {
    if (this.autofocus) {
      this.focusInput()
    }
    this.$emit('update:hasError', this.calculatedHasError)
  },
  methods: {
    clearInputField () {
      this.innerValue = ''
      this.$emit('update:modelValue', '')
      this.$emit('clear')
    },
    onFocus () {
      this.isFocus = true
    },
    updateWithValidate (
      newValue: string | number | null,
      oldValue: string | number | null,
      includeIndex = false
    ) {
      const indexValue = includeIndex ? this.index : undefined
      switch (this.type) {
        case 'number':
          if (newValue === '' || newValue === null) {
            this.$emit('update:modelValue', null, indexValue)
          } else {
            const numberValue = Number(newValue)
            if (Number.isNaN(numberValue)) {
              this.$emit('update:modelValue', oldValue, indexValue)
            } else if (typeof numberValue === 'number') {
              if (this.maxValue && numberValue > this.maxValue) {
                this.$emit('update:modelValue', this.maxValue, indexValue)
              } else if (this.minValue && numberValue < this.minValue) {
                this.$emit('update:modelValue', this.minValue, indexValue)
              } else {
                this.$emit('update:modelValue', numberValue, indexValue)
              }
            }
          }
          break
        case 'date':
        case 'datetime-local':
          this.$emit('update:modelValue', newValue === '' ? null : newValue, indexValue)
          break
        default:
          this.$emit('update:modelValue', newValue, indexValue)
      }
    },
    onEnterKey () {
      this.$emit('enterHit', this.innerValue)
      this.updateWithValidate(this.innerValue, this.modelValue)
      this.innerValue = this.modelValue
    },
    onBlur () {
      this.isFocus = false
      this.$emit('blurEvent')
      this.updateWithValidate(this.innerValue, this.modelValue)
      this.innerValue = this.modelValue
    },
    focusInput () {
      if (this.elInput) {
        this.elInput.focus()
      }
    }
  }
})
