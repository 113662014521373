import { ThemeMode } from '@/library/types/theme/enum'
import { Themes } from '@/library/types/theme/types'

export type State = {
  themes: Themes,
  currentThemeMode: ThemeMode
}

export const state: State = {
  themes: { light: {}, dark: {} },
  currentThemeMode: ThemeMode.LIGHT
}
