export const LOCAL_STORAGE_KEYS = {
  NOT_SHOW_LINK_CONFIRM: 'GEOHUB_NOT_SHOW_LINK_CONFIRM',
  NOT_SHOW_PC_UPLOAD_CONFIRM: 'GEOHUB_NOT_SHOW_PC_UPLOAD_CONFIRM_KEY',
  HISTORY_MANAGER_WINDOW_SIZE: 'GEOHUB_HISTORY_MANAGER_WINDOW_SIZE',
  ATTRIBUTIVE_TABLE_LAYER_SIZE: 'GEOHUB_ATTRIBUTIVE_TABLE_LAYER_SIZE',
  ATTRIBUTIVE_TABLE_LAYER_LOCATION: 'GEOHUB_ATTRIBUTIVE_TABLE_LAYER_LOCATION'
}

export const LOCAL_STORAGE_SEPARATOR = '~'

type ParsedSizeResult = {
  width: number
  height: number
}

type ParsedLocationResult = {
  top: number
  left: number
}

export const parseLocalStorageSize = (key: string): ParsedSizeResult | undefined => {
  if (typeof window.localStorage !== 'object') return
  const size = window.localStorage.getItem(key)
  if (!size || !size.length) return
  const [parsedWidth, parsedHeight] = size.split(LOCAL_STORAGE_SEPARATOR)
  const [width, height] = [Number(parsedWidth), Number(parsedHeight)]
  if (width && height && !Number.isNaN(width) && !Number.isNaN(height)) {
    return {
      width,
      height
    }
  }
}

export const setLocalStorageSize = (key: string, width?: number, height?: number): void => {
  if (typeof window.localStorage !== 'object') return
  if (width && height && !Number.isNaN(width) && !Number.isNaN(height)) {
    window.localStorage.setItem(key, width + LOCAL_STORAGE_SEPARATOR + height)
  }
}

export const parseLocalStorageLocation = (key: string): ParsedLocationResult | undefined => {
  if (typeof window.localStorage !== 'object') return
  const location = window.localStorage.getItem(key)
  if (!location || !location.length) return
  const [parsedTop, parsedLeft] = location.split(LOCAL_STORAGE_SEPARATOR)
  const [top, left] = [Number(parsedTop), Number(parsedLeft)]
  if (top && left && !Number.isNaN(top) && !Number.isNaN(left)) {
    return {
      top,
      left
    }
  }
}

export const setLocalStorageLocation = (key: string, top?: number, left?: number): void => {
  if (typeof window.localStorage !== 'object') return
  if (top && left && !Number.isNaN(top) && !Number.isNaN(left)) {
    window.localStorage.setItem(key, top + LOCAL_STORAGE_SEPARATOR + left)
  }
}
