export enum PrintFormat {
  A2 = 'a2',
  A3 = 'a3',
  A4 = 'a4',
  A5 = 'a5'
}

export enum BaseTaskCategory {
  LAYER_IMPORT = 'layerImport',
  LAYER_EXPORT = 'layerExport',
  LAYER_CONVERT_TO_3D = 'convert3d',
  LAYER_TRANSFORM_3D = 'transform'
}

export enum BaseTaskStatus {
  PENDING = 'pending',
  CANCELED = 'canceled',
  CREATED = 'created',
  STARTED = 'started',
  DELETED = 'deleted',
  DONE = 'done',
  ERROR = 'error'
}

export enum AttributeType {
  STRING = 'string',
  NUMBER = 'number'
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DESTRUCTIVE = 'destructive',
  DESTRUCTIVE_SECONDARY = 'destructive-secondary'
}

export enum SizeType {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

export enum MenuTriggerType {
  CLICK = 'click',
  HOVER = 'hover'
}

export enum SelectType {
  DEFAULT = 'default',
  CLEAR = 'clear'
}

export enum RadioType {
  DEFAULT = 'default',
  ICON = 'icon'
}

export enum IconType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CLEAR = 'clear',
  CLEAR_DESTRUCTIVE = 'clear-destructive',
  CANCEL = 'cancel',
  ACCEPT = 'accept',
  VISION = 'vision',
  SORTING = 'sorting',
  MODAL_CLOSE = 'modal-close',
  STATIC = 'static'
}

export enum TextType {
  HEADER = 'header',
  BODY = 'body',
  BUTTON = 'button'
}

export enum OrientationType {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export enum WindowStretchType {
  TOP_LEFT = 'top_left',
  TOP_RIGHT = 'top_right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_RIGHT = 'bottom_right',
  ALL = 'all'
}

export enum WindowVariant {
  OPERATIONS_HISTORY = 'operationsHistory'
}

export enum FieldCategory {
  GROUP = 'group',
  INPUT = 'input',
  BUTTON = 'button',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  COLOR_PICKER = 'colorPicker',
  RANGE = 'range',
  LIMIT_RANGE = 'limitRange',
  SWITCH = 'switch',
  GRADIENT_PICKER = 'gradientPicker',
  TEXT_AREA = 'textArea',
  CUSTOM = 'custom',
  SVG_ICON_LOADER = 'svgIconLoader',
  ALIGNMENT = 'alignment'
}

export enum FloatingVuePlacement {
  AUTO = 'auto',
  AUTO_START = 'auto-start',
  AUTO_END = 'auto-end',
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end'
}

export enum LogicOperator {
  AND = 'and',
  OR = 'or'
}

export enum FilterOperation {
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  LE = 'le',
  GE = 'ge',
  LT = 'lt',
  GT = 'gt',
  IN = 'in',
  CONTAINS = 'contains',
  EQ = 'eq',
  NEQ = 'neq',
  EMPTY = 'empty',
  NOT_EMPTY = 'not_empty'
}

export enum ErrorCategory {
  IMPORT = 'import',
  EXPORT = 'export'
}
