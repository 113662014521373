import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Viewer from '../components/viewer/Viewer.vue'
import PersonalArea from '@/components/lk/PersonalArea.vue'
import Activities from '@/components/lk/Activities.vue'
import Profile from '@/components/lk/Profile.vue'
import AdminPanel from '@/components/admin/AdminPanel.vue'
import GroupCreate from '@/components/admin/create/GroupCreate.vue'
import Exit from '@/components/auth/Exit.vue'
import { useStore } from '@/store'
import { HeaderTab, ServiceCode } from '@/types/baykalsk/enums'
import auth from '@/library/auth'
import CityProjects from '@/components/services/city-projects/CityProjects.vue'
import ProjectPage from '@/components/services/city-projects/ProjectPage.vue'
import CityPresentation from '@/components/services/city-projects/CityPresentation.vue'
import Users from '@/components/admin/Users.vue'
import Groups from '@/components/admin/Groups.vue'
import Map from '@/components/admin/Map.vue'
import DataPanel from '@/components/data/DataPanel.vue'
import Meteo from '@/components/data/Meteo.vue'
import Atmo from '@/components/data/Atmo.vue'
import Estate from '@/components/services/real-estate/Estate.vue'
import CityVacancies from '@/components/services/city-vacancies/CityVacancies.vue'
import CitySuggestions from '@/components/services/city-suggestions/CitySuggestions.vue'
import VideoStreams from '@/components/services/video-streams/VideoStreams.vue'

import ModerationPanel from '@/components/moderation/ModerationPanel.vue'
import Suggestions from '@/components/moderation/Suggestions.vue'
import Estates from '@/components/moderation/Estates.vue'
import EstatePreview from '@/components/moderation/estate/EstatePreview.vue'
import Vacancies from '@/components/moderation/Vacancies.vue'
import EgrnPage from '@/components/moderation/EgrnPage.vue'
import VacancyPreview from '@/components/moderation/vacancies/VacancyPreview.vue'
import SuggestionPreview from '@/components/moderation/suggestions/SuggestionPreview.vue'

import StartPage from '@/components/start-page/StartPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'start-page',
    component: StartPage
  },
  {
    path: '/viewer',
    name: 'viewer',
    meta: {
      requiresAuth: false,
      headerSection: HeaderTab.MAP
    },
    component: Viewer
  },
  {
    path: '/lk',
    name: 'personal-area',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    component: PersonalArea,
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: Profile
      },
      {
        path: 'activities',
        name: 'activities',
        component: Activities
      }
    ]
  },

  {
    path: '/administration',
    name: 'administration',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    component: AdminPanel,
    children: [
      {
        path: 'users',
        name: 'users',
        component: Users
      },
      {
        path: 'groups',
        name: 'groups',
        component: Groups
      },
      {
        path: 'map',
        name: 'map',
        component: Map
      }
    ]
  },

  {
    path: '/moderation',
    name: 'moderation',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    component: ModerationPanel,
    children: [
      {
        path: 'suggestions',
        name: 'moderation-suggestions',
        component: Suggestions
      },
      {
        path: 'estates',
        name: 'moderation-estates',
        component: Estates
      },
      {
        path: 'vacancies',
        name: 'moderation-vacancies',
        component: Vacancies
      },
      {
        path: 'egrn-page',
        name: 'moderation-egrn',
        component: EgrnPage
      }
    ]
  },

  {
    path: '/moderation-estate/preview/:uuid',
    name: 'moderation-estate-preview',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON,
      previous: 'moderation-estates'
    },
    props: {
      default: (route) => ({
        uuid: route.params.uuid != null && typeof route.params.uuid === 'string' ? route.params.uuid : null
      })
    },
    components: {
      default: EstatePreview
    }
  },

  {
    path: '/moderation-suggestion/preview/:uuid',
    name: 'moderation-suggestion-preview',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON,
      previous: 'moderation-suggestions'
    },
    props: {
      default: (route) => ({
        uuid: route.params.uuid != null && typeof route.params.uuid === 'string' ? route.params.uuid : null
      })
    },
    components: {
      default: SuggestionPreview
    }
  },

  {
    path: '/moderation-vacancy/preview/:uuid',
    name: 'moderation-vacancy-preview',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON,
      previous: 'moderation-vacancies'
    },
    props: {
      default: (route) => ({
        uuid: route.params.uuid != null && typeof route.params.uuid === 'string' ? route.params.uuid : null
      })
    },
    components: {
      default: VacancyPreview
    }
  },

  {
    path: '/lk-suggestion/preview/:uuid',
    name: 'lk-suggestion-preview',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    props: {
      default: (route) => ({
        uuid: route.params.uuid != null && typeof route.params.uuid === 'string' ? route.params.uuid : null
      })
    },
    components: {
      default: SuggestionPreview
    }
  },

  {
    path: '/lk-estate/preview/:uuid',
    name: 'lk-estate-preview',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    props: {
      default: (route) => ({
        uuid: route.params.uuid != null && typeof route.params.uuid === 'string' ? route.params.uuid : null
      })
    },
    components: {
      default: EstatePreview
    }
  },

  {
    path: '/lk-vacancy/preview/:uuid',
    name: 'lk-vacancy-preview',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    props: {
      default: (route) => ({
        uuid: route.params.uuid != null && typeof route.params.uuid === 'string' ? route.params.uuid : null
      })
    },
    components: {
      default: VacancyPreview
    }
  },

  {
    path: '/data',
    name: 'data',
    meta: {
      requiresAuth: false,
      headerSection: HeaderTab.DATA
    },
    component: DataPanel,
    children: [
      {
        path: 'meteo',
        name: 'meteo',
        component: Meteo
      },
      {
        path: 'atmo',
        name: 'atmo',
        component: Atmo
      }
    ]
  },

  {
    path: '/group/create',
    name: 'group-create',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    components: {
      default: GroupCreate
    }
  },
  {
    path: '/group/edit/:groupId/:name/:deletable',
    name: 'group-edit',
    meta: {
      requiresAuth: true,
      headerSection: HeaderTab.PERSON
    },
    props: {
      default: (route) => ({
        groupId: route.params.groupId != null && typeof route.params.groupId === 'string' ? parseInt(route.params.groupId) : null,
        name: route.params.name != null && typeof route.params.name === 'string' ? route.params.name : null,
        isCreate: false,
        deletable: route.params.deletable != null && typeof route.params.deletable === 'string' ? route.params.deletable === 'true' : false
      })
    },
    components: {
      default: GroupCreate
    }
  },
  {
    path: '/services/city-projects',
    name: 'city_projects',
    meta: {
      requiresAuth: false,
      code: 'city_projects',
      headerSection: HeaderTab.SERVICE
    },
    components: {
      default: CityProjects
    }
  },
  {
    path: '/services/project/:projectId',
    name: 'project-page',
    meta: {
      requiresAuth: false,
      code: 'city_projects',
      headerSection: HeaderTab.SERVICE
    },
    props: {
      default: (route) => ({
        projectId: route.params.projectId != null && typeof route.params.projectId === 'string' ? parseInt(route.params.projectId) : null,
        isEdit: false
      })
    },
    components: {
      default: ProjectPage
    }
  },
  {
    path: '/services/project/:projectId',
    name: 'project-edit-page',
    meta: {
      requiresAuth: true,
      code: 'city_projects',
      headerSection: HeaderTab.SERVICE
    },
    props: {
      default: (route) => ({
        projectId: route.params.projectId != null && typeof route.params.projectId === 'string' ? parseInt(route.params.projectId) : null,
        isEdit: true
      })
    },
    components: {
      default: ProjectPage
    }
  },
  {
    path: '/services/project/demo-edit/:projectId',
    name: 'project-edit-demo-page',
    meta: {
      requiresAuth: true,
      code: 'city_projects',
      headerSection: HeaderTab.SERVICE
    },
    props: {
      default: (route) => ({
        projectId: route.params.projectId != null && typeof route.params.projectId === 'string' ? parseInt(route.params.projectId) : null,
        isEdit: true
      })
    },
    components: {
      default: CityPresentation
    }
  },
  {
    path: '/services/project/demo/:projectId',
    name: 'project-demo-page',
    meta: {
      requiresAuth: false,
      code: 'city_projects',
      headerSection: HeaderTab.SERVICE
    },
    props: {
      default: (route) => ({
        projectId: route.params.projectId != null && typeof route.params.projectId === 'string' ? parseInt(route.params.projectId) : null,
        isEdit: false
      })
    },
    components: {
      default: CityPresentation
    }
  },
  {
    path: '/services/real-estate',
    name: ServiceCode.REAL_ESTATE,
    meta: {
      requiresAuth: false,
      code: ServiceCode.REAL_ESTATE,
      headerSection: HeaderTab.SERVICE
    },
    components: {
      default: Estate
    }
  },
  {
    path: '/services/city-suggestions',
    name: ServiceCode.CITY_SUGGESTION,
    meta: {
      requiresAuth: false,
      code: ServiceCode.CITY_SUGGESTION,
      headerSection: HeaderTab.SERVICE
    },
    components: {
      default: CitySuggestions
    }
  },
  {
    path: '/services/city-vacancies',
    name: ServiceCode.VACANCY,
    meta: {
      requiresAuth: false,
      code: ServiceCode.VACANCY,
      headerSection: HeaderTab.SERVICE
    },
    components: {
      default: CityVacancies
    }
  },
  {
    path: '/services/video-streams',
    name: ServiceCode.VIDEO_SURVEILLANCE,
    meta: {
      requiresAuth: false,
      code: ServiceCode.VIDEO_SURVEILLANCE,
      headerSection: HeaderTab.SERVICE
    },
    components: {
      default: VideoStreams
    }
  },
  {
    path: '/exit',
    name: 'exit',
    meta: {
      requiresAuth: false,
      headerSection: HeaderTab.PERSON
    },
    beforeEnter: () => {
      auth.keycloak.logout({ redirectUri: window.location.origin })
    },
    component: Exit
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => {
      return { name: 'start-page' }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const store = useStore()

router.beforeEach((to, from, next) => {
  if (!store.state.auth.authenticated) {
    if (to.meta.requiresAuth) {
      return next({ name: 'start-page' })
    }
  }
  next()
})

export default router
