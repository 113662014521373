export enum AppActionTypes {
  ADMIN_GET_USERS = 'ADMIN_GET_USERS',
  ADMIN_GET_GROUPS = 'ADMIN_GET_GROUPS',
  ADMIN_PUT_USER_GROUPS = 'ADMIN_PUT_USER_GROUPS',
  ADMIN_GET_USERS_OF_GROUP = 'ADMIN_GET_USERS_OF_GROUP',
  ADMIN_PUT_GROUP_USERS = 'ADMIN_PUT_GROUP_USERS',
  ADMIN_GET_MAP = 'ADMIN_GET_MAP',
  ADMIN_GET_CAMERAS_ACCESS = 'ADMIN_GET_CAMERAS_ACCESS',
  ADMIN_GET_LAYERS_OF_GROUP = 'ADMIN_GET_LAYERS_OF_GROUP',
  ADMIN_GET_SERVICES = 'ADMIN_GET_SERVICES',
  ADMIN_GET_SERVICES_OF_GROUP = 'ADMIN_GET_SERVICES_OF_GROUP',
  ADMIN_SAVE_GROUP = 'ADMIN_SAVE_GROUP',
  ADMIN_EDIT_GROUP = 'ADMIN_EDIT_GROUP',
  ADMIN_DELETE_GROUP = 'ADMIN_DELETE_GROUP',
  ADMIN_SET_MAP_LAYERS_LIMIT = 'ADMIN_SET_MAP_LAYERS_LIMIT',
  ADMIN_GET_DRAFT_LAYERS = 'ADMIN_GET_DRAFT_LAYERS',
  ADMIN_SAVE_DRAFT_LAYER = 'ADMIN_SAVE_DRAFT_LAYER',

  CPS_GET_CITY_PROJECTS = 'CPS_GET_CITY_PROJECTS',
  CPS_GET_ONE_CITY_PROJECT = 'CPS_GET_ONE_CITY_PROJECT',
  CPS_GET_ONE_CITY_PROJECT_PRESENTATION = 'CPS_GET_ONE_CITY_PROJECT_PRESENTATION',
  CPS_CREATE_PROJECT = 'CPS_CREATE_PROJECT',
  CPS_UPDATE_PROJECT = 'CPS_UPDATE_PROJECT',
  CPS_DELETE_PROJECT = 'CPS_DELETE_PROJECT',
  CPS_UPDATE_PUBLISH = 'CPS_UPDATE_PUBLISH',
  CPS_PUT_PRESENTATION = 'CPS_PUT_PRESENTATION',
  CPS_POST_ATTACHMENT = 'CPS_POST_ATTACHMENT',
  CPS_DELETE_ATTACHMENT = 'CPS_DELETE_ATTACHMENT',
  CPS_GET_COMMENTS = 'CPS_GET_COMMENTS',
  CPS_POST_COMMENT = 'CPS_POST_COMMENT',
  CPS_DELETE_COMMENT = 'CPS_DELETE_COMMENT',

  METEO_GET_WEATHER = 'METEO_GET_WEATHER',
  METEO_GET_DATES = 'METEO_GET_DATES',
  METEO_GET_HISTORY_WEATHER = 'METEO_GET_HISTORY_WEATHER',
  AIR_GET_MONITORING = 'AIR_GET_MONITORING',
  AIR_GET_DATES = 'AIR_GET_DATES',
  AIR_GET_HISTORY_MONITORING = 'AIR_GET_HISTORY_MONITORING',

  RES_GET_ESTATE_LIST = 'RES_GET_ESTATE_LIST',
  RES_GET_ESTATE_ITEM = 'RES_GET_ESTATE_ITEM',
  RES_POST_ATTACHMENT = 'RES_POST_ATTACHMENT',
  SERVICE_FIND_ADDRESS = 'SERVICE_FIND_ADDRESS',
  RES_CREATE_ADVERT = 'RES_CREATE_ADVERT',

  COMMON_GET_PRE_LAYERS = 'COMMON_GET_PRE_LAYERS',

  MODERATION_ESTATE_GET_LIST = 'MODERATION_ESTATE_GET_LIST',
  MODERATION_ESTATE_DELETE = 'MODERATION_ESTATE_DELETE',
  MODERATION_ESTATE_UPDATE_STATUS = 'MODERATION_ESTATE_UPDATE_STATUS',

  EGRN_GET_LIST = 'EGRN_GET_LIST',
  EGRN_POST_XML = 'EGRN_POST_XML',
  EGRN_CANCEL_XML = 'EGRN_CANCEL_XML',
  EGRN_GET_XML = 'EGRN_GET_XML',
  EGRN_GET_SMAV = 'EGRN_GET_SMAV',

  CVS_GET_VACANCIES_LIST = 'CVS_GET_VACANCIES_LIST',
  CVS_GET_VACANCY_ITEM = 'CVS_GET_VACANCY_ITEM',
  CVS_CREATE_VACANCY = 'CVS_CREATE_VACANCY',

  VSS_GET_VIDEO_LIST = 'VSS_GET_VIDEO_LIST',
  VSS_PUT_VIDEO_LIST = 'VSS_PUT_VIDEO_LIST',
  VSS_POST_VIDEO = 'VSS_POST_VIDEO',
  VSS_PUT_VIDEO = 'VSS_PUT_VIDEO',
  VSS_DELETE_VIDEO = 'VSS_DELETE_VIDEO',
  VSS_DELETE_GROUP = 'VSS_DELETE_GROUP',
  VSS_PATCH_VIDEO = 'VSS_PATCH_VIDEO',
  VSS_PATCH_GROUP = 'VSS_PATCH_GROUP',
  VSS_POST_GROUP = 'VSS_POST_GROUP',
  VSS_GET_VIDEO = 'VSS_GET_VIDEO',
  VSS_DOWNLOAD_RECORD = 'VSS_DOWNLOAD_RECORD',
  VSS_GET_RECORDS = 'VSS_GET_RECORDS',
  VSS_GET_AVAILABLE_DATES = 'VSS_GET_AVAILABLE_DATES',

  MODERATION_VACANCIES_GET_LIST = 'MODERATION_VACANCIES_GET_LIST',
  MODERATION_VACANCY_DELETE = 'MODERATION_VACANCY_DELETE',
  MODERATION_VACANCY_UPDATE_STATUS = 'MODERATION_VACANCY_UPDATE_STATUS',

  CSS_GET_SUGGESTIONS_LIST = 'CSS_GET_SUGGESTIONS_LIST',
  CSS_GET_SUGGESTION_ITEM = 'CSS_GET_SUGGESTION_ITEM',
  CSS_POST_ATTACHMENT = 'CSS_POST_ATTACHMENT',
  CSS_CREATE_SUGGESTION = 'CSS_CREATE_SUGGESTION',

  MODERATION_SUGGESTIONS_GET_LIST = 'MODERATION_SUGGESTIONS_GET_LIST',
  MODERATION_SUGGESTION_DELETE = 'MODERATION_SUGGESTION_DELETE',
  MODERATION_SUGGESTION_UPDATE_STATUS = 'MODERATION_SUGGESTION_UPDATE_STATUS',

  LAYER_BACKUP_CREATE = 'MAPS_LAYER_BACKUP_CREATE',
  LAYER_BACKUP_GET_LIST = 'MAPS_LAYER_BACKUP_GET_LIST',
  LAYER_BACKUP_RESTORE = 'MAPS_LAYER_BACKUP_RESTORE',

  LK_GET_ACTIVITY_SUGGESTIONS = 'LK_GET_ACTIVITY_SUGGESTIONS',
  LK_GET_ACTIVITY_ESTATE = 'LK_GET_ACTIVITY_ESTATE',
  LK_GET_ACTIVITY_VACANCIES = 'LK_GET_ACTIVITY_VACANCIES'
}
