
import { defineComponent } from 'vue'

import AppHeader from '@/components/app/AppHeader.vue'
import AppContainer from '@/components/app/AppContainer.vue'

export default defineComponent({
  components: { AppHeader, AppContainer },
  computed: {
    isStartPage (): boolean {
      return this.$router.currentRoute.value.name === 'start-page'
    }
  },
  methods: {
    navigateTo (moduleName: string) {
      this.$router.push({ name: moduleName })
    }
  }
})
