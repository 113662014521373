import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b998287"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "base-range__label"
}
const _hoisted_2 = ["min", "max", "step", "value", "disabled"]
const _hoisted_3 = {
  key: 1,
  class: "base-range__label"
}
const _hoisted_4 = {
  key: 2,
  class: "base-range__unit"
}
const _hoisted_5 = { class: "base-range__unit_label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-range", { disabled: _ctx.disabled }])
  }, [
    (_ctx.withLabels)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.minValue), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "base-range__input",
      type: "range",
      min: _ctx.minValue,
      max: _ctx.maxValue,
      step: _ctx.step,
      value: _ctx.selectedValue,
      disabled: _ctx.disabled,
      style: _normalizeStyle({ width: _ctx.width }),
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerInput && _ctx.handlerInput(...args))),
      onMousemove: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, null, 44, _hoisted_2),
    (_ctx.withLabels)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.maxValue), 1))
      : _createCommentVNode("", true),
    (_ctx.unit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_base_input, {
            modelValue: _ctx.selectedValue,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedValue) = $event)),
              _ctx.handlerInput
            ],
            class: "base-range__unit_input",
            type: "number",
            "min-value": _ctx.minValue,
            "max-value": _ctx.maxValue,
            "number-step": _ctx.step,
            disabled: _ctx.disabled,
            "has-error": _ctx.hasError,
            style: _normalizeStyle({ width: _ctx.inputWidth }),
            "model-modifiers": { lazy: 'true' },
            "custom-styles": { height: '22px', textAlign: 'center' },
            onMousemove: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
          }, null, 8, ["modelValue", "min-value", "max-value", "number-step", "disabled", "has-error", "style", "onUpdate:modelValue"]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.unit), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}