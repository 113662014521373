import { MutationTree } from 'vuex'

import { State } from './state'
import { AppMutationTypes } from './mutation-types'
import { UserModel, CPSMarkerModel, ServiceAccessModel, CameraAccess } from '@/types/baykalsk/types'
import { CityPresentationAnimation, ServicePerm, ServiceCode } from '@/types/baykalsk/enums'
import { GeoMap, Position3dInfo } from '@/library/types'

export type Mutations<S = State> = {
  [AppMutationTypes.SET_ORDERS](state: S, payload: UserModel[]): void;
  [AppMutationTypes.SET_MAP](state: S, payload: GeoMap | null): void;
  [AppMutationTypes.SET_MAP_LAYERS_VISIBLE](state: S, payload: number[]): void;
  [AppMutationTypes.SET_MAP_GROUPS_VISIBLE](state: S, payload: number[]): void;
  [AppMutationTypes.SET_MAP_LAYER_EXPANDED](state: S, payload: number[]): void;
  [AppMutationTypes.SET_LIST_OF_READ](state: S, payload: number[]): void;
  [AppMutationTypes.SET_LIST_OF_WRITE](state: S, payload: number[]): void;
  [AppMutationTypes.SET_LIST_OF_DENY](state: S, payload: number[]): void;
  [AppMutationTypes.SET_CAMERAS_PERMS](state: S, payload: CameraAccess[]): void;
  [AppMutationTypes.SET_CAMERA_LIST_OF_READ](state: S, payload: string[]): void;
  [AppMutationTypes.SET_CAMERA_LIST_OF_DENY](state: S, payload: string[]): void;
  [AppMutationTypes.RESET_ACCESS_LISTS](state: S): void;
  [AppMutationTypes.SET_LIST_OF_SERVICE_MARKERS](state: S, payload: CPSMarkerModel[]): void;
  [AppMutationTypes.SET_ACTIVE_CPS_MARKER](state: S, payload: CPSMarkerModel | null): void;
  [AppMutationTypes.SET_ACTIVE_SLIDE_EXTENT](state: S, payload: number[]): void;
  [AppMutationTypes.SET_ACTIVE_SLIDE_EXTENT3D](state: S, payload: Position3dInfo): void;
  [AppMutationTypes.SET_SLIDE_ANIMATION](state: S, payload: CityPresentationAnimation): void;
  [AppMutationTypes.SET_MAP_LAYERS_LIMIT](state: S, payload: number): void;
  [AppMutationTypes.SET_CPS_ACCESS_LEVEL](state: S, payload: ServicePerm): void;
  [AppMutationTypes.SET_SERVICES_ACCESS_LEVELS](state: S, payload: ServiceAccessModel[]): void;
  [AppMutationTypes.SET_MAP_IS_3D_ENABLED](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [AppMutationTypes.SET_ORDERS] (state: State, orders: UserModel[]) {
    state.orders = orders
  },

  [AppMutationTypes.SET_MAP] (state: State, map: GeoMap | null) {
    if (!map) return
    state.map = map
  },

  [AppMutationTypes.SET_MAP_LAYERS_VISIBLE] (state: State, ids: number[]) {
    state.visibleLayers = Array.from(new Set(ids))
  },

  [AppMutationTypes.SET_MAP_GROUPS_VISIBLE] (state: State, ids: number[]) {
    state.visibleGroups = Array.from(new Set(ids))
  },

  [AppMutationTypes.SET_MAP_LAYER_EXPANDED] (state: State, expandedLayers: number[]) {
    state.expandedLayers = expandedLayers
  },

  [AppMutationTypes.SET_LIST_OF_READ] (state: State, ids: number[]) {
    state.listOfRead = Array.from(new Set(ids))
  },

  [AppMutationTypes.SET_LIST_OF_WRITE] (state: State, ids: number[]) {
    state.listOfWrite = Array.from(new Set(ids))
  },

  [AppMutationTypes.SET_LIST_OF_DENY] (state: State, ids: number[]) {
    state.listOfDeny = Array.from(new Set(ids))
  },

  [AppMutationTypes.RESET_ACCESS_LISTS] (state: State) {
    state.listOfRead = []
    state.listOfWrite = []
    state.listOfDeny = []
  },

  [AppMutationTypes.SET_CAMERAS_PERMS] (state: State, perms: CameraAccess[]) {
    state.camerasPerms = [...perms]
  },

  [AppMutationTypes.SET_CAMERA_LIST_OF_READ] (state: State, ids: string[]) {
    state.cameraListOfRead = Array.from(new Set(ids))
  },

  [AppMutationTypes.SET_CAMERA_LIST_OF_DENY] (state: State, ids: string[]) {
    state.cameraListOfDeny = Array.from(new Set(ids))
  },

  [AppMutationTypes.SET_LIST_OF_SERVICE_MARKERS] (state: State, list: CPSMarkerModel[]) {
    state.listOfCPSMarkers = Array.from(list)
  },

  [AppMutationTypes.SET_ACTIVE_CPS_MARKER] (state: State, marker: CPSMarkerModel | null) {
    state.activeCPSMarker = marker
  },

  [AppMutationTypes.SET_ACTIVE_SLIDE_EXTENT] (state: State, extent: number[]) {
    state.activeCPSSlideExtent = extent
  },

  [AppMutationTypes.SET_ACTIVE_SLIDE_EXTENT3D] (state: State, extent: Position3dInfo) {
    state.activeCPSSlideExtent3D = extent
  },

  [AppMutationTypes.SET_SLIDE_ANIMATION] (state: State, anim: CityPresentationAnimation) {
    state.currentSlideAnimation = anim
  },

  [AppMutationTypes.SET_MAP_LAYERS_LIMIT] (state: State, limit: number) {
    state.maxVisibleLayers = limit
  },

  [AppMutationTypes.SET_CPS_ACCESS_LEVEL] (state: State, perm: ServicePerm) {
    state.CPSaccessLevel = perm
  },

  [AppMutationTypes.SET_SERVICES_ACCESS_LEVELS] (state: State, services: ServiceAccessModel[]) {
    services.forEach(s => {
      switch (s.code) {
        case ServiceCode.CITY_PROJECTS:
          state.CPSaccessLevel = s.perm
          break
        case ServiceCode.CITY_SUGGESTION:
          state.CSSaccessLevel = s.perm
          break
        case ServiceCode.REAL_ESTATE:
          state.RESaccessLevel = s.perm
          break
        case ServiceCode.VACANCY:
          state.CVSaccessLevel = s.perm
          break
        case ServiceCode.VIDEO_SURVEILLANCE:
          state.VideoAccessLevel = s.perm
          break
      }
    })
  },

  [AppMutationTypes.SET_MAP_IS_3D_ENABLED] (state: State, is3D: boolean) {
    state.is3dEnabled = is3D
  }
}
