import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_container = _resolveComponent("app-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isStartPage)
      ? (_openBlock(), _createBlock(_component_app_header, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_app_container, {
      class: _normalizeClass(["app-container", {'app-container--start-page': _ctx.isStartPage}])
    }, null, 8, ["class"])
  ], 64))
}