import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3074a02e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "olSurveyRef",
  class: "survey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_base_icon, {
      "model-value": _ctx.isActive,
      type: _ctx.IconType.SECONDARY,
      size: _ctx.SizeType.LG,
      src: _ctx.imgMiniMap,
      toggled: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick()))
    }, null, 8, ["model-value", "type", "size", "src"]), [
      [_directive_tooltip, _ctx.$t('maps.view.minimap.title')]
    ]),
    _createElementVNode("div", _hoisted_1, null, 512)
  ], 64))
}