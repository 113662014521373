
import { WmsLegendRuleSymbolizer } from '@/library/types/maps/wmsLegends'
import { defineComponent, PropType } from 'vue'
import LegendLine from './LegendLine.vue'
import LegendPoint from './LegendPoint.vue'
import LegendPolygon from './LegendPolygon.vue'
import LegendRaster from './LegendRaster.vue'

export default defineComponent({
  components: { LegendPolygon, LegendPoint, LegendLine, LegendRaster },
  props: {
    symbol: { type: Object as PropType<WmsLegendRuleSymbolizer>, required: true }
  }
})
