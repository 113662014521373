import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { useStore } from './store'

// import cssVars from 'css-vars-ponyfill'
import themeDefault from './themes/default.json'
import themes from '@/library/themes'
import i18n from './i18n'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import auth from '@/library/auth'

import { AuthActionTypes } from './store/modules/auth/action-types'
import { AuthMutationTypes } from './store/modules/auth/mutation-types'
import Toast, { PluginOptions, POSITION } from 'vue-toastification'

const store = useStore()

const toastOptions: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 10000,
  icon: {
    iconClass: 'toast-icon',
    iconTag: 'div'
  },
  closeButtonClassName: 'toast-close',
  closeButton: 'div',
  shareAppContext: true,
  onMounted: (_, toastApp) => {
    toastApp.use(router)
  }
}

/* cssVars({
  variables: themeDefault
}) */
themes.setDefault()
themes.setTheme(themeDefault)
// themes.setActiveMode()

auth.init().then((result: unknown) => {
  if (!result) {
    store.commit(AuthMutationTypes.SET_AUTHENTICATED, false)
    doMount()
  } else {
    store.commit(AuthMutationTypes.SET_AUTHENTICATED, true)
    /* Need waiting for answer GET<self> cause backend check */
    /* for user exist, to avoid create new user by every request */
    store.dispatch(AuthActionTypes.GET_SELF)
      .then(() => {
        doMount()
      }
      )
  }
})

const doMount = () => {
  const application = createApp(App).use(i18n).use(store).use(router).use(FloatingVue, {
    themes: {
      select: {
        $extend: 'menu',
        placement: 'bottom',
        triggers: ['click']
      },
      hint: {
        placement: 'auto',
        triggers: ['hover']
      },
      'base-tooltip': {
        $extend: 'menu',
        resetCss: true
      },
      contextMenu: {
        $extend: 'menu',
        resetCss: true
      },
      selector: {
        resetCss: true
      },
      calendar: {
        resetCss: true
      }
    }
  }).use(Toast, toastOptions)
  application.mount('#app')
}
