import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ef50815"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-alignment", { disabled: _ctx.disabled }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variants, (item) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: _normalizeClass(["base-alignment__item", {selected: item.id === _ctx.selectedValue}]),
        onClick: ($event: any) => (_ctx.handlerChoice(item.id))
      }, null, 10, _hoisted_1)), [
        [_directive_tooltip, item.title]
      ])
    }), 128))
  ], 2))
}