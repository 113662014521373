
import { defineComponent, inject, computed, PropType } from 'vue'
import {
  ImageryLayer,
  OpenStreetMapImageryProvider,
  Credit,
  Rectangle,
  Ellipsoid
} from 'cesium'

import { CViewerKey } from './../symbol'

export default defineComponent({
  name: 'COsmProvider',

  props: {
    url: {
      type: String,
      required: true
    },
    fileExtension: {
      type: String,
      default: 'png'
    },
    rectangle: {
      type: Object as PropType<Rectangle>,
      default: undefined
    },
    minimumLevel: {
      type: Number,
      default: 0
    },
    maximumLevel: {
      type: Number,
      default: undefined
    },
    ellipsoid: {
      type: Object as PropType<Ellipsoid>,
      default: undefined
    },
    credit: {
      type: [String, Object] as PropType<string | Credit>,
      default: ''
    }
  },

  setup () {
    const viewer = inject(CViewerKey)
    return {
      viewer: computed(() => viewer?.value)
    }
  },

  data: () => ({
    layer: null as ImageryLayer | null
  }),

  watch: {
    viewer () {
      this.reloadLayer()
    }
  },

  beforeUnmount () {
    if (this.viewer && this.layer && !this.viewer.isDestroyed()) {
      this.viewer.scene.globe.imageryLayers.remove(this.layer as ImageryLayer, true)
    }
  },

  mounted () {
    this.reloadLayer()
  },

  methods: {
    reloadLayer () {
      if (this.viewer) {
        this.layer = this.viewer.scene.globe.imageryLayers.addImageryProvider(
          new OpenStreetMapImageryProvider({
            url: this.url,
            fileExtension: this.fileExtension,
            rectangle: this.rectangle,
            minimumLevel: this.minimumLevel,
            maximumLevel: this.maximumLevel,
            ellipsoid: this.ellipsoid,
            credit: this.credit
          }),
          1
        )
      }
    }
  }
})
