import i18n from '@/i18n'

import { FeatureType } from '@/library/types/maps/enums'
import {
  StyleEditorMode,
  PointSymbolType,
  LinePositionType
} from '@/library/types/styles_editor/enums'

const $t = i18n.global.t

export const styleFormatOptions = [{
  value: $t('maps.layers.styles.base.formatOptions.simple'),
  key: 'simple'
}, {
  value: $t('maps.layers.styles.base.formatOptions.editable'),
  key: 'editable'
}]

export const polygonStrokeTypeOptions = [{
  key: 'solid',
  value: $t('maps.layers.styles.polygon.strokeOptions.solid')
}, {
  key: 'dashed',
  value: $t('maps.layers.styles.polygon.strokeOptions.dashed')
}]

export const lineTypeOptions = [{
  key: 'solid',
  value: $t('maps.layers.styles.line.lineTypeOptions.solid')
}, {
  key: 'dotted',
  value: $t('maps.layers.styles.line.lineTypeOptions.dotted')
}, {
  key: 'dashed',
  value: $t('maps.layers.styles.line.lineTypeOptions.dashed')
}]

export const sizeOptions = [{
  key: 'standart',
  value: $t('maps.layers.styles.base.sizeOptions.standart')
}, {
  key: 'byValue',
  value: $t('maps.layers.styles.base.sizeOptions.byValue')
}]

export const fontFamilyOptions = [
  { key: 'openSans', value: 'Open Sans' },
  { key: 'montserrat', value: 'Montserrat' },
  { key: 'notoSerif', value: 'Noto Serif' },
  { key: 'roboto', value: 'Roboto' }
]

export const fontStyleOptions = [{
  key: 'Regular',
  value: $t('maps.layers.styles.signature.fontStyleOptions.normal')
}, {
  key: 'Bold',
  value: $t('maps.layers.styles.signature.fontStyleOptions.bold')
}, {
  key: 'Italic',
  value: $t('maps.layers.styles.signature.fontStyleOptions.italic')
}]

export const symbolPositionOptions = [{
  key: 'start',
  value: $t('maps.layers.styles.signature.symbolPositionOptions.start')
}, {
  key: 'end',
  value: $t('maps.layers.styles.signature.symbolPositionOptions.end')
}]

export const geometryTypeOptions = [{
  key: FeatureType.POLYGON,
  value: $t('maps.layers.styles.geometryType.polygon')
}, {
  key: FeatureType.POINT,
  value: $t('maps.layers.styles.geometryType.point')
}, {
  key: FeatureType.LINE_STRING,
  value: $t('maps.layers.styles.geometryType.line')
}, {
  key: FeatureType.MULTI_POLYGON,
  value: $t('maps.layers.styles.geometryType.multipolygon')
}, {
  key: FeatureType.MULTI_POINT,
  value: $t('maps.layers.styles.geometryType.multipoint')
}, {
  key: FeatureType.MULTI_LINE_STRING,
  value: $t('maps.layers.styles.geometryType.multiline')
}]

export const editorModeOptions = [{
  key: StyleEditorMode.STYLE_EDITOR,
  value: $t('maps.layers.styles.editorMode.style')
}, {
  key: StyleEditorMode.SLD_EDITOR,
  value: $t('maps.layers.styles.editorMode.sld')
}]

export const pointSymbolTypeOptions = [{
  key: PointSymbolType.SIMPLE,
  value: $t('maps.layers.styles.pointSymbolType.simple')
}, {
  key: PointSymbolType.SVG,
  value: $t('maps.layers.styles.pointSymbolType.svg')
}]

export const linePositionOptions = [{
  key: LinePositionType.FOLLOW_LINE,
  value: $t('maps.layers.styles.linePositionType.follow_line')
}, {
  key: LinePositionType.HORIZONTAL,
  value: $t('maps.layers.styles.linePositionType.horizontal')
}]
