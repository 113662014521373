
import { defineComponent, PropType } from 'vue'

import { SizeType, TextType } from '@/library/types/base/enums'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'

import imgDone from '@/library/assets/img/icDone.svg'

export default defineComponent({
  components: { BaseIcon, BaseHeader },
  props: {
    modelValue: { type: [Array, Boolean], required: true },
    value: { type: [String, Boolean, Number], required: false, default: null },
    title: { type: String, required: false, default: null },
    trueValue: { type: [String, Number, Boolean], default: true },
    falseValue: { type: [String, Number, Boolean], default: false },
    disabled: { type: Boolean, default: false },
    showTip: { type: Boolean, default: false },
    textLevel: { type: [String, Number], default: '3' },
    textType: { type: String as PropType<TextType>, default: TextType.BUTTON },
    size: {
      type: String as PropType<SizeType>,
      default: SizeType.MD
    }
  },
  emits: ['update:modelValue'],
  setup () {
    return {
      imgDone,
      TextType
    }
  },
  computed: {
    checked () {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) !== -1
      } else {
        return this.modelValue === this.trueValue
      }
    }
  },
  methods: {
    updateModel () {
      if (this.disabled) {
        return
      }

      if (this.checked) {
        this.$emit('update:modelValue', Array.isArray(this.modelValue) ? this.modelValue.filter(x => x !== this.value) : this.falseValue)
      } else {
        this.$emit('update:modelValue', Array.isArray(this.modelValue) ? [...this.modelValue, this.value] : this.trueValue)
      }
    }
  }
})
