import { onMounted, onBeforeUnmount, Ref } from 'vue'

export function useClickOutside (
  elementRefs: Array<Ref<HTMLElement | null>>,
  handler: () => void) {
  const clickOutside = (e: Event) => {
    if (elementRefs.every(ref => !ref.value?.contains(e.target as Node))) handler()
  }
  onMounted(() => {
    window.addEventListener('click', clickOutside)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('click', clickOutside)
  })
}
