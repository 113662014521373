import { GeoMapLayer, VisibleLayer, GeoMapLayergroup } from '@/library/types'
import { GeoMapLayerType } from '../types/maps/enums'

export const MIN_ALLOWABLE_ZOOM = 1
export const MAX_ALLOWABLE_ZOOM = 24
export const ZOOM_PRECISION = 1
export const PROP_GEO_LAYER_ID = 'geoLayerId'
export const PROP_APPLY_STYLE = 'applyMapboxStyle'
export const MAX_LAYER_NAME_LENGTH = 300

export const getLayersList = (group: GeoMapLayergroup): GeoMapLayer[] => {
  return group.groups.reduce<GeoMapLayer[]>((arr, item) => {
    return [...arr, ...getLayersList(item)]
  }, group.layers)
}

export const getGroupsList = (group: GeoMapLayergroup): GeoMapLayergroup[] => {
  return group.groups.reduce<GeoMapLayergroup[]>((arr, item) => {
    return [...arr, ...getGroupsList(item)]
  }, group.groups)
}

export const findGroup = (group: GeoMapLayergroup, id: number): GeoMapLayergroup | null => {
  return group.groups.reduce((sg: null | GeoMapLayergroup, item) => {
    if (item.id === id) {
      sg = item
      return sg
    } else {
      return sg || findGroup(item, id)
    }
  }, null)
}

export const findLayer = (layers: GeoMapLayer[], id: number): GeoMapLayer | undefined => {
  return layers.find(x => x.id === id)
}

export const recursiveGetLayer = (group: GeoMapLayergroup, layerId: number): GeoMapLayer | undefined => {
  let result: GeoMapLayer | undefined
  result = findLayer(group.layers, layerId)
  group.groups.forEach(childGroup => {
    if (!result) {
      result = recursiveGetLayer(childGroup, layerId)
    }
  })
  return result
}

export const findParentGroup = (group: GeoMapLayergroup, id: number): GeoMapLayergroup | null => {
  let result: GeoMapLayergroup | null = null
  group.groups.forEach((item) => {
    if (item.id === id) {
      result = group
    } else if (result === null) {
      result = findParentGroup(item, id)
    }
  })
  return result
}

export const recursiveDeleteLayers = (group: GeoMapLayergroup, layerIds: number[]): void => {
  group.groups.forEach(childGroup => {
    recursiveDeleteLayers(childGroup, layerIds)
  })
  group.layersIds = group.layersIds.filter(layerId => {
    return !layerIds.includes(layerId)
  })
  group.layers = group.layers.filter(item => {
    return !layerIds.includes(item.id)
  })
}

export const recursiveRefreshLayer = (group: GeoMapLayergroup, newLayer: GeoMapLayer): void => {
  group.groups.forEach(childGroup => {
    recursiveRefreshLayer(childGroup, newLayer)
  })
  group.layers.every((item, index) => {
    if (item.id === newLayer.id) {
      group.layers.splice(index, 1, newLayer)
      return false
    }
    return true
  })
}

export const getTreeLevelById = (
  group: GeoMapLayergroup, itemId: number, level = 0
): number | undefined => {
  let result: number | undefined
  if (group.id === itemId || group.layers.find(layer => layer.id === itemId)) {
    return level
  }
  group.groups.every(childGroup => {
    if (!result) {
      result = getTreeLevelById(childGroup, itemId, Number(level) + 1)
    }
    return !result
  })
  return result
}

export const getTreeMaxDeep = (
  group: GeoMapLayergroup, deep = 0, maxDeep = 0
): number => {
  maxDeep = Math.max(maxDeep, deep)
  if (group.groups.length === 0) {
    return maxDeep
  }
  return group.groups.reduce((acc, childGroup) => {
    return Math.max(acc, getTreeMaxDeep(childGroup, Number(deep) + 1, maxDeep))
  }, maxDeep)
}

export const isLayer = (item: GeoMapLayer | GeoMapLayergroup): item is GeoMapLayer => {
  return (item as GeoMapLayer).url !== undefined
}

export const isGroup = (item: GeoMapLayer | GeoMapLayergroup): item is GeoMapLayergroup => {
  return (item as GeoMapLayergroup).groups !== undefined
}

export const getFlatList = (group: GeoMapLayergroup): (GeoMapLayer | GeoMapLayergroup)[] => {
  const arr = [...group.groups, ...group.layers].sort((a, b) => b.zIndex - a.zIndex)
  return arr.reduce<(GeoMapLayer | GeoMapLayergroup)[]>((flat, item) => {
    flat.push(item)
    if (isGroup(item)) {
      flat = [...flat, ...getFlatList(item)]
    }
    return flat
  }, [])
}

export const getSortedUniqueLayers = (
  layers: VisibleLayer[], rootGroup?: GeoMapLayergroup
): VisibleLayer[] => {
  const existIds: number[] = []
  const uniqueItems = layers.filter(item => {
    if (!existIds.includes(item.layerId)) {
      existIds.push(item.layerId)
      return true
    }
    return false
  })
  if (rootGroup) {
    const flatList = getFlatList(rootGroup)
    uniqueItems.sort((left: VisibleLayer, right: VisibleLayer) => {
      const leftInstance = flatList.find(layer => left.layerId === layer.id)
      const rightInstance = flatList.find(layer => right.layerId === layer.id)
      if (leftInstance && rightInstance) {
        return rightInstance.zIndex - leftInstance.zIndex
      }
      return 0
    })
  }
  return uniqueItems
}

export const isLayerHasExtent = (layer: GeoMapLayer): boolean => {
  if (layer.extent !== null) {
    return true
  }
  return [
    GeoMapLayerType.TILESET_3D,
    GeoMapLayerType.WFS,
    GeoMapLayerType.HYBRID
  ].includes(layer.type)
}

export const isRasterLayer = (layer: GeoMapLayer): boolean => {
  return layer.type !== GeoMapLayerType.HYBRID && typeof layer.rasterId === 'number'
}

export const is3dLayer = (layer: GeoMapLayer): boolean => {
  return layer.type === GeoMapLayerType.TILESET_3D
}

export const isHybridLayer = (layer: GeoMapLayer): boolean => {
  return layer.type === GeoMapLayerType.HYBRID
}

export const isExternalLayer = (layer: GeoMapLayer): boolean => {
  return layer.url.length > 0 && !layer.isGeo
}

export const deepLayersCountInGroup = (
  groups: GeoMapLayergroup[], onlyWithExtent = false
): number => {
  return groups.reduce((count, childGroup) => {
    if (Array.isArray(childGroup.groups)) {
      let filteredLayers = childGroup.layers
      if (onlyWithExtent) {
        filteredLayers = childGroup.layers.filter(isLayerHasExtent)
      }
      const existValue = deepLayersCountInGroup(childGroup.groups, onlyWithExtent)
      return count + filteredLayers.length + existValue
    }
    return count
  }, 0)
}
