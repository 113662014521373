import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_base_icon, {
    type: _ctx.IconType.SECONDARY,
    "model-value": _ctx.isActive,
    size: _ctx.SizeType.LG,
    src: _ctx.imgScale,
    toggled: "",
    onClick: _ctx.handleClick
  }, null, 8, ["type", "model-value", "size", "src", "onClick"])), [
    [_directive_tooltip, _ctx.$t('maps.extent.actions.scaleArea')]
  ])
}