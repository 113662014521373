import { GetterTree } from 'vuex'

import { RootState } from '@/store'

import { State } from './state'

import { GeoMap, GeoMapLayer, GeoMapLayergroup } from '@/library/types'
import { getLayersList, getGroupsList } from '@/library/helpers/layers'

export type Getters = {
  getMaps(state: State): GeoMap[] | null
  getMap(state: State): GeoMap | null
  getLayersList(state: State): GeoMapLayer[]
  getGroupsList(state: State): GeoMapLayergroup[]
}

export const getters: GetterTree<State, RootState> & Getters = {
  getMaps: (state) => state.list,
  getMap: (state) => state.map || null,
  getGroupsList: (state) => {
    if (!state.map) {
      return []
    }

    return getGroupsList(state.map.groups)
  },
  getLayersList: (state) => {
    if (!state.map) {
      return []
    }

    return getLayersList(state.map.groups)
  }
}
