import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_empty_message = _resolveComponent("base-empty-message")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-drop-zone", { fullScreen: _ctx.fullScreen }])
  }, [
    (_ctx.limitedZone)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["base-drop-zone__inner", { active: _ctx.isZoneActive }]),
          onDragenter: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isZoneActive = true), ["prevent"])),
          onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"])),
          onDragleave: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deactivateZone && _ctx.deactivateZone(...args)), ["prevent"])),
          onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dropHandler && _ctx.dropHandler(...args)), ["prevent"]))
        }, [
          _createVNode(_component_base_empty_message, {
            icon: _ctx.icon,
            width: 500,
            height: 200,
            title: _ctx.title
          }, null, 8, ["icon", "title"])
        ], 34))
      : (_openBlock(), _createBlock(_component_base_empty_message, {
          key: 1,
          icon: _ctx.icon,
          width: 500,
          height: 200,
          title: _ctx.title
        }, null, 8, ["icon", "title"]))
  ], 2)), [
    [_vShow, _ctx.isDragging]
  ])
}