import { createStore, createLogger } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

// TODO: How to surpass cyclical dependency linting errors cleanly?

// eslint-disable-next-line import/no-cycle
import { store as auth, AuthStore, State as AuthState } from '@/store/modules/auth'
import { store as baykalsk, BaykalskStore, State as BaykalskState } from '@/store/modules/baykalsk'

export type RootState = {
  auth: AuthState
  baykalsk: BaykalskState
};

export type Store = AuthStore<Pick<RootState, 'auth'>>
  & BaykalskStore<Pick<RootState, 'baykalsk'>>

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

// Plug in session storage based persistence
// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore<RootState>({
  plugins,
  modules: {
    auth,
    baykalsk
  }
})

export function useStore (): Store {
  return store as Store
}
