import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07555a2f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input" }
const _hoisted_2 = ["type", "disabled", "placeholder", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "elInput",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      class: _normalizeClass(["base-input__input", {'search': _ctx.search, 'is-error': !_ctx.innerValue.length || _ctx.innerValue.trim() === '' || _ctx.regExpNotValid }]),
      type: _ctx.type,
      disabled: _ctx.disabled,
      placeholder: _ctx.innerPlaceholder,
      maxlength: _ctx.max,
      onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleKeyUp && _ctx.handleKeyUp(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_2), [
      [_vModelDynamic, _ctx.innerValue]
    ])
  ]))
}