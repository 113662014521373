import { GetterTree } from 'vuex'

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/library/store'

import { GeoMap, GeoMapLayer, GeoMapLayergroup } from '@/library/types'
import { getLayersList, getGroupsList } from '@/library/helpers/layers'

import { State } from './state'

export type Getters = {
  getMaps(state: State): GeoMap[] | null
  getMap(state: State): GeoMap | null
  getVisibleLayers(state: State): GeoMapLayer[]
  getVisibleLayerIds(state: State): number[]
  getLayersList(state: State): GeoMapLayer[]
  getGroupsList(state: State): GeoMapLayergroup[]
}

export const getters: GetterTree<State, RootState> & Getters = {
  getMaps: (state) => state.list,
  getMap: (state) => state.map || null,
  getGroupsList: (state) => {
    if (!state.map) {
      return []
    }

    return getGroupsList(state.map.groups)
  },

  getVisibleLayers: (state) => {
    if (!state.map) {
      return []
    }
    const layerIds = Object.values(state.visibleLayers).map(item => item.layerId)
    return getLayersList(state.map.groups).filter(item => {
      return layerIds.includes(item.id)
    })
  },

  getVisibleLayerIds: (state) => {
    if (!state.map) {
      return []
    }
    return Object.values(state.visibleLayers).map(item => item.layerId)
  },

  getLayersList: (state) => {
    if (!state.map) {
      return []
    }

    return getLayersList(state.map.groups)
  }
}
