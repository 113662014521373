import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-251b2b8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-3d" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_osm_provider = _resolveComponent("c-osm-provider")!
  const _component_c_open_topo_map_provider = _resolveComponent("c-open-topo-map-provider")!
  const _component_c_base_map_provider = _resolveComponent("c-base-map-provider")!
  const _component_c_google_provider = _resolveComponent("c-google-provider")!
  const _component_c_wms_provider = _resolveComponent("c-wms-provider")!
  const _component_c_tms_provider = _resolveComponent("c-tms-provider")!
  const _component_c_primitive_tileset = _resolveComponent("c-primitive-tileset")!
  const _component_c_datasource_geojson = _resolveComponent("c-datasource-geojson")!
  const _component_c_viewer = _resolveComponent("c-viewer")!
  const _component_click_info3_d = _resolveComponent("click-info3-d")!
  const _component_cesium_control_full_extent = _resolveComponent("cesium-control-full-extent")!
  const _component_bookmarks = _resolveComponent("bookmarks")!
  const _component_extent_link_creator = _resolveComponent("extent-link-creator")!
  const _component_geolocation = _resolveComponent("geolocation")!
  const _component_map_location = _resolveComponent("map-location")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_actions_top_right = _resolveComponent("actions-top-right")!
  const _component_ofp_tool = _resolveComponent("ofp-tool")!
  const _component_action_legend = _resolveComponent("action-legend")!
  const _component_action_base_map = _resolveComponent("action-base-map")!
  const _component_action_relief = _resolveComponent("action-relief")!
  const _component_actions_bottom_right = _resolveComponent("actions-bottom-right")!
  const _component_layers = _resolveComponent("layers")!
  const _component_attribution_table = _resolveComponent("attribution-table")!
  const _component_layer_style = _resolveComponent("layer-style")!
  const _component_sld_editor = _resolveComponent("sld-editor")!
  const _component_layer_settings = _resolveComponent("layer-settings")!
  const _component_layer_external_settings = _resolveComponent("layer-external-settings")!
  const _component_layer_convert_to3d = _resolveComponent("layer-convert-to3d")!
  const _component_layer_transform3d = _resolveComponent("layer-transform3d")!
  const _component_cesium_control_footer = _resolveComponent("cesium-control-footer")!
  const _component_map_sync = _resolveComponent("map-sync")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_c_viewer, {
        id: "cesiumCanvas",
        ref: "cViewerRef",
        key: 'loadReliefWithLimit' + _ctx.isWithRelief,
        "scene-mode": 3,
        "scene3-d-only": "",
        "load-relief-with-limit": _ctx.isWithRelief,
        "onCamera:moveend": _cache[0] || (_cache[0] = ($event: any) => {_ctx.lazyUpdateSession(); _ctx.emitExtent();}),
        "onCamera:changed": _ctx.lazyUpdateSession
      }, {
        default: _withCtx(() => [
          (_ctx.currentBasemap === _ctx.BaseMapType.OSM)
            ? (_openBlock(), _createBlock(_component_c_osm_provider, {
                key: 0,
                url: "https://a.tile.openstreetmap.org"
              }))
            : (_ctx.currentBasemap === _ctx.BaseMapType.OPEN_TOPO_MAP)
              ? (_openBlock(), _createBlock(_component_c_open_topo_map_provider, { key: 1 }))
              : (_ctx.currentBasemap === _ctx.BaseMapType.BBM)
                ? (_openBlock(), _createBlock(_component_c_base_map_provider, { key: 2 }))
                : (_ctx.currentBasemap === _ctx.BaseMapType.GOOGLE_SPUTNIK)
                  ? (_openBlock(), _createBlock(_component_c_google_provider, {
                      key: 3,
                      url: "https://www.google.com/maps/@?api=1&map_action=map&basemap=satellite"
                    }))
                  : _createCommentVNode("", true),
          (_ctx.showOFP)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ofpWMS, (item) => {
                  return (_openBlock(), _createBlock(_component_c_wms_provider, {
                    key: item.id,
                    url: item.url,
                    parameters: _ctx.wmsParameters,
                    "is-ofp": ""
                  }, null, 8, ["url", "parameters"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ofpTMS, (item) => {
                  return (_openBlock(), _createBlock(_component_c_tms_provider, {
                    key: item.id,
                    url: _ctx.getUrlResourse(item),
                    "geo-layer": item
                  }, null, 8, ["url", "geo-layer"]))
                }), 128))
              ], 64))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageLayers, (item) => {
            return (_openBlock(), _createBlock(_component_c_wms_provider, {
              key: item.id,
              url: item.url,
              parameters: _ctx.wmsParameters,
              "geo-layer": item
            }, null, 8, ["url", "parameters", "geo-layer"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tiledLayers, (item) => {
            return (_openBlock(), _createBlock(_component_c_tms_provider, {
              key: item.id,
              url: _ctx.getUrlResourse(item),
              "geo-layer": item
            }, null, 8, ["url", "geo-layer"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layers3dTileset, (item) => {
            return (_openBlock(), _createBlock(_component_c_primitive_tileset, {
              key: item.id,
              "geo-layer": item,
              url: _ctx.getUrlResourse(item),
              onReady: ($event: any) => (_ctx.registerLayer(item.id, $event, item.config3d))
            }, null, 8, ["geo-layer", "url", "onReady"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vectorLayers, (item) => {
            return (_openBlock(), _createBlock(_component_c_datasource_geojson, {
              key: item.id,
              data: _ctx.getWfsUrl(item),
              "clamp-to-ground": true,
              "geo-layer": item,
              onReady: ($event: any) => (_ctx.registerLayer(item.id, $event))
            }, null, 8, ["data", "geo-layer", "onReady"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["load-relief-with-limit", "onCamera:changed"]))
    ]),
    _createVNode(_component_click_info3_d),
    (_ctx.allFunctionalEnable)
      ? (_openBlock(), _createBlock(_component_cesium_control_full_extent, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.allFunctionalEnable)
      ? (_openBlock(), _createBlock(_component_actions_top_right, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_bookmarks),
            _createVNode(_component_extent_link_creator),
            _createVNode(_component_geolocation),
            _createVNode(_component_map_location),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drawingActionInstances, (drawingActionInstance) => {
              return (_openBlock(), _createBlock(_component_base_icon, {
                key: `n${drawingActionInstance.name}a${drawingActionInstance.isActive}`,
                type: _ctx.IconType.SECONDARY,
                class: _normalizeClass(["actions__tools-btn", { 'active': drawingActionInstance.isActive }]),
                title: drawingActionInstance.name,
                onClick: ($event: any) => (_ctx.toggle(drawingActionInstance.name))
              }, {
                default: _withCtx(() => [
                  (drawingActionInstance.name === _ctx.toolName.horizontal)
                    ? (_openBlock(), _createBlock(_component_inline_svg, {
                        key: 0,
                        src: _ctx.imgRuler
                      }, null, 8, ["src"]))
                    : (drawingActionInstance.name === _ctx.toolName.area)
                      ? (_openBlock(), _createBlock(_component_inline_svg, {
                          key: 1,
                          src: _ctx.imgRuler
                        }, null, 8, ["src"]))
                      : (_openBlock(), _createBlock(_component_inline_svg, {
                          key: 2,
                          src: _ctx.imgTools
                        }, null, 8, ["src"]))
                ]),
                _: 2
              }, 1032, ["type", "class", "title", "onClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_actions_bottom_right, null, {
      default: _withCtx(() => [
        _createVNode(_component_ofp_tool, {
          "ofp-state": _ctx.showOFP,
          "onUpdate:ofpState": _cache[1] || (_cache[1] = (newState) => _ctx.showOFP = newState)
        }, null, 8, ["ofp-state"]),
        _createVNode(_component_action_legend),
        _createVNode(_component_action_base_map),
        _createVNode(_component_action_relief, {
          relief: _ctx.isWithRelief,
          onReliefState: _ctx.changeIsWithRelief
        }, null, 8, ["relief", "onReliefState"]),
        _renderSlot(_ctx.$slots, "actions-bottom-right", {}, undefined, true)
      ]),
      _: 3
    }),
    (_ctx.withLayers)
      ? (_openBlock(), _createBlock(_component_layers, {
          key: 2,
          "is-on3d": "",
          onFlyToLayer: _ctx.flyToLayer,
          onFlyToGroup: _ctx.flyToGroup,
          onSetLayerOpacity: _ctx.setLayerOpacity
        }, null, 8, ["onFlyToLayer", "onFlyToGroup", "onSetLayerOpacity"]))
      : _createCommentVNode("", true),
    _createVNode(_component_attribution_table),
    _createVNode(_component_layer_style),
    _createVNode(_component_sld_editor),
    _createVNode(_component_layer_settings),
    _createVNode(_component_layer_external_settings),
    _createVNode(_component_layer_convert_to3d),
    _createVNode(_component_layer_transform3d),
    _createVNode(_component_cesium_control_footer),
    _createVNode(_component_map_sync)
  ], 64))
}