export enum ChangeLogColumnProperties {
  actionType = 'action_type',
  attributeName = 'attribute_name',
  author = 'author',
  idGeocore = 'id_geocore',
  isRestorable = 'is_restorable',
  modified = 'modified',
  newValue = 'new_value',
  prevValue = 'prev_value'
}

export enum ChangeLogActionType {
  changeAttribute = 'change_attribute',
  changeGeometry = 'change_geometry',
  createAttribute = 'create_attribute',
  removeAttribute = 'remove_attribute',
  createObject = 'create_object',
  deleteObject = 'delete_object',
}
