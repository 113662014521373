
import { defineComponent, ref, inject, computed } from 'vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { SizeType, IconType } from '@/library/types/base/enums'
import MapDownload from './MapDownload.vue'
import MapDownloadSvg from '@/library/assets/img/download2.svg'
import { jsPDF as JsPDF } from 'jspdf'
import { Map2DKey } from '@/library/maps/viewer/symbol'
import html2canvas from 'html2canvas'
import { ActionTopRightKey } from './../symbol'

const ACTION_KEY = 'mapDownload'

declare type IData = {
  fileName: string,
  style: {
    left: string,
    top: string,
    offsetTop: number,
    offsetLeft: number
  },
  clientX: number,
  clientY: number,
  movementX: number,
  movementY: number
}

export default defineComponent({
  components: {
    MapDownload,
    BaseIcon
  },
  emits: [
    'close',
    'printLegend'
  ],
  setup () {
    const draggableContainerRef = ref()
    const mapRoot = inject(Map2DKey)

    const ActionTopRight = inject(ActionTopRightKey)
    const isActive = computed({
      get () {
        return ActionTopRight?.value.activeAction === ACTION_KEY
      },
      set (val: boolean) {
        if (!ActionTopRight?.value) {
          return
        }

        if (val) {
          ActionTopRight.value.activateAction(ACTION_KEY)
        } else {
          ActionTopRight.value.activateAction(undefined)
        }
      }
    })

    return {
      draggableContainerRef,
      mapRoot,
      ActionTopRight,
      isActive,
      MapDownloadSvg,
      SizeType,
      IconType
    }
  },
  data (): IData {
    return {
      fileName: '',
      style: {
        left: '',
        top: '',
        offsetTop: 0,
        offsetLeft: 0
      },
      clientX: 0,
      clientY: 0,
      movementX: 0,
      movementY: 0
    }
  },
  methods: {
    printMap (fileName: string, format: string) {
      const map = this.mapRoot?.olMap?.map
      const mapContainer = document.getElementsByClassName('map-2d')[0]
      if (!map || !mapContainer) {
        return
      }

      var image = new Image()
      image.crossOrigin = 'anonymous'

      const exportOptions = {
        useCORS: true,
        ignoreElements: (element: Element) => {
          const className = element.className || ''
          return className.includes && typeof className.includes === 'function' && (
            className.includes('map-download') ||
            className.includes('actions') ||
            className.includes('control-history') ||
            className.includes('control-full-extent')
          )
        }
      }

      html2canvas(mapContainer, exportOptions).then((canvas) => {
        if (format === 'pdf') {
          image.src = canvas.toDataURL('image/jpeg', 1.0)
          const pdf = new JsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: 'a4'
          })
          var printableWidth = pdf.internal.pageSize.getWidth()
          var printableHeight = (canvas.height * printableWidth) / canvas.width * 1.3
          pdf.addImage(image, 'JPEG', 0, 0, printableWidth, printableHeight)
          pdf.save(fileName)
          this.mapDownloadClose()
        } else {
          const data = canvas.toDataURL(`image/${format}`)
          image.src = data
          var anchor = document.createElement('a')
          anchor.setAttribute('download', fileName)
          anchor.setAttribute('href', image.src)
          anchor.click()
          this.mapDownloadClose()
        }
      })
    },
    mapDownloadClose () {
      this.isActive = false
    },
    dragMouseDown (event: MouseEvent) {
      event.preventDefault()
      this.clientX = event.clientX
      this.clientY = event.clientY
      document.addEventListener('mousemove', this.elementDrag)
      document.addEventListener('mouseup', this.closeDragElement)
    },
    elementDrag (event: MouseEvent) {
      event.preventDefault()
      this.movementX = this.clientX - event.clientX
      this.movementY = this.clientY - event.clientY
      this.clientX = event.clientX
      this.clientY = event.clientY
      const elementWithRefsTop = this.draggableContainerRef.offsetTop
      const elementWithRefsLeft = this.draggableContainerRef.offsetLeft
      if (this.draggableContainerRef) {
        this.style.top = (elementWithRefsTop - this.movementY) + 'px'
        this.style.left = (elementWithRefsLeft - this.movementX) + 'px'
      }
    },
    closeDragElement () {
      document.removeEventListener('mouseup', this.closeDragElement)
      document.removeEventListener('mousemove', this.elementDrag)
    }
  }
})
