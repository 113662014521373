import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dead859"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-input" }
const _hoisted_2 = {
  key: 0,
  class: "required"
}
const _hoisted_3 = ["type", "disabled", "placeholder", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["base-input__placeholder", {'has-value': _ctx.hasValue}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.placeholder) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ], 2),
    _withDirectives(_createElementVNode("input", {
      ref: "elInput",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerValue) = $event)),
      class: _normalizeClass(["base-input__input", {'search': _ctx.search, is_error: _ctx.isError }]),
      type: _ctx.type,
      disabled: _ctx.disabled,
      placeholder: _ctx.innerPlaceholder,
      maxlength: _ctx.max,
      onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyUp && _ctx.handleKeyUp(...args)))
    }, null, 42, _hoisted_3), [
      [_vModelDynamic, _ctx.innerValue]
    ])
  ]))
}