import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "selectRef",
  class: "base-single-select"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_BaseSingleSelectList = _resolveComponent("BaseSingleSelectList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "base-single-select__title",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show, _ctx.innerModelValue = '-1'))
    }, [
      (!_ctx.sorterMode)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_base_icon, { class: "panel-body__element-icon" }, {
        default: _withCtx(() => [
          _createVNode(_component_inline_svg, {
            src: _ctx.sorterMode ? _ctx.svgSorter : _ctx.svgSelectArrow
          }, null, 8, ["src"])
        ]),
        _: 1
      })
    ]),
    (_ctx.show && !_ctx.$props.asDisabled)
      ? (_openBlock(), _createBlock(_component_BaseSingleSelectList, {
          key: 0,
          class: "base-single-select__list",
          options: _ctx.options,
          "model-value": _ctx.modelValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => { _ctx.show = false, _ctx.innerModelValue = value, _ctx.$emit('update:modelValue', value) })
        }, null, 8, ["options", "model-value"]))
      : _createCommentVNode("", true)
  ], 512))
}