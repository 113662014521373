import { createToastInterface, EventBus, PluginOptions, POSITION } from 'vue-toastification'

const myEventBus = new EventBus()

const toastOptions: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 10000,
  icon: {
    iconClass: 'toast-icon',
    iconTag: 'div'
  },
  closeButtonClassName: 'toast-close',
  closeButton: 'div',
  eventBus: myEventBus
}

const geoToast = createToastInterface(toastOptions)

export default geoToast
