
import i18n from '@/i18n'
import { defineComponent } from 'vue'

const DEFAULT_VARIANT = 'center'

export default defineComponent({
  props: {
    modelValue: { type: String, required: true },
    inverted: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  emits: ['update:modelValue'],

  data: () => ({
    selectedValue: DEFAULT_VARIANT
  }),

  computed: {
    variants () {
      return [
        { id: 'top_left', title: this.$t('base.alignment.top_left') },
        { id: 'top', title: this.$t('base.alignment.top') },
        { id: 'top_right', title: this.$t('base.alignment.top_right') },
        { id: 'left', title: this.$t('base.alignment.left') },
        { id: 'center', title: this.$t('base.alignment.center') },
        { id: 'right', title: this.$t('base.alignment.rigth') },
        { id: 'bottom_left', title: this.$t('base.alignment.bottom_left') },
        { id: 'bottom', title: this.$t('base.alignment.bottom') },
        { id: 'bottom_right', title: this.$t('base.alignment.bottom_right') }
      ]
    }
  },

  watch: {
    modelValue (newValue) {
      this.selectedValue = this.inverted ? this.invert(newValue) : newValue
    }
  },

  mounted () {
    if (this.modelValue) {
      this.selectedValue = this.inverted ? this.invert(this.modelValue) : this.modelValue
    } else {
      this.selectedValue = DEFAULT_VARIANT
    }
  },

  methods: {
    $t: i18n.global.t,

    invert (id: string) {
      if (id.includes('top')) {
        id = id.replace('top', 'bottom')
      } else if (id.includes('bottom')) {
        id = id.replace('bottom', 'top')
      }
      if (id.includes('left')) {
        id = id.replace('left', 'right')
      } else if (id.includes('right')) {
        id = id.replace('right', 'left')
      }
      return id
    },

    handlerChoice (id: string) {
      this.$emit('update:modelValue', this.inverted ? this.invert(id) : id)
    }
  }
})
