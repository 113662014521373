import { createI18n, I18nOptions, LocaleMessages, VueMessageType } from 'vue-i18n'
import en from './locales/en.json'
import ru from './locales/ru.json'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages (): LocaleMessages<VueMessageType> {
  const messages: LocaleMessages<VueMessageType> = {}
  messages.en = en
  messages.ru = ru
  return messages
}

const datetimeBaseConfig: I18nOptions['messages'] = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric'
  },
  datetime: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  },
  longDate: {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  },
  time: {
    hour: 'numeric',
    minute: 'numeric'
  },
  daymonth: {
    day: 'numeric',
    month: 'long'
  }
}

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  en: datetimeBaseConfig,
  ru: datetimeBaseConfig
}

const pluralizationRules = {
  ru: function (choice: number, choicesLength: number) {
    // this === VueI18n instance, so the locale property also exists here

    if (choice === 0) {
      return 0
    }

    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1

    if (choicesLength < 4) {
      return !teen && endsWithOne ? 1 : 2
    }
    if (!teen && endsWithOne) {
      return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2
    }

    return choicesLength < 4 ? 2 : 3
  }
}

export default createI18n({
  globalInjection: true,
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
  messages: loadLocaleMessages(),
  pluralizationRules: pluralizationRules,
  datetimeFormats: datetimeFormats
})
