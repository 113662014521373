import { Ref, computed, inject, ref } from 'vue'
import { EARTH_EQUATOR_LENGTH } from '../main'
import { Extent } from 'ol/extent'
import { Map2DKey } from '@/library/maps/viewer/symbol'
import { FitOptions } from 'ol/View'
import OlMap from './ol-map/OlMap.vue'

export function useThrottle<T extends (...args: any[]) => Promise<void>>(): {
  isSubmit: Ref<boolean>,
  throttle: (func: T) => ((...args: Parameters<T>) => Promise<void>)
  } {
  const isSubmit = ref(false)

  const throttle = (func: T): (...args: Parameters<T>) => Promise<void> => {
    return async function (...args: Parameters<T>): Promise<void> {
      if (isSubmit.value) return
      isSubmit.value = true
      try {
        await func(...args)
      } finally {
        isSubmit.value = false
      }
    }
  }

  return {
    isSubmit,
    throttle
  }
}

export function useNearestExtent (olMap?: Ref<InstanceType<typeof OlMap> | null>): {
  getNearestExtent: (sourceExtent: Extent) => Extent,
  flyToNearestExtent: (sourceExtent: Extent, needToAdjustZoom?: boolean, options?: FitOptions) => void
  normalizeExtent: (sourceExtent: Extent) => Extent
  } {
  const map2d = inject(Map2DKey)

  const map = computed(() => olMap?.value?.map ?? map2d?.value.olMap?.map)

  const getMid = (left: number, rigth: number): number => {
    if (left < 0 && rigth >= 0) return left + ((Math.abs(left) + rigth) / 2)
    if (left < 0 && rigth < 0) return rigth - Math.abs((left - rigth) / 2)
    return left + ((rigth - left) / 2) // default case: (left >= 0 && rigth > 0)
  }

  const getDistance = (first: number, second: number): number => {
    const [start, end] = [first, second].sort((a, b) => a - b)
    if (start >= 0 || end <= 0) return Math.abs(end - start)
    return end - start // default case: (start < 0 && end > 0)
  }

  const normalizeExtent = (sourceExtent: Extent): Extent => {
    const currentMid = getMid(0, 0)
    const sourceMid = getMid(sourceExtent[0], sourceExtent[2])
    const distance = getDistance(currentMid, sourceMid)
    const equatorsCount = Math.round(distance / EARTH_EQUATOR_LENGTH)
    const totalEquatorsLength = equatorsCount * EARTH_EQUATOR_LENGTH
    const newSourceExtent = JSON.parse(JSON.stringify(sourceExtent)) as Extent

    const sign = Math.sign(sourceMid) || 1
    for (let i = 0; i < 3; i += 2) {
      newSourceExtent[i] += totalEquatorsLength * (sign * -1)
    }

    return newSourceExtent
  }

  const getNearestExtent = (sourceExtent: Extent): Extent => {
    const currentExtent = map.value?.getView().calculateExtent()

    if (!currentExtent) return sourceExtent

    const currentMid = getMid(currentExtent[0], currentExtent[2])
    const sourceMid = getMid(sourceExtent[0], sourceExtent[2])
    const distance = getDistance(currentMid, sourceMid)
    const equatorsCount = Math.round(distance / EARTH_EQUATOR_LENGTH)
    const totalEquatorsLength = equatorsCount * EARTH_EQUATOR_LENGTH
    const newSourceExtent = JSON.parse(JSON.stringify(sourceExtent)) as Extent

    const sign = Math.sign(currentMid) || 1
    for (let i = 0; i < 3; i += 2) {
      newSourceExtent[i] += totalEquatorsLength * sign
    }

    return newSourceExtent
  }

  const ZoomAdjuster = () => {
    const zoomOfExtent = map.value.getView().getZoom() as number
    map.value.getView().animate({
      zoom: zoomOfExtent - 2,
      duration: 100
    })
  }

  const flyToNearestExtent = (sourceExtent: Extent,
    needToAdjustZoom?: boolean,
    options: FitOptions = { duration: 2000, padding: [50, 50, 50, 50], callback: needToAdjustZoom ? ZoomAdjuster : undefined }) => {
    if (!map.value) return
    map.value.getView().fit(getNearestExtent(sourceExtent), options)
  }

  return { getNearestExtent, flyToNearestExtent }
}
