import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a2b764e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-storage__content" }
const _hoisted_2 = { class: "modal-storage__footer" }
const _hoisted_3 = { class: "modal-storage__footer-block" }
const _hoisted_4 = { class: "modal-storage__footer-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_header = _resolveComponent("base-header")!
  const _component_storage = _resolveComponent("storage")!
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    title: "",
    class: "modal-storage",
    "with-top-separator": false,
    "body-custom-styles": { padding: '0px 24px 20px' }
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_storage, {
          ref: "storage",
          "select-files": _ctx.selectedFiles,
          "onUpdate:select-files": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFiles) = $event)),
          "is-select-file": true
        }, {
          header: _withCtx(() => [
            _createVNode(_component_base_header, {
              title: _ctx.$t('storage.titleLayerAdd'),
              level: "1"
            }, null, 8, ["title"])
          ]),
          _: 1
        }, 8, ["select-files"])
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_base_loading, {
            key: 0,
            overlay: true,
            class: "modal-storage__loading"
          }))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_base_header, {
            title: _ctx.statusText,
            level: "4"
          }, null, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerButtons, (item, index) => {
            return (_openBlock(), _createBlock(_component_base_button, {
              key: index,
              type: item.type,
              disabled: item.disabled,
              class: "modal-storage__footer-btn",
              onClick: item.handler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["type", "disabled", "onClick"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}