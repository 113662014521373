import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98f69da6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_map_select = _resolveComponent("base-map-select")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_base_icon, {
      "model-value": _ctx.isActive,
      type: _ctx.IconType.SECONDARY,
      src: _ctx.imgBasemap,
      size: _ctx.SizeType.LG,
      toggled: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick()))
    }, null, 8, ["model-value", "type", "src", "size"]), [
      [_directive_tooltip, _ctx.$t('maps.view.basemaps.title')]
    ]),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(_component_base_map_select, {
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick()))
          })
        ], 512), [
          [_vShow, _ctx.isActive]
        ])
      ]),
      _: 1
    })
  ], 64))
}