
import { defineComponent, PropType, ref, provide } from 'vue'
import {
  Viewer,
  TileMapServiceImageryProvider,
  buildModuleUrl,
  TerrainProvider,
  ImageryProvider,
  ProviderViewModel,
  ClockViewModel,
  SkyAtmosphere,
  SkyBox,
  MapProjection,
  Globe,
  DataSourceCollection,
  Camera,
  GeocoderService,
  CesiumTerrainProvider
} from 'cesium'

import { CViewerKey } from './symbol'

export default defineComponent({
  name: 'CViewer',

  props: {
    sceneMode: {
      type: Number as PropType<2 | 3>,
      default: 3
    },
    showCredit: {
      type: Boolean,
      default: false
    },
    animation: {
      type: Boolean,
      default: false
    },
    baseLayerPicker: {
      type: Boolean,
      default: false
    },
    fullscreenButton: {
      type: Boolean,
      default: false
    },
    vrButton: {
      type: Boolean,
      default: false
    },
    geocoder: {
      type: [Boolean, Array] as PropType<boolean | Array<GeocoderService>>,
      default: false
    },
    homeButton: {
      type: Boolean,
      default: false
    },
    infoBox: {
      type: Boolean,
      default: false
    },
    sceneModePicker: {
      type: Boolean,
      default: false
    },
    selectionIndicator: {
      type: Boolean,
      default: false
    },
    timeline: {
      type: Boolean,
      default: false
    },
    navigationHelpButton: {
      type: Boolean,
      default: false
    },
    navigationInstructionsInitiallyVisible: {
      type: Boolean,
      default: false
    },
    scene3DOnly: {
      type: Boolean,
      default: false
    },
    shouldAnimate: {
      type: Boolean,
      default: false
    },
    clockViewModel: {
      type: Object as PropType<ClockViewModel>,
      default: () => undefined
    },
    selectedImageryProviderViewModel: {
      type: Object as PropType<ProviderViewModel>,
      default: () => undefined
    },
    imageryProviderViewModels: {
      type: Array as PropType<Array<ProviderViewModel>>,
      default: () => undefined
    },
    selectedTerrainProviderViewModel: {
      type: Object as PropType<ProviderViewModel>,
      default: () => undefined
    },
    terrainProviderViewModels: {
      type: Array as PropType<Array<ProviderViewModel>>,
      default: () => undefined
    },
    imageryProvider: {
      type: Object as PropType<ImageryProvider>,
      default: () => new TileMapServiceImageryProvider({
        url: buildModuleUrl('Assets/Textures/NaturalEarthII')
      })
    },
    loadReliefWithLimit: {
      type: Boolean,
      default: false
    },
    terrainProvider: {
      type: Object as PropType<TerrainProvider>,
      default: () => process.env.VUE_APP_TERRAIN_ENABLE &&
        process.env.VUE_APP_TERRAIN_ENABLE === 'true' &&
        process.env.VUE_APP_TERRAIN_URL &&
        process.env.VUE_APP_TERRAIN_URL.length
        ? new CesiumTerrainProvider({ url: process.env.VUE_APP_TERRAIN_URL, requestVertexNormals: true })
        : undefined
    },
    skyBox: {
      type: [Object, Boolean] as PropType<SkyBox | false>,
      default: () => undefined
    },
    skyAtmosphere: {
      type: [Object, Boolean] as PropType<SkyAtmosphere | false>,
      default: () => undefined
    },
    fullscreenElement: {
      type: [String, Element] as PropType<string | Element>,
      default: undefined
    },
    useDefaultRenderLoop: {
      type: Boolean,
      default: true
    },
    targetFrameRate: {
      type: Number,
      default: undefined
    },
    showRenderLoopErrors: {
      type: Boolean,
      default: true
    },
    useBrowserRecommendedResolution: {
      type: Boolean,
      default: true
    },
    automaticallyTrackDataSourceClocks: {
      type: Boolean,
      default: true
    },
    contextOptions: {
      type: Object,
      default: undefined
    },
    mapProjection: {
      type: Object as PropType<MapProjection>,
      default: undefined
    },
    globe: {
      type: [Object, Boolean] as PropType<Globe | false>,
      default: () => undefined
    },
    orderIndependentTranslucency: {
      type: Boolean,
      default: true
    },
    creditContainer: {
      type: [String, Element] as PropType<string | Element>,
      default: undefined
    },
    creditViewport: {
      type: [String, Element] as PropType<string | Element>,
      default: undefined
    },
    dataSources: {
      type: Object as PropType<DataSourceCollection>,
      default: undefined
    },
    shadows: {
      type: Boolean,
      default: false
    },
    terrainShadows: {
      type: Number,
      default: 3
    },
    mapMode2D: {
      type: Number,
      default: 1
    },
    projectionPicker: {
      type: Boolean,
      default: false
    },
    requestRenderMode: {
      type: Boolean,
      default: false
    },
    maximumRenderTimeChange: {
      type: Number,
      default: 0.0
    },
    camera: {
      type: Object as PropType<Camera>,
      default: undefined
    }
  },

  emits: ['camera:moveend', 'camera:changed'],

  setup () {
    const viewer = ref<Viewer | null>(null)
    provide(CViewerKey, viewer)
    return {
      viewer
    }
  },

  watch: {
    sceneMode () {
      this.reloadMap()
    }
  },

  mounted () {
    this.reloadMap()
  },

  beforeUnmount () {
    if (this.viewer) {
      this.viewer.destroy()
    }
  },

  methods: {
    reloadMap () {
      if (this.viewer) {
        this.viewer.destroy()
      }
      const containerRef = this.$refs.container as HTMLDivElement
      this.viewer = new Viewer(containerRef, {
        imageryProvider: this.imageryProvider,
        sceneMode: this.sceneMode,
        animation: this.animation,
        timeline: this.timeline,
        navigationHelpButton: this.navigationHelpButton,
        homeButton: this.homeButton,
        infoBox: this.infoBox,
        showRenderLoopErrors: this.showRenderLoopErrors,
        fullscreenButton: this.fullscreenButton,
        sceneModePicker: this.sceneModePicker,
        shadows: this.shadows,
        baseLayerPicker: this.baseLayerPicker,
        selectionIndicator: this.selectionIndicator,
        geocoder: this.geocoder,
        navigationInstructionsInitiallyVisible: this.navigationInstructionsInitiallyVisible,
        skyBox: this.skyBox,
        maximumRenderTimeChange: this.maximumRenderTimeChange,
        requestRenderMode: this.requestRenderMode,
        projectionPicker: this.projectionPicker,
        mapMode2D: this.mapMode2D,
        terrainShadows: this.terrainShadows,
        dataSources: this.dataSources,
        creditViewport: this.creditViewport,
        creditContainer: this.creditContainer,
        orderIndependentTranslucency: this.orderIndependentTranslucency,
        globe: this.globe,
        mapProjection: this.mapProjection,
        contextOptions: this.contextOptions,
        automaticallyTrackDataSourceClocks: this.automaticallyTrackDataSourceClocks,
        useBrowserRecommendedResolution: this.useBrowserRecommendedResolution,
        targetFrameRate: this.targetFrameRate,
        useDefaultRenderLoop: this.useDefaultRenderLoop,
        skyAtmosphere: this.skyAtmosphere,
        terrainProvider: this.loadReliefWithLimit ? this.terrainProvider : undefined,
        terrainProviderViewModels: this.terrainProviderViewModels,
        selectedTerrainProviderViewModel: this.selectedTerrainProviderViewModel,
        imageryProviderViewModels: this.imageryProviderViewModels,
        selectedImageryProviderViewModel: this.selectedImageryProviderViewModel,
        clockViewModel: this.clockViewModel,
        shouldAnimate: this.shouldAnimate,
        scene3DOnly: this.scene3DOnly,
        vrButton: this.vrButton
      })

      if (this.viewer) {
        this.viewer.camera.moveEnd.addEventListener(() => {
          this.$emit('camera:moveend', this.viewer)
        })
        this.viewer.camera.changed.addEventListener(() => {
          this.$emit('camera:changed', this.viewer)
        })
      }
    }
  }
})
