import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4be3e65b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-radio__icon" }
const _hoisted_2 = { class: "base-radio__icon-sub" }
const _hoisted_3 = { class: "base-radio__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-radio", {'checked': _ctx.checked}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      (_ctx.checked)
        ? (_openBlock(), _createBlock(_component_inline_svg, {
            key: 0,
            class: "base-radio__icon-check",
            src: _ctx.RadioFull
          }, null, 8, ["src"]))
        : (_openBlock(), _createBlock(_component_inline_svg, {
            key: 1,
            class: "base-radio__icon-check",
            src: _ctx.RadioStill
          }, null, 8, ["src"]))
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ], 2))
}