
import { defineComponent, ref } from 'vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  props: {
    modelValue: { type: String, required: true },
    placeholder: { type: String, required: true },
    required: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    innerPlaceholder: { type: String, default: 'Введите..' },
    max: { type: Number, default: undefined },
    isName: { type: Boolean, default: false }
  },
  emits: [
    'update:modelValue',
    'update:valid'
  ],
  setup () {
    var elInput = ref<HTMLInputElement>()
    const toast = useToast()
    const regExpNotValid = ref<boolean>(false)
    return {
      elInput,
      toast,
      regExpNotValid
    }
  },
  data () {
    return {
      innerValue: this.modelValue
    }
  },
  computed: {
    hasValue () {
      return this.modelValue != null && this.modelValue.length > 0
    }
  },
  watch: {
    innerValue: function (val) {
      this.$emit('update:modelValue', val)
    }
  },
  methods: {
    showErrorNotification () {
      this.toast.clear()
      if (this.isName) this.toast.error('Введенное значение не соответствует требованиям')
      else this.toast.error('Введенное значение не соответствует требованиям')
    },
    validate () {
      let isValid = true

      if (this.innerValue.length && this.innerValue.trim() === '') {
        this.showErrorNotification()
        isValid = false
      }

      if (!this.innerValue.length) {
        this.toast.error(this.$t('baikalsk.admin.create-group.notifications.errors.empty-name'))
        isValid = false
      }

      if (this.innerValue.length) {
        const patrn = /^[_ёЁa-zA-Zа-яА-Я0-9\s-]*$/
        if (patrn.test(this.innerValue)) {
          this.regExpNotValid = false
        } else {
          this.regExpNotValid = true
          this.showErrorNotification()
          isValid = false
        }
      }
      if (isValid) this.toast.clear()
      this.$emit('update:valid', isValid)
      return isValid
    },
    /* need handle input to avoid user using windows + v with smiles, signs etc in clipboard */
    handleInput () {
      this.validate()
    },
    handleKeyUp () {
      if (this.$props.max) {
        if (this.innerValue.length >= this.$props.max) {
          this.toast.clear()
          this.validate()
          this.toast.error(this.$t('baikalsk.shared.base-input.max-length', { n: this.$props.max }))
        }
      }
    },
    focusInput () {
      if (this.elInput) {
        this.elInput.focus()
      }
    }
  }
})
