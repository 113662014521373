
import i18n from '@/i18n'
import { defineComponent, PropType } from 'vue'
import Gradient from 'gradient-parser'

import BaseIcon from '@/library/base/BaseIcon.vue'
import BaseButton from '@/library/base/BaseButton.vue'
import BaseColorPicker from '@/library/base/BaseColorPicker.vue'
import { RGB_COLORS } from '@/library/helpers'
import { IconType, ButtonType, SizeType } from '@/library/types/base/enums'

import imgClose from '@/library/assets/img/icCrossedClose.svg'

const DEFAULT_COLOR = '#000'
const DEFAULT_GRADIENT = RGB_COLORS.map(item => ({
  color: item
}))

export default defineComponent({
  components: {
    BaseIcon,
    BaseButton,
    BaseColorPicker
  },

  props: {
    modelValue: { type: Array as PropType<string[]>, default: undefined },
    disabled: { type: Boolean, default: false },
    withExistVariants: { type: Boolean, default: false }
  },

  emits: ['update:modelValue'],

  setup () {
    return {
      IconType,
      SizeType,
      imgClose
    }
  },

  data: () => ({
    mode: 'newGradint',
    isOpenedPicker: false,
    gradient: '',
    colorStops: [] as string[],
    pickerLocation: {
      left: '0px',
      top: '0px'
    },
    colors: DEFAULT_GRADIENT
  }),

  computed: {
    defaultGradients () {
      const result = Array.from(Array(3).keys()).map(() => {
        const randomColors = Array.from(Array(5).keys()).map(() => {
          return this.getRandomColor()
        })
        return {
          colorStops: randomColors,
          gradient: `linear-gradient(90deg, ${randomColors.join(', ')})`
        }
      })
      return [{
        colorStops: RGB_COLORS,
        gradient: `linear-gradient(90deg, ${RGB_COLORS.join(', ')})`
      }, ...result]
    },

    footerActions () {
      if (this.mode === 'newGradient') {
        return [{
          label: this.$t('base.gradient-picker.btns.close'),
          handler: this.backAction,
          type: ButtonType.SECONDARY
        }, {
          label: this.$t('base.gradient-picker.btns.save'),
          handler: this.applyGradient,
          type: ButtonType.PRIMARY
        }]
      } else {
        return [{
          label: this.$t('base.gradient-picker.btns.new-gradient'),
          handler: this.openNewGradient,
          type: ButtonType.PRIMARY
        }]
      }
    },

    currentGradient () {
      if (Array.isArray(this.modelValue) && this.modelValue.length) {
        return `linear-gradient(90deg, ${this.modelValue.join(', ')})`
      } else {
        return `linear-gradient(90deg, ${RGB_COLORS.join(', ')})`
      }
    }
  },

  watch: {
    colors: {
      handler () {
        this.recalcGradient()
      },
      deep: true
    }
  },

  mounted () {
    window.addEventListener('click', this.closePicker)
    window.addEventListener('wheel', this.recalcPosition)
    if (this.withExistVariants) {
      this.mode = 'defaultSets'
    }
    if (!Array.isArray(this.modelValue) || !this.modelValue.length) {
      this.$emit('update:modelValue', RGB_COLORS)
    }
  },

  beforeUnmount () {
    window.removeEventListener('click', this.closePicker)
    window.removeEventListener('wheel', this.recalcPosition)
  },

  methods: {
    $t: i18n.global.t,

    getRandomColor () {
      return `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, 'f')}`
    },

    openNewGradient () {
      this.mode = 'newGradient'
    },

    applyGradient (colorStops?: string[]) {
      this.closePicker()
      if (Array.isArray(colorStops) && colorStops.length) {
        this.$emit('update:modelValue', colorStops)
      } else {
        this.$emit('update:modelValue', this.colorStops)
      }
    },

    addColor () {
      this.colors.push({ color: DEFAULT_COLOR })
    },

    deleteColor (index: number) {
      this.colors.splice(index, 1)
    },

    resetColors () {
      try {
        if (typeof this.modelValue === 'string') {
          const gradientInfo = Gradient.parse(this.modelValue)
          if (gradientInfo.colorStops.length > 1) {
            this.colors = []
            gradientInfo.colorStops.forEach((item: Record<string, string>) => {
              this.colors.push({ color: item.value })
            })
          }
        } else if (Array.isArray(this.modelValue) && this.modelValue.length) {
          this.colors = this.modelValue.map(color => {
            return {
              color: color
            }
          })
        } else {
          this.colors = DEFAULT_GRADIENT
        }
      } catch {
        this.colors = DEFAULT_GRADIENT
      }
    },

    backAction () {
      if (this.withExistVariants) {
        this.mode = 'defaultSets'
      } else {
        this.isOpenedPicker = false
      }
    },

    closePicker () {
      this.isOpenedPicker = false
      if (this.withExistVariants) {
        this.mode = 'defaultSets'
      }
    },

    recalcPosition () {
      const rect = this.$el.getBoundingClientRect()
      this.pickerLocation.left = `${rect.x}px`
      this.pickerLocation.top = `${rect.y + rect.height}px`
    },

    openPicker () {
      this.recalcPosition()
      this.resetColors()
      this.recalcGradient()
      this.isOpenedPicker = true
    },

    recalcGradient () {
      this.colorStops = this.colors.slice(0, 12).map(item => item.color)
      this.gradient = `linear-gradient(90deg, ${this.colorStops.join(', ')})`
    }
  }
})
