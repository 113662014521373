
import { defineComponent, ref } from 'vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import InlineSvg from 'vue-inline-svg'
import BaseSingleSelectList from '@/components/base/BaseSingleSelectList/BaseSingleSelectList.vue'
import svgSelectArrow from '@/assets/svg/select-svg.svg'
import svgSorter from '@/assets/svg/sorter.svg'

type BaseSingleSelectData = {
  show: boolean,
  innerModelValue: string
}

export default defineComponent({
  components: {
    BaseIcon,
    InlineSvg,
    BaseSingleSelectList
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    title: {
      type: [String, Number],
      default: ''
    },
    sorterMode: {
      type: [Boolean],
      default: false
    },
    asDisabled: {
      type: [Boolean],
      default: false
    },
    options: {
      type: Array as () => Array<{name: string | number, value: string | number}>,
      default: () => {
        return []
      }
    }
  },
  emits: ['update:modelValue'],
  data (): BaseSingleSelectData {
    return {
      show: false,
      innerModelValue: ''
    }
  },
  methods: {
    handleClick (evt: MouseEvent) {
      if (!this.selectRef) {
        return
      }

      if (!this.selectRef.contains(evt.target as HTMLElement)) {
        this.show = false
        this.innerModelValue = '-1'
      }
    }
  },
  mounted () {
    window.addEventListener('click', this.handleClick)
  },
  unmounted () {
    window.removeEventListener('click', this.handleClick)
  },
  setup () {
    const selectRef = ref<HTMLElement | null>(null)
    return {
      selectRef,
      svgSelectArrow,
      svgSorter
    }
  }
})
