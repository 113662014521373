import { CoordinateFormat, CoordinateType, CoordinateUnit } from '@/library/types/maps/enums'
import { AllowDecimalCoordinateSepator } from '@/library/types'
import { roundAfterDecimalPoint } from '@/library/helpers'

export const ALLOW_REG_EXP = /^-?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)[,; ]-?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/

export const DMS_UNITS = [CoordinateUnit.DEGREES, CoordinateUnit.MINUTES, CoordinateUnit.SECONDS]

export const COORDINATE_UNITS = {
  [CoordinateUnit.DECIMAL]: '°',
  [CoordinateUnit.DEGREES]: '°',
  [CoordinateUnit.MINUTES]: '\'',
  [CoordinateUnit.SECONDS]: '"'
}

type ParsedCoordinateResult = {
  numbers: number[]
  separator: AllowDecimalCoordinateSepator
}

export const parseCoordinateSeparator = (input: string): AllowDecimalCoordinateSepator => {
  let separator: AllowDecimalCoordinateSepator
  if (input.includes(',')) {
    separator = ','
  } else if (input.includes(';')) {
    separator = ';'
  } else {
    separator = ' '
  }
  return separator
}

export const parseCoordinates = (input: string): ParsedCoordinateResult | undefined => {
  input = input.trimEnd().trimStart()
  if (!ALLOW_REG_EXP.test(input)) return
  if (input.length === 0) return
  const parts = input.split(/[,;\s]/g).filter(x => x.length).map(item => parseFloat(item))
  if (parts.length !== 2) return
  return {
    numbers: parts,
    separator: parseCoordinateSeparator(input)
  }
}

export const getMinValue = (
  format: CoordinateFormat, type: CoordinateType, unit: CoordinateUnit
): number => {
  if (format === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
    if (type === CoordinateType.LONGITUDE) {
      return [CoordinateUnit.DEGREES, CoordinateUnit.DECIMAL].includes(unit) ? -180 : 0
    } else {
      return [CoordinateUnit.DEGREES, CoordinateUnit.DECIMAL].includes(unit) ? -90 : 0
    }
  } else {
    return type === CoordinateType.LATITUDE ? -90 : -180
  }
}

export const getMaxValue = (
  format: CoordinateFormat, type: CoordinateType, unit: CoordinateUnit
): number => {
  if (format === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
    if (type === CoordinateType.LONGITUDE) {
      return [CoordinateUnit.DEGREES, CoordinateUnit.DECIMAL].includes(unit) ? 180 : 59
    } else {
      return [CoordinateUnit.DEGREES, CoordinateUnit.DECIMAL].includes(unit) ? 90 : 59
    }
  } else {
    return (type === CoordinateType.LATITUDE) ? 90 : 180
  }
}

export const convertDecimalToDMS = (decimal: number): Record<string, number> => {
  const isNegative = decimal < 0
  const absoluteValue = Math.abs(decimal)
  const degrees = Math.floor(absoluteValue)
  const minutesFull = (absoluteValue - degrees) * 60
  const minutes = Math.floor(minutesFull)
  const seconds = Math.round((minutesFull - minutes) * 60)
  return {
    [CoordinateUnit.DEGREES]: isNegative ? -degrees : degrees,
    [CoordinateUnit.MINUTES]: minutes,
    [CoordinateUnit.SECONDS]: seconds
  }
}

export const convertDMSToDecimal = (info: Record<CoordinateUnit, string | number>, numbers?: number): number => {
  const parsedDegress = Number(info[CoordinateUnit.DEGREES])
  const sign = !parsedDegress ? 1 : Math.sign(parsedDegress)
  const degress = Math.abs(parsedDegress)
  const minutes = Math.abs(Number(info[CoordinateUnit.MINUTES]))
  const seconds = Math.abs(Number(info[CoordinateUnit.SECONDS]))
  const decimal = sign * (degress + minutes / 60 + seconds / 3600)
  return numbers ? roundAfterDecimalPoint(decimal, numbers) : decimal
}
