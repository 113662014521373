import {
  GeoMap,
  VectorLayerFeature,
  VectorLayerColumn,
  VisibleLayer,
  GeoMapLayergroup,
  GetSessionData,
  ImportTask,
  ExportTask,
  LayerStyleIcon,
  MultipleTreeSelect,
  GeoMapLayer
} from '@/library/types'
import { BaseMapType, GeoMapType, TreeSelectType } from '@/library/types/maps/enums'
import { WindowVariant } from '@/library/types/base/enums'

export type State = {
  list: GeoMap[] | null
  map?: GeoMap | null
  cachedStyleIcons: LayerStyleIcon[]
  cachedRootStructure?: GeoMapLayergroup
  failedDragging?: boolean
  draggableTreeElement?: GeoMapLayer | GeoMapLayergroup
  visibleLayers: VisibleLayer[]
  expandedLayers: number[]
  visibleGroups: number[]
  selectedGroups: number[]
  groupEditId?: number
  selectedLayers: number[]
  tasks: (ImportTask | ExportTask)[]
  editableVectorLayerObject: {
    indexObject: VectorLayerFeature | null
    currentObject: VectorLayerFeature | null
    saverObject: VectorLayerFeature | null
    parentLayerId: number | null
    columns: VectorLayerColumn[]
  }
  layersOpacity: Record<number, number>
  openedWindows: Record<WindowVariant, boolean>
  basemap: BaseMapType
  type: GeoMapType
  savedSession?: GetSessionData
  multipleTreeSelect: MultipleTreeSelect
  exportTasksIds: number[]
}

export const state: State = {
  list: null,
  cachedStyleIcons: [],
  editableVectorLayerObject: {
    parentLayerId: null,
    indexObject: null,
    currentObject: null,
    saverObject: null,
    columns: []
  },
  openedWindows: {
    [WindowVariant.OPERATIONS_HISTORY]: false
  },
  visibleLayers: [],
  visibleGroups: [],
  expandedLayers: [],
  selectedGroups: [],
  selectedLayers: [],
  layersOpacity: {},
  multipleTreeSelect: { isEnable: false, type: TreeSelectType.DELETE },
  basemap: BaseMapType.BBM,
  tasks: [],
  type: GeoMapType.D2,
  exportTasksIds: []
}
