
import { defineComponent } from 'vue'
import i18n from '@/i18n'

import BaseIcon from '@/library/base/BaseIcon.vue'
import BaseButton from '@/library/base/BaseButton.vue'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseLoading from '@/library/base/BaseLoading.vue'
import { ButtonType, IconType, TextType } from '@/library/types/base/enums'

import imgQuestion from '@/library/assets/img/icQuestion.svg'
import imgClose from '@/library/assets/img/icCrossedClose.svg'

const $t = i18n.global.t

export default defineComponent({
  components: { BaseButton, BaseIcon, BaseHeader, BaseLoading },
  props: {
    isDelete: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isCompacted: { type: Boolean, default: false },
    withSeparators: { type: Boolean, default: false },
    closeAfterClick: { type: Boolean, default: false },
    cancelText: { type: String, default: $t('base.confirmBtns.cancel') },
    confirmText: { type: String, default: $t('base.confirmBtns.confirm') },
    questionText: { type: String, required: true },
    showClose: { type: Boolean, default: false },
    width: { type: String, default: undefined },
    captionText: { type: String, default: undefined }
  },
  emits: ['cancel', 'confirm', 'close'],

  setup () {
    return {
      imgQuestion,
      imgClose,
      ButtonType,
      IconType,
      TextType
    }
  },

  computed: {
    confirmDeleteText () {
      return $t('base.confirmBtns.delete')
    },

    cancelDeleteText () {
      return $t('base.confirmBtns.notDelete')
    }
  }
})
