import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74ae2606"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "base-limit-range__bar"
}
const _hoisted_2 = { class: "base-limit-range__block" }
const _hoisted_3 = { class: "base-limit-range__label" }
const _hoisted_4 = {
  key: 0,
  class: "base-limit-range__unit"
}
const _hoisted_5 = { class: "base-limit-range__block" }
const _hoisted_6 = { class: "base-limit-range__label" }
const _hoisted_7 = {
  key: 0,
  class: "base-limit-range__unit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_double_range = _resolveComponent("base-double-range")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-limit-range", { with_bar: _ctx.withRangeBar }])
  }, [
    (_ctx.withRangeBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_base_double_range, {
            "min-value": _ctx.minValue,
            "max-value": _ctx.maxValue,
            "start-value": Number(_ctx.selectedStartValue),
            "end-value": Number(_ctx.selectedEndValue),
            step: _ctx.step,
            disabled: _ctx.disabled,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, null, 8, ["min-value", "max-value", "start-value", "end-value", "step", "disabled"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fromLabelTranslate), 1),
      _createVNode(_component_base_input, {
        class: "base-limit-range__input",
        type: "number",
        "model-modifiers": { lazy: 'true' },
        "min-value": _ctx.minValue,
        "max-value": _ctx.maxValue,
        "number-step": _ctx.step,
        "model-value": _ctx.selectedStartValue,
        disabled: _ctx.disabled,
        "has-error": _ctx.hasError,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.handlerInput($event, 'startValue')))
      }, null, 8, ["min-value", "max-value", "number-step", "model-value", "disabled", "has-error"]),
      (_ctx.unit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.unit), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.toLabelTranslate), 1),
      _createVNode(_component_base_input, {
        class: "base-limit-range__input",
        type: "number",
        "model-modifiers": { lazy: 'true' },
        "min-value": _ctx.minValue,
        "max-value": _ctx.maxValue,
        "number-step": _ctx.step,
        "model-value": _ctx.selectedEndValue,
        disabled: _ctx.disabled,
        "has-error": _ctx.hasError,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.handlerInput($event, 'endValue')))
      }, null, 8, ["min-value", "max-value", "number-step", "model-value", "disabled", "has-error"]),
      (_ctx.unit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.unit), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}