import { UserModel, CPSMarkerModel, CameraAccess } from '@/types/baykalsk/types'
import { CityPresentationAnimation, ServicePerm } from '@/types/baykalsk/enums'
import { GeoMap, GeoMapLayergroup, Position3dInfo } from '@/library/types'

export type State = {
  orders?: UserModel[],
  map?: GeoMap | null,
  list: GeoMap[] | null,
  visibleLayers: number[],
  expandedLayers: number[],
  visibleGroups: number[],
  groups: GeoMapLayergroup[],
  listOfRead: number[],
  listOfWrite: number[],
  listOfDeny: number[],
  camerasPerms:CameraAccess[],
  cameraListOfRead: string[],
  cameraListOfDeny: string[],
  listOfCPSMarkers: CPSMarkerModel[],
  activeCPSMarker: CPSMarkerModel | null,
  activeCPSSlideExtent: number[],
  activeCPSSlideExtent3D?: Position3dInfo,
  currentSlideAnimation: CityPresentationAnimation,
  maxVisibleLayers: number,
  CPSaccessLevel: ServicePerm,
  RESaccessLevel: ServicePerm,
  CVSaccessLevel: ServicePerm,
  CSSaccessLevel: ServicePerm,
  VideoAccessLevel: ServicePerm,
  is3dEnabled: boolean
}

export const state: State = {
  list: null,
  orders: [],
  groups: [],
  visibleLayers: [],
  visibleGroups: [],
  expandedLayers: [],
  listOfRead: [],
  listOfWrite: [],
  listOfDeny: [],
  camerasPerms: [],
  cameraListOfRead: [],
  cameraListOfDeny: [],
  listOfCPSMarkers: [],
  activeCPSMarker: null,
  activeCPSSlideExtent: [],
  activeCPSSlideExtent3D: undefined,
  currentSlideAnimation: CityPresentationAnimation.SLOW,
  maxVisibleLayers: 10,
  CPSaccessLevel: ServicePerm.READ,
  RESaccessLevel: ServicePerm.READ,
  CVSaccessLevel: ServicePerm.READ,
  CSSaccessLevel: ServicePerm.READ,
  VideoAccessLevel: ServicePerm.DENY,
  is3dEnabled: false
}
