export enum WsMapMessageType {
  INFO = 'info',
  PING = 'ping',
  EVENT = 'event'
}

export enum WsMapEntity {
  MAP = 'map',
  LAYER = 'layer',
  FEATURE = 'feature',
  TASK = 'task'
}

export enum WsMapEntityEvent {
  UPDATE = 'update',
  BULK_UPDATE = 'bulkUpdate',
  CREATE = 'create',
  DELETE = 'delete',
  BULK_DELETE = 'bulkDelete'
}
