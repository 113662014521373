import { FeatureGeometryCoordinates } from '@/library/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArrayOfNumber = (value: FeatureGeometryCoordinates | any[]): value is number[] => {
  return Array.isArray(value) && (value as number[]).every(item => typeof item === 'number')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArrayOfNumber2 = (value: FeatureGeometryCoordinates | any[]): value is number[][] => {
  return Array.isArray(value) && (value as number[][]).every(item => isArrayOfNumber(item))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArrayOfNumber3 = (value: FeatureGeometryCoordinates | any[]): value is number[][][] => {
  return Array.isArray(value) && (value as number[][][]).every(item => isArrayOfNumber2(item))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArrayOfNumber4 = (value: FeatureGeometryCoordinates | any[]): value is number[][][][] => {
  return Array.isArray(value) && (value as number[][][][]).every(item => isArrayOfNumber3(item))
}
