
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    startValue: { type: Number, default: 0 },
    endValue: { type: Number, default: 100 },
    minValue: { type: Number, default: 0 },
    maxValue: { type: Number, default: 100 },
    step: { type: Number, default: 1 },
    width: { type: String, default: 'auto' },
    disabled: { type: Boolean, default: false }
  },

  emits: ['update:modelValue'],

  data: () => ({
    selectedStartValue: null as number | null,
    selectedEndValue: null as number | null
  }),

  computed: {
    inputWidth () {
      return `${String(this.maxValue).length * 10}px`
    }
  },

  watch: {
    startValue (newValue) {
      this.selectedStartValue = newValue
    },

    endValue (newValue) {
      this.selectedEndValue = newValue
    }
  },

  mounted () {
    this.selectedStartValue = this.startValue
    this.selectedEndValue = this.endValue
  },

  methods: {
    handlerInput (category: 'start' | 'end', event: Event) {
      const target = event.target as HTMLInputElement
      const newValue = Number(target.value)
      const isValid = !Number.isNaN(newValue)
      if (category === 'start') {
        this.selectedStartValue = isValid ? newValue : null
      } else {
        this.selectedEndValue = isValid ? newValue : null
      }
      this.$emit('update:modelValue', {
        startValue: this.selectedStartValue,
        endValue: this.selectedEndValue
      })
    }
  }
})
