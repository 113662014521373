import { Directive } from 'vue'

const resize: Directive = {
  mounted (el, binding) {
    if (typeof binding.value !== 'function') {
      throw new Error('v-resize argument must be a function')
    }
    const observer = new ResizeObserver((entries) => {
      binding.value(entries[0])
    })
    observer.observe(el)
    el.__resize_observer__ = observer
  },
  unmounted (el) {
    const observer = el.__resize_observer__
    observer.unobserve(el)
    delete el.__resize_observer__
  }
}

export default resize
