
import { defineComponent } from 'vue'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
export default defineComponent({
  components: {
    PerfectScrollbar
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array as () => Array<{name: string | number, value: string | number}>,
      default: () => {
        return []
      }
    }
  },
  emits: ['update:modelValue']
})
