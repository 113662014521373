import auth from '@/library/auth'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

// TODO: Auth
const authToken = (config: AxiosRequestConfig) => {
  config.headers = config.headers || {}
  config.headers.authorization = 'Bearer ' + auth.keycloak.token
  return config
}

const onResponseSuccess = (response: AxiosResponse) => {
  return response
}

// TODO: show message error
const onResponseError = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    throw error
  } else {
    throw error
  }
}

api.interceptors.request.use(authToken)
api.interceptors.response.use(onResponseSuccess, onResponseError)

export default api
