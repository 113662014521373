import auth from '@/library/auth'

export const downloadFile = (source: string | File | Blob, fileName?: string): void => {
  // Загружает по ссылке файл на копьютер
  if (source instanceof File || source instanceof Blob) {
    source = window.URL.createObjectURL(source)
  }
  if (typeof source === 'string') {
    const link = document.createElement('a')
    link.setAttribute('href', source)
    link.setAttribute('download', fileName || '')
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(source)
  }
}

export const downloadFileFromLink = (link: string): void => {
  window.open(process.env.VUE_APP_API_URL + link + `?token=${auth.keycloak.token}`)
}
