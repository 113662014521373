import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fedcd180"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_geocode_map = _resolveComponent("geocode-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_geocode_map, {
      key: _ctx.id,
      "map-id": _ctx.id
    }, null, 8, ["map-id"]))
  ]))
}