import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_modal_buttons_ok_cancel = _resolveComponent("base-modal-buttons-ok-cancel")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    class: "directory-create-confirm",
    "close-after-click": false,
    title: _ctx.$t('storage.confirm.createDirectory'),
    onKeydown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, {
    body: _withCtx(() => [
      _createVNode(_component_base_input, {
        modelValue: _ctx.newDirectoryName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newDirectoryName) = $event)),
        class: "storage-explorer__search",
        autofocus: true
      }, null, 8, ["modelValue"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_base_modal_buttons_ok_cancel, {
        onCancel: _ctx.cancel,
        onConfirm: _ctx.confirm
      }, {
        cancel: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('storage.btns.cancel')), 1)
        ]),
        confirm: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('storage.btns.add')), 1)
        ]),
        _: 1
      }, 8, ["onCancel", "onConfirm"])
    ]),
    _: 1
  }, 8, ["title"]))
}