import { LayerColumnDbType } from '@/library/types/maps/enums'
import { MAX_ALLOWABLE_ZOOM, MIN_ALLOWABLE_ZOOM, ZOOM_PRECISION } from '@/library/helpers'

export const HIDDEN_DB_TYPES = [
  LayerColumnDbType.GEOMETRY,
  LayerColumnDbType.TIMESTAMP
]

export const NON_STRICT_COMPARISON = Math.pow(10, -ZOOM_PRECISION)
export const DEFAULT_ICON = 'https://minio-dev.geocode.tech/icons/marker.svg'

export const DEFAULT_SECONDARY_COLOR = '#ffffff'
export const DEFAULT_PRIMARY_COLOR = '#3399CC'
export const DEFAULT_STROKE_OPACITY = 100
export const DEFAULT_FILL_OPACITY = 40
export const DEFAULT_STROKE_WIDTH = 1.25
export const SLD_VERSION = '1.0.0'

export const CUSTOM_SLD_STYLE = 'custom_sld_style'

export const DEFAULT_RASTER_STYLE = `<?xml version="1.0" encoding="UTF-8"?>
<StyledLayerDescriptor version="${SLD_VERSION}"
 xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd"
 xmlns="http://www.opengis.net/sld"
 xmlns:ogc="http://www.opengis.net/ogc"
 xmlns:xlink="http://www.w3.org/1999/xlink"
 xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <NamedLayer>
    <Name>default_raster</Name>
    <UserStyle>
      <Title>Default Raster</Title>
      <Abstract>A default GeoHub style that draws a raster, good for displaying imagery</Abstract>
      <FeatureTypeStyle>
        <Rule>
          <Name>rule_1</Name>
          <Title>Opaque Raster</Title>
          <Abstract>A raster with 100% opacity</Abstract>
          <RasterSymbolizer>
            <Opacity>1.0</Opacity>
          </RasterSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>`

export const DEFAULT_POINT_STYLE = `<?xml version="1.0" encoding="UTF-8"?>
<StyledLayerDescriptor xmlns="http://www.opengis.net/sld" xmlns:se="http://www.opengis.net/se" xmlns:ogc="http://www.opengis.net/ogc" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/${SLD_VERSION}/StyledLayerDescriptor.xsd" version="${SLD_VERSION}">
  <NamedLayer>
    <Name>point_default</Name>
    <UserStyle>
      <Name>point_default</Name>
      <FeatureTypeStyle>
        <Rule>
          <Name>point</Name>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">${DEFAULT_SECONDARY_COLOR}</CssParameter>
                  <CssParameter name="fill-opacity">${DEFAULT_FILL_OPACITY / 100}</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">${DEFAULT_PRIMARY_COLOR}</CssParameter>
                  <CssParameter name="stroke-width">${DEFAULT_STROKE_WIDTH}</CssParameter>
                  <CssParameter name="stroke-opacity">${DEFAULT_STROKE_OPACITY / 100}</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>`

export const DEFAULT_POLYGON_STYLE = `<?xml version="1.0" encoding="UTF-8"?>
<StyledLayerDescriptor xmlns="http://www.opengis.net/sld" xmlns:se="http://www.opengis.net/se" xmlns:ogc="http://www.opengis.net/ogc" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/${SLD_VERSION}/StyledLayerDescriptor.xsd" version="${SLD_VERSION}">
  <NamedLayer>
    <Name>poly_default</Name>
    <UserStyle>
      <Name>poly_default</Name>
      <FeatureTypeStyle>
        <Rule>
          <Name>polygon</Name>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">${DEFAULT_SECONDARY_COLOR}</CssParameter>
              <CssParameter name="fill-opacity">${DEFAULT_FILL_OPACITY / 100}</CssParameter>
            </Fill>
            <Stroke>
              <CssParameter name="stroke">${DEFAULT_PRIMARY_COLOR}</CssParameter>
              <CssParameter name="stroke-width">${DEFAULT_STROKE_WIDTH}</CssParameter>
              <CssParameter name="stroke-opacity">${DEFAULT_STROKE_OPACITY / 100}</CssParameter>
            </Stroke>
          </PolygonSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>`

export const DEFAULT_LINE_STYLE = `<?xml version="1.0" encoding="UTF-8"?>
<StyledLayerDescriptor xmlns="http://www.opengis.net/sld" xmlns:se="http://www.opengis.net/se" xmlns:ogc="http://www.opengis.net/ogc" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/${SLD_VERSION}/StyledLayerDescriptor.xsd" version="${SLD_VERSION}">
  <NamedLayer>
    <Name>line_default</Name>
    <UserStyle>
      <Name>line_default</Name>
      <FeatureTypeStyle>
        <Rule>
          <Name>line</Name>
          <LineSymbolizer>
            <Stroke>
              <CssParameter name="stroke">${DEFAULT_PRIMARY_COLOR}</CssParameter>
              <CssParameter name="stroke-width">${DEFAULT_STROKE_WIDTH}</CssParameter>
              <CssParameter name="stroke-opacity">${DEFAULT_STROKE_OPACITY / 100}</CssParameter>
            </Stroke>
          </LineSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>`

export const PREVIEW_SIGNATURE_PROPS = [
  'signatureFontSize',
  'signatureFontStyle',
  'signatureFontColor',
  'signatureFontColorOpacity',
  'signatureRotateAngle',
  'signatureSvgIcon',
  'signatureOutlineColor',
  'signatureOutlineOpacity',
  'signatureOutlineSize'
]

export const getMinZoom = (value: number): number => {
  if (!Number.isNaN(value)) {
    return value > MIN_ALLOWABLE_ZOOM ? value - 1 : value
  }
  return MIN_ALLOWABLE_ZOOM
}

export const getMaxZoom = (value: number): number => {
  if (!Number.isNaN(value)) {
    const newValue = value + NON_STRICT_COMPARISON
    return newValue < MAX_ALLOWABLE_ZOOM ? newValue - 1 : newValue
  }
  return MAX_ALLOWABLE_ZOOM
}

export const getPointSymbol = (
  size?: number | string,
  fillColor?: string,
  fillAlpha?: number | string,
  outlineWidth?: number | string,
  outlineColor?: string,
  outlineAlpha?: number | string
): string => {
  const totalSize = Number(size) + (parseFloat(String(outlineWidth)))
  return `<svg width="${totalSize}" height="${totalSize}" viewBox="0 0 ${totalSize} ${totalSize}" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="${totalSize / 2}" cy="${totalSize / 2}" r="${Number(size) / 2}" fill="${fillColor}" fill-opacity="${fillAlpha}" stroke="${outlineColor}" stroke-opacity="${outlineAlpha}" stroke-width="${outlineWidth}"/>
  </svg>`
}
