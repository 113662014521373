
import { defineComponent } from 'vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  components: { BaseButton, BaseIcon, InlineSvg },
  props: {
    isTriple: { type: Boolean, required: false, default: false },
    note: { type: Boolean, required: false, default: false }
  },
  emits: ['cancel', 'confirm', 'deny']
})
