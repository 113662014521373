// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum MapsActionTypes {
  FETCH_MAPS = 'MAPS_FETCH_MAPS',
  FETCH_MAP = 'MAPS_FETCH_MAP',
  FETCH_UNAUTHORISED_MAP = 'MAPS_FETCH_UNAUTHORISED_MAP',
  FETCH_MAP_CONFIG = 'MAPS_FETCH_MAP_CONFIG',
  MAP_REFRESH = 'MAPS_MAP_REFRESH',
  FETCH_TASKS = 'MAPS_FETCH_TASKS',
  FETCH_TASK = 'MAPS_TASK_FETCH',
  FETCH_LAYER = 'MAPS_FETCH_LAYER',
  FETCH_LAYER_FEATURE = 'MAPS_FETCH_LAYER_FEATURE',
  LAYER_FEATURES_BATCH_UPDATE = 'MAPS_LAYER_FEATURES_BATCH_UPDATE',
  LAYER_FEATURE_UPDATE = 'MAPS_LAYER_FEATURE_UPDATE',
  LAYER_FEATURE_CREATE = 'MAPS_LAYER_FEATURE_CREATE',
  LAYER_FEATURE_DELETE = 'MAPS_LAYER_FEATURE_DELETE',
  LAYER_REFRESH = 'MAPS_LAYER_REFRESH',
  MAP_CREATE = 'MAPS_MAP_CREATE',
  LAYER_IMPORT = 'MAPS_LAYER_IMPORT',
  CREATE_GROUP = 'MAPS_CREATE_GROUP',
  DELETE_GROUP = 'MAPS_DELETE_GROUP',
  DELETE_GROUPS = 'MAPS_DELETE_GROUPS',
  CREATE_LAYER = 'MAPS_CREATE_LAYER',
  DELETE_LAYER = 'MAPS_DELETE_LAYER',
  DELETE_LAYERS = 'MAPS_DELETE_LAYERS',
  UPDATE_GROUP_INFO = 'MAPS_UPDATE_GROUP_INFO',
  UPDATE_LAYER_INFO = 'MAPS_UPDATE_LAYER_INFO',
  MAP_UPDATE_GROUP_ROOT = 'MAPS_MAP_UPDATE_GROUP_ROOT',
  GET_SESSION_DATA = 'MAPS_GET_SESSION_DATA',
  PUT_SESSION_DATA = 'MAPS_PUT_SESSION_DATA',
  UPDATE_ATTRIBUTES = 'MAPS_UPDATE_ATTRIBUTES',
  MAP_RENAME = 'MAPS_MAP_RENAME',
  MAP_DELETE = 'MAPS_MAP_DELETE',
  MAP_RESTORE = 'MAPS_MAP_RESTORE',
  MOVE_MAP_TO_TRASH = 'MAPS_MOVE_MAP_TO_TRASH',
  LAYER_EXPORT_TASK = 'MAPS_LAYER_EXPORT_TASK',
  GET_LAYER_COLUMNS = 'MAPS_GET_LAYER_COLUMNS',
  PATCH_LAYER = 'MAPS_PATCH_LAYER',
  PATCH_LAYER_STYLE = 'MAPS_PATCH_LAYER_SYLE',
  FILE_DOWNLOAD = 'MAPS_FILE_DOWNLOAD',
  PATCH_MAP = 'MAPS_PATCH_MAP',
  PATCH_USER_ACCESS_LEVEL = 'MAPS_PATCH_USER_ACCESS_LEVEL',
  DELETE_USER_ACCESS_LEVEL = 'MAPS_DELETE_USER_ACCESS_LEVEL',
  GET_USER_ACCESS_LEVEL = 'MAPS_GET_USER_ACCESS_LEVEL',
  GET_BOOKMARKS = 'MNAPS_GET_BOOKMARKS',
  PATCH_BOOKMARK = 'MAPS_PATCH_BOOKMARK',
  DELETE_BOOKMARK = 'MAPS_DELETE_BOOKMARK',
  CREATE_BOOKMARK = 'MAPS_CREATE_BOOKMARK',
  RESET_TREE_STRUCTURE = 'MAPS_RESET_TREE_STRUCTURE',
  MAP_FEATURES_SEARCH = 'MAPS_MAP_FEATURES_SEARCH',
  MAP_FEATURES_SEARCH_BUFFER = 'MAPS_MAP_FEATURES_SEARCH_BUFFER',
  MAP_TEXT_SEARCH = 'MAPS_MAP_TEXT_SEARCH',
  GET_GROUPS_INFO = 'MAPS_GET_GROUPS_INFO',
  GET_LAYER_ATTRIBUTE_VALUES = 'MAPS_GET_LAYER_ATTRIBUTE_VALUES',
  DELETE_LAYER_FEATURES = 'MAPS_DELETE_LAYER_FEATURES',
  LAYER_COLUMNS_DELETE = 'MAPS_LAYER_COLUMNS_DELETE',
  LAYER_COLUMNS_CREATE = 'MAPS_LAYER_COLUMNS_CREATE',
  LAYER_COLUMNS_UPDATE = 'MAPS_LAYER_COLUMNS_UPDATE',
  FETCH_FILTERED_LAYER_FEATURES = 'MAPS_FETCH_FILTERED_LAYER_FEATURES',
  FETCH_LAYER_FEATURE_ATTRIBUTES = 'MAPS_FETCH_LAYER_FEATURE_ATTRIBUTES',
  LAYER_STYLE_COPY = 'MAPS_LAYER_STYLE_COPY',
  GET_LAYER_STYLE_ICONS = 'MAPS_GET_LAYER_STYLE_ICONS',
  LAYER_CONVERT_TO_3D = 'MAPS_LAYER_CONVERT_TO_3D',
  LAYER_TRANSFORM_3D = 'MAPS_LAYER_TRANSFORM_3D',
  LAYER_LEGEND_IMAGE = 'MAPS_GET_LAYER_LEGEND_IMAGE'
}

export enum StorageActionTypes {
  DELETE_ITEM = 'STORAGE_DELETE_ITEM',
  RENAME_ITEM = 'STORAGE_RENAME_ITEM',
  COPY_ITEM = 'STORAGE_COPY_ITEM',
  MOVE_ITEM = 'STORAGE_MOVE_ITEM'
}
