import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ce8cc57"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "base-header__counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_focus = _resolveDirective("focus")!

  return (!_ctx.editEnable)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.calculateClass(false)),
        style: _normalizeStyle(_ctx.innerStyle),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
      }, [
        (_ctx.selectable)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "base-header__text",
              onMouseover: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('mouseleaveText', $event); _ctx.isTextHovered = true}),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('mouseleaveText', $event); _ctx.isTextHovered = false})
            }, _toDisplayString(_ctx.title), 33))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ], 64))
      ], 6)), [
        [_directive_tooltip, _ctx.showTip ? _ctx.title : undefined]
      ])
    : (_ctx.editEnable && _ctx.showWidthCounter && _ctx.isValidMaxWidth)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editableNewHeader) = $event)),
            type: "text",
            class: _normalizeClass(["base-header input", _ctx.calculateClass(true)]),
            onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.blurField && _ctx.blurField(...args))),
            onKeyup: _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.blurField && _ctx.blurField(...args)), ["enter"])),
            onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handlerInput && _ctx.handlerInput(...args))),
            onMouseover: _cache[7] || (_cache[7] = e => _ctx.$emit('mouseoverText', e)),
            onMouseleave: _cache[8] || (_cache[8] = e => _ctx.$emit('mouseleaveText', e))
          }, null, 34), [
            [_vModelText, _ctx.editableNewHeader],
            [_directive_focus]
          ]),
          _createElementVNode("div", null, _toDisplayString(_ctx.editableNewHeader.length) + " / " + _toDisplayString(_ctx.maxWidth), 1)
        ]))
      : _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 2,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editableNewHeader) = $event)),
          type: "text",
          class: _normalizeClass(_ctx.calculateClass(true)),
          onBlur: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.blurField && _ctx.blurField(...args))),
          onMouseover: _cache[11] || (_cache[11] = e => _ctx.$emit('mouseoverText', e)),
          onMouseleave: _cache[12] || (_cache[12] = e => _ctx.$emit('mouseleaveText', e))
        }, null, 34)), [
          [_vModelText, _ctx.editableNewHeader],
          [_directive_focus]
        ])
}