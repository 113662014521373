
import i18n from '@/i18n'
import { defineComponent, inject } from 'vue'

import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseWindow from '@/library/base/BaseWindow.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { ActionTopRightKey } from '../../../actions/symbol'
import { useStore } from '@/library/store'
import { IconType, ButtonType, TextType, SizeType } from '@/library/types/base/enums'
import { MapActiveToolType } from '@/library/types/maps/enums'
import imgLink from '@/library/assets/img/icLink.svg'
import BaseScrollbar from '@/library/base/BaseScrollbar.vue'
import BaseCopy from '@/library/base/BaseCopy.vue'
import toast from '@/library/toast'
import NotificationComponent from '@/library/toast/NotificationComponent.vue'
import { Map3DKey } from '../../../symbol'
import { Cartesian3 } from 'cesium'
import { WINDOW_BAR_HEIGHT } from '@/library/helpers'

const store = useStore()

export default defineComponent({
  components: {
    BaseHeader,
    BaseWindow,
    BaseIcon,
    BaseScrollbar,
    BaseCopy
  },

  setup () {
    const ActionTopRight = inject(ActionTopRightKey)
    return {
      ActionTopRight,
      IconType,
      SizeType,
      TextType,
      ButtonType
    }
  },

  data () {
    return {
      map3d: inject(Map3DKey),
      link: '',
      imgLink,
      contentHeight: 0
    }
  },

  computed: {
    isActive (): boolean {
      if (this.ActionTopRight) {
        return this.ActionTopRight.activeAction === MapActiveToolType.MAP_EXTENT_LINK_CREATOR
      }
      return false
    },

    visibleLayers () {
      return store.state.maps.visibleLayers
    },

    iframe (): string {
      const _iframe = document.createElement('iframe')
      _iframe.src = this.link
      _iframe.width = '100%'
      _iframe.height = '100%'
      return _iframe.outerHTML
    },

    infoBoxHeight (): number {
      return this.contentHeight + WINDOW_BAR_HEIGHT
    }
  },

  methods: {
    $t: i18n.global.t,

    closePanel () {
      if (this.ActionTopRight) {
        this.ActionTopRight.activateAction(null)
      }
    },

    prepareCustomToastComponent (type: string) {
      let headerText = ''
      let subheaderText = ''
      switch (type) {
        case 'extent':
          headerText = this.$t('maps.extent.copyExtentNotificationHeader')
          subheaderText = ''
          break
        case 'iframe':
          headerText = this.$t('maps.extent.copyIframeNotificationHeader')
          subheaderText = ''
          break
      }
      return {
        component: NotificationComponent,
        props: {
          header: headerText,
          subheader: subheaderText
        }
      }
    },

    showNotification (type: string) {
      toast.success(this.prepareCustomToastComponent(type))
    },

    recalcLink () {
      // const mapId = Number(this.$route.params.id)
      const viewer = this.map3d?.viewer?.viewer

      if (!viewer) {
        return
      }

      const camera = viewer.scene.camera
      const position = camera.positionCartographic
      const destination = Cartesian3.fromRadians(position.longitude, position.latitude, position.height)

      const prefix = `${document.location.origin}/viewer?`

      const preparedLayers = this.visibleLayers.map(item => {
        return JSON.stringify(item)
      })
      this.link = prefix + [
          `orientation=[${camera.heading}, ${camera.pitch}, ${camera.roll}]`,
          `destination=[${destination.x}, ${destination.y}, ${destination.z}]`,
          `visibleLayers=${encodeURI(preparedLayers.join('~'))}`
      ].join('&')
    },

    handleClick () {
      if (!this.ActionTopRight) {
        return
      }
      if (this.isActive) {
        this.ActionTopRight.activateAction(null)
      } else {
        this.ActionTopRight.activateAction(MapActiveToolType.MAP_EXTENT_LINK_CREATOR)
        this.recalcLink()
        this.$nextTick(() => {
          this.contentHeight = (this.$refs.basePanelRef as HTMLElement)
            .getBoundingClientRect()
            .height
        })
      }
    }
  }
})
