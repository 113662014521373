import { LineString } from 'ol/geom'
import { AnyFunction, ModelFeatureProp } from '../types'

const equator = new LineString([[-180, 0], [180, 0]])
equator.transform('EPSG:4326', 'EPSG:3857')
export const EARTH_EQUATOR_LENGTH = equator.getLength()
export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000
export const DOCUMENTATION_LINK = 'https://disk.yandex.ru/d/j3E-YUiyOCUhPQ'

export const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text)
}

export const roundAfterDecimalPoint = (
  value: number, count: number, mode?: 'floor' | 'ceil'
): number => {
  const koef = Math.pow(10, count)
  if (mode === 'floor') {
    return Math.floor(value * koef) / koef
  } else if (mode === 'ceil') {
    return Math.ceil(value * koef) / koef
  }
  return Math.round(value * koef) / koef
}

export const wordEndingByCount = (
  count: number, base: string, endings: string[]
): string => {
  count = Math.abs(count) % 100
  const count1 = count % 10
  if (count > 10 && count < 20) {
    return base + endings[2]
  } else if (count1 > 1 && count1 < 5) {
    return base + endings[1]
  } else if (count1 === 1) {
    return base + endings[0]
  }
  return base + endings[2]
}

export const generateRangeBetweenNumbers = (
  start: number, end: number, count: number
): number[] => {
  const result: number[] = []
  const diff = end - start
  const step = roundAfterDecimalPoint((diff / (count - 1)), 2)
  let index = 0
  result.push(start)
  while (index < count - 2) {
    result.push(roundAfterDecimalPoint(result[index] + step, 2))
    index += 1
  }
  result.push(end)
  return result
}

export const isStrictObject = (value: unknown): boolean => {
  if (value === null || Array.isArray(value)) {
    return false
  }
  return ['function', 'object'].includes(typeof value)
}

export const isDeepStrictEqual = (left: unknown, right: unknown): boolean => {
  if (isStrictObject(left) && isStrictObject(right)) {
    const leftObject = left as Record<string, unknown>
    const rightObject = right as Record<string, unknown>
    const leftKeys = Object.keys(leftObject)
    const rightKeys = Object.keys(rightObject)
    if (leftKeys.length !== rightKeys.length) {
      return false
    }
    for (const property of leftKeys) {
      const leftPropValue = leftObject[property as keyof typeof leftObject]
      const rightPropValue = rightObject[property as keyof typeof rightObject]
      const areObjects = isStrictObject(leftPropValue) && isStrictObject(rightPropValue)
      if (
        (areObjects && !isDeepStrictEqual(leftPropValue, rightPropValue)) ||
        (!areObjects && leftPropValue !== rightPropValue)
      ) {
        return false
      }
    }
    return true
  }
  return false
}

export const isStrictEmpty = (value: unknown): boolean => {
  return value === undefined || value === null || Number.isNaN(value) || value === ''
}

export const convertVhToPx = (percent: number): number => {
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  return (percent * h) / 100
}

export const convertVwToPx = (percent: number): number => {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (percent * w) / 100
}

export const findClosestValue = (enumerate: number[], target: number): number => {
  let closestValue = enumerate[0]
  let minDiff = Math.abs(target - closestValue)
  for (let i = 1; i < enumerate.length; i++) {
    const diff = Math.abs(target - enumerate[i])
    if (diff < minDiff) {
      minDiff = diff
      closestValue = enumerate[i]
    }
  }
  return closestValue
}

export const debounceCall = (func: AnyFunction, timeout?: number): AnyFunction => {
  timeout = timeout ?? 0
  let timer: number | undefined
  return (...args: unknown[]) => {
    clearTimeout(timer)
    timer = window.setTimeout(() => {
      return func.apply(this, args)
    }, timeout)
  }
}

export const prepareAnyClickHandler = (
  targetCount: number,
  callback: AnyFunction
): AnyFunction => {
  let clickCount = 0
  return (...args: unknown[]): void => {
    clickCount++
    if (clickCount === targetCount) {
      callback.apply(this, args)
      clickCount = 0
    } else {
      setTimeout(() => {
        clickCount = 0
      }, 500)
    }
  }
}

export const validateLink = (value: ModelFeatureProp): boolean => {
  return typeof value === 'string' && (
    value.trim().startsWith('http://') ||
    value.trim().startsWith('https://')
  )
}

export const sumFromArray = (array: number[]): number => {
  return array.reduce((accumulator, currentValue) => {
    return accumulator + currentValue
  }, 0)
}

export const getRandomItemFromArray = (array: unknown[]): unknown => {
  const randomIndex = Math.floor(Math.random() * array.length)
  return array[randomIndex]
}
