import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2d58c78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signature-preview" }
const _hoisted_2 = { class: "signature-preview__block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_style_preview = _resolveComponent("style-preview")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_style_preview, {
      "figure-type": _ctx.modelValue.figureType,
      styles: _ctx.modelValue.styles
    }, null, 8, ["figure-type", "styles"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_inline_svg, {
        class: "signature-preview__icon",
        src: _ctx.modelValue.icon || _ctx.icon
      }, null, 8, ["src"]),
      _createElementVNode("div", {
        class: "signature-preview__text",
        style: _normalizeStyle(_ctx.modelValue.textStyle)
      }, _toDisplayString(_ctx.$t('maps.layers.styles.signature.previewText')), 5)
    ])
  ]))
}