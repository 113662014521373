export enum UsersSorterFields {
  FNAME = 'firstName',
  LNAME = 'lastName',
  EMAIL = 'email',
  STATUS = 'lastAccess'
}

export enum UserOnlineStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export enum StartPageLinks {
  VIEWER = 'viewer',
  VIEWER2D = 'viewer2D',
  VIEWER3D = 'viewer3D',
  LOGIN = 'login',
  CPS = 'cps',
  CSS = 'css',
  BAYKALCENTER = 'baikal-center',
  BAYKALSK = 'baikalsk',
  IGT = 'igt'
}

export enum LayerPerm {
  READ = 'read',
  WRITE = 'readwrite',
  DENY = 'deny'
}

export enum ServicePerm {
  MODERATION = 'moderation',
  MODIFICATION = 'modification',
  COMMENTING = 'commenting',
  READ = 'read',
  DENY = 'deny'
}

export enum ServiceCode {
  CITY_PROJECTS = 'city_projects',
  REAL_ESTATE = 'real_estate',
  VACANCY = 'vacancy',
  CITY_SUGGESTION = 'city_suggestion',
  VIDEO_SURVEILLANCE = 'video_streams',
}

export enum HeaderTab {
  MAP = 'map',
  DATA = 'data',
  SERVICE = 'service',
  PERSON = 'person'
}

export enum ProjectStatus {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
}

export enum CityProjectType {
  TWO_D = '2D',
  THREE_D = '3D',
}

export enum CityPresentationPanelTab {
  LAYERS = 'layers',
  INFOBOXES = 'box',
  MAP = 'map'
}

export enum VideoStreamsStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export enum SearchProjectStatus {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  ALL = 'all'
}

export enum CityPresentationAnimation {
  FAST = 'fast',
  SLOW = 'slow',
}

export enum InfoboxBind {
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
}

export enum WeatherTabIconCode {
  TEMP = 'temp',
  PRESIP = 'precipitation',
  PRESSURE = 'pressure',
  HUMIDITY = 'humidity',
  WINDSPEED = 'windspeed',
  PM25 = 'pm25',
  PM10 = 'pm10',
  CO = 'co',
  O3 = 'o3',
  NO2 = 'no2',
  SO2 = 'so2',
  SQUARE = 'square'
}

export enum EstatePurposeType {
  COMMERCIAL = 'commercial',
  LIVE = 'live'
}

export enum EstateAdevertType {
  RENT = 'rent',
  SALE = 'sale'
}

export enum SearchEstateAdvert {
  RENT = 'rent',
  SALE = 'sale',
  ALL = 'all'
}

export enum EstateSortField {
  PUBLISHED = 'published',
  PRICE = 'price'
}

export enum ModerationEstateSorterFields {
  CREATED = 'created',
  NAME = 'name',
  STATUS = 'status',
  AUTHOR = 'author',
  TYPE = 'advertType'
}

export enum VacanciesSortField {
  PUBLISHED = 'published',
  SALARYAVERAGE = 'salaryAverage'
}

export enum SearchVacancyExperience {
  NO = 'no_experience',
  ALL = 'any_experience',
  UPTOTHREE = 'one_three_years',
  UPTOSIX = 'three_six_years',
  FROMSIX = 'six_more_years'
}

export enum SearchVacancyEducation {
  SECONDARY = 'secondary',
  VOCATIONAL = 'secondary_vocational',
  HIGHER = 'higher'
}

export enum ModerationVacanciesSorterFields {
  CREATED = 'created',
  NAME = 'name',
  STATUS = 'status',
  AUTHOR = 'author',
  ORGANIZATION = 'organization'
}

export enum CitySuggestionType {
  IDEA = 'idea',
  PROBLEM = 'problem'
}

export enum CitySuggestionStatus {
  CONSIDERATION = 'consideration',
  REJECTED = 'rejected',
  INWORK = 'in_work',
  SUSPENDED = 'suspended',
  COMPLETED = 'completed'
}

export enum ModerationSuggestionsSorterFields {
  CREATED = 'created',
  NAME = 'name',
  STATUS = 'status',
  AUTHOR = 'author',
  TYPE = 'suggestionType'
}

export enum EgrnStatus {
  SENT = 'request_sent',
  DONWLOAD = 'data_download',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
  ERROR = 'download_error',
  UPDATED = 'data_updated',
}

export enum ModerationEgrnSorterFields {
  NAME = 'name',
  SIZE = 'size',
  UPLOADED = 'created',
  STATUS = 'status'
}
