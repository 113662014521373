
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import GroupItem from './GroupItem.vue'
import i18n from '@/i18n'

import { GeoMapLayer, GeoMapLayergroup } from '@/library/types'

const store = useStore()

export default defineComponent({
  components: {
    GroupItem
  },
  data () {
    return {
      windowRect: {},
      getMapRootGroup: !store.state.baykalsk.map ? [] : [...store.state.baykalsk.map.groups.groups, ...store.state.baykalsk.map.groups.layers].sort((a, b) => b.zIndex - a.zIndex)
    }
  },
  computed: {
    rootGroup () {
      return store.state.baykalsk.map?.groups ?? null
    }
  },

  mounted () {
    this.refreshWindowRect()
  },

  methods: {
    $t: i18n.global.t,

    refreshWindowRect () {
      if (this.$refs.container) {
        const container = this.$refs.container as HTMLElement
        if (container.parentElement) {
          this.windowRect = container.parentElement.getBoundingClientRect()
        }
      }
    },
    isGroup (inp : GeoMapLayer | GeoMapLayergroup) : inp is GeoMapLayergroup {
      return (inp as GeoMapLayergroup).groups !== undefined
    },
    getFlatList (group: GeoMapLayergroup) {
      const arr = [...group.groups, ...group.layers].sort((a, b) => b.zIndex - a.zIndex)
      return arr.reduce<(GeoMapLayer | GeoMapLayergroup)[]>((flat, item) => {
        flat.push(item)
        if (this.isGroup(item)) {
          flat = [...flat, ...this.getFlatList(item)]
        }
        return flat
      }, [])
    }
  }
})
