import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b7df366"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "basePanelRef",
  class: "extent-link__panel"
}
const _hoisted_2 = { class: "extent-link__item item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_header = _resolveComponent("base-header")!
  const _component_base_copy = _resolveComponent("base-copy")!
  const _component_base_scrollbar = _resolveComponent("base-scrollbar")!
  const _component_base_window = _resolveComponent("base-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_base_icon, {
      "model-value": _ctx.isActive,
      type: _ctx.IconType.SECONDARY,
      size: _ctx.SizeType.LG,
      src: _ctx.imgLink,
      toggled: "",
      onClick: _ctx.handleClick
    }, null, 8, ["model-value", "type", "size", "src", "onClick"]), [
      [_directive_tooltip, _ctx.$t('maps.extent.actions.share')]
    ]),
    (_ctx.isActive)
      ? (_openBlock(), _createBlock(_component_base_window, {
          key: 0,
          class: "extent-link",
          top: 130,
          right: 70,
          width: 618,
          height: _ctx.infoBoxHeight,
          "min-w": 618,
          "min-h": 232,
          onClose: _ctx.closePanel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_scrollbar, { class: "extent-link__content" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_base_header, {
                    level: "2",
                    class: "extent-link__title",
                    title: _ctx.$t('maps.extent.actions.share')
                  }, null, 8, ["title"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_base_header, {
                      level: "4",
                      class: "item__title",
                      title: _ctx.$t('maps.extent.actions.mapExtent')
                    }, null, 8, ["title"]),
                    _createVNode(_component_base_copy, {
                      text: _ctx.link,
                      "always-visible": "",
                      class: "item__copy",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNotification('extent')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_base_header, {
                          level: "3",
                          "show-tip": "",
                          type: _ctx.TextType.BODY,
                          title: _ctx.link
                        }, null, 8, ["type", "title"])
                      ]),
                      _: 1
                    }, 8, ["text"])
                  ])
                ], 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["height", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}