
import { computed, defineComponent, inject, PropType } from 'vue'
import InlineSvg from 'vue-inline-svg'
import i18n from '@/i18n'

import { MapsMutationTypes } from '@/library/store/modules/maps/mutation-types'
import { MapsActionTypes } from '@/library/store/modules/maps/action-types'
import { AppActionTypes } from '@/store/modules/baykalsk/action-types'
import { useStore } from '@/library/store'
import toast from '@/library/toast'
import {
  GeoMapLayer,
  MenuTreeItem,
  AttributeInfo,
  Symbolizer,
  GeometryStyleOptions,
  LayerFilterItem,
  MapBoxStyle
} from '@/library/types'
import {
  NUMBER_DB_TYPES,
  MAX_LAYER_NAME_LENGTH,
  prepareGeometryStyle,
  getLayerGeometryType,
  parseExpressionAttributeName,
  getGeometrySymbolOptions,
  applyComplexStyle,
  sortLegend,
  isLayerHasExtent,
  is3dLayer,
  isHybridLayer,
  isRasterLayer,
  isExternalLayer,
  isLayer
} from '@/library/helpers'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseBodyText from '@/library/base/BaseBodyText.vue'
import BaseScrollbar from '@/library/base/BaseScrollbar.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import LegendSymbolMulti from '@/library/maps/viewer/shared/legend/LegendSymbolMulti.vue'
import LegendExternalSymbol from '@/library/maps/viewer/shared/legend/LegendExternalSymbol.vue'
import LegendSymbol from '@/library/maps/viewer/shared/legend/LegendSymbol.vue'
import {
  GeoMapLayerType,
  LayerExportType,
  GeoMapAccessAction,
  LayerActiveToolType,
  MapActiveToolType,
  LayerExternalMode,
  FeatureType,
  TreeSelectType
} from '@/library/types/maps/enums'
import {
  IconType,
  TextType,
  SizeType
} from '@/library/types/base/enums'
import { ComplexStyleType } from '@/library/types/styles_editor/enums'
import { Map2DKey, Map3DKey } from '@/library/maps/viewer/symbol'
import auth from '@/library/auth'

import LegendLayerImage from '@/library/maps/viewer/shared/legend/LegendLayerImage.vue'
import imgCollapse from '@/library/assets/img/icArrow.svg'
import imgRaster from '@/library/assets/img/maps/symbols/raster.svg'
import img3d from '@/library/assets/img/maps/symbols/model3d.svg'
import { useStore as usestoreApp } from '@/store'
import BaseRadio from '@/components/base/BaseRadio.vue'
import { AppMutationTypes } from '@/store/modules/baykalsk/mutation-types'
import { LayerPerm } from '@/types/baykalsk/enums'
import { useToast } from 'vue-toastification'
import ToastTo3D from '@/components/app/ToastTo3D.vue'
import imgDisabled from '@/library/assets/img/icVisionOff.svg'
import imgActive from '@/library/assets/img/icVisionOn.svg'
import imgConfirm from '@/library/assets/img/icDone.svg'
import imgClose from '@/library/assets/img/icCrossedClose.svg'
import imgEdit from '@/library/assets/img/icEdit.svg'
// import imgInfo from '@/library/assets/img/icInfo.svg'
import imgAdd from '@/library/assets/img/icAdd.svg'
import imgEdit2 from '@/library/assets/img/icEdit2.svg'
import imgExport from '@/library/assets/img/icExport.svg'
import imgCopy from '@/library/assets/img/icCopy.svg'
import imgDelete from '@/library/assets/img/icDelete.svg'
import imgTable from '@/library/assets/img/icTable.svg'
import imgTransp from '@/library/assets/img/icTransp.svg'
import imgSetting from '@/library/assets/img/icSetting.svg'
import imgScale from '@/library/assets/img/icScale.svg'
import img3dLayer from '@/library/assets/img/ic3dLayer.svg'
import imgEditObject from '@/library/assets/img/icEditObject.svg'
import { CUSTOM_SLD_STYLE, DEFAULT_PRIMARY_COLOR, DEFAULT_RASTER_STYLE, DEFAULT_STROKE_WIDTH } from '@/library/maps/viewer/layers/styles/helpers/main'

const store = useStore()
const baykalskStore = usestoreApp()
const appToast = useToast()

const ATTRIBUTE_LOADING_SIZE = 50
const COMMON_PROPERTY = '__common__'
const t = i18n.global.t

export default defineComponent({
  components: {
    BaseBodyText,
    BaseHeader,
    BaseIcon,
    InlineSvg,
    LegendSymbol,
    LegendSymbolMulti,
    LegendExternalSymbol,
    BaseRadio,
    BaseScrollbar,
    LegendLayerImage
  },
  props: {
    layer: { type: Object as PropType<GeoMapLayer>, required: true },
    windowRect: { type: Object, required: true },
    isPresentation: {
      type: Boolean,
      default: false
    },
    is3dPresentationMode: {
      type: Boolean,
      default: false
    },
    level: { type: Number, default: 0 }
  },
  emits: [
    'flyTo',
    'openInfoWindow',
    'openExportWindow',
    'showOpacitySlider',
    'switchedOpacity',
    'update',
    'mouseoverText',
    'mouseleaveText'
  ],
  setup () {
    const map2d = inject(Map2DKey)
    const map3d = inject(Map3DKey)

    const mapProvider = computed(() => {
      return map2d?.value || map3d?.value
    })

    return {
      MAX_LAYER_NAME_LENGTH,
      map2d,
      map3d,
      mapProvider,
      imgCollapse,
      imgRaster,
      img3d,
      imgDisabled,
      imgActive,
      imgConfirm,
      imgClose,
      t,
      LayerExportType,
      IconType,
      SizeType,
      LayerPerm,
      TextType
    }
  },
  data () {
    return {
      isRenameActive: false,
      menuOpened: false,
      isOpenedConfirmDelete: false,
      opacityScrollOpened: false,
      styleOptions: {} as GeometryStyleOptions,
      isLoading: false,
      isSubmitEdit: false,
      isBlockedRefresh: false,
      isBlockedLazyLoad: false,
      loadedPosition: 0,
      renamedLayerName: '',
      opacityPosition: {
        opacity: 1,
        left: '',
        top: ''
      },
      layerType: undefined as FeatureType | undefined,
      visibleLayersAttributes: [] as AttributeInfo[],
      layersAttributes: {} as Record<string, AttributeInfo>
    }
  },
  computed: {
    isEnabledMultipleSelect () {
      return store.state.maps.multipleTreeSelect.isEnable
    },

    dragCounter () {
      if (!this.isEnabledMultipleSelect || store.state.maps.multipleTreeSelect.type !== TreeSelectType.GROUPS_DRAG) {
        return null
      }

      if (!store.state.maps.draggableTreeElement || !isLayer(store.state.maps.draggableTreeElement)) {
        return null
      }

      if (store.state.maps.draggableTreeElement.id !== this.layer.id) {
        return null
      }

      if (store.state.maps.selectedLayers.length > 1) {
        return store.state.maps.selectedLayers.length - 1
      } else {
        return null
      }
    },

    isEnabledMultipleSelectStyleCopy: {
      get () {
        return store.state.maps.multipleTreeSelect.isEnable && store.state.maps.multipleTreeSelect.type === TreeSelectType.STYLE_COPY
      },
      set (newState: boolean) {
        if (newState) {
          store.commit(MapsMutationTypes.SET_MULTIPLE_TREE_SELECT, { isEnable: true, type: TreeSelectType.STYLE_COPY, layerId: this.layer.id })
        } else {
          store.commit(MapsMutationTypes.SET_MULTIPLE_TREE_SELECT, { isEnable: false, type: TreeSelectType.STYLE_COPY })
        }

        if (this.mapProvider) {
          if (newState) {
            this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.MULTIPLE_SELECT })
          } else {
            this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.CLICK })
          }
        }
      }
    },

    isAvailableForExport () {
      return this.layer.type === GeoMapLayerType.HYBRID && this.layer.columns
    },

    commonInfo () {
      return this.layersAttributes[COMMON_PROPERTY]
    },

    layerLegendSymbol (): Symbolizer | undefined {
      if (this.layerType) {
        if (this.layer.style?.mboxStyle?.name === CUSTOM_SLD_STYLE) {
          const defaultOptions = {
            stroke: DEFAULT_PRIMARY_COLOR,
            strokeWidth: DEFAULT_STROKE_WIDTH
          }
          return prepareGeometryStyle(this.layerType, defaultOptions)
        }
        return prepareGeometryStyle(this.layerType, this.styleOptions)
      }
      return undefined
    },

    layerLegendExternalSymbol (): Symbolizer | undefined {
      const geometryType = this.layer.style?.mboxStyle.__geometryType__ || FeatureType.POLYGON
      const defaultOptions = getGeometrySymbolOptions(this.layer.style?.mboxStyle)
      if (!defaultOptions.stroke || !defaultOptions.stroke.length) {
        defaultOptions.stroke = store.getters.currentTheme['--geocode-bg-inverse']
      }
      return prepareGeometryStyle(geometryType, defaultOptions)
    },

    isActiveMeasurementTool () {
      return this.mapProvider?.mapActiveTool === MapActiveToolType.MAP_MEASUREMENT
    },

    isHiddenEditLayer () {
      return (
        !this.isHybridLayer ||
        this.isActiveMeasurementTool ||
        !this.checkAccess(auth.access.actions.LAYER_EDIT)
      )
    },

    isAllowSettings () {
      return this.isHybridLayer || this.isExternalLegendSymbol
    },

    isRaster () {
      return isRasterLayer(this.layer)
    },

    isAllowTable () {
      const isTableName = this.layer.tableName && this.layer.tableName.length
      return isTableName
    },

    menuItems () {
      const result: MenuTreeItem[] = [{
        /* label: this.t('maps.layers.tree.menu.showInfo'),
        handler: this.openInfoDialog,
        icon: imgInfo,
        isHidden: !this.checkAccess(auth.access.actions.LAYER_EDIT)
      }, { */
        label: this.t('maps.layers.tree.menu.rename'),
        handler: this.toggleRename,
        isHidden: !(baykalskStore.state.auth.isAdmin),
        icon: imgEdit
      }, {
        label: this.t('maps.layers.tree.menu.settings'),
        icon: imgSetting,
        handler: this.showSettings,
        isHidden: !this.isAllowSettings || !this.checkAccess(auth.access.actions.LAYER_EDIT)
      }, {
        label: this.t('maps.layers.tree.menu.fly-to'),
        handler: this.flyTo,
        icon: imgScale,
        isHidden: !isLayerHasExtent(this.layer)
      }, {
        label: this.t('maps.layers.tree.menu.openTable'),
        handler: this.showAttributeTable,
        icon: imgTable,
        isHidden: !this.isAllowTable
      }, {
        label: this.t('maps.layers.tree.menu.set-opacity'),
        icon: imgTransp,
        handler: this.showOpacityRange
      }, {
        label: this.t('maps.layers.tree.menu.edit-layer.title'),
        icon: imgEditObject,
        isHidden: this.isHiddenEditLayer,
        children: [{
          label: this.t('maps.layers.tree.menu.edit-layer.options.edit'),
          icon: imgEdit,
          handler: this.editLayerObject
        }, {
          label: this.t('maps.layers.tree.menu.edit-layer.options.create'),
          icon: imgAdd,
          handler: this.createLayerObject
        }]
      },
      {
        label: this.t('maps.layers.tree.menu.3d'),
        icon: img3dLayer,
        isHidden: !store.state.maps.map?.is3d || !this.isHybridLayer || this.layer.type !== GeoMapLayerType.HYBRID || !(this.layerType === FeatureType.POLYGON || this.layerType === FeatureType.MULTI_POLYGON) || !this.checkAccess(auth.access.actions.LAYER_EDIT),
        children: [{
          label: this.t('maps.layers.tree.menu.convertTo3d'),
          icon: imgAdd,
          handler: this.showConvertTo3d
        }, {
          label: this.t('maps.layers.tree.menu.settings3d'),
          icon: imgSetting,
          handler: this.showSettings3d,
          isHidden: !this.layer.urlTileset || !this.layer.urlTileset.length
        }]
      }, {
        label: this.t('maps.layers.tree.menu.settings3d'),
        icon: imgSetting,
        isHidden: !store.state.maps.map?.is3d || !this.layer.isGeo || this.layer.type !== GeoMapLayerType.TILESET_3D || !this.checkAccess(auth.access.actions.LAYER_EDIT),
        handler: this.showSettings3d
      }, {
        label: this.t('maps.layers.tree.menu.edit-style.title'),
        icon: imgEdit2,
        isHidden: !this.isShowedStyle || !this.checkAccess(auth.access.actions.LAYER_STYLE),
        children: this.layer.type === GeoMapLayerType.HYBRID ? [{
          label: this.t('maps.layers.tree.menu.edit-style.options.edit'),
          icon: imgEdit,
          handler: this.showStylesEditor
        }, {
          label: this.t('maps.layers.tree.menu.edit-style.options.copy'),
          icon: imgCopy,
          handler: this.showStyleCopy
        }] : [{
          label: this.t('maps.layers.tree.menu.edit-style.options.edit'),
          icon: imgEdit,
          handler: this.showStylesEditor
        }]
      }, {
        label: this.t('maps.layers.tree.menu.export'),
        icon: imgExport,
        isHidden: this.isExternalLegendSymbol || !this.checkAccess(auth.access.actions.EXPORT),
        handler: this.exportLayer
      }, {
        label: this.t('maps.layers.tree.menu.remove-layer'),
        icon: imgDelete,
        handler: this.isOpenedConfirmDeleteDialog,
        isHidden: !(baykalskStore.state.auth.isAdmin)
      }, {
        label: this.t('maps.layers.change-log.menu-title'),
        handler: this.showChangeLog,
        isHidden: !this.checkAccess(auth.access.actions.LAYER_EDIT) || this.isRaster || this.is3dLayer || this.isFromLinkLayer
      }, {
        label: this.t('maps.layers.backups.menu-title'),
        isHidden: !(baykalskStore.state.auth.isAdmin) || this.isRaster || this.is3dLayer || this.isFromLinkLayer,
        children: [{
          label: this.t('maps.layers.backups.menu-create'),
          handler: this.layerCopyCreate
        }, {
          label: this.t('maps.layers.backups.menu-restore'),
          handler: this.showLayerBackup
        }]
      }]
      return result
    },

    menuItemsPresentation () {
      const result: MenuTreeItem[] = [{
        label: this.t('maps.layers.tree.menu.fly-to'),
        handler: this.flyTo,
        isHidden: !isLayerHasExtent(this.layer)
      }]
      return result
    },

    haveMultipleItemsLegend () {
      return this.totalCountOfAttributes > 1
    },

    haveRasterLegend () {
      return (
        (this.isRaster && this.layer.style && this.layer.style.sldStyle !== DEFAULT_RASTER_STYLE) ||
        (this.isHybridLayer && this.layer.style?.mboxStyle?.name === CUSTOM_SLD_STYLE)
      )
    },

    totalCountOfAttributes () {
      return Object.keys(this.layersAttributes).length - 1
    },
    isHybridLayer () {
      return isHybridLayer(this.layer)
    },
    isShowedStyle () {
      return [GeoMapLayerType.WFS, GeoMapLayerType.HYBRID].includes(this.layer.type) || this.isRaster
    },
    isExternalLegendSymbol () {
      return isExternalLayer(this.layer)
    },
    is3dLayer () {
      return is3dLayer(this.layer)
    },
    isFromLinkLayer () {
      return !!this.layer.url.length
    },
    alpha: {
      get () {
        const alphaValue = store.state.maps.layersOpacity[this.layer.id] ?? 1
        return alphaValue * 100
      },
      set (value: number) {
        store.commit(MapsMutationTypes.SET_LAYERS_ALPHA, { layerId: this.layer.id, layerAlpha: (value / 100) })
      }
    },
    isSelected: {
      get () {
        return store.state.maps.selectedLayers.includes(this.layer.id)
      },
      set (state: boolean) {
        const selectedIds = store.state.maps.selectedLayers
        if (state) {
          const newItems = [...selectedIds, this.layer.id]
          store.commit(MapsMutationTypes.SET_LAYERS_SELECTED, newItems)
        } else {
          const newItems = selectedIds.filter(itemId => itemId !== this.layer.id)
          store.commit(MapsMutationTypes.SET_LAYERS_SELECTED, newItems)
        }
      }
    },
    visibleLayers () {
      return store.state.maps.visibleLayers
    },
    visibleLayersIds () {
      return store.getters.getVisibleLayerIds
    },
    isAllowModifyFeature () {
      const activeToolName = this.mapProvider?.layerActiveTool.name
      const activeLayerId = this.mapProvider?.layerActiveTool.params?.geoLayer?.id
      return activeToolName && activeLayerId === this.layer.id && [
        LayerActiveToolType.FEATURE_CREATE,
        LayerActiveToolType.FEATURE_EDITOR
      ].includes(activeToolName)
    },
    visible: {
      get () {
        return this.visibleLayersIds.includes(this.layer.id)
      },
      set (newValue: boolean) {
        const oldValue = this.visibleLayersIds.includes(this.layer.id)
        if (newValue === oldValue) return
        if (newValue) {
          store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, [...this.visibleLayers, {
            layerId: this.layer.id,
            filters: []
          }])
        } else if (!this.isAllowModifyFeature) {
          const newLayers = this.visibleLayers.filter(item => item.layerId !== this.layer.id)
          store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, newLayers)
        }
      }
    },
    openedLegend: {
      get () {
        return store.state.maps.expandedLayers.includes(this.layer.id)
      },
      set (state: boolean) {
        if (state) {
          const newItems = [...store.state.maps.expandedLayers, this.layer.id]
          store.commit(MapsMutationTypes.SET_MAP_LAYER_EXPANDED, newItems)
        } else {
          const newItems = store.state.maps.expandedLayers.filter(layerId => {
            return layerId !== this.layer.id
          })
          store.commit(MapsMutationTypes.SET_MAP_LAYER_EXPANDED, newItems)
        }
      }
    },

    levelShift () {
      return (((this.level - 1) * 10) + 'px') as string
    },

    styleAttributeName () {
      let attribute: string | undefined
      if (this.layer.style?.mboxStyle && this.haveMultipleItemsLegend) {
        this.layer.style.mboxStyle.layers.every(layer => {
          switch (layer.type) {
            case 'line':
              attribute = parseExpressionAttributeName(layer.paint?.['line-color'])
              break
            case 'fill':
              attribute = parseExpressionAttributeName(layer.paint?.['fill-color'])
              break
            case 'circle':
              attribute = parseExpressionAttributeName(layer.paint?.['circle-color'])
              break
            case 'symbol':
              attribute = parseExpressionAttributeName(layer.layout?.['icon-image'])
              break
          }
          return !attribute
        })
      }
      return attribute
    },
    access: {
      get () {
        if (baykalskStore.state.baykalsk.listOfRead.includes(this.layer.id)) return LayerPerm.READ
        if (baykalskStore.state.baykalsk.listOfWrite.includes(this.layer.id)) return LayerPerm.WRITE
        if (baykalskStore.state.baykalsk.listOfDeny.includes(this.layer.id)) return LayerPerm.DENY
        else return false
      },
      set (value: LayerPerm) {
        switch (value) {
          case LayerPerm.READ:
            if (!baykalskStore.state.baykalsk.listOfRead.includes(this.layer.id)) {
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_WRITE, baykalskStore.state.baykalsk.listOfWrite.filter(x => x !== this.layer.id))
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_DENY, baykalskStore.state.baykalsk.listOfDeny.filter(x => x !== this.layer.id))
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_READ, [...baykalskStore.state.baykalsk.listOfRead, this.layer.id])
            }
            break
          case LayerPerm.WRITE:
            if (!baykalskStore.state.baykalsk.listOfWrite.includes(this.layer.id)) {
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_READ, baykalskStore.state.baykalsk.listOfRead.filter(x => x !== this.layer.id))
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_DENY, baykalskStore.state.baykalsk.listOfDeny.filter(x => x !== this.layer.id))
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_WRITE, [...baykalskStore.state.baykalsk.listOfWrite, this.layer.id])
            }
            break
          case LayerPerm.DENY:
            if (!baykalskStore.state.baykalsk.listOfDeny.includes(this.layer.id)) {
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_READ, baykalskStore.state.baykalsk.listOfRead.filter(x => x !== this.layer.id))
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_WRITE, baykalskStore.state.baykalsk.listOfWrite.filter(x => x !== this.layer.id))
              baykalskStore.commit(AppMutationTypes.SET_LIST_OF_DENY, [...baykalskStore.state.baykalsk.listOfDeny, this.layer.id])
            }
            break
        }
      }
    }
  },

  watch: {
    'layer.style.mboxStyle' (oldStyle: MapBoxStyle, newStyle: MapBoxStyle) {
      if (!this.isBlockedRefresh) {
        this.layerType = getLayerGeometryType(this.layer)
        if (oldStyle?.modified === newStyle?.modified) {
          this.initLayersAttributes()
        } else {
          this.initLayersAttributes(true)
        }
      }
    },

    'windowRect.width' () {
      this.refreshOpacityPosition()
    },

    isEnabledMultipleSelect (state: boolean) {
      if (state) {
        if (this.opacityScrollOpened) {
          this.opacityScrollOpened = false
        }
      }
    },

    opacityScrollOpened (newState) {
      this.$emit('switchedOpacity', newState)
      this.refreshOpacityPosition()
    },

    visible () {
      if (!this.isBlockedRefresh) {
        let ignoreFilters = false
        if (store.state.maps.map?.groups) {
          const visibleLayers = this.visibleLayers
          const allLayers = store.getters.getLayersList
          ignoreFilters = visibleLayers.length === allLayers.length
        }
        this.initLayersAttributes(true, ignoreFilters)
      }
    }
  },

  mounted () {
    this.layerType = getLayerGeometryType(this.layer)
    this.isBlockedRefresh = true
    this.initLayersAttributes(true)
    this.$nextTick(() => {
      this.isBlockedRefresh = false
    })
    window.addEventListener('wheel', this.refreshOpacityPosition)
  },

  beforeUnmount () {
    window.removeEventListener('wheel', this.refreshOpacityPosition)
  },

  methods: {
    checkAccess (permission: GeoMapAccessAction) {
      return auth.access.checkAccess(this.layer.perm, permission)
    },

    openInfoDialog () {
      this.$emit('openInfoWindow', this.layer)
    },

    refreshOpacityPosition () {
      if (!this.opacityScrollOpened || !this.$refs.container) {
        return
      }
      const rect = (this.$refs.container as HTMLElement).getBoundingClientRect()
      if (rect) {
        const offsetCompensation = 231
        const isHidden = (
          rect.bottom < this.windowRect.top + 54 ||
          rect.top > this.windowRect.bottom
        )
        this.opacityPosition.opacity = isHidden ? 0 : 1
        this.opacityPosition.left = `${rect.right - offsetCompensation}px`
        this.opacityPosition.top = `${rect.y}px`
      }
    },

    refreshAttributesForShow () {
      this.visibleLayersAttributes = Object.entries(this.layersAttributes).filter(item => {
        return item[0] !== COMMON_PROPERTY
      }).slice(0, this.loadedPosition).map(item => {
        return Object.assign(item[1], { attribute: item[0] })
      })
      sortLegend(this.visibleLayersAttributes)
    },

    loadMoreAttributes () {
      if (!this.haveMultipleItemsLegend || this.isBlockedLazyLoad) {
        return
      }
      this.isBlockedLazyLoad = true
      setTimeout(() => {
        if (this.loadedPosition >= this.totalCountOfAttributes) {
          this.isBlockedLazyLoad = false
          return
        }
        const newloadedPosition = this.loadedPosition + ATTRIBUTE_LOADING_SIZE
        const newItems = Object.entries(this.layersAttributes).filter(item => {
          return item[0] !== COMMON_PROPERTY
        }).slice(this.loadedPosition, newloadedPosition).map(item => {
          return Object.assign(item[1], { attribute: item[0] })
        })
        if (
          this.loadedPosition === 0 ||
          ATTRIBUTE_LOADING_SIZE > this.totalCountOfAttributes ||
          newloadedPosition < this.totalCountOfAttributes
        ) {
          this.loadedPosition = newloadedPosition
        }
        this.visibleLayersAttributes = [...this.visibleLayersAttributes, ...newItems]
        sortLegend(this.visibleLayersAttributes)
        this.isBlockedLazyLoad = false
      })
    },

    showStylesEditor () {
      if (this.mapProvider) {
        const toolName = this.isRaster ? LayerActiveToolType.SLD_EDITOR : LayerActiveToolType.STYLE
        this.mapProvider.setLayerActiveTool({
          name: toolName,
          params: {
            geoLayer: this.layer
          }
        })
      }
    },

    showStyleCopy () {
      this.isEnabledMultipleSelectStyleCopy = true
    },

    showAttributeTable () {
      if (this.mapProvider) {
        this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.ATTRIBUTE_TABLE, params: { geoLayer: this.layer } })
      }
    },

    showChangeLog () {
      if (this.mapProvider) {
        this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.CHANGE_LOG, params: { geoLayer: this.layer } })
      }
    },

    showLayerBackup () {
      if (this.mapProvider) {
        this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.LAYER_BACKUP, params: { geoLayer: this.layer } })
      }
    },

    showConvertTo3d () {
      if (this.mapProvider) {
        this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.LAYER_CONVERT_TO_3D, params: { geoLayer: this.layer } })
      }
    },

    showSettings3d () {
      if (this.mapProvider) {
        this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.LAYER_TRANSFORM_3D, params: { geoLayer: this.layer } })
      }
    },

    getExternalMode () {
      switch (this.layer.type) {
        case GeoMapLayerType.WFS:
          return LayerExternalMode.WFS
        case GeoMapLayerType.WMS:
          return LayerExternalMode.WMS
        case GeoMapLayerType.TMS:
          return LayerExternalMode.TMS
      }
    },

    showSettings () {
      if (this.mapProvider) {
        if (this.isExternalLegendSymbol) {
          this.mapProvider.setLayerActiveTool({
            name: LayerActiveToolType.LAYER_EXTERNAL_ADD,
            params: {
              geoLayer: this.layer,
              externalMode: this.getExternalMode()
            }
          })
        } else {
          this.mapProvider.setLayerActiveTool({
            name: LayerActiveToolType.LAYER_EDIT,
            params: {
              geoLayer: this.layer
            }
          })
        }
      }
    },

    parseAttrValue (attributeName: string, value: number | string | boolean) {
      if (this.layer.columns) {
        const result = this.layer.columns.filter(column => column.name === attributeName)
        if (result.length) {
          const dbType = result[0].dbType
          if (NUMBER_DB_TYPES.includes(dbType)) {
            value = Number(value)
            return !Number.isNaN(value) ? value : undefined
          }
        }
      }
      return String(value)
    },

    async saveLayerStyleFilter () {
      if (this.layer.style?.mboxStyle) {
        try {
          const filterQuery = this.prepareFilterQuery()
          this.isBlockedRefresh = true
          const preparedLayers = this.visibleLayers.slice()
          const findedItem = preparedLayers.find(item => item.layerId === this.layer.id)
          let totalEnabled = false
          if (filterQuery && this.totalCountOfAttributes === filterQuery.length) {
            totalEnabled = true
          }
          if (findedItem) {
            if (filterQuery?.length && !totalEnabled) {
              findedItem.filters = filterQuery
            } else {
              findedItem.filters = []
            }
          }
          store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, preparedLayers)
          if (this.checkAccess(auth.access.actions.LAYER_EDIT) || !this.visible) {
            if (this.mapProvider) {
              this.mapProvider.lazyUpdateSession()
            }
          }
          if (this.visible && !filterQuery && !totalEnabled && this.haveMultipleItemsLegend) {
            this.updateVisibility(false)
          }
          this.$nextTick(() => {
            this.isBlockedRefresh = false
          })
        } catch (error) {
          if (error instanceof Error && error.message.includes('413')) {
            toast.error(this.t('base.large-error'))
          } else {
            toast.error(this.t('maps.layers.styles.errors.apply'))
          }
        }
      }
    },

    updateVisibility (newState: boolean) {
      this.isBlockedRefresh = true
      this.visible = newState
      this.$nextTick(() => {
        this.isBlockedRefresh = false
      })
    },

    recalcVisibleAttributes (info: AttributeInfo) {
      info.visible = !info.visible
      if (!this.visible && info.visible) {
        this.updateVisibility(true)
      }
      if (this.styleAttributeName) {
        const originInfo = this.layersAttributes[info.attribute as string]
        if (originInfo) {
          originInfo.visible = info.visible
        }
        this.$nextTick(() => {
          this.saveLayerStyleFilter()
        })
      }
    },

    showOpacityRange () {
      this.opacityScrollOpened = true
    },

    isOpenedConfirmDeleteDialog () {
      this.isOpenedConfirmDelete = true
    },

    prepareFilterQuery () {
      const filterQuery: LayerFilterItem[] = []
      Object.entries(this.layersAttributes).forEach(args => {
        if (args[1].visible && this.styleAttributeName) {
          const value = this.parseAttrValue(this.styleAttributeName, args[0])
          if (value !== null && value !== undefined && value !== COMMON_PROPERTY) {
            const filterItem: LayerFilterItem = {
              attribute: this.styleAttributeName
            }
            if (args[1].isRanged) {
              // TODO: Реализовать после добавления диапозонов в редактор стилей
              // filterItem.minValue = value
              // filterItem.maxValue = value
            } else {
              filterItem.value = value
            }
            filterQuery.push(filterItem)
          }
        }
      })
      return filterQuery.length ? filterQuery : undefined
    },

    toggleLayerVisibility () {
      appToast.clear()
      /* check maxVisibleLayers first */
      if (!this.visible) {
        const limit = baykalskStore.state.baykalsk.maxVisibleLayers
        const visibleCount = store.state.maps.visibleLayers.length
        if (visibleCount >= limit) {
          toast.clear()
          toast.error(this.t('maps.layers.tree.layers-limit-error'))
          return
        }
      } else toast.clear()
      if (!this.visible && this.is3dLayer && (!baykalskStore.state.baykalsk.is3dEnabled || !this.is3dPresentationMode)) {
        if (!baykalskStore.state.baykalsk.is3dEnabled && !this.isPresentation) {
          appToast.warning(ToastTo3D)
          return
        } else if (this.isPresentation && !this.is3dPresentationMode) {
          appToast.warning('Недоступно в презентации 2Д проекта')
          return
        }
      }
      const newState = !this.visible
      this.updateVisibility(newState)
      Object.values(this.layersAttributes).forEach(item => {
        item.visible = newState
      })
      this.$nextTick(() => {
        this.refreshAttributesForShow()
        this.saveLayerStyleFilter()
      })
    },

    getVisibleState (attributeValue: string, needAttributesParse: boolean, filter?: LayerFilterItem[]) {
      attributeValue = String(attributeValue)
      if (!filter) return true
      if (needAttributesParse) {
        return filter.some(queryObject => String(queryObject.value) === attributeValue)
      }
      return false
    },

    layerCopyCreate () {
      baykalskStore.dispatch(AppActionTypes.LAYER_BACKUP_CREATE, this.layer.id)
        .then(() => {
          toast.success(this.t('maps.layers.backups.toast.create-success'))
        })
        .catch((error) => {
          if (error.detail && error.detail?.msg) toast.error(error.detail.msg)
          else toast.error(this.t('maps.layers.backups.toast.create-fail'))
        })
    },

    parseFilters (ignoreFilters = false) {
      if (!ignoreFilters) {
        const result = this.visibleLayers.find(item => item.layerId === this.layer.id)
        if (result) {
          return result.filters.length ? result.filters : undefined
        }
      }
      return undefined
    },

    initLayersAttributes (isReset = false, ignoreFilters = false) {
      if (!this.layerType) return
      this.layersAttributes = {
        [COMMON_PROPERTY]: {
          style: {},
          visible: this.visible
        }
      }
      const style = this.layer?.style?.mboxStyle
      if (style) {
        this.styleOptions = getGeometrySymbolOptions(style)
        const filterQueries = this.parseFilters(ignoreFilters)
        if (Array.isArray(this.styleOptions.fill)) {
          applyComplexStyle(
            ComplexStyleType.FILL, false, this.layerType, this.styleOptions,
            this.layersAttributes, filterQueries, this.visible
          )
        } else if (Array.isArray(this.styleOptions.stroke)) {
          applyComplexStyle(
            ComplexStyleType.STROKE, false, this.layerType, this.styleOptions,
            this.layersAttributes, filterQueries, this.visible
          )
        } else if (Array.isArray(this.styleOptions.icon)) {
          applyComplexStyle(
            ComplexStyleType.ICON, false, this.layerType, this.styleOptions,
            this.layersAttributes, filterQueries, this.visible
          )
        }
      }
      if (isReset) {
        this.resetAttributes()
      } else if (this.visibleLayersAttributes.length === 0) {
        this.loadMoreAttributes()
      }
      if (ignoreFilters) {
        this.$nextTick(() => {
          Object.values(this.layersAttributes).forEach(item => {
            item.visible = this.visible
          })
        })
      }
    },

    resetAttributes () {
      setTimeout(() => {
        this.visibleLayersAttributes = []
        this.loadedPosition = 0
        this.loadMoreAttributes()
      })
    },

    toggleRename () {
      setTimeout(() => {
        this.renamedLayerName = this.layer.name
        this.isRenameActive = true
      }, 100)
    },

    createLayerObject () {
      this.visible = true
      if (this.mapProvider) {
        this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.FEATURE_CREATE, params: { geoLayer: this.layer } })
      }
    },
    editLayerHeader () {
      if (this.renamedLayerName.trim().length && this.renamedLayerName !== this.layer.name) {
        if (this.isSubmitEdit) return
        this.isSubmitEdit = true
        store.dispatch(MapsActionTypes.UPDATE_LAYER_INFO, {
          itemId: this.layer.id,
          newName: this.renamedLayerName
        })
          .then(() => {
            this.hideRenameLayerName()
          })
          .finally(() => (this.isSubmitEdit = false))
      } else {
        this.hideRenameLayerName()
      }
    },
    hideRenameLayerName () {
      this.isRenameActive = false
    },
    editLayerObject () {
      this.visible = true
      if (this.mapProvider) {
        this.mapProvider.setLayerActiveTool({ name: LayerActiveToolType.FEATURE_EDITOR, params: { geoLayer: this.layer } })
      }
    },
    flyTo () {
      appToast.clear()
      if (this.is3dLayer && (!baykalskStore.state.baykalsk.is3dEnabled || !this.is3dPresentationMode)) {
        if (!baykalskStore.state.baykalsk.is3dEnabled && !this.isPresentation) {
          appToast.warning(ToastTo3D)
          return
        } else if (this.isPresentation && !this.is3dPresentationMode) {
          appToast.warning('Недоступно в презентации 2Д проекта')
          return
        }
      }
      if (!this.visible) {
        this.visible = true
        this.$nextTick().then(() => {
          this.$emit('flyTo', this.layer.id)
        })
      } else {
        this.$emit('flyTo', this.layer.id)
      }
    },

    async exportLayer () {
      this.$emit('openExportWindow', this.layer)
    },

    async doDeleteLayer () {
      try {
        if (this.isLoading) return
        this.isLoading = true
        await store.dispatch(MapsActionTypes.DELETE_LAYER, this.layer.id)
        toast.success(this.t('maps.layers.tree.menu.successLayerDeleted'))
      } catch {
        toast.error(this.t('maps.layers.tree.menu.removeLayerError'))
      } finally {
        this.isOpenedConfirmDelete = false
        this.isLoading = false
      }
    }
  }
})
