import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["password-input", {'password-input--error': _ctx.isError}])
  }, [
    _createVNode(_component_base_input, {
      modelValue: _ctx.innerPassword,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerPassword) = $event)),
      placeholder: _ctx.placeholder,
      "inner-placeholder": _ctx.innerPlaceholder,
      type: _ctx.isVisible ? 'text' : 'password',
      disabled: _ctx.isDisabled
    }, null, 8, ["modelValue", "placeholder", "inner-placeholder", "type", "disabled"]),
    _createVNode(_component_base_icon, { class: "password-input__eye" }, {
      default: _withCtx(() => [
        _createVNode(_component_inline_svg, {
          src: require(`@/assets/svg/is_visible_${_ctx.isVisible}.svg`),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isVisible = !_ctx.isVisible))
        }, null, 8, ["src"])
      ]),
      _: 1
    })
  ], 2))
}