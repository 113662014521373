
import { defineComponent, inject, computed } from 'vue'
import {
  ImageryLayer,
  UrlTemplateImageryProvider
} from 'cesium'

import { CViewerKey } from './../symbol'

export default defineComponent({
  name: 'CBaseMapProvider',

  props: {
    url: { type: String, default: 'https://a.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png' }
  },

  setup () {
    const viewer = inject(CViewerKey)
    return {
      viewer: computed(() => viewer?.value)
    }
  },

  data: () => ({
    layer: null as ImageryLayer | null
  }),

  watch: {
    viewer () {
      this.reloadLayer()
    }
  },

  beforeUnmount () {
    if (this.viewer && this.layer && !this.viewer.isDestroyed()) {
      this.viewer.scene.globe.imageryLayers.remove(this.layer as ImageryLayer)
    }
  },

  mounted () {
    this.reloadLayer()
  },

  methods: {
    reloadLayer () {
      if (this.viewer) {
        this.layer = this.viewer.scene.globe.imageryLayers.addImageryProvider(
          new UrlTemplateImageryProvider({
            url: this.url
          }),
          1
        )
      }
    }
  }
})
