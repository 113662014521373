import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_base_icon, {
    class: "control-full-extent",
    src: _ctx.imgExtent,
    size: _ctx.SizeType.LG,
    type: _ctx.IconType.SECONDARY,
    onClick: _ctx.handleClick
  }, null, 8, ["src", "size", "type", "onClick"])), [
    [_directive_tooltip, _ctx.$t('maps.extent.actions.fullScale')]
  ])
}