import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-656f26b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = {
  key: 0,
  class: "modal-hr"
}
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = {
  key: 1,
  class: "modal-hr"
}
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_base_header = _resolveComponent("base-header")!
  const _component_base_scrollbar = _resolveComponent("base-scrollbar")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "modal-mask",
        onMousedown: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.disableCloseOnClickOutside && _ctx.$emit('close')))
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_base_loading, {
                key: 0,
                overlay: true
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "modal-container",
            onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_base_header, {
                level: "1",
                "with-ellipsis": false,
                title: _ctx.title
              }, null, 8, ["title"]),
              _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ]),
            _renderSlot(_ctx.$slots, "subheader", {}, undefined, true),
            (_ctx.withTopSeparator)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_base_scrollbar, {
                class: "modal-body-ps",
                style: _normalizeStyle(_ctx.bodyCustomStyles),
                options: { wheelSpeed: 0.5 }
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["style", "options"])
            ]),
            (_ctx.withBottomSeparator)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                _createVNode(_component_base_button, {
                  class: "modal-default-button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" OK ")
                  ]),
                  _: 1
                })
              ], true)
            ])
          ], 32)
        ])
      ], 32)
    ]),
    _: 3
  }))
}