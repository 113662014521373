import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e74c878"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "required"
}
const _hoisted_2 = ["placeholder", "disabled", "type"]
const _hoisted_3 = { class: "base-input__footer" }
const _hoisted_4 = {
  key: 0,
  class: "base-input__footer-error"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input", _ctx.calculatedClass])
  }, [
    (_ctx.placeholder)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["base-input__placeholder", { 'has-value': _ctx.hasValue, 'with-action-left': _ctx.search }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
        }, [
          _createTextVNode(_toDisplayString(_ctx.placeholder) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      ref: "elInput",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerValue) = $event)),
      placeholder: _ctx.nativePlaceholder,
      disabled: _ctx.disabled || _ctx.disabledWithText,
      style: _normalizeStyle(_ctx.customStyles),
      class: _normalizeClass(_ctx.inputClass),
      type: _ctx.inputType,
      onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnterKey && _ctx.onEnterKey(...args)), ["enter"])),
      onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }, null, 46, _hoisted_2), [
      [_vModelDynamic, _ctx.innerValue]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.innerErrorMessage)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.innerErrorMessage), 1)
          ])), [
            [_directive_tooltip, _ctx.innerErrorMessage]
          ])
        : (_openBlock(), _createElementBlock("div", _hoisted_5)),
      ((_ctx.showWidthCounter && _ctx.maxWidth) || (_ctx.showWidthCounterOnFocus && _ctx.maxWidth && _ctx.isFocus))
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["base-input__footer-counter", {'has-error': String(_ctx.modelValue).length > _ctx.maxWidth}])
          }, _toDisplayString(`${String(_ctx.innerValue).length} / ${_ctx.maxWidth}`), 3))
        : _createCommentVNode("", true)
    ]),
    (_ctx.search)
      ? _withDirectives((_openBlock(), _createBlock(_component_base_icon, {
          key: 1,
          class: "base-input__left-icon",
          type: _ctx.IconType.CLEAR,
          src: _ctx.imgSearch,
          size: _ctx.SizeType.SM,
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('searchClick', _ctx.innerValue)))
        }, null, 8, ["type", "src", "size"])), [
          [_directive_tooltip, _ctx.searchIconTip]
        ])
      : _createCommentVNode("", true),
    (_ctx.clearable)
      ? _withDirectives((_openBlock(), _createBlock(_component_base_icon, {
          key: 2,
          class: "base-input__right-icon",
          type: _ctx.IconType.CLEAR,
          src: _ctx.imgClose,
          size: _ctx.SizeType.SM,
          onClick: _ctx.clearInputField
        }, null, 8, ["type", "src", "size", "onClick"])), [
          [_directive_tooltip, _ctx.t('base.input.clearIconTip')]
        ])
      : (_ctx.hasLock)
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 3,
            class: "base-input__right-icon",
            type: _ctx.IconType.STATIC,
            src: _ctx.imgLock
          }, null, 8, ["type", "src"]))
        : _createCommentVNode("", true)
  ], 2))
}