import { ActionTree, ActionContext } from 'vuex'

import { RootState } from '@/store'

import { State } from './state'
import { Mutations } from './mutations'
import { AppMutationTypes } from './mutation-types'
import { AppActionTypes } from './action-types'
import {
  UsersPayload,
  UsersResult,
  GroupsPayload,
  GroupsResult,
  PutUserGroupsPayload,
  UsersInGroupPayload,
  PutGroupUsersPayload,
  ServicesResult,
  GroupCreatePayload,
  UserGroupModel,
  LayersResult,
  GroupEditPayload,
  CityProjectsResult,
  CityProject,
  CityProjectCommentsResult,
  CreateCommentPayload,
  CreateProjectPayload,
  UpdateProjectPayload,
  UpdatePublishPayload,
  CityProjectsPayload,
  CityProjectPresentation,
  mapLayersLimitPayload,
  DraftLayerPayload,
  CPSAttachAnswer,
  WeatherResponce,
  WeatherDates,
  WeatherGraphResponce,
  AirMonitoringItem,
  AirGraph,
  EstateSearchModel,
  EstatePanelItemsResponce,
  CreateEstateAdvertModel,
  EstateAttach,
  EstateItem,
  AddressDetectData,
  ModerationEstateGetPayload,
  ModerationEstateResult,
  ModerationEgrnPayload,
  ModerationEgrnListResult,
  ModerationEgrnDownloadResponce,
  ModerationEgrnItem,
  RESStatusPayload,
  VacanciesSearchModel,
  CityVacanciesResponce,
  CityVacancyItemModel,
  CreateVacancyModel,
  ModerationVacanciesGetPayload,
  ModerationVacanciesResult,
  CitySuggestionsSearchModel,
  CitySuggestionsResponce,
  SuggestionItem,
  CreateSuggestionModel,
  CSSStatusPayload,
  ModerationSuggestionsGetPayload,
  ModerationSuggestionsResult,
  VideoStreamsSearchModel,
  VideoStreamsResponse,
  VideoStreamsPutModel,
  LayerBackupRestorePayload,
  LayerBackupListResult,
  Stream,
  VideoRecordDownloadPayload,
  VideoRecordDownloadResponce,
  VideoStreamsPatchModel,
  StreamGroup,
  VideoStreamsPostModel,
  FindAdressPayload,
  VideoStreamsDownloadModel,
  VideoStreamsDates,
  CommonPreenabledLayers,
  AdminCamerasAccess,
  LkActivitySuggestionsResult,
  LkActivityEstateResult,
  LkActivityVacancyResult
} from '@/types/baykalsk/types'
import { GeoMap } from '@/library/types'

import api from '@/api'
import cpsApi from '@/api/cps'
import cvsApi from '@/api/cvs'
import cssApi from '@/api/css'
import vssApi from '@/api/vss'
import weatherApi from '@/api/weather'
import resApi from '@/api/res'
import qs from 'qs'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AppActionTypes.ADMIN_GET_USERS]({ commit, state }: AugmentedActionContext, payload: UsersPayload): Promise<UsersResult>,
  [AppActionTypes.ADMIN_GET_GROUPS]({ commit, state }: AugmentedActionContext, payload: GroupsPayload): Promise<GroupsResult>,
  [AppActionTypes.ADMIN_PUT_USER_GROUPS]({ commit, state }: AugmentedActionContext, payload: PutUserGroupsPayload): Promise<void>,
  [AppActionTypes.ADMIN_GET_USERS_OF_GROUP]({ commit, state }: AugmentedActionContext, payload: UsersInGroupPayload): Promise<UsersResult>,
  [AppActionTypes.ADMIN_PUT_GROUP_USERS]({ commit, state }: AugmentedActionContext, payload: PutGroupUsersPayload): Promise<void>,
  [AppActionTypes.ADMIN_GET_MAP]({ commit, state }: AugmentedActionContext, payload: number): Promise<GeoMap>,
  [AppActionTypes.ADMIN_GET_CAMERAS_ACCESS]({ commit, state }: AugmentedActionContext, payload: number): Promise<AdminCamerasAccess>,
  [AppActionTypes.ADMIN_GET_LAYERS_OF_GROUP]({ commit, state }: AugmentedActionContext, payload: number): Promise<LayersResult>,
  [AppActionTypes.ADMIN_GET_SERVICES]({ commit, state }: AugmentedActionContext, payload: null): Promise<ServicesResult>,
  [AppActionTypes.ADMIN_GET_SERVICES_OF_GROUP]({ commit, state }: AugmentedActionContext, payload: number): Promise<ServicesResult>,
  [AppActionTypes.ADMIN_SAVE_GROUP]({ commit, state }: AugmentedActionContext, payload: GroupCreatePayload): Promise<UserGroupModel>,
  [AppActionTypes.ADMIN_EDIT_GROUP]({ commit, state }: AugmentedActionContext, payload: GroupEditPayload): Promise<UserGroupModel>,
  [AppActionTypes.ADMIN_DELETE_GROUP]({ commit, state }: AugmentedActionContext, payload: number): Promise<string>,
  [AppActionTypes.ADMIN_GET_DRAFT_LAYERS]({ commit, state }: AugmentedActionContext, payload: number): Promise<UsersResult>,
  [AppActionTypes.ADMIN_SAVE_DRAFT_LAYER]({ commit, state }: AugmentedActionContext, payload: DraftLayerPayload): Promise<UsersResult>,
  [AppActionTypes.ADMIN_SET_MAP_LAYERS_LIMIT]({ commit, state }: AugmentedActionContext, payload: mapLayersLimitPayload): Promise<void>,

  [AppActionTypes.CPS_GET_CITY_PROJECTS]({ commit, state }: AugmentedActionContext, payload: CityProjectsPayload): Promise<CityProjectsResult>,
  [AppActionTypes.CPS_GET_ONE_CITY_PROJECT]({ commit, state }: AugmentedActionContext, payload: number): Promise<CityProject>,
  [AppActionTypes.CPS_GET_COMMENTS]({ commit, state }: AugmentedActionContext, payload: number): Promise<CityProjectCommentsResult>,
  [AppActionTypes.CPS_POST_COMMENT]({ commit, state }: AugmentedActionContext, payload: CreateCommentPayload): Promise<void>,
  [AppActionTypes.CPS_DELETE_COMMENT]({ commit, state }: AugmentedActionContext, payload: number): Promise<string>
  [AppActionTypes.CPS_CREATE_PROJECT]({ commit, state }: AugmentedActionContext, payload: CreateProjectPayload): Promise<CityProject>,
  [AppActionTypes.CPS_UPDATE_PROJECT]({ commit, state }: AugmentedActionContext, payload: UpdateProjectPayload): Promise<CityProject>,
  [AppActionTypes.CPS_UPDATE_PUBLISH]({ commit, state }: AugmentedActionContext, payload: UpdatePublishPayload): Promise<CityProject>,
  [AppActionTypes.CPS_DELETE_PROJECT]({ commit, state }: AugmentedActionContext, payload: number): Promise<string>
  [AppActionTypes.CPS_GET_ONE_CITY_PROJECT_PRESENTATION]({ commit, state }: AugmentedActionContext, payload: number): Promise<CityProjectPresentation>,
  [AppActionTypes.CPS_PUT_PRESENTATION]({ commit, state }: AugmentedActionContext, payload: CityProjectPresentation): Promise<CityProjectPresentation>,
  [AppActionTypes.CPS_POST_ATTACHMENT]({ commit, state }: AugmentedActionContext, payload: FormData): Promise<CPSAttachAnswer>,
  [AppActionTypes.CPS_DELETE_ATTACHMENT]({ commit, state }: AugmentedActionContext, payload: number): Promise<string>,

  [AppActionTypes.METEO_GET_WEATHER]({ commit, state }: AugmentedActionContext, payload: null): Promise<WeatherResponce>,
  [AppActionTypes.METEO_GET_DATES]({ commit, state }: AugmentedActionContext, payload: null): Promise<WeatherDates>,
  [AppActionTypes.METEO_GET_HISTORY_WEATHER]({ commit, state }: AugmentedActionContext, payload: WeatherDates): Promise<WeatherGraphResponce>,
  [AppActionTypes.AIR_GET_MONITORING]({ commit, state }: AugmentedActionContext, payload: null): Promise<AirMonitoringItem>,
  [AppActionTypes.AIR_GET_DATES]({ commit, state }: AugmentedActionContext, payload: null): Promise<WeatherDates>,
  [AppActionTypes.AIR_GET_HISTORY_MONITORING]({ commit, state }: AugmentedActionContext, payload: WeatherDates): Promise<AirGraph>,

  [AppActionTypes.RES_GET_ESTATE_LIST]({ commit, state }: AugmentedActionContext, payload: EstateSearchModel): Promise<EstatePanelItemsResponce>,
  [AppActionTypes.RES_GET_ESTATE_ITEM]({ commit, state }: AugmentedActionContext, payload: string): Promise<EstateItem>,
  [AppActionTypes.RES_POST_ATTACHMENT]({ commit, state }: AugmentedActionContext, payload: FormData): Promise<EstateAttach>,
  [AppActionTypes.SERVICE_FIND_ADDRESS]({ commit, state }: AugmentedActionContext, payload: FindAdressPayload): Promise<AddressDetectData>,
  [AppActionTypes.RES_CREATE_ADVERT]({ commit, state }: AugmentedActionContext, payload: CreateEstateAdvertModel): Promise<EstateItem>,

  [AppActionTypes.COMMON_GET_PRE_LAYERS]({ commit, state }: AugmentedActionContext, payload: null): Promise<CommonPreenabledLayers>,

  [AppActionTypes.MODERATION_ESTATE_GET_LIST]({ commit, state }: AugmentedActionContext, payload: ModerationEstateGetPayload): Promise<ModerationEstateResult>,
  [AppActionTypes.MODERATION_ESTATE_DELETE]({ commit, state }: AugmentedActionContext, payload: string): Promise<string>,
  [AppActionTypes.MODERATION_ESTATE_UPDATE_STATUS]({ commit, state }: AugmentedActionContext, payload: RESStatusPayload): Promise<string>,

  [AppActionTypes.EGRN_GET_LIST]({ commit, state }: AugmentedActionContext, payload: ModerationEgrnPayload): Promise<ModerationEgrnListResult>,
  [AppActionTypes.EGRN_POST_XML]({ commit, state }: AugmentedActionContext, payload: FormData): Promise<ModerationEgrnItem>,
  [AppActionTypes.EGRN_CANCEL_XML]({ commit, state }: AugmentedActionContext, payload: string): Promise<ModerationEgrnItem>,
  [AppActionTypes.EGRN_GET_XML]({ commit, state }: AugmentedActionContext, payload: string): Promise<ModerationEgrnDownloadResponce>,
  [AppActionTypes.EGRN_GET_SMAV]({ commit, state }: AugmentedActionContext, payload: null): Promise<string>,

  [AppActionTypes.CVS_GET_VACANCIES_LIST]({ commit, state }: AugmentedActionContext, payload: VacanciesSearchModel): Promise<CityVacanciesResponce>,
  [AppActionTypes.CVS_GET_VACANCY_ITEM]({ commit, state }: AugmentedActionContext, payload: string): Promise<CityVacancyItemModel>,
  [AppActionTypes.CVS_CREATE_VACANCY]({ commit, state }: AugmentedActionContext, payload: CreateVacancyModel): Promise<CityVacancyItemModel>,

  [AppActionTypes.VSS_GET_VIDEO_LIST]({ commit, state }: AugmentedActionContext, payload: VideoStreamsSearchModel): Promise<VideoStreamsResponse>,
  [AppActionTypes.VSS_PUT_VIDEO_LIST]({ commit, state }: AugmentedActionContext, payload: VideoStreamsPutModel): Promise<void>,
  [AppActionTypes.VSS_POST_VIDEO]({ commit, state }: AugmentedActionContext, payload: Stream): Promise<Stream>,
  [AppActionTypes.VSS_PUT_VIDEO]({ commit, state }: AugmentedActionContext, payload: Stream): Promise<Stream>,
  [AppActionTypes.VSS_DELETE_VIDEO]({ commit, state }: AugmentedActionContext, uuid: string): Promise<string>,
  [AppActionTypes.VSS_DELETE_GROUP]({ commit, state }: AugmentedActionContext, uuid: string): Promise<string>,
  [AppActionTypes.VSS_PATCH_VIDEO]({ commit, state }: AugmentedActionContext, payload: VideoStreamsPatchModel): Promise<string>,
  [AppActionTypes.VSS_PATCH_GROUP]({ commit, state }: AugmentedActionContext, payload: VideoStreamsPatchModel): Promise<string>,
  [AppActionTypes.VSS_POST_GROUP]({ commit, state }: AugmentedActionContext, payload: VideoStreamsPostModel): Promise<StreamGroup>,
  [AppActionTypes.VSS_GET_VIDEO]({ commit, state }: AugmentedActionContext, uuid: string): Promise<Stream>,
  [AppActionTypes.VSS_DOWNLOAD_RECORD]({ commit, state }: AugmentedActionContext, payload: VideoRecordDownloadPayload): Promise<VideoRecordDownloadResponce>,
  [AppActionTypes.VSS_GET_RECORDS]({ commit, state }: AugmentedActionContext, payload: VideoStreamsDownloadModel): Promise<BlobPart>,
  [AppActionTypes.VSS_GET_AVAILABLE_DATES]({ commit, state }: AugmentedActionContext, uuid: string): Promise<VideoStreamsDates>,

  [AppActionTypes.MODERATION_VACANCIES_GET_LIST]({ commit, state }: AugmentedActionContext, payload: ModerationVacanciesGetPayload): Promise<ModerationVacanciesResult>,
  [AppActionTypes.MODERATION_VACANCY_DELETE]({ commit, state }: AugmentedActionContext, payload: string): Promise<string>,
  [AppActionTypes.MODERATION_VACANCY_UPDATE_STATUS]({ commit, state }: AugmentedActionContext, payload: RESStatusPayload): Promise<string>,

  [AppActionTypes.CSS_GET_SUGGESTIONS_LIST]({ commit, state }: AugmentedActionContext, payload: CitySuggestionsSearchModel): Promise<CitySuggestionsResponce>,
  [AppActionTypes.CSS_GET_SUGGESTION_ITEM]({ commit, state }: AugmentedActionContext, payload: string): Promise<SuggestionItem>,
  [AppActionTypes.CSS_POST_ATTACHMENT]({ commit, state }: AugmentedActionContext, payload: FormData): Promise<EstateAttach>,
  [AppActionTypes.CSS_CREATE_SUGGESTION]({ commit, state }: AugmentedActionContext, payload: CreateSuggestionModel): Promise<SuggestionItem>,

  [AppActionTypes.MODERATION_SUGGESTIONS_GET_LIST]({ commit, state }: AugmentedActionContext, payload: ModerationSuggestionsGetPayload): Promise<ModerationSuggestionsResult>,
  [AppActionTypes.MODERATION_SUGGESTION_DELETE]({ commit, state }: AugmentedActionContext, payload: string): Promise<string>,
  [AppActionTypes.MODERATION_SUGGESTION_UPDATE_STATUS]({ commit, state }: AugmentedActionContext, payload: CSSStatusPayload): Promise<string>,

  [AppActionTypes.LAYER_BACKUP_CREATE] ({ commit, state }: AugmentedActionContext, payload: number): Promise<void>,
  [AppActionTypes.LAYER_BACKUP_GET_LIST] ({ commit, state }: AugmentedActionContext, payload: number): Promise<LayerBackupListResult>,
  [AppActionTypes.LAYER_BACKUP_RESTORE] ({ commit, state }: AugmentedActionContext, payload: LayerBackupRestorePayload): Promise<void>,

  [AppActionTypes.LK_GET_ACTIVITY_SUGGESTIONS]({ commit, state }: AugmentedActionContext, payload: null): Promise<LkActivitySuggestionsResult>,
  [AppActionTypes.LK_GET_ACTIVITY_ESTATE]({ commit, state }: AugmentedActionContext, payload: null): Promise<LkActivityEstateResult>,
  [AppActionTypes.LK_GET_ACTIVITY_VACANCIES]({ commit, state }: AugmentedActionContext, payload: null): Promise<LkActivityVacancyResult>,
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [AppActionTypes.ADMIN_GET_USERS] (_, payload: UsersPayload) {
    return api.get<UsersResult>('/users', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_GROUPS] (_, payload: GroupsPayload) {
    return api.get<GroupsResult>('/user_groups', { params: payload })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_PUT_USER_GROUPS] (_, payload: PutUserGroupsPayload) {
    return api.put('/users/user_groups', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_USERS_OF_GROUP] (_, payload: UsersInGroupPayload) {
    return api.get<UsersResult>(`/user_groups/${payload.id}/users`, { params: payload })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_PUT_GROUP_USERS] (_, payload: PutGroupUsersPayload) {
    return api.put(`/user_groups/${payload.id}/users`, { users: payload.users })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_MAP] ({ commit }, payload: number) {
    return api.get<GeoMap>(`/maps/${payload}`)
      .then(({ data }) => {
        commit(AppMutationTypes.SET_MAP, data)
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_CAMERAS_ACCESS] ({ commit }, payload: number) {
    return api.get<AdminCamerasAccess>(`/user_groups/${payload}/cameras`)
      .then(({ data }) => {
        commit(AppMutationTypes.SET_CAMERAS_PERMS, data.values)
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_LAYERS_OF_GROUP] (_, payload: number) {
    return api.get<LayersResult>(`/user_groups/${payload}/layers`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_SERVICES] () {
    return api.get<ServicesResult>('/services')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_SERVICES_OF_GROUP] (_, payload: number) {
    return api.get<ServicesResult>(`/user_groups/${payload}/services`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_SAVE_GROUP] (_, payload: GroupCreatePayload) {
    return api.post<UserGroupModel>('/user_groups', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_EDIT_GROUP] (_, payload: GroupEditPayload) {
    return api.put<UserGroupModel>(`/user_groups/${payload.id}`, payload.data)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_DELETE_GROUP] (_, id: number) {
    return api.delete(`/user_groups/${id}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_SET_MAP_LAYERS_LIMIT] ({ commit }, payload: mapLayersLimitPayload): Promise<void> {
    return api.patch('/maps/3', payload)
      .then(({ data }) => {
        commit(AppMutationTypes.SET_MAP_LAYERS_LIMIT, data.maxVisibleLayers)
        return data
      })
  },

  async [AppActionTypes.ADMIN_GET_DRAFT_LAYERS] (_, id: number) {
    return api.get<UsersResult>(`/layers/${id}/premoderation`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.ADMIN_SAVE_DRAFT_LAYER] (_, payload: DraftLayerPayload) {
    return api.post<UsersResult>(`/layers/premoderation/${payload.layer_id}`, payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_GET_CITY_PROJECTS] (_, payload: CityProjectsPayload) {
    return cpsApi.get<CityProjectsResult>('/city_projects', { params: payload })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_GET_ONE_CITY_PROJECT] (_, payload: number) {
    return cpsApi.get<CityProject>(`/city_projects/${payload}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_GET_COMMENTS] (_, payload: number) {
    return cpsApi.get<CityProjectCommentsResult>(`/city_projects/${payload}/comments`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_POST_COMMENT] (_, payload: CreateCommentPayload) {
    return cpsApi.post(`/city_projects/${payload.id_geocore}/comments`, payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_DELETE_COMMENT] (_, id: number) {
    return cpsApi.delete(`/city_projects/comments/${id}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_CREATE_PROJECT] (_, payload: CreateProjectPayload) {
    return cpsApi.post<CityProject>('/city_projects', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_UPDATE_PROJECT] (_, payload: UpdateProjectPayload) {
    return cpsApi.put<CityProject>(`/city_projects/${payload.id}`, payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_DELETE_PROJECT] (_, id: number) {
    return cpsApi.delete(`/city_projects/${id}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_UPDATE_PUBLISH] (_, payload: UpdatePublishPayload) {
    return cpsApi.put<CityProject>(`/city_projects/${payload.id}`, payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_GET_ONE_CITY_PROJECT_PRESENTATION] (_, payload: number) {
    return cpsApi.get<CityProjectPresentation>(`/city_projects/${payload}/presentation`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_PUT_PRESENTATION] (_, payload: CityProjectPresentation) {
    return cpsApi.put<CityProjectPresentation>(`/city_projects/${payload.projectId}/presentation`, payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_POST_ATTACHMENT] (_, payload: FormData) {
    return cpsApi.post<CPSAttachAnswer>('/attachment', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CPS_DELETE_ATTACHMENT] (_, id: number) {
    return cpsApi.delete(`/attachment/${id}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.METEO_GET_WEATHER] () {
    return weatherApi.get<WeatherResponce>('/actual_weather')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.METEO_GET_DATES] () {
    return weatherApi.get<WeatherDates>('/history_weather_dates')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.METEO_GET_HISTORY_WEATHER] (_, payload: WeatherDates) {
    return weatherApi.get<WeatherGraphResponce>('/history_weather', { params: payload })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.AIR_GET_MONITORING] () {
    return weatherApi.get<AirMonitoringItem>('/air_monitoring')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.AIR_GET_DATES] () {
    return weatherApi.get<WeatherDates>('/history_air_dates')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.AIR_GET_HISTORY_MONITORING] (_, payload: WeatherDates) {
    return weatherApi.get<AirGraph>('/history_air', { params: payload })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.RES_GET_ESTATE_LIST] (_, payload: EstateSearchModel) {
    return resApi.get<EstatePanelItemsResponce>('/adverts', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.RES_GET_ESTATE_ITEM] (_, id: string) {
    return resApi.get<EstateItem>(`/adverts/${id}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.RES_POST_ATTACHMENT] (_, payload: FormData) {
    return resApi.post<EstateAttach>('/attachment', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.SERVICE_FIND_ADDRESS] (_, payload: FindAdressPayload) {
    return api.post<AddressDetectData>('/address', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.RES_CREATE_ADVERT] (_, payload: CreateEstateAdvertModel) {
    return resApi.post<EstateItem>('/adverts', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.COMMON_GET_PRE_LAYERS] () {
    return api.get<CommonPreenabledLayers>('/estate_layers')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_ESTATE_GET_LIST] (_, payload: ModerationEstateGetPayload) {
    return resApi.get<ModerationEstateResult>('/moderation/adverts', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_ESTATE_DELETE] (_, uuid: string) {
    return resApi.delete(`/adverts/${uuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_ESTATE_UPDATE_STATUS] (_, payload: RESStatusPayload) {
    return resApi.put(`/adverts/${payload.uuid}`, {
      status: payload.status
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.EGRN_GET_LIST] (_, payload: ModerationEgrnPayload) {
    return api.get<ModerationEgrnListResult>('/egrn/records', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.EGRN_POST_XML] (_, payload: FormData) {
    return api.post<ModerationEgrnItem>('/egrn/upload', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.EGRN_CANCEL_XML] (_, uuid: string) {
    return api.post<ModerationEgrnItem>(`/egrn/cancel_uploading?uuid=${uuid}`, {
      uuid: uuid
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.EGRN_GET_XML] (_, uuid: string) {
    return api.get<ModerationEgrnDownloadResponce>(`/egrn/download?uuid=${uuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.EGRN_GET_SMAV] () {
    return api.get<string>('/egrn/update')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CVS_GET_VACANCIES_LIST] (_, payload: VacanciesSearchModel) {
    return cvsApi.get<CityVacanciesResponce>('/vacancies', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CVS_GET_VACANCY_ITEM] (_, id: string) {
    return cvsApi.get<CityVacancyItemModel>(`/vacancies/${id}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CVS_CREATE_VACANCY] (_, payload: CreateVacancyModel) {
    return cvsApi.post<CityVacancyItemModel>('/vacancies', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_VACANCIES_GET_LIST] (_, payload: ModerationVacanciesGetPayload) {
    return cvsApi.get<ModerationVacanciesResult>('/moderation/vacancies', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_VACANCY_DELETE] (_, uuid: string) {
    return cvsApi.delete(`/vacancies/${uuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_VACANCY_UPDATE_STATUS] (_, payload: RESStatusPayload) {
    return cvsApi.put(`/vacancies/${payload.uuid}`, {
      status: payload.status
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_GET_VIDEO_LIST] (_, payload: VideoStreamsSearchModel) {
    return vssApi.get<VideoStreamsResponse>('/tree', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_PUT_VIDEO_LIST] (_, payload: VideoStreamsPutModel) {
    vssApi.put<VideoStreamsResponse>('/tree', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_POST_VIDEO] (_, payload: Stream) {
    return vssApi.post<Stream>('/camera', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_PUT_VIDEO] (_, payload: Stream) {
    return vssApi.put<Stream>(`/camera/${payload.uuid}`, payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_DELETE_VIDEO] (_, uuid: string) {
    return vssApi.delete<string>(`/camera/${uuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_DELETE_GROUP] (_, uuid: string) {
    return vssApi.delete<string>(`/group/${uuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_PATCH_VIDEO] (_, payload: VideoStreamsPatchModel) {
    return vssApi.patch<string>(`/camera/${payload.uuid}`, { name: payload.name })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_PATCH_GROUP] (_, payload: VideoStreamsPatchModel) {
    return vssApi.patch<string>(`/group/${payload.uuid}`, { name: payload.name })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_POST_GROUP] (_, payload: VideoStreamsPostModel) {
    return vssApi.post<StreamGroup>('/group', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_GET_VIDEO] (_, uuid: string) {
    return vssApi.get<Stream>(`/camera/${uuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_DOWNLOAD_RECORD] (_, payload: VideoRecordDownloadPayload) {
    return vssApi.get<VideoRecordDownloadResponce>(`/camera/${payload.uuid}/records`, {
      params: {
        fromDatetime: payload.fromDatetime,
        toDatetime: payload.toDatetime
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_GET_RECORDS] (_, payload: VideoStreamsDownloadModel) {
    let _percent = 0
    return vssApi.get<BlobPart>(`/camera/${payload.uuid}/records/archive`, {
      params: payload.dates,
      responseType: 'blob',
      cancelToken: payload.source.token,
      onDownloadProgress: (progressEvent) => {
        const totalLength = progressEvent.total
        const downloadedLength = progressEvent.loaded
        if (totalLength !== 0) {
          payload.percent.value = Math.floor((downloadedLength / totalLength) * 100)
        } else {
          if (_percent < 99) _percent++
          payload.percent.value = _percent
        }
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.VSS_GET_AVAILABLE_DATES] (_, uuid: string) {
    return vssApi.get<VideoStreamsDates>(`/camera/${uuid}/records/dates`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CSS_GET_SUGGESTIONS_LIST] (_, payload: CitySuggestionsSearchModel) {
    return cssApi.get<CitySuggestionsResponce>('/suggestions', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CSS_GET_SUGGESTION_ITEM] (_, id: string) {
    return cssApi.get<SuggestionItem>(`/suggestions/${id}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CSS_POST_ATTACHMENT] (_, payload: FormData) {
    return cssApi.post<EstateAttach>('/attachment', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.CSS_CREATE_SUGGESTION] (_, payload: CreateSuggestionModel) {
    return cssApi.post<SuggestionItem>('/suggestions', payload)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_SUGGESTIONS_GET_LIST] (_, payload: ModerationSuggestionsGetPayload) {
    return cssApi.get<ModerationSuggestionsResult>('/moderation/suggestions', {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_SUGGESTION_DELETE] (_, uuid: string) {
    return cssApi.delete(`/suggestions/${uuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.MODERATION_SUGGESTION_UPDATE_STATUS] (_, payload: CSSStatusPayload) {
    return cssApi.put(`/suggestions/${payload.uuid}`, {
      status: payload.status
    })
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.LAYER_BACKUP_CREATE] (_, layerId: number) {
    return api.post(`/layers/backups/${layerId}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.LAYER_BACKUP_GET_LIST] (_, layerId: number) {
    return api.get<LayerBackupListResult>(`/layers/backups/${layerId}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.LAYER_BACKUP_RESTORE] (_, payload: LayerBackupRestorePayload) {
    return api.post(`/layers/backups/${payload.layerId}/${payload.backupUuid}`)
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.LK_GET_ACTIVITY_SUGGESTIONS] () {
    return cssApi.get<LkActivitySuggestionsResult>('/lk/suggestions')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.LK_GET_ACTIVITY_ESTATE] () {
    return resApi.get<LkActivityEstateResult>('/lk/adverts')
      .then(({ data }) => {
        return data
      })
  },

  async [AppActionTypes.LK_GET_ACTIVITY_VACANCIES] () {
    return cvsApi.get<LkActivityVacancyResult>('/lk/vacancies')
      .then(({ data }) => {
        return data
      })
  }
}
