
import { defineComponent } from 'vue'
import InlineSvg from 'vue-inline-svg'
import BaseIcon from '@/library/base/BaseIcon.vue'

export default defineComponent({
  name: 'Pagination',
  components: {
    InlineSvg,
    BaseIcon
  },
  props: {
    totalCount: {
      type: Number,
      required: true
    },
    totalWord: {
      type: String,
      required: false,
      default: 'Всего'
    },
    wholeTotalItems: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    visiblePagesCount: {
      type: Number,
      default: 5
    }
  },
  emits: ['change-page'],
  data () {
    return {
      emptyFiller: '.'
    }
  },
  computed: {
    hasPrev () {
      return this.currentPage - 1 > 0
    },
    hasNext () {
      return this.currentPage + 1 <= this.totalCount
    },
    firstPage () {
      return 1
    },
    lastPage () {
      return this.totalCount
    },
    visiblePages () {
      const {
        lastPage,
        firstPage,
        totalCount,
        emptyFiller,
        currentPage,
        visiblePagesCount
      } = this

      let visiblePages: (string | number)[]
      const visibleThreshold = (visiblePagesCount - 1) / 2
      let visiblePagesArray = Array(visiblePagesCount - 1).fill(0)

      if (lastPage <= visiblePagesCount) {
        visiblePages = []
        for (let i = 1; i <= lastPage; i += 1) visiblePages.push(i)
        return visiblePages
      }

      if (currentPage < visibleThreshold + 1) {
        visiblePagesArray[0] = firstPage
        visiblePages = visiblePagesArray.map((_, idx) => firstPage + idx)
        visiblePages.push(emptyFiller)
        visiblePages.push(lastPage)
        return visiblePages
      }

      if (currentPage >= totalCount - visibleThreshold + 1) {
        visiblePages = visiblePagesArray.map((_, idx) => lastPage - idx)
        visiblePages.reverse()
        visiblePages.unshift(emptyFiller)
        visiblePages.unshift(firstPage)
        return visiblePages
      }

      visiblePagesArray = Array(visiblePagesCount - 2).fill(0)
      visiblePagesArray[0] = currentPage - visibleThreshold + 1
      visiblePages = visiblePagesArray.map(
        (_, idx) => visiblePagesArray[0] + idx
      )

      visiblePages.unshift(emptyFiller)
      visiblePages.unshift(firstPage)

      if (!visiblePages.includes(lastPage)) {
        visiblePages.push(emptyFiller)
        visiblePages.push(lastPage)
      }

      return visiblePages
    }
  },
  methods: {
    prevPage () {
      if (this.currentPage !== 1) {
        this.$emit('change-page', this.currentPage - 1)
      }
    },
    nextPage () {
      if (this.currentPage < this.totalCount) { this.$emit('change-page', this.currentPage + 1) }
    },
    toPage (pageNum: string | number) {
      this.$emit('change-page', pageNum)
    }
  }
})
