// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum MapsMutationTypes {
  SET_LIST = 'MAPS_SET_LIST',
  SET_MAP = 'MAPS_SET_MAP',
  SET_MAP_STATE_DEFAULT = 'MAPS_SET_MAP_STATE_DEFAULT',
  SET_MAP_LAYERS_VISIBLE = 'MAPS_SET_MAP_LAYERS_VISIBLE',
  SET_MAP_GROUPS_VISIBLE = 'MAPS_SET_MAP_GROUPS_VISIBLE',
  SET_MAP_GROUPS_ORDER = 'MAPS_SET_MAP_GROUPS_ORDER',
  SET_MAP_LAYERS_ORDER = 'MAPS_SET_`MAP_LAYERS_ORDER',
  SET_MAP_BASEMAP = 'MAPS_SET_MAP_BASEMAP',
  ADD_LAYERS = 'MAPS_ADD_LAYERS',
  SET_LAYERS_ALPHA = 'MAPS_SET_MAP_LAYERS_ALPHA',
  SET_TASKS = 'MAPS_SET_TASK',
  ADD_TASK = 'MAPS_ADD_TASK',
  UPDATE_TASK = 'MAPS_UPDATE_TASK',
  ADD_EXPORT_TASK_ID = 'MAPS_ADD_EXPORT_TASK_ID',
  ADD_GROUP = 'MAPS_ADD_GROUP',
  REMOVE_GROUP = 'MAPS_REMOVE_GROUP',
  SET_ROOT_GROUP = 'MAPS_SET_ROOT_GROUP',
  SET_ROOT_LAYERS = 'MAPS_SET_ROOT_LAYERS',
  SET_ROOT_GROUP_ORDER = 'MAPS_SET_ROOT_GROUP_ORDER',
  UPDATE_MAP_GROUP = 'MAPS_UPDATE_MAP_GROUP',
  SET_MAP_GROUP_INFO = 'MAPS_SET_MAP_GROUP_INFO',
  SET_MAP_LAYER_NAME = 'MAPS_SET_MAP_LAYER_NAME',
  MAP_GROUP_DELETE = 'MAPS_MAP_GROUP_DELETE',
  ADD_MAP = 'MAPS_MAP_ADD',
  SET_MAP_SESSION_DATA = 'MAPS_SET_MAP_SESSION_DATA',
  SET_LAYER = 'MAPS_SET_LAYER',
  SET_MAP_LAYER_EXPANDED = 'SET_MAP_LAYER_EXPANDED',
  SET_GROUPS_SELECTED = 'SET_MAP_GROUPS_SELECTED',
  SET_LAYERS_SELECTED = 'SET_MAP_LAYERS_SELECTED',
  SET_WINDOW_OPEN_STATE = 'SET_WINDOW_OPEN_STATE',
  SET_MULTIPLE_TREE_SELECT = 'MAPS_SET_MULTIPLE_TREE_SELECT'
}
