import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40b6d427"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-checkbox__icon" }
const _hoisted_2 = { class: "base-checkbox__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox", {'checked': _ctx.checked, 'disabled': _ctx.disabled}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_inline_svg, {
        class: "base-checkbox__icon-check",
        src: require(_ctx.checked ? '@/assets/svg/checkbox-selected.svg' : '@/assets/svg/checkbox.svg')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ], true)
    ])
  ], 2))
}