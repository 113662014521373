
import i18n from '@/i18n'
import { defineComponent } from 'vue'

import BaseInput from '@/library/base/BaseInput.vue'
import BaseDoubleRange from '@/library/base/BaseDoubleRange.vue'

export default defineComponent({
  components: {
    BaseInput,
    BaseDoubleRange
  },

  props: {
    startValue: { type: [String, Number], default: null },
    endValue: { type: [String, Number], default: null },
    step: { type: Number, default: 1 },
    minValue: { type: Number, default: undefined },
    maxValue: { type: Number, default: undefined },
    fromLabel: { type: String, default: undefined },
    toLabel: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    unit: { type: String, default: undefined },
    hasError: { type: Boolean, default: false },
    withRangeBar: { type: Boolean, default: false }
  },

  emits: ['update:modelValue'],

  data: () => ({
    selectedStartValue: 0,
    selectedEndValue: 0
  }),

  computed: {
    fromLabelTranslate () {
      return this.fromLabel ? this.fromLabel : this.$t('base.limit-range.from-label')
    },

    toLabelTranslate () {
      return this.toLabel ? this.toLabel : this.$t('base.limit-range.to-label')
    }
  },

  watch: {
    startValue (newValue) {
      this.selectedStartValue = newValue
    },

    endValue (newValue) {
      this.selectedEndValue = newValue
    }
  },

  mounted () {
    this.selectedStartValue = Number.isNaN(this.startValue) ? 0 : Number(this.startValue)
    this.selectedEndValue = Number.isNaN(this.endValue) ? 0 : Number(this.endValue)
  },

  methods: {
    $t: i18n.global.t,

    handlerInput (newValue: string, propertyName: 'startValue' | 'endValue') {
      const result: Record<string, number | string> = {
        startValue: this.startValue,
        endValue: this.endValue
      }
      result[propertyName] = newValue
      this.$emit('update:modelValue', result)
    }
  }
})
