import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78d99d52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-layers" }
const _hoisted_2 = { class: "map-layers__content" }
const _hoisted_3 = {
  ref: "container",
  class: "map-layers__content-ps"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_group_item = _resolveComponent("group-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.rootGroup)
              ? (_openBlock(), _createBlock(_component_group_item, {
                  key: 0,
                  list: _ctx.getMapRootGroup,
                  "window-rect": _ctx.windowRect
                }, null, 8, ["list", "window-rect"]))
              : _createCommentVNode("", true)
          ], 512)
        ])
      ]),
      _: 1
    })
  ]))
}