import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71a72d65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-panel__header" }
const _hoisted_2 = {
  key: 0,
  class: "base-panel__line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_header = _resolveComponent("base-header")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-panel", { 'as-notification': _ctx.asNotification }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_base_header, {
            key: 0,
            level: _ctx.headerLevel,
            title: _ctx.title,
            "with-ellipsis": _ctx.headerWithEllipsis,
            class: "base-panel__header-name"
          }, null, 8, ["level", "title", "with-ellipsis"]))
        : _createCommentVNode("", true),
      (!_ctx.hideClose)
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 1,
            modelValue: _ctx.hidden,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hidden) = $event)),
            type: _ctx.IconType.CLEAR,
            size: _ctx.SizeType.SM,
            src: _ctx.imgClose,
            class: "base-panel__header-close",
            onClick: _ctx.close
          }, null, 8, ["modelValue", "type", "size", "src", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$slots.default && _ctx.showSeparator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "base-panel__content",
      style: _normalizeStyle(_ctx.customContentStyles)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ], 2))
}