import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "legend-symbol" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_legend_polygon = _resolveComponent("legend-polygon")!
  const _component_legend_point = _resolveComponent("legend-point")!
  const _component_legend_line = _resolveComponent("legend-line")!
  const _component_legend_raster = _resolveComponent("legend-raster")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.symbol.Polygon)
      ? (_openBlock(), _createBlock(_component_legend_polygon, {
          key: 0,
          polygon: _ctx.symbol.Polygon
        }, null, 8, ["polygon"]))
      : (_ctx.symbol.Point)
        ? (_openBlock(), _createBlock(_component_legend_point, {
            key: 1,
            point: _ctx.symbol.Point
          }, null, 8, ["point"]))
        : (_ctx.symbol.Line)
          ? (_openBlock(), _createBlock(_component_legend_line, {
              key: 2,
              line: _ctx.symbol.Line
            }, null, 8, ["line"]))
          : (_ctx.symbol.Raster)
            ? (_openBlock(), _createBlock(_component_legend_raster, {
                key: 3,
                raster: _ctx.symbol.Raster
              }, null, 8, ["raster"]))
            : _createCommentVNode("", true)
  ]))
}