
import { defineComponent } from 'vue'
import BaseLoading from '@/components/base/BaseLoading.vue'

export default defineComponent({
  components: { BaseLoading },
  props: {
    type: {
      type: String,
      default: 'standart',
      validator: (val: string) => {
        return ['standart', 'reverse', 'danger', 'destructive_l'].indexOf(val) !== -1
      }
    },
    size: {
      type: String,
      default: 'normal',
      validator: (val: string) => {
        return ['big', 'normal', 'small'].indexOf(val) !== -1
      }
    },
    submit: {
      type: Boolean,
      default: false
    },
    disabled: { type: Boolean, default: false }
  },
  setup () {
    return {}
  }
})
