import { MutationTree } from 'vuex'

import { State } from './state'
import { AuthMutationTypes } from './mutation-types'
import {
  UserSelfModel
} from '@/types/auth/types'

export type Mutations<S = State> = {
  [AuthMutationTypes.SET_USER](state: S, payload: UserSelfModel): void;
  [AuthMutationTypes.SET_AUTHENTICATED](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [AuthMutationTypes.SET_USER] (state: State, user: UserSelfModel) {
    state.user = user
    if (user.groups.findIndex(x => x.name === 'Администратор') !== -1) state.isAdmin = true
    state.userShort = ((user.firstName?.substring(0, 1) ?? '') + (user.lastName?.substring(0, 1) ?? ''))
  },

  [AuthMutationTypes.SET_AUTHENTICATED] (state: State, newState: boolean) {
    state.authenticated = newState
  }
}
