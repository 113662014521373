
import { ref, defineComponent } from 'vue'

import BaseIcon from '../BaseIcon.vue'

import imgArrow from '@/library/assets/img/icArrow.svg'

export default defineComponent({
  components: { BaseIcon },
  props: {
    iconSrc: { type: String, default: undefined },
    name: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    iconMirrored: { type: Boolean, default: false },
    iconRotateAngle: { type: Number, default: undefined },
    level: { type: Number, default: 0 }
  },
  emits: [
    'click',
    'updateSequence'
  ],
  setup () {
    const itemRef = ref<HTMLElement | null>(null)
    return {
      imgArrow,
      itemRef
    }
  },
  data () {
    return {
      showList: false,
      topOffset: 0
    }
  },

  watch: {
    showList (state) {
      if (this.$slots.list) {
        this.$nextTick(() => {
          const listElement = this.$refs.list as HTMLElement
          if (listElement) {
            const rect = listElement.getBoundingClientRect()
            if (rect && rect.top + rect.height > window.innerHeight) {
              const offset = rect.top + rect.height - window.innerHeight
              this.topOffset = -(10 + offset)
            } else {
              this.topOffset = 0
            }
          }
          this.$emit('updateSequence', {
            level: this.level + 1,
            state: state,
            width: this.itemRef?.clientWidth
          })
        })
      }
    }
  },

  methods: {
    onMouseEnter () {
      this.showList = true
    },

    onMouseLeave () {
      this.showList = false
    },

    clickHandler (event: MouseEvent) {
      const listElement = this.$refs.list as HTMLElement
      if (this.$slots.list && this.level === 0 && !listElement.contains(event.target as Node)) {
        event.stopPropagation()
      }
    }
  }
})
