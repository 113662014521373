import { GeoMapAccessAction, GeoMapPermission } from '../types/maps/enums'

const checkAccess = (perm: GeoMapPermission | undefined | null, accessAction: GeoMapAccessAction): boolean => {
  if (!perm) {
    return false
  }

  switch (perm) {
    case GeoMapPermission.OWNER:
      return true
    case GeoMapPermission.READWRITE:
      switch (accessAction) {
        case GeoMapAccessAction.MAP_SHARING:
          return false
        default:
          return true
      }
    case GeoMapPermission.READ:
      return false
  }
  return false
}

export default {
  actions: GeoMapAccessAction,
  permission: GeoMapPermission,
  checkAccess
}
