
import { defineComponent, PropType, computed, ref } from 'vue'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'
import { MoveEvent } from 'vuedraggable'
import i18n from '@/i18n'

import { MapsMutationTypes } from '@/library/store/modules/maps/mutation-types'
import { IconType, SizeType } from '@/library/types/base/enums'
import { useStore } from '@/library/store'
import {
  GeoMapLayer,
  MenuTreeItem,
  GeoMapLayergroup,
  VisibleLayer
} from '@/library/types'
import { getTreeMaxDeep, debounceCall, isLayer, getLayersList, getGroupsList, deepLayersCountInGroup, is3dLayer } from '@/library/helpers'
import { MapsActionTypes } from '@/library/store/modules/maps/action-types'
import LayerItem from './LayerItem.vue'
import BaseCheckbox from '@/library/base/BaseCheckbox.vue'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseMenu from '@/library/base/menu/BaseMenu.vue'
import BaseConfirm from '@/library/base/modal/BaseConfirm.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import BaseAccept from '@/library/base/BaseAccept.vue'
import BaseInput from '@/library/base/BaseInput.vue'
import { useStore as usestoreApp } from '@/store'
import { useToast } from 'vue-toastification'
import ToastTo3D from '@/components/app/ToastTo3D.vue'
import auth from '@/library/auth'
import toast from '@/library/toast'
import { TreeSelectType } from '@/library/types/maps/enums'

import imgOpen from '@/library/assets/img/icFolder2.svg'
import imgClose from '@/library/assets/img/icFolder.svg'
import imgCross from '@/library/assets/img/icCrossedClose.svg'
import imgConfirm from '@/library/assets/img/icDone.svg'
import imgDisabled from '@/library/assets/img/icVisionOff.svg'
import imgActive from '@/library/assets/img/icVisionOn.svg'
// import imgInfo from '@/library/assets/img/icInfo.svg'
import imgEdit from '@/library/assets/img/icEdit.svg'
import imgEdit2 from '@/library/assets/img/icEdit2.svg'
import imgTransp from '@/library/assets/img/icTransp.svg'
import imgDelete from '@/library/assets/img/icDelete.svg'
import imgScale from '@/library/assets/img/icScale.svg'

const store = useStore()
const baykalskStore = usestoreApp()
const appToast = useToast()

const t = i18n.global.t
const MAX_NESTING_LEVEL = 5

export default defineComponent({
  name: 'GroupItem',
  components: {
    BaseCheckbox,
    BaseConfirm,
    BaseHeader,
    BaseIcon,
    BaseMenu,
    LayerItem,
    Draggable,
    BaseAccept,
    BaseInput
  },
  props: {
    list: {
      type: Object as PropType<Array<GeoMapLayer | GeoMapLayergroup>> | null,
      required: true
    },
    parentGroup: {
      type: Object as PropType<GeoMapLayergroup>,
      required: true
    },
    windowRect: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 1
    },
    isPresentation: {
      type: Boolean,
      default: false
    },
    is3dPresentationMode: {
      type: Boolean,
      default: false
    },
    isTotalSelected: { type: Boolean, default: false },
    draggableDisabled: { type: Boolean, default: false }
  },
  emits: [
    'update',
    'flyToLayer',
    'flyToGroup',
    'openLayerInfo',
    'openGroupInfo',
    'openExportWindow',
    'switchedOpacity',
    'ungroupLayers',
    'mouseoverText',
    'mouseleaveText'
  ],

  setup (props) {
    const setColorRef = ref<HTMLElement | null>(null)

    const selectedGroupsIds = computed({
      get () {
        return store.state.maps.selectedGroups
      },
      set (newIds: number[]) {
        store.commit(MapsMutationTypes.SET_GROUPS_SELECTED, newIds)
      }
    })

    const selectedLayersIds = computed({
      get () {
        return store.state.maps.selectedLayers
      },
      set (newIds: number[]) {
        store.commit(MapsMutationTypes.SET_LAYERS_SELECTED, newIds)
      }
    })

    const countSelectedItems = computed(() => {
      return selectedLayersIds.value.length + selectedGroupsIds.value.length
    })

    const recalcGroupSelected = (group: GeoMapLayergroup) => {
      if (props.isTotalSelected || !countSelectedItems.value) {
        return
      }
      const allLayersSelected = group.layers.every(layer => {
        return selectedLayersIds.value.includes(layer.id)
      })
      const allGroupsSelected = group.groups.every(childGroup => {
        return selectedGroupsIds.value.includes(childGroup.id)
      })
      const notEmptyGroup = (group.layers.length + group.groups.length) > 0
      if (notEmptyGroup) {
        if (allLayersSelected && allGroupsSelected) {
          selectedGroupsIds.value = [...selectedGroupsIds.value, group.id]
        } else {
          selectedGroupsIds.value = selectedGroupsIds.value.filter(id => id !== group.id)
        }
      }
    }

    const lazyRecalcSelection = debounceCall(recalcGroupSelected, 100)
    return {
      lazyRecalcSelection,
      countSelectedItems,
      selectedGroupsIds,
      selectedLayersIds,
      imgConfirm,
      imgCross,
      imgOpen,
      imgClose,
      imgDisabled,
      imgActive,
      IconType,
      SizeType,
      setColorRef,
      isLayer,
      t,
      baykalskStore,
      auth,
      store
    }
  },

  data () {
    return {
      enabledOpacityLayers: [] as number[],
      renamedGroupName: '',
      selectedColor: '',
      activeGroup: null as GeoMapLayergroup | null,
      openedLegend: false,
      isRenameActive: false,
      showConfirmDelete: false,
      showConfirmUngroup: false,
      isLoading: false,
      isSubmitEdit: false,
      isOpenedInfoWindow: false,
      cursorIsOnText: false
    }
  },

  computed: {
    isEnabledMultipleSelect () {
      return store.state.maps.multipleTreeSelect.isEnable
    },

    isEnabledMultipleSelectMove () {
      return this.isEnabledMultipleSelect && store.state.maps.multipleTreeSelect.type === TreeSelectType.GROUPS_DRAG
    },

    draggableElement: {
      get () {
        return store.state.maps.draggableTreeElement
      },
      set (newElement: GeoMapLayer | GeoMapLayergroup) {
        store.state.maps.draggableTreeElement = newElement
      }
    },

    failedDragging: {
      get () {
        return store.state.maps.failedDragging
      },
      set (newState: boolean) {
        store.state.maps.failedDragging = newState
      }
    },

    draggableClass () {
      return (!this.draggableDisabled && this.isAllowMapEdit) ? '.draggable-block' : ''
    },

    visibleLayersIds () {
      return store.getters.getVisibleLayerIds
    },

    visibleLayers () {
      return store.state.maps.visibleLayers
    },

    expandedGroups () {
      return store.state.maps.visibleGroups
    },

    isAllowEditGroup () {
      return baykalskStore.state.auth.isAdmin
    },

    isAllowMapEdit () {
      return baykalskStore.state.auth.isAdmin
    },

    allItems: {
      get () {
        return [...this.list].sort((a, b) => b.zIndex - a.zIndex)
      },
      set (newItems: (GeoMapLayer | GeoMapLayergroup)[]) {
        if (this.isEnabledMultipleSelect && store.state.maps.multipleTreeSelect.type === TreeSelectType.GROUPS_DRAG) {
          if (this.draggableElement) {
            newItems = this.addSelectedLayers(newItems)
          } else {
            return
          }
        }

        if (this.draggableElement && !isLayer(this.draggableElement)) {
          const groupDeep = getTreeMaxDeep(this.draggableElement)
          if ((this.level + groupDeep) > MAX_NESTING_LEVEL) {
            toast.error(t('maps.layers.tree.moveFailed'))
            store.dispatch(MapsActionTypes.RESET_TREE_STRUCTURE)
            return
          }
        }
        if (this.failedDragging) {
          this.failedDragging = false
          return
        }

        this.draggableElement = undefined

        this.refreshGroup(newItems)
        this.update()
      }
    }
  },

  watch: {
    countSelectedItems (newCount: number, oldCount: number) {
      if (newCount !== oldCount && this.parentGroup.id) {
        this.lazyRecalcSelection(this.parentGroup)
      }
    },
    'store.state.maps.groupEditId' (newGroupId?: number) {
      this.$nextTick(() => {
        const group = this.list.find(x => !isLayer(x) && x.id === newGroupId)
        if (group && !isLayer(group)) {
          this.toggleRename(group)
        }
      })
    }
  },

  methods: {
    update () {
      this.$emit('update')
    },

    onMouseLeaveText () {
      this.cursorIsOnText = false
      this.$emit('mouseleaveText')
    },
    onMouseOverText () {
      this.cursorIsOnText = true
      this.$emit('mouseoverText')
    },

    addSelectedLayers (newItems: (GeoMapLayer | GeoMapLayergroup)[]) {
      if (!store.state.maps.selectedLayers.length || !store.state.maps.map?.groups || !this.draggableElement) {
        return newItems
      }

      const allLayers = getLayersList(store.state.maps.map.groups)
      const allGroups = getGroupsList(store.state.maps.map.groups)

      const insertLayers = allLayers.filter(x => store.state.maps.selectedLayers.includes(x.id) && !newItems.find(i => i.id === x.id))
      const moveLayers = allLayers.filter(x => store.state.maps.selectedLayers.includes(x.id) && newItems.find(i => i.id === x.id) && x.id !== this.draggableElement?.id)
      const items = newItems.filter(x => !isLayer(x) || !moveLayers.includes(x))
      const insertStartIndex = items.findIndex(x => isLayer(x) && x.id === this.draggableElement?.id)

      Array.from([...insertLayers, this.draggableElement as GeoMapLayer]).forEach(iL => {
        let lGroup = allGroups.find(x => x.layers.find(y => y.id === iL.id) != null)

        if (!lGroup) {
          lGroup = store.state.maps.map?.groups.layers.find(y => y.id === iL.id) != null ? store.state.maps.map?.groups : undefined
        }

        if (lGroup) {
          lGroup.layers = lGroup.layers.filter(x => x.id !== iL.id)
        }
      })

      items.splice(insertStartIndex + 1, 0, ...[...moveLayers, ...insertLayers])

      return items
    },

    async recursiveSetColor (groups: GeoMapLayergroup[], color: string) {
      const asyncUpdates: Promise<void>[] = []
      groups.forEach(group => {
        asyncUpdates.push(
          store.dispatch(MapsActionTypes.UPDATE_GROUP_INFO, {
            itemId: group.id,
            colorCode: color
          })
        )
        if (Array.isArray(group.groups) && group.groups.length) {
          this.recursiveSetColor(group.groups, color)
        }
      })
      await Promise.all(asyncUpdates)
    },

    async handlerGroupColor () {
      if (this.activeGroup && this.selectedColor.length) {
        try {
          this.isLoading = true
          await this.recursiveSetColor([this.activeGroup], this.selectedColor)
        } catch {
          toast.error(this.t('maps.layers.tree.menu.refreshGroupColorError'))
        } finally {
          this.isLoading = false
        }
      }
    },

    refreshGroup (newItems: (GeoMapLayer | GeoMapLayergroup)[]) {
      const newGroup = Object.assign({}, this.parentGroup)
      newGroup.groups = []
      newGroup.layers = []

      newItems.forEach((item, index) => {
        item.zIndex = newItems.length - index
        if (isLayer(item)) {
          newGroup.layers.push(item)
        } else {
          newGroup.groups.push(item)
        }
      })

      store.commit(MapsMutationTypes.UPDATE_MAP_GROUP, newGroup)
    },

    moveHandler (evt: MoveEvent<GeoMapLayer | GeoMapLayergroup>) {
      if (evt.draggedContext.element) {
        this.draggableElement = Object.assign({}, evt.draggedContext.element) as GeoMapLayer | GeoMapLayergroup
      }

      if (this.isEnabledMultipleSelect && store.state.maps.multipleTreeSelect.type === TreeSelectType.GROUPS_DRAG) {
        if (!this.draggableElement || !isLayer(this.draggableElement) || !this.selectedLayersIds.includes(this.draggableElement.id)) {
          return false
        }
      }
    },

    /* toggleVisibleLayers (state: boolean, existItems: VisibleLayer[], ...newItems: number[]) {
      if (state) {
        const filteredItems = newItems.filter(item => {
          return !existItems.some(layer => layer.layerId === item)
        }).map(layerId => {
          return { layerId, filters: [] }
        })
        return [...existItems, ...filteredItems]
      } else {
        return existItems.filter(layer => !newItems.includes(layer.layerId))
      }
    }, */

    toggleGroupVisible (state: boolean, group: GeoMapLayergroup) {
      appToast.clear()
      /*  check maxVisibleLayers first */
      const limit = baykalskStore.state.baykalsk.maxVisibleLayers
      const visibleCount = store.state.maps.visibleLayers.length
      const availableCount = limit - visibleCount
      if (state) {
        if (visibleCount >= limit) {
          toast.clear()
          toast.error(this.t('maps.layers.tree.layers-limit-error'))
          return
        }
      } else toast.clear()
      if (state && group.layers.some(v => is3dLayer(v)) && (!baykalskStore.state.baykalsk.is3dEnabled || !this.is3dPresentationMode)) {
        if (!baykalskStore.state.baykalsk.is3dEnabled && !this.isPresentation) {
          appToast.warning(ToastTo3D)
          return
        } else if (this.isPresentation && !this.is3dPresentationMode) {
          appToast.warning('Недоступно в презентации 2Д проекта')
          return
        }
      }
      const layersIds = group.layers.map(layer => layer.id)
      group.groups.forEach(childGroup => {
        this.toggleGroupVisible(state, childGroup)
      })
      if (state) {
        if (layersIds.length >= availableCount) {
          toast.clear()
          toast.error(this.t('maps.layers.tree.layers-limit-error'))
        }
        const newLayers = this.visibleLayers.slice()
        layersIds.slice(0, availableCount).forEach(layerId => {
          newLayers.push({
            layerId: layerId,
            filters: []
          })
        })
        store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, newLayers.slice(0, limit))
      } else {
        const newLayers = this.visibleLayers.filter(item => !layersIds.includes(item.layerId))
        store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, newLayers)
      }

      /* const newLayers = this.toggleVisibleLayers(state, this.visibleLayers, ...layersIds)
      store.commit(MapsMutationTypes.SET_MAP_LAYERS_VISIBLE, newLayers) */
    },

    toggleGroupSelect (state: boolean, group: GeoMapLayergroup) {
      const layersIds = group.layers.map(layer => layer.id)
      const groupsIds = [group.id, ...group.groups.map(group => group.id)]
      group.groups.forEach(childGroup => {
        this.toggleGroupSelect(state, childGroup)
      })
      this.selectedGroupsIds = this.toggleItems(state, this.selectedGroupsIds, ...groupsIds)
      this.selectedLayersIds = this.toggleItems(state, this.selectedLayersIds, ...layersIds)
    },

    toggleItems (state: boolean, existItems: number[], ...newItems: number[]) {
      if (state) {
        return [...existItems, ...newItems.filter(item => !existItems.includes(item))]
      } else {
        return existItems.filter(item => !newItems.includes(item))
      }
    },

    isVisibleGroup (group: GeoMapLayergroup) {
      const layersIds = group.layers.map(layer => layer.id)
      const hasVisibleLayer = layersIds.some(layerId => {
        return this.visibleLayersIds.includes(layerId)
      })
      const hasVisibleChildGroup = group.groups.some(childGroup => {
        return this.isVisibleGroup(childGroup)
      })
      return hasVisibleLayer || hasVisibleChildGroup
    },

    isSelectedGroup (group: GeoMapLayergroup) {
      return this.selectedGroupsIds.includes(group.id)
    },

    handlerSwitchOpacity (args: [number, boolean]) {
      const [id, state] = args
      if (state) {
        if (!this.enabledOpacityLayers.includes(id)) {
          this.enabledOpacityLayers.push(id)
        }
      } else {
        const deletedIndex = this.enabledOpacityLayers.indexOf(id)
        this.enabledOpacityLayers.splice(deletedIndex, 1)
      }
      this.$emit('switchedOpacity', args)
    },

    recursiveOpacitySearch (items: GeoMapLayer[] | GeoMapLayergroup[], isGroup?: boolean): boolean {
      if (isGroup) {
        return items.some((item: GeoMapLayer | GeoMapLayergroup) => {
          item = item as GeoMapLayergroup
          return (
            this.recursiveOpacitySearch(item.layers) ||
            this.recursiveOpacitySearch(item.groups, true)
          )
        })
      } else {
        return items.some((item: GeoMapLayer | GeoMapLayergroup) => {
          return this.enabledOpacityLayers.includes(item.id)
        })
      }
    },

    isDraggableBlock (item: GeoMapLayer | GeoMapLayergroup, isGroup: boolean) {
      if (this.cursorIsOnText || this.draggableDisabled) {
        return ''
      }

      let hasOpacity = false
      if (this.enabledOpacityLayers.length) {
        if (isGroup) {
          hasOpacity = this.recursiveOpacitySearch([item] as GeoMapLayergroup[], true)
        } else {
          hasOpacity = this.enabledOpacityLayers.includes(item.id)
        }
      }
      return hasOpacity ? '' : 'draggable-block'
    },

    toggleRename (groupElement: GeoMapLayergroup) {
      setTimeout(() => {
        this.activeGroup = groupElement
        this.renamedGroupName = this.activeGroup.name
        this.isRenameActive = true
      }, 100)
    },

    menuClickHandler (item: MenuTreeItem, defaultEvent: Event) {
      if (item.handler) {
        item.handler.apply(null, item.args ? item.args : [defaultEvent])
      }
    },

    async openInfoDialog (element: GeoMapLayergroup) {
      this.$emit('openGroupInfo', element)
    },

    prepareMenuItems (element: GeoMapLayergroup) {
      const hasChildren = deepLayersCountInGroup([element]) > 0
      const hasExtentLayers = deepLayersCountInGroup([element], true) > 0
      const result: MenuTreeItem[] = [{
        /* label: this.t('maps.layers.tree.menu.showInfo'),
        handler: this.openInfoDialog,
        icon: imgInfo,
        args: [element],
        isHidden: !this.isAllowEditGroup
      }, { */
        label: this.t('maps.layers.tree.menu.rename'),
        handler: this.toggleRename,
        icon: imgEdit,
        args: [element],
        isHidden: !this.isAllowEditGroup
      }, {
        label: this.t('maps.layers.tree.menu.ungroup'),
        handler: this.openUngroupConfirm,
        icon: imgTransp,
        args: [element],
        isHidden: !this.isAllowEditGroup || !hasChildren
      }, {
        label: this.t('maps.layers.tree.menu.setColor'),
        handler: this.openColorPicker,
        icon: imgEdit2,
        args: [element],
        isHidden: !this.isAllowEditGroup
      }, {
        label: this.t('maps.layers.tree.menu.flyToGroup'),
        handler: this.flyToGroup,
        icon: imgScale,
        args: [element],
        isHidden: !hasExtentLayers
      }, {
        label: this.t('maps.layers.tree.menu.remove-group'),
        icon: imgDelete,
        handler: this.openDeleteConfirm,
        args: [element],
        isHidden: !this.isAllowEditGroup
      }]
      return result.filter(x => !x.isHidden)
    },

    menuItemsPresentation (element: GeoMapLayergroup) {
      const hasExtentLayers = deepLayersCountInGroup([element], true) > 0
      const result: MenuTreeItem[] = [{
        label: this.t('maps.layers.tree.menu.flyToGroup'),
        handler: this.flyToGroup,
        icon: imgScale,
        args: [element],
        isHidden: !hasExtentLayers
      }]
      return result.filter(x => !x.isHidden)
    },

    flyToGroup (element: GeoMapLayergroup) {
      appToast.clear()
      if (element.layers.some(v => is3dLayer(v)) && (!baykalskStore.state.baykalsk.is3dEnabled || !this.is3dPresentationMode)) {
        if (!baykalskStore.state.baykalsk.is3dEnabled && !this.isPresentation) {
          appToast.warning(ToastTo3D)
          return
        } else if (this.isPresentation && !this.is3dPresentationMode) {
          appToast.warning('Недоступно в презентации 2Д проекта')
          return
        }
      }
      this.$emit('flyToGroup', element)
    },

    ungroupLayers () {
      this.showConfirmUngroup = false
      this.$emit('ungroupLayers', [this.parentGroup, this.activeGroup])
    },

    setVisible (id: number) {
      if (!this.expandedGroups.includes(id)) {
        store.commit(MapsMutationTypes.SET_MAP_GROUPS_VISIBLE, [...this.expandedGroups, id])
      } else if (this.expandedGroups.includes(id)) {
        store.commit(MapsMutationTypes.SET_MAP_GROUPS_VISIBLE, this.expandedGroups.filter(x => x !== id))
      }
    },
    checkVisibility (id: number) {
      return this.expandedGroups.includes(id)
    },
    openDeleteConfirm (element: GeoMapLayergroup) {
      this.showConfirmDelete = true
      this.activeGroup = element
    },
    openUngroupConfirm (element: GeoMapLayergroup) {
      this.showConfirmUngroup = true
      this.activeGroup = element
    },
    async deleteGroup () {
      try {
        if (this.isLoading || !this.activeGroup) return
        this.isLoading = true
        await store.dispatch(MapsActionTypes.DELETE_GROUP, Number(this.activeGroup.id))
        this.activeGroup = null
        toast.success(this.t('maps.layers.tree.menu.successGroupDeleted'))
      } catch {
        toast.error(this.t('maps.layers.tree.menu.removeGroupError'))
      } finally {
        this.showConfirmDelete = false
        this.isLoading = false
      }
    },
    openColorPicker (element: GeoMapLayergroup) {
      if (this.setColorRef) {
        this.activeGroup = element
        this.selectedColor = this.activeGroup.colorCode || ''
        this.setColorRef.click()
      }
    },
    hideRenameGroupName () {
      this.isRenameActive = false
      this.renamedGroupName = ''
    },
    editGroupHeader () {
      if (this.renamedGroupName.trim().length && this.renamedGroupName !== this.activeGroup?.name) {
        if (this.isSubmitEdit) return
        this.isSubmitEdit = true

        store.dispatch(MapsActionTypes.UPDATE_GROUP_INFO, {
          itemId: this.activeGroup?.id,
          newName: this.renamedGroupName
        })
          .then(() => {
            this.hideRenameGroupName()
          })
          .catch(() => {
            toast.clear()
            toast.error(this.t('maps.layers.tree.menu.failGroupRename'))
            this.hideRenameGroupName()
          })
          .finally(() => (this.isSubmitEdit = false))
      } else {
        this.hideRenameGroupName()
      }
    }
  }
})
