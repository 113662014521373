import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e68a210"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-tined__header" }
const _hoisted_2 = { class: "modal-tined__close" }
const _hoisted_3 = { class: "modal-tined__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-mask",
    onMousedown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, [
    _createElementVNode("div", {
      class: "modal-tined",
      style: _normalizeStyle({
        width: `${_ctx.width}px`,
        height: `${_ctx.height}px`
      }),
      onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_base_icon, {
            type: "static",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_inline_svg, {
                src: require('@/assets/svg/modal-tined-close.svg')
              }, null, 8, ["src"])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 36)
  ], 32))
}