
import i18n from '@/i18n'
import { defineComponent } from 'vue'

import BaseModal from '@/library/base/modal/BaseModal.vue'
import BaseModalButtonsOkCancel from '@/library/base/modal/BaseModalButtonsOkCancel.vue'
import BaseInput from '@/library/base/BaseInput.vue'

const DEFAULT_DIRECTORY_NAME = i18n.global.t('storage.defaultDirectoryName')

export default defineComponent({
  components: {
    BaseModalButtonsOkCancel,
    BaseInput,
    BaseModal
  },

  emits: ['cancel', 'confirm'],

  data: () => ({
    newDirectoryName: DEFAULT_DIRECTORY_NAME
  }),

  methods: {
    $t: i18n.global.t,

    cancel () {
      this.newDirectoryName = DEFAULT_DIRECTORY_NAME
      this.$emit('cancel')
    },

    confirm () {
      this.$emit('confirm', this.newDirectoryName)
    }
  }
})
