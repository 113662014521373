export enum IconType {
  ACTION = 'action',
  ERROR = 'error',
  TOGGLE = 'toggle',
  STATIC = 'static'
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export enum ButtonIconPosition {
  NO = 'no',
  LEFT = 'left',
  RIGHT = 'right',
  ICON_ONLY = 'icon-only'
}

export enum ElementSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big'
}

export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password'
}

export enum CheckboxType {
  ICON_ONLY = 'icon-only',
  WITH_TEXT = 'with-text',
  WITH_BORDER = 'with-border'
}
