import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ed66d5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-menu-item__name" }
const _hoisted_2 = {
  key: 1,
  class: "base-menu-item__arrow"
}
const _hoisted_3 = {
  key: 0,
  class: "base-menu-item__list-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "itemRef",
    class: _normalizeClass(["base-menu-item", { disabled: _ctx.disabled }]),
    onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event))),
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    (_ctx.iconSrc)
      ? (_openBlock(), _createBlock(_component_base_icon, {
          key: 0,
          src: _ctx.iconSrc,
          mirrored: _ctx.iconMirrored,
          "rotate-angle": _ctx.iconRotateAngle,
          class: "base-menu-item__icon"
        }, null, 8, ["src", "mirrored", "rotate-angle"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "name", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.name), 1)
      ], true)
    ]),
    (_ctx.$slots.list)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_base_icon, {
            src: _ctx.imgArrow,
            class: "layer-item__header-actions-btn"
          }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "list",
      class: "base-menu-item__list",
      style: _normalizeStyle({ top: `${_ctx.topOffset}px` })
    }, [
      _createVNode(_Transition, { name: "slide" }, {
        default: _withCtx(() => [
          (_ctx.showList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "list", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ], 4)
  ], 34))
}