
import i18n from '@/i18n'
import { defineComponent, inject, ref } from 'vue'
import { Point } from 'ol/geom'
import { fromLonLat, toLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import VectorLayerLayer from 'ol/layer/Vector'
import Feature from 'ol/Feature'
import { Style, Icon } from 'ol/style'
import { Coordinate, toStringHDMS } from 'ol/coordinate'
import Modify, { ModifyEvent } from 'ol/interaction/Modify'

import OlFeature from '../ol-map/feature/OlFeature.vue'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import BaseWindow from '@/library/base/BaseWindow.vue'
import BasePanel from '@/library/base/BasePanel.vue'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseSelect from '@/library/base/BaseSelect.vue'
import BaseButton from '@/library/base/BaseButton.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import BaseTextSwitch from '@/library/base/BaseTextSwitch.vue'
import { LocationProps } from '@/library/types'
import { copyToClipboard, roundAfterDecimalPoint } from '@/library/helpers'
import { SizeType, IconType, TextType } from '@/library/types/base/enums'
import { OlMapKey } from './../../olMap'
import { MapActiveToolType, CoordinateFormat } from '@/library/types/maps/enums'
import { ActionTopRightKey } from './../../../actions/symbol'

import imgLocation from '@/library/assets/img/icLocation.svg'
import imgMarkerIcon from '@/library/assets/img/marker.svg'
import Overlay from 'ol/Overlay'

import { msk38Converter } from '@/helpers/index'

export default defineComponent({
  components: {
    BaseTextSwitch,
    BaseWindow,
    BasePanel,
    BaseHeader,
    BaseSelect,
    BaseButton,
    BaseIcon
  },

  setup () {
    const ActionTopRight = inject(ActionTopRightKey)
    return {
      ActionTopRight,
      imgLocation,
      SizeType,
      IconType,
      TextType
    }
  },

  data () {
    const errors: Record<string, string> = {}
    const markerLayer = undefined as VectorLayerLayer<VectorSource<Point>> | undefined
    const markerOverlay = undefined as InstanceType<typeof Overlay> | undefined
    const dragInteraction = undefined as InstanceType<typeof Modify> | undefined
    const firstProjection = 'EPSG:4326'
    const Msk38Projection = '+proj=tmerc +lat_0=0 +lon_0=103.03333333333 +k=1 +x_0=3250000 +y_0=-5411057.63 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs'
    return {
      isOpenedPanel: false,
      isDragging: false,
      selectedMode: 'define',
      panelLocation: {
        top: undefined as number | undefined,
        left: undefined as number | undefined
      },
      dragInteraction,
      firstProjection,
      Msk38Projection,
      fields: {
        latitude: {
          decimal: '0',
          msk38: '0',
          degress: '0',
          minutes: '0',
          seconds: '0'
        },
        longitude: {
          decimal: '0',
          msk38: '0',
          degress: '0',
          minutes: '0',
          seconds: '0'
        }
      },
      selectedFormat: CoordinateFormat.DEGREES_MINUTES_SECONDS,
      OlMap: inject(OlMapKey),
      markerOverlay,
      markerLayer,
      errors
    }
  },

  computed: {
    formattedCoordinates (): Record<string, string>[] {
      const isMsk38 = this.selectedFormat === CoordinateFormat.MSK38
      const withSeconds = this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS
      return [{
        label: this.$t('maps.location.pointLat'),
        text: this.parseFormatCoordinate('latitude', withSeconds, isMsk38)
      }, {
        label: this.$t('maps.location.pointLon'),
        text: this.parseFormatCoordinate('longitude', withSeconds, isMsk38)
      }]
    },

    columns () {
      if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
        return ['label', 'degress', 'minutes', 'seconds']
      } else if (this.selectedFormat === CoordinateFormat.MSK38) {
        return ['label', 'msk38']
      } else {
        return ['label', 'decimal']
      }
    },

    units (): Record<string, string> {
      return {
        decimal: '°',
        msk38: '°',
        degress: '°',
        minutes: '\'',
        seconds: '"'
      }
    },

    modeButtons () {
      return {
        define: this.$t('maps.location.switchDefine'),
        goto: this.$t('maps.location.switchGoto')
      }
    },

    formatOptions () {
      return [{
        key: CoordinateFormat.DEGREES_MINUTES_SECONDS,
        value: this.$t('maps.location.degressWithSeconds')
      }, {
        key: CoordinateFormat.MSK38,
        value: 'МСК38'
      }, {
        key: CoordinateFormat.DECIMAL_DEGRESS,
        value: this.$t('maps.location.decimalDegress')
      }]
    },

    isActive (): boolean {
      if (this.ActionTopRight) {
        return this.ActionTopRight.activeAction === MapActiveToolType.MAP_LOCATION
      }
      return false
    }
  },

  watch: {
    isActive (state) {
      if (!state) {
        this.closePanel()
      }
    }
  },

  mounted () {
    window.addEventListener('resize', this.recalcFormPosition)
    const currentMap = this.OlMap?.map
    if (currentMap) {
      currentMap.on('click', this.handlerMapClick)
      currentMap.on('pointermove', this.handlerMapPointerMove)
    }
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.recalcFormPosition)
    const currentMap = this.OlMap?.map
    if (currentMap) {
      currentMap.un('click', this.handlerMapClick)
      currentMap.un('pointermove', this.handlerMapPointerMove)
    }
    this.closePanel()
  },

  methods: {
    $t: i18n.global.t,

    handlerMapClick (event: MapBrowserEvent<MouseEvent>) {
      if (this.isActive) {
        if (this.isOpenedPanel) {
          this.updateCoordinates(event.coordinate)
        } else {
          this.openMenu(event.coordinate)
        }
      }
    },

    handlerMapPointerMove (event: MapBrowserEvent<MouseEvent>) {
      if (this.isActive && !this.isOpenedPanel) {
        this.createCursor(event.coordinate)
      }
      if (this.isDragging) {
        this.setOverlayPosition(event.coordinate)
      }
    },

    handleClick () {
      if (!this.ActionTopRight) {
        return
      }
      if (this.isActive) {
        this.ActionTopRight.activateAction(null)
      } else {
        this.ActionTopRight.activateAction(MapActiveToolType.MAP_LOCATION)
      }
    },

    updateCoordinates (coordinate: Coordinate, withMove?: boolean) {
      const currentMap = this.OlMap?.map
      if (currentMap) {
        const source = this.markerLayer?.getSource && this.markerLayer.getSource()
        if (source) {
          source.forEachFeature(feature => {
            (feature.getGeometry() as Point).setCoordinates(coordinate)
          })
        }
        this.defineLocation(coordinate)
        if (this.markerOverlay) {
          this.markerOverlay.setPosition(coordinate)
        }
        if (withMove) {
          currentMap.getView().setCenter(coordinate)
        }
      }
    },

    copyCoordinates () {
      const coords = this.formattedCoordinates.map(item => item.text)
      copyToClipboard(coords.join(' '))
    },

    parseFormatCoordinate (category: 'latitude' | 'longitude', degressWithSeconds?: boolean, isMsk38?: boolean) {
      const currentMap = this.OlMap?.map
      const source = this.markerLayer?.getSource && this.markerLayer.getSource()
      if (currentMap && source) {
        let center: number[] = []
        source.forEachFeature(feature => {
          center = (feature.getGeometry() as Point).getCoordinates()
        })
        if (Array.isArray(center) && center.length === 2) {
          let coords = toLonLat(center)
          if (degressWithSeconds) {
            coords = coords.map(item => roundAfterDecimalPoint(item, 5))
            const hdms = toStringHDMS(coords).split(' ')
            let args: string[]
            if (category === 'latitude') {
              args = hdms.slice(0, 3)
              if (coords[1] < 0) {
                args[0] = String(-Math.abs(parseFloat(args[0]))) + '°'
              }
            } else {
              const lonStart = hdms.length === 6 ? 3 : 4
              args = hdms.slice(lonStart, lonStart + 3)
              if (coords[0] < 0) {
                args[0] = String(-Math.abs(parseFloat(args[0]))) + '°'
              }
            }
            return args.join(' ')
          } else if (!isMsk38) {
            const newValue = category === 'latitude' ? coords[1] : coords[0]
            return String(roundAfterDecimalPoint(newValue, 5))
          } else {
            const newValue = category === 'latitude' ? this.fields.latitude.msk38 : this.fields.longitude.msk38
            return String(roundAfterDecimalPoint(newValue, 2))
          }
        }
      }
      return ''
    },

    getCellText (rowData: Record<string, string>, rowId: string, columnId: string) {
      if (columnId === 'label') {
        return this.$t(`maps.location.${rowId}`)
      } else {
        return String(rowData[columnId]) + this.units[columnId]
      }
    },

    getMinValue (rowId: string, columnId: string) {
      if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
        if (rowId === 'latitude') {
          return ['degress', 'decimal'].includes(columnId) ? -180 : 0
        } else {
          return ['degress', 'decimal'].includes(columnId) ? -90 : 0
        }
      } else {
        return rowId === 'latitude' ? -180 : -90
      }
    },

    getMaxValue (rowId: string, columnId: string) {
      if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
        if (rowId === 'latitude') {
          return ['degress', 'decimal'].includes(columnId) ? 180 : 59
        } else {
          return ['degress', 'decimal'].includes(columnId) ? 90 : 59
        }
      } else {
        return rowId === 'latitude' ? 180 : 180// 180 : 90 by Geohub
      }
    },

    getCellValue (rowData: Record<string, string>, columnId: string) {
      return rowData[columnId]
    },

    updateCellValue (info: Record<string, string>,
      rowId: string, columnId: string, value: string | Event
    ) {
      const uniqueKey = `${rowId}_${columnId}`
      if (this.errors[uniqueKey]) {
        delete this.errors[uniqueKey]
      }
      if (value instanceof Event) {
        const target = value.target as HTMLInputElement
        value = target.value
      }
      const presentedValue = Number(value)
      info[columnId] = value
      if (
        columnId !== 'msk38' &&
        (Number.isNaN(presentedValue) ||
        presentedValue < this.getMinValue(rowId, columnId) ||
        presentedValue > this.getMaxValue(rowId, columnId))
      ) {
        this.errors[uniqueKey] = 'error'
      } else if (columnId === 'msk38' && Number.isNaN(presentedValue)) {
        this.errors[uniqueKey] = 'error'
      } else {
        if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
          const degress = Number(info.degress)
          const minutes = Math.abs(Number(info.minutes))
          const seconds = Math.abs(Number(info.seconds))
          const decimal = degress + minutes / 60 + seconds / 3600
          info.decimal = String(roundAfterDecimalPoint(decimal, 5))
        } else if (this.selectedFormat === CoordinateFormat.MSK38) {
          if (rowId === 'latitude') info.decimal = msk38Converter(true, [Number(this.fields.longitude.msk38), Number(info.msk38)])[1]
          if (rowId === 'longitude') info.decimal = msk38Converter(true, [Number(info.msk38), Number(this.fields.latitude.msk38)])[0]
        } else {
          const coords = [
            Number(this.fields.latitude.decimal),
            Number(this.fields.longitude.decimal)
          ]
          const hdms = toStringHDMS(coords).split(' ')
          const lonStart = hdms.length === 6 ? 3 : 4
          const start = rowId === 'latitude' ? 0 : lonStart
          const args = hdms.slice(start, start + 3)
          this.parseHDMSString((rowId as 'latitude' | 'longitude'), coords, args)
          /* write msk38 to info */
          if (rowId === 'latitude') info.msk38 = roundAfterDecimalPoint(msk38Converter(false, [Number(this.fields.longitude.decimal), Number(info.decimal)])[1], 2)
          if (rowId === 'longitude') info.msk38 = roundAfterDecimalPoint(msk38Converter(false, [Number(info.decimal), Number(this.fields.latitude.decimal)])[0], 2)
        }
      }
    },

    recalcFormPosition () {
      const ref = this.$refs.action as InstanceType<typeof BaseIcon> | null
      if (ref) {
        const rect = (ref.$el as HTMLElement).getBoundingClientRect()
        this.panelLocation.left = rect.x - 470
        this.panelLocation.top = rect.y
      }
    },

    async openMenu (coordinate: Coordinate) {
      this.selectedFormat = CoordinateFormat.DEGREES_MINUTES_SECONDS
      this.recalcFormPosition()
      this.isOpenedPanel = true
      this.defineLocation(coordinate)
      await this.$nextTick()
      this.extendMarker(coordinate)
    },

    defineLocation (center: number[]) {
      const currentMap = this.OlMap?.map
      if (currentMap && Array.isArray(center) && center.length === 2) {
        const coords = toLonLat(center)
        const hdms = toStringHDMS(coords).split(' ')
        const lonStart = hdms.length === 6 ? 3 : 4
        this.parseHDMSString('latitude', coords, hdms.slice(0, 3))
        this.parseHDMSString('longitude', coords, hdms.slice(lonStart, lonStart + 3))
        const latitude = roundAfterDecimalPoint(coords[1], 5)
        const longitude = roundAfterDecimalPoint(coords[0], 5)
        this.fields.latitude.decimal = String(latitude)
        this.fields.longitude.decimal = String(longitude)

        const innerMsk38Value = ref(msk38Converter(false, coords.slice()))
        const latitudeMsk38 = roundAfterDecimalPoint(innerMsk38Value.value[1], 2)
        const longitudeMsk38 = roundAfterDecimalPoint(innerMsk38Value.value[0], 2)
        this.fields.latitude.msk38 = String(latitudeMsk38)
        this.fields.longitude.msk38 = String(longitudeMsk38)
      }
    },

    parseHDMSString (category: 'latitude' | 'longitude', coords: number[], args: string[]) {
      ['degress', 'minutes', 'seconds'].forEach((property, index) => {
        let value = parseFloat(args[index])
        if (property === 'degress') {
          if (category === 'latitude' && coords[1] < 0) {
            value = -(value)
          } else if (category === 'longitude' && coords[0] < 0) {
            value = -(value)
          }
        }
        const newValue = !Number.isNaN(value) ? String(value) : '0'
        this.fields[category][property as LocationProps] = newValue
      })
    },

    setOverlayPosition (coordinate: number[]) {
      if (this.markerOverlay) {
        this.markerOverlay.setPosition(coordinate)
      }
    },

    createCursor (coordinate: number[]) {
      const currentMap = this.OlMap?.map
      if (currentMap) {
        if (this.markerLayer) {
          currentMap.removeLayer(this.markerLayer as VectorLayerLayer<VectorSource<Point>>)
        }
        const dotMarker = new Feature({
          type: 'icon',
          geometry: new Point(coordinate)
        })
        this.markerLayer = new VectorLayerLayer({
          source: new VectorSource({
            features: [dotMarker]
          }),
          style: new Style({
            image: new Icon({
              src: imgMarkerIcon,
              anchor: [0.5, 1],
              scale: 1
            })
          }),
          zIndex: 10000
        })
        currentMap.addLayer(this.markerLayer as VectorLayerLayer<VectorSource<Point>>)
      }
    },

    extendMarker (coordinate: number[]) {
      const currentMap = this.OlMap?.map
      const overlayNode = this.$refs.overlayMarker as HTMLElement
      if (currentMap && overlayNode) {
        this.markerOverlay = new Overlay({
          element: overlayNode,
          offset: [0, -170],
          position: coordinate
        })
        this.dragInteraction = new Modify({
          source: this.markerLayer?.getSource() || undefined
        })
        this.dragInteraction.on('modifystart', () => {
          this.isDragging = true
        })
        this.dragInteraction.on('modifyend', (e: ModifyEvent) => {
          const feature = e.features.getArray()[0] as unknown as typeof OlFeature
          this.defineLocation(feature.getGeometry().getCoordinates())
          this.isDragging = false
        })
        currentMap.addInteraction(this.dragInteraction as Modify)
        currentMap.addOverlay(this.markerOverlay as Overlay)
      }
    },

    deleteMarker () {
      const currentMap = this.OlMap?.map
      if (currentMap) {
        if (this.markerLayer) {
          currentMap.removeLayer(this.markerLayer as VectorLayerLayer<VectorSource<Point>>)
        }
        if (this.markerOverlay) {
          currentMap.removeOverlay(this.markerOverlay as Overlay)
        }
        if (this.dragInteraction) {
          currentMap.removeInteraction(this.dragInteraction as Modify)
        }
      }
    },

    closePanel () {
      this.deleteMarker()
      this.isOpenedPanel = false
    },

    applyCoordinates () {
      if (Object.keys(this.errors).length === 0) {
        const coordinates = [
          Number(this.fields.latitude.decimal),
          Number(this.fields.longitude.decimal)
        ]
        this.updateCoordinates(fromLonLat(coordinates.reverse()), true)
      }
    }
  }
})
