import { ActionTree, ActionContext } from 'vuex'

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store'

import { State } from './state'
import { Mutations } from './mutations'
import { AuthMutationTypes } from './mutation-types'
import { AuthActionTypes } from './action-types'
import {
  UserSelfModel,
  EditSelfPayload
} from '@/types/auth/types'

import api from '@/api'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AuthActionTypes.GET_SELF]({ commit, state }: AugmentedActionContext, payload: null): Promise<UserSelfModel>,
  [AuthActionTypes.EDIT_SELF]({ commit, state }: AugmentedActionContext, payload: EditSelfPayload): Promise<UserSelfModel>
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [AuthActionTypes.GET_SELF] ({ commit }) {
    return api.get<UserSelfModel>('/users/self')
      .then(({ data }) => {
        commit(AuthMutationTypes.SET_USER, data)
        return data
      })
  },

  async [AuthActionTypes.EDIT_SELF] ({ commit }, payload: EditSelfPayload) {
    return api.put<UserSelfModel>('/users/self', payload)
      .then(({ data }) => {
        commit(AuthMutationTypes.SET_USER, data)
        return data
      })
  }
}
