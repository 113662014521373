
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: { type: String, default: '#000' },
    disabled: { type: Boolean, default: false }
  },

  emits: ['update:modelValue'],

  data: () => ({
    color: '' as string
  }),

  watch: {
    modelValue (newColor: string) {
      this.color = newColor
    }
  },

  mounted () {
    this.color = this.modelValue
  },

  methods: {
    openPicker () {
      const picker = this.$refs?.picker as HTMLInputElement
      picker.click()
    },

    handlerInput (event: Event) {
      const target = event.target as HTMLInputElement
      this.$emit('update:modelValue', target.value)
    }
  }
})
