
import i18n from '@/i18n'
import { defineComponent, inject, computed } from 'vue'

import BaseIcon from '@/library/base/BaseIcon.vue'
import BasePanel from '@/library/base/BasePanel.vue'
import BaseLoading from '@/library/base/BaseLoading.vue'
import BaseButton from '@/library/base/BaseButton.vue'
import BaseWindow from '@/library/base/BaseWindow.vue'
import BaseCheckbox from '@/library/base/BaseCheckbox.vue'
import LayerInfoWindow from './info/LayerInfoWindow.vue'
import GroupInfoWindow from './info/GroupInfoWindow.vue'
import LayerExportWindow from '@/library/maps/viewer/shared/modal/LayerExportWindow.vue'
import { useStore } from '@/library/store'
import LayersHeader from './LayersHeader.vue'
import GroupItem from './GroupItem.vue'
import {
  WindowStretchType,
  ButtonType,
  IconType,
  SizeType,
  TextType
} from '@/library/types/base/enums'
import {
  GeoMapLayer,
  GeoMapLayergroup,
  ExpressionCondition,
  MapBoxStyle,
  ColorField,
  LightweightGroupStructure,
  StyleCopyPayload
} from '@/library/types'
import { convertVhToPx, getFlatList, isLayer, isGroup, recursiveGetLayer } from '@/library/helpers'
import { MapsActionTypes } from '@/library/store/modules/maps/action-types'
import { MapsMutationTypes } from '@/library/store/modules/maps/mutation-types'
import toast from '@/library/toast'

import imgArrow from '@/library/assets/img/icArrow.svg'
import imgLayers from '@/library/assets/img/icLayers.svg'
import { LayerActiveToolType, TreeSelectType } from '@/library/types/maps/enums'
import { Map2DKey, Map3DKey } from '../symbol'

const store = useStore()

const HEADER_HEIGHT = 54
const ITEM_HEIGHT = 44
const ITEM_ATTRIBUTE_HEIGHT = 24

export default defineComponent({
  components: {
    LayerExportWindow,
    LayerInfoWindow,
    GroupInfoWindow,
    LayersHeader,
    BaseLoading,
    GroupItem,
    BaseIcon,
    BasePanel,
    BaseButton,
    BaseCheckbox,
    BaseWindow
  },
  props: {
    isOn3d: {
      type: Boolean,
      default: false
    },
    isPresentation: {
      type: Boolean,
      default: false
    },
    is3dPresentationMode: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'flyToLayer',
    'flyToGroup'
  ],
  setup () {
    const map2d = inject(Map2DKey)
    const map3d = inject(Map3DKey)

    const mapProvider = computed(() => {
      return map2d?.value || map3d?.value
    })

    return {
      WindowStretchType,
      ButtonType,
      SizeType,
      TextType,
      IconType,
      HEADER_HEIGHT,
      mapProvider
    }
  },
  data () {
    return {
      windowRect: {},
      hidden: false,
      isLoading: false,
      isOpenedLayerInfo: false,
      isOpenedGroupInfo: false,
      isOpenedLayerExport: false,
      updateTimer: 0,
      windowWidth: 310,
      resizedWindowHeight: 0,
      openedLayer: null as GeoMapLayer | null,
      openedGroup: null as GeoMapLayergroup | null,
      maxWindowHeight: convertVhToPx(85),
      imgArrow,
      imgLayers
    }
  },
  computed: {
    selectedGroupsIds: {
      get () {
        return store.state.maps.selectedGroups
      },
      set (newIds: number[]) {
        store.commit(MapsMutationTypes.SET_GROUPS_SELECTED, newIds)
      }
    },

    selectedLayersIds: {
      get () {
        return store.state.maps.selectedLayers
      },
      set (newIds: number[]) {
        store.commit(MapsMutationTypes.SET_LAYERS_SELECTED, newIds)
      }
    },

    countSelectedItems () {
      return this.selectedLayersIds.length + this.selectedGroupsIds.length
    },

    isEnabledMultipleSelect () {
      return store.state.maps.multipleTreeSelect.isEnable
    },

    isDragDisable () {
      return store.state.maps.multipleTreeSelect.isEnable && store.state.maps.multipleTreeSelect.type !== TreeSelectType.GROUPS_DRAG
    },

    isEnabledMultipleSelectDelete: {
      get () {
        return store.state.maps.multipleTreeSelect.isEnable && store.state.maps.multipleTreeSelect.type === TreeSelectType.DELETE
      },
      set (newState: boolean) {
        store.commit(MapsMutationTypes.SET_MULTIPLE_TREE_SELECT, { isEnable: newState, type: TreeSelectType.DELETE })

        if (this.mapProvider) {
          if (newState) {
            this.mapProvider.setLayerActiveTool(LayerActiveToolType.MULTIPLE_SELECT)
          } else {
            this.mapProvider.setLayerActiveTool(LayerActiveToolType.CLICK)
          }
        }
      }
    },

    isEnabledMultipleSelectStyleCopy: {
      get () {
        return store.state.maps.multipleTreeSelect.isEnable && store.state.maps.multipleTreeSelect.type === TreeSelectType.STYLE_COPY
      },
      set (newState: boolean) {
        store.commit(MapsMutationTypes.SET_MULTIPLE_TREE_SELECT, { isEnable: newState, type: TreeSelectType.STYLE_COPY })

        if (this.mapProvider) {
          if (newState) {
            this.mapProvider.setLayerActiveTool(LayerActiveToolType.MULTIPLE_SELECT)
          } else {
            this.mapProvider.setLayerActiveTool(LayerActiveToolType.CLICK)
          }
        }
      }
    },

    isEnabledMultipleSelectMove: {
      get () {
        return store.state.maps.multipleTreeSelect.isEnable && store.state.maps.multipleTreeSelect.type === TreeSelectType.GROUPS_DRAG
      },
      set (newState: boolean) {
        if (newState) {
          store.commit(MapsMutationTypes.SET_MULTIPLE_TREE_SELECT, { isEnable: true, type: TreeSelectType.GROUPS_DRAG })
        } else {
          store.commit(MapsMutationTypes.SET_MULTIPLE_TREE_SELECT, { isEnable: false, type: TreeSelectType.GROUPS_DRAG })
        }

        if (this.mapProvider) {
          if (newState) {
            this.mapProvider.setLayerActiveTool(LayerActiveToolType.MULTIPLE_SELECT)
          } else {
            this.mapProvider.setLayerActiveTool(LayerActiveToolType.CLICK)
          }
        }
      }
    },

    styleCopyLayerName () {
      if (!this.rootGroup || !this.isEnabledMultipleSelectStyleCopy || !store.state.maps.multipleTreeSelect.layerId) {
        return ''
      }
      const scLayer = recursiveGetLayer(this.rootGroup, store.state.maps.multipleTreeSelect.layerId)
      return scLayer?.name ?? ''
    },

    totalTreeItems () {
      return this.rootGroup ? getFlatList(this.rootGroup) : []
    },

    isTotalSelected: {
      get () {
        return this.countSelectedItems > 0 && this.countSelectedItems === this.totalTreeItems.length
      },
      set (state: boolean) {
        if (state) {
          this.selectedGroupsIds = this.totalTreeItems.filter(item => isGroup(item)).map(item => item.id)
          this.selectedLayersIds = this.totalTreeItems.filter(item => isLayer(item)).map(item => item.id)
        } else {
          this.selectedGroupsIds = []
          this.selectedLayersIds = []
        }
      }
    },

    expandedLayers () {
      return store.state.maps.expandedLayers
    },

    windowHeight () {
      if (this.resizedWindowHeight) {
        return this.resizedWindowHeight
      }
      if (!store.state.maps.map) {
        return this.maxWindowHeight
      }
      const items = this.getOpenedFlatList(store.state.maps.map.groups)
      let totalLength = items.length * ITEM_HEIGHT + HEADER_HEIGHT + 15
      items.forEach(item => {
        if (isLayer(item) && this.expandedLayers.includes(item.id)) {
          const visibleAttributesCount = this.parseMapboxAttributeValues(item)
          if (visibleAttributesCount > 1) {
            totalLength += (visibleAttributesCount * ITEM_ATTRIBUTE_HEIGHT)
          }
        }
      })
      if (store.state.maps.multipleTreeSelect.isEnable) {
        totalLength += ITEM_HEIGHT
      }
      return totalLength > this.maxWindowHeight ? this.maxWindowHeight : totalLength
    },
    rootGroup () {
      return store.state.maps.map?.groups ?? null
    },
    getMapRootGroup () {
      if (!store.state.maps.map) {
        return []
      }

      if (this.$props.isOn3d) {
        /* this is temporary fix to hide some layres on 3d map layers list */
        const disabledIdsList = [613]
        const layers = store.state.maps.map.groups.layers.filter(item => !disabledIdsList.some(v => item.id === v))
        const groups = JSON.parse(JSON.stringify(store.state.maps.map.groups.groups))
        groups.forEach(group => {
          group.layers = group.layers.filter(item => !disabledIdsList.some(v => item.id === v))
          group.layersIds = group.layersIds.filter(item => !disabledIdsList.some(v => item === v))
        })
        return [...groups, ...layers].sort((a, b) => b.zIndex - a.zIndex)
      }

      const groups = store.state.maps.map.groups.groups
      const layers = store.state.maps.map.groups.layers
      return [...groups, ...layers].sort((a, b) => b.zIndex - a.zIndex)
    }
  },

  watch: {
    hidden () {
      this.refreshWindowRect()
    }
  },

  mounted () {
    this.refreshWindowRect()
    window.addEventListener('resize', this.handlerAppResize)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.handlerAppResize)
  },

  methods: {
    $t: i18n.global.t,

    handlerOpenGroupInfo (group: GeoMapLayergroup) {
      this.openedGroup = group
      this.isOpenedGroupInfo = true
    },

    handlerOpenLayerInfo (layer: GeoMapLayer) {
      this.openedLayer = layer
      this.isOpenedLayerInfo = true
    },

    handlerOpenExportWindow (layer: GeoMapLayer) {
      this.openedLayer = layer
      this.isOpenedLayerExport = true
    },

    parseMapboxAttributeValues (layer: GeoMapLayer) {
      const attributes = new Set<string | number>()
      const style = layer?.style?.mboxStyle
      if (style && Array.isArray(style.layers) && style.layers.length) {
        style.layers.forEach(styleLayer => {
          if (styleLayer.paint) {
            let values: ColorField | ExpressionCondition | number | undefined
            if (styleLayer.type === 'fill') {
              values = styleLayer.paint['fill-color']
            } else if (styleLayer.type === 'circle') {
              values = styleLayer.paint['circle-color']
            } else if (styleLayer.type === 'line') {
              values = styleLayer.paint['line-color']
              if (!Array.isArray(values)) {
                values = styleLayer.paint['line-width']
              }
            }
            if (values && Array.isArray(values)) {
              const startPosition = values[0] === 'case' ? 1 : 2
              const stepMode = values[0] === 'step'
              for (let i = startPosition; i < values.length - 2; i += 2) {
                let attributeValue: string
                const condition = (stepMode ? values[i + 1] : values[i]) as ExpressionCondition
                if (Array.isArray(condition) && condition.length > 1 && condition[1].length === 3) {
                  attributeValue = String(condition[1][2])
                } else {
                  attributeValue = String(stepMode ? values[i + 1] : values[i])
                }
                attributes.add(attributeValue)
              }
            }
          }
        })
      }
      return attributes.size > 6 ? 6 : attributes.size
    },

    async ungroupLayers (groups: [GeoMapLayergroup, GeoMapLayergroup]) {
      try {
        if (this.isLoading) return
        this.isLoading = true
        const [parent, origin] = groups
        const otherGroups = origin.groups.slice().filter(item => item.id !== origin.id)
        parent.layers = [...parent.layers, ...origin.layers.slice()]
        parent.groups = [...parent.groups, ...otherGroups]
        origin.layers = []
        origin.groups = []
        origin.layersIds = []
        origin.groupsIds = []
        store.commit(MapsMutationTypes.UPDATE_MAP_GROUP, parent)
        await this.updateRootGroup()
        await store.dispatch(MapsActionTypes.DELETE_GROUP, origin.id)
      } catch {
        toast.error(this.$t('maps.layers.tree.menu.ungroupError'))
      } finally {
        this.isLoading = false
      }
    },

    async deleteLayers () {
      if (this.isLoading) {
        return
      }
      try {
        this.isLoading = true
        if (this.selectedGroupsIds.length) {
          await store.dispatch(MapsActionTypes.DELETE_GROUPS, this.selectedGroupsIds)
        }
        if (this.selectedLayersIds.length) {
          await store.dispatch(MapsActionTypes.DELETE_LAYERS, this.selectedLayersIds)
        }
        toast.success(this.$t('maps.layers.tree.successMultipleDelete'))
        this.isTotalSelected = false
      } catch {
        toast.error(this.$t('maps.layers.tree.errorMultipleDelete'))
      } finally {
        this.isEnabledMultipleSelectDelete = false
        this.isLoading = false
      }
    },

    async styleCopyApply () {
      if (this.isLoading || !(this.isEnabledMultipleSelectStyleCopy && store.state.maps.multipleTreeSelect.layerId)) {
        return
      }

      try {
        this.isLoading = true
        if (this.selectedLayersIds.length) {
          const payload: StyleCopyPayload = {
            sourceLayerId: store.state.maps.multipleTreeSelect.layerId ?? -1,
            destinationLayersIds: this.selectedLayersIds.filter(x => x !== store.state.maps.multipleTreeSelect.layerId)
          }
          const result = await store.dispatch(MapsActionTypes.LAYER_STYLE_COPY, payload)
          if (result.successLayersIds.length && !result.errorLayersIds.length) {
            toast.success(this.$t('maps.layers.tree.styleCopy.success'))
          } else if (result.errorLayersIds.length) {
            result.errorLayersIds.forEach(layerId => {
              if (this.rootGroup) {
                const layer = recursiveGetLayer(this.rootGroup, layerId)
                if (layer) {
                  toast.error(this.$t('maps.layers.tree.styleCopy.errorLayer', { name: layer?.name }))
                }
              }
            })
          }
        }
        this.isTotalSelected = false
      } catch {
        toast.error(this.$t('maps.layers.tree.styleCopy.error'))
      } finally {
        this.isEnabledMultipleSelectStyleCopy = false
        this.isLoading = false
      }
    },

    handlerAppResize () {
      this.maxWindowHeight = convertVhToPx(85)
    },

    refreshWindowRect () {
      if (this.$refs.container) {
        const container = this.$refs.container as HTMLElement
        if (container.parentElement) {
          this.windowRect = container.parentElement.getBoundingClientRect()
        }
      }
    },

    handlerWindowResize (width: number, height: number) {
      this.windowWidth = width
      if (height <= this.maxWindowHeight) {
        this.resizedWindowHeight = height
      } else {
        this.resizedWindowHeight = this.maxWindowHeight
      }
      this.refreshWindowRect()
    },

    getOpenedFlatList (group: GeoMapLayergroup) {
      if (group.name !== 'root' && !store.state.maps.visibleGroups.includes(group.id)) {
        return []
      }
      const arr = [...group.groups, ...group.layers].sort((a, b) => b.zIndex - a.zIndex)
      return arr.reduce<(GeoMapLayer | GeoMapLayergroup)[]>((flat, item) => {
        flat.push(item)
        if (isGroup(item)) {
          flat = [...flat, ...this.getOpenedFlatList(item)]
        }
        return flat
      }, [])
    },
    saveLayerStyle (args: [GeoMapLayer, MapBoxStyle]) {
      if (args[0].style) {
        args[0].style.mboxStyle = args[1]
      }
    },

    getLightweightStructure (group: GeoMapLayergroup): LightweightGroupStructure {
      const groups = group.groups.map(childGroup => {
        return this.getLightweightStructure(childGroup)
      })
      const layers = group.layers.map(layer => {
        return {
          id: layer.id,
          zIndex: layer.zIndex
        }
      })
      return {
        id: group.id,
        zIndex: group.zIndex,
        groups,
        layers
      }
    },

    updateRootGroup (): Promise<void> {
      this.isLoading = true

      return new Promise((resolve, reject) => {
        if (this.updateTimer) {
          clearTimeout(this.updateTimer)
        }

        this.updateTimer = window.setTimeout(() => {
          if (this.isEnabledMultipleSelectMove) {
            this.isEnabledMultipleSelectMove = false
          }

          this.$nextTick(async () => {
            try {
              if (this.rootGroup) {
                const flatList = getFlatList(this.rootGroup)
                flatList.forEach((item, index) => {
                  item.zIndex = flatList.length - index
                })
                const lightweightStructure = this.getLightweightStructure(this.rootGroup)
                await store.dispatch(MapsActionTypes.MAP_UPDATE_GROUP_ROOT, lightweightStructure)
              }
              resolve()
            } catch (error) {
              if (error instanceof Error && error.message.includes('413')) {
                toast.error(this.$t('base.large-error'))
              } else {
                toast.error(this.$t('maps.layers.styles.errors.apply'))
              }
              reject(error)
            } finally {
              this.isLoading = false
            }
          })
        }, 200)
      })
    }
  }
})
