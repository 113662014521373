import lightTheme from './default.json'
import darkTheme from './dark.json'
import commonStyles from './common.json'
import { ThemeMutationTypes } from '../store/modules/theme/mutation-types'
import { useStore } from '@/library/store'
import { Themes } from '../types/theme/types'
import { ThemeMode } from '../types/theme/enum'
import cssVars from 'css-vars-ponyfill'

const store = useStore()

const setDefault = () : void => {
  store.commit(ThemeMutationTypes.SET_THEMES, {
    light: Object.assign({}, lightTheme, commonStyles),
    dark: Object.assign({}, darkTheme, commonStyles)
  })
}

const setCustoms = (themes: Themes) : void => {
  store.commit(ThemeMutationTypes.SET_THEMES, {
    light: Object.assign({}, lightTheme, themes.light, commonStyles),
    dark: Object.assign({}, darkTheme, themes.dark, commonStyles)
  })
}

const setActiveMode = (themeMode?: ThemeMode): void => {
  const _themeMode = themeMode || localStorage.getItem('theme') || ThemeMode.LIGHT
  store.commit(ThemeMutationTypes.SET_MODE, _themeMode as ThemeMode)
  localStorage.setItem('theme', _themeMode)
  cssVars({
    variables: store.getters.currentTheme
  })
}

const setTheme = (theme: Record<string, string>): void => {
  cssVars({
    variables: Object.assign({}, lightTheme, theme)
  })
}

const themes = {
  setDefault,
  setCustoms,
  setTheme,
  setActiveMode
}

export default themes
