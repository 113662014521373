import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_header = _resolveComponent("base-header")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox", [{'checked': _ctx.checked, 'disabled': _ctx.disabled}, _ctx.size]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args)))
  }, [
    _createVNode(_component_base_icon, {
      size: _ctx.size,
      class: "base-checkbox__icon",
      src: _ctx.imgDone
    }, null, 8, ["size", "src"]),
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_base_header, {
          key: 0,
          title: _ctx.title,
          level: _ctx.textLevel,
          type: _ctx.textType,
          "show-tip": _ctx.showTip,
          class: "base-checkbox__title"
        }, null, 8, ["title", "level", "type", "show-tip"]))
      : _createCommentVNode("", true)
  ], 2))
}