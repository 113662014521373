import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b46082f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-select" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      class: "base-select__common",
      disabled: _ctx.disabled
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
        return (_openBlock(), _createElementBlock("option", {
          key: item.id,
          value: item.id
        }, _toDisplayString(item.name), 9, _hoisted_3))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.innerValue]
    ])
  ]))
}