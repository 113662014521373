
import { defineComponent } from 'vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  components: { BaseIcon, InlineSvg },
  props: {
    title: { type: String, required: true, default: '' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 200 }
  },
  emits: ['close']
})
