
import { defineComponent, PropType } from 'vue'

type BaseSelectItem = {
  id: string | number
  name: string | number
}

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<BaseSelectItem[]>,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:modelValue'
  ],
  data () {
    return {
      innerValue: this.modelValue
    }
  },
  watch: {
    innerValue: function (val) {
      this.$emit('update:modelValue', val)
    },
    modelValue: function (val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    }
  },
  methods: {
  }
})
