
import { defineComponent, inject, computed, PropType } from 'vue'
import {
  ImageryLayer,
  UrlTemplateImageryProvider,
  Resource
} from 'cesium'

import { CViewerKey } from './../symbol'
import { GeoMapLayer } from '@/library/types'
import { useStore } from '@/library/store'

const store = useStore()

export default defineComponent({
  name: 'CTmsProvider',

  props: {
    url: {
      type: Object as PropType<Resource>,
      required: true
    },
    geoLayer: {
      type: Object as PropType<GeoMapLayer | null>,
      required: false,
      default: null
    }
  },

  setup () {
    const viewer = inject(CViewerKey)
    return {
      viewer: computed(() => viewer?.value)
    }
  },

  data: () => ({
    layer: null as ImageryLayer | null
  }),

  computed: {
    alpha () {
      return store.state.maps.layersOpacity[this.geoLayer?.id ?? -1] ?? 1
    }
  },

  watch: {
    viewer () {
      this.reloadLayer()
    },

    alpha (newVal) {
      if (this.layer) {
        this.layer.alpha = newVal
      }
    }
  },

  mounted () {
    this.reloadLayer()
  },

  beforeUnmount () {
    if (this.viewer && this.layer && !this.viewer.isDestroyed()) {
      this.viewer.scene.imageryLayers.remove(this.layer as ImageryLayer)
    }
  },

  methods: {
    reloadLayer () {
      if (this.viewer) {
        const innerUrl = this.url.clone()
        innerUrl.url = this.url.url.replace('{-y}', '{reverseY}')
        this.layer = this.viewer.scene.imageryLayers.addImageryProvider(
          new UrlTemplateImageryProvider({
            url: innerUrl
          })
        )
      }
    }
  }
})
