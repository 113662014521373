import { createStore, createLogger } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

// TODO: How to surpass cyclical dependency linting errors cleanly?
// eslint-disable-next-line import/no-cycle
import { store as maps, MapsStore, State as MapsState } from '@/library/store/modules/maps'
import { store as theme, ThemeStore, State as ThemeState } from '@/library/store/modules/theme'

export type RootState = {
  maps: MapsState;
  theme: ThemeState;
};

export type Store = MapsStore<Pick<RootState, 'maps'>> & ThemeStore<Pick<RootState, 'theme'>>;

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

// Plug in session storage based persistence
// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore<RootState>({
  plugins,
  modules: {
    maps,
    theme
  }
})

export function useStore (): Store {
  return store as Store
}
