
import { defineComponent } from 'vue'

export default defineComponent({
  components: { },
  emits: [],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
})
