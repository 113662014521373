
import i18n from '@/i18n'
import { defineComponent, inject } from 'vue'

import BaseWindow from '@/library/base/BaseWindow.vue'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseSelect from '@/library/base/BaseSelect.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import BaseTextSwitch from '@/library/base/BaseTextSwitch.vue'
// import { LocationProps } from '@/library/types'
// import { copyToClipboard, roundAfterDecimalPoint } from '@/library/helpers'
import { SizeType, IconType, TextType } from '@/library/types/base/enums'
import { MapActiveToolType, CoordinateFormat } from '@/library/types/maps/enums'
import { ActionTopRightKey } from './../../../actions/symbol'

import imgLocation from '@/library/assets/img/icLocation.svg'
import imgMarkerIcon from '@/library/assets/img/marker.svg'
import {
  Cartesian2,
  Cartesian3,
  Cartographic,
  CustomDataSource,
  Entity,
  HeadingPitchRange,
  HeightReference,
  Math,
  Scene,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  VerticalOrigin
} from 'cesium'
import { Map3DKey } from '../../../symbol'
import { roundAfterDecimalPoint } from '@/library/helpers'

import { msk38Converter } from '@/helpers/index'

export default defineComponent({
  components: {
    BaseTextSwitch,
    BaseWindow,
    BaseHeader,
    BaseSelect,
    BaseIcon
  },

  setup () {
    const ActionTopRight = inject(ActionTopRightKey)
    return {
      ActionTopRight,
      imgLocation,
      SizeType,
      IconType,
      TextType
    }
  },

  data () {
    const errors: Record<string, string> = {}
    const markerLayer = new CustomDataSource('maplocation')

    const firstProjection = 'EPSG:4326'
    const Msk38Projection = '+proj=tmerc +lat_0=0 +lon_0=103.03333333333 +k=1 +x_0=3250000 +y_0=-5411057.63 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs'

    return {
      isOpenedPanel: false,
      isDragging: false,
      selectedMode: 'define',
      panelLocation: {
        top: undefined as number | undefined,
        left: undefined as number | undefined
      },
      position: undefined as Cartesian3 | undefined,
      fields: {
        latitude: {
          decimal: 0,
          msk38: '0',
          degress: 0,
          minutes: 0,
          seconds: 0
        },
        longitude: {
          decimal: 0,
          msk38: '0',
          degress: 0,
          minutes: 0,
          seconds: 0
        }
      },
      selectedFormat: CoordinateFormat.DECIMAL_DEGRESS,
      map3d: inject(Map3DKey),
      markerLayer,
      firstProjection,
      Msk38Projection,
      handler: undefined as ScreenSpaceEventHandler | undefined,
      errors
    }
  },

  computed: {
    columns () {
      if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
        return ['label', 'degress', 'minutes', 'seconds']
      } else if (this.selectedFormat === CoordinateType.MSK38) {
        return ['label', 'msk38']
      } else {
        return ['label', 'decimal']
      }
    },

    units (): Record<string, string> {
      return {
        decimal: '°',
        msk38: '°',
        degress: '°',
        minutes: '\'',
        seconds: '"'
      }
    },

    modeButtons () {
      return {
        define: this.$t('maps.location.switchDefine'),
        goto: this.$t('maps.location.switchGoto')
      }
    },

    formatOptions () {
      return [{
        key: CoordinateFormat.DEGREES_MINUTES_SECONDS,
        value: this.$t('maps.location.degressWithSeconds')
      }, {
        key: CoordinateType.MSK38,
        value: 'МСК38'
      }, {
        key: CoordinateFormat.DECIMAL_DEGRESS,
        value: this.$t('maps.location.decimalDegress')
      }]
    },

    isActive (): boolean {
      if (this.ActionTopRight) {
        return this.ActionTopRight.activeAction === MapActiveToolType.MAP_LOCATION
      }
      return false
    }
  },

  watch: {
    isActive (state) {
      if (!state) {
        this.closePanel()
      }
    }
  },

  mounted () {
    this.addLayer()
    window.addEventListener('resize', this.recalcFormPosition)
  },

  beforeUnmount () {
    this.closePanel()
    this.removeLayer()
    window.removeEventListener('resize', this.recalcFormPosition)
  },

  methods: {
    $t: i18n.global.t,

    addLayer () {
      const viewer = this.map3d?.viewer?.viewer

      if (!viewer) {
        return
      }

      viewer.dataSources.add(this.markerLayer)

      this.handler = new ScreenSpaceEventHandler(viewer.scene.canvas)
      this.handler.setInputAction(this.handlerMapClick, ScreenSpaceEventType.LEFT_CLICK)
      this.handler.setInputAction(this.handlerMapPointerMove, ScreenSpaceEventType.MOUSE_MOVE)
    },

    removeLayer () {
      const viewer = this.map3d?.viewer?.viewer

      if (!viewer || viewer.isDestroyed()) {
        return
      }

      viewer.dataSources.remove(this.markerLayer)

      if (this.handler && !this.handler.isDestroyed()) {
        this.handler.destroy()
      }
    },

    getCoordinates (position: Cartesian2) {
      const currentMap = this.map3d?.viewer?.viewer
      if (!currentMap) {
        return
      }

      var ray = currentMap.camera.getPickRay(position)
      if (!ray) {
        return
      }

      var mousePosition = currentMap.scene.globe.pick(ray, currentMap.scene as Scene)
      if (!mousePosition) {
        return
      }

      return mousePosition
    },

    convertDdToDms (deg: number) {
      var d = window.Math.floor(deg)
      var minfloat = (deg - d) * 60
      var m = window.Math.floor(minfloat)
      var secfloat = (minfloat - m) * 60
      var s = window.Math.round(secfloat)

      if (s === 60) {
        m++
        s = 0
      }
      if (m === 60) {
        d++
        m = 0
      }
      return {
        d,
        m,
        s
      }
    },

    handlerMapClick (event: ScreenSpaceEventHandler.PositionedEvent) {
      if (this.isActive) {
        const position = this.getCoordinates(event.position)

        if (position) {
          var cartographic = Cartographic.fromCartesian(position)
          const ddLat = Math.toDegrees(cartographic.latitude)
          const dmsLat = this.convertDdToDms(ddLat)

          const ddLon = Math.toDegrees(cartographic.longitude)
          const dmsLon = this.convertDdToDms(ddLon)

          this.fields.latitude.decimal = roundAfterDecimalPoint(ddLat, 5)
          this.fields.latitude.degress = dmsLat.d
          this.fields.latitude.minutes = dmsLat.m
          this.fields.latitude.seconds = dmsLat.s

          this.fields.longitude.decimal = roundAfterDecimalPoint(ddLon, 5)
          this.fields.longitude.degress = dmsLon.d
          this.fields.longitude.minutes = dmsLon.m
          this.fields.longitude.seconds = dmsLon.s

          const innerMsk38Value = ref(msk38Converter(false, [ddLon, ddLat]))
          const latitudeMsk38 = roundAfterDecimalPoint(innerMsk38Value.value[1], 2)
          const longitudeMsk38 = roundAfterDecimalPoint(innerMsk38Value.value[0], 2)
          this.fields.latitude.msk38 = String(latitudeMsk38)
          this.fields.longitude.msk38 = String(longitudeMsk38)

          this.createCursor(position)
        }
        this.recalcFormPosition()
        this.isOpenedPanel = true
      }
    },

    handlerMapPointerMove (movement: ScreenSpaceEventHandler.MotionEvent) {
      if (this.isActive && !this.isOpenedPanel) {
        const position = this.getCoordinates(movement.endPosition)
        if (position) {
          this.createCursor(position)
        }
      }
      // if (this.isDragging) {
      //   this.setOverlayPosition(event.coordinate)
      // }
    },

    handleClick () {
      if (!this.ActionTopRight) {
        return
      }
      if (this.isActive) {
        this.ActionTopRight.activateAction(null)
      } else {
        this.ActionTopRight.activateAction(MapActiveToolType.MAP_LOCATION)
      }
    },

    getCellText (rowData: Record<string, number>, rowId: string, columnId: string) {
      if (columnId === 'label') {
        return this.$t(`maps.location.${rowId}`)
      } else {
        return String(rowData[columnId]) + this.units[columnId]
      }
    },

    getMinValue (rowId: string, columnId: string) {
      if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
        if (rowId === 'latitude') {
          return ['degress', 'decimal'].includes(columnId) ? -180 : 0
        } else {
          return ['degress', 'decimal'].includes(columnId) ? -90 : 0
        }
      } else {
        return rowId === 'latitude' ? -180 : -90
      }
    },

    getMaxValue (rowId: string, columnId: string) {
      if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
        if (rowId === 'latitude') {
          return ['degress', 'decimal'].includes(columnId) ? 180 : 59
        } else {
          return ['degress', 'decimal'].includes(columnId) ? 90 : 59
        }
      } else {
        return rowId === 'latitude' ? 180 : 90
      }
    },

    getCellValue (rowData: Record<string, number>, columnId: string) {
      return rowData[columnId]
    },

    updateCellValue (info: Record<string, number>,
      rowId: string, columnId: string, value: number | Event
    ) {
      const uniqueKey = `${rowId}_${columnId}`
      if (this.errors[uniqueKey]) {
        delete this.errors[uniqueKey]
      }
      if (value instanceof Event) {
        const target = value.target as HTMLInputElement
        value = Number(target.value)
      }
      const presentedValue = Number(value)
      info[columnId] = value
      if (
        columnId !== 'msk38' &&
        (Number.isNaN(presentedValue) ||
        presentedValue < this.getMinValue(rowId, columnId) ||
        presentedValue > this.getMaxValue(rowId, columnId))
      ) {
        this.errors[uniqueKey] = 'error'
      } else if (columnId === 'msk38' && Number.isNaN(presentedValue)) {
        this.errors[uniqueKey] = 'error'
      } else {
        if (this.selectedFormat === CoordinateFormat.DEGREES_MINUTES_SECONDS) {
          const degress = Number(info.degress)
          const minutes = window.Math.abs(Number(info.minutes))
          const seconds = window.Math.abs(Number(info.seconds))
          const decimal = degress + minutes / 60 + seconds / 3600
          info.decimal = roundAfterDecimalPoint(decimal, 5)// may be do String here
        } else if (this.selectedFormat === CoordinateType.MSK38) {
          if (rowId === 'latitude') info.decimal = msk38Converter(true, [Number(this.fields.longitude.msk38), Number(info.msk38)])[1]
          if (rowId === 'longitude') info.decimal = msk38Converter(true, [Number(info.msk38), Number(this.fields.latitude.msk38)])[0]
        } else {
          const dms = this.convertDdToDms(info.decimal)
          info.degress = dms.d
          info.minutes = dms.m
          info.seconds = dms.s

          /* write msk38 to info */
          if (rowId === 'latitude') info.msk38 = roundAfterDecimalPoint(msk38Converter(false, [Number(this.fields.longitude.decimal), Number(info.decimal)])[1], 2)
          if (rowId === 'longitude') info.msk38 = roundAfterDecimalPoint(msk38Converter(false, [Number(info.decimal), Number(this.fields.latitude.decimal)])[0], 2)
        }
      }
    },

    applyCoordinates () {
      this.createCursor(Cartesian3.fromDegrees(this.fields.longitude.decimal, this.fields.latitude.decimal), true)
    },

    recalcFormPosition () {
      const ref = this.$refs.action as InstanceType<typeof BaseIcon> | null
      if (ref) {
        const rect = (ref.$el as HTMLElement).getBoundingClientRect()
        this.panelLocation.left = rect.x - 470
        this.panelLocation.top = rect.y
      }
    },

    createCursor (position: Cartesian3, flyTo = false) {
      this.markerLayer.entities.removeAll()
      const featureOptions : Entity.ConstructorOptions = {
        billboard: {
          image: imgMarkerIcon,
          heightReference: HeightReference.CLAMP_TO_GROUND,
          verticalOrigin: VerticalOrigin.BOTTOM
        },
        position: position
      }
      const feature = this.markerLayer.entities.add(featureOptions)

      if (flyTo) {
        const viewer = this.map3d?.viewer?.viewer
        if (viewer) {
          viewer.flyTo(feature, {
            offset: new HeadingPitchRange(
              0,
              -1.5,
              10000
            )
          })
        }
      }
    },

    deleteMarker () {
      this.markerLayer.entities.removeAll()
    },

    closePanel () {
      this.deleteMarker()
      this.isOpenedPanel = false
    }
  }
})
