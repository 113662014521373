import { Extent, createEmpty, extend } from 'ol/extent'
import { fromLonLat } from 'ol/proj'
import { EasingFunction } from 'cesium'
import Map from 'ol/Map'

import { GeoMapLayer, GeoMapLayergroup, OlMapLayerWfs } from '@/library/types'
import { PROP_GEO_LAYER_ID, getLayersList } from '@/library/helpers'
import { Polygon } from 'ol/geom'

export const isCorrectExtent2d = (extent: number[]): boolean => {
  return (
    extent.length === 4 &&
    !extent.includes(Infinity) &&
    !extent.includes(-Infinity) &&
    extent.every(item => item !== null && !Number.isNaN(item))
  )
}

export const fitToExtent = (extent: number[] | Polygon | Extent, map?: Map | null, withPadding = true): void => {
  if (map && (
    (Array.isArray(extent) && isCorrectExtent2d(extent)) ||
    extent instanceof Polygon
  )) {
    map.getView().fit(extent, {
      duration: 2000,
      easing: EasingFunction.QUADRATIC_IN_OUT,
      padding: withPadding ? [50, 50, 50, 50] : []
    })
  }
}

export const flyToLayer = (layer: GeoMapLayer, map?: Map | null, layersWfs?: OlMapLayerWfs[], fitToExtentFunc?: (ext: Extent, needToAdjustZoom?: boolean) => void): void => {
  if (!layer || !map) { return }
  if (layer.extent) {
    const topLeft = fromLonLat([layer.extent.xMin, layer.extent.yMin])
    const bottomRigth = fromLonLat([layer.extent.xMax, layer.extent.yMax])
    if (fitToExtentFunc) {
      fitToExtentFunc([...topLeft, ...bottomRigth], true)
    } else {
      fitToExtent([...topLeft, ...bottomRigth], map)
    }
  } else {
    setTimeout(() => {
      const layerEntity = layersWfs?.find(x => x.olLayer.get(PROP_GEO_LAYER_ID) === layer.id)
      if (!layerEntity) { return }
      const layerSource = layerEntity.olLayer.getSource()
      if (!layerSource) { return }
      const extent = layerSource.getExtent()
      if (![Infinity, -Infinity].includes(extent[0])) {
        if (fitToExtentFunc) {
          fitToExtentFunc(extent, true)
        } else {
          fitToExtent(extent, map)
        }
      } else {
        layerSource.once('featuresloadend', () => {
          if (fitToExtentFunc) {
            fitToExtentFunc(layerSource.getExtent(), true)
          } else {
            fitToExtent(layerSource.getExtent(), map)
          }
        })
      }
    }, 100)
  }
}

const extendGroupExtend = (layer: GeoMapLayer, layersWfs?: OlMapLayerWfs[]): Promise<Extent | void> => {
  return new Promise(resolve => {
    if (layer.extent) {
      const topLeft = fromLonLat([layer.extent.xMin, layer.extent.yMin])
      const bottomRigth = fromLonLat([layer.extent.xMax, layer.extent.yMax])
      resolve([...topLeft, ...bottomRigth])
    } else {
      const layerEntity = layersWfs?.find(x => x.olLayer.get(PROP_GEO_LAYER_ID) === layer.id)
      if (!layerEntity) { return resolve() }
      const layerSource = layerEntity.olLayer.getSource()
      if (!layerSource) { return resolve() }
      const extent = layerSource.getExtent()
      if (![Infinity, -Infinity].includes(extent[0])) {
        resolve(extent)
      } else {
        layerSource.once('featuresloadend', () => {
          resolve(layerSource.getExtent())
        })
      }
    }
  })
}

export const flyToGroup = async (
  group: GeoMapLayergroup, map?: Map | null,
  layersWfs?: OlMapLayerWfs[], fitToExtentFunc?: (ext: Extent, needToAdjustZoom?: boolean) => void
): Promise<void> => {
  if (!group || !map) { return }
  const fullExtent = createEmpty()
  const layers = getLayersList(group)
  const asyncExtents: Promise<Extent | void>[] = []
  layers.forEach(layer => {
    asyncExtents.push(extendGroupExtend(layer, layersWfs))
  })
  const resolvedExtents = await Promise.all(asyncExtents)
  resolvedExtents.forEach(extent => {
    if (Array.isArray(extent)) {
      extend(fullExtent, extent)
    }
  })
  if (fitToExtentFunc) {
    fitToExtentFunc(fullExtent, true)
  } else {
    fitToExtent(fullExtent, map)
  }
}
