import {
  UserSelfModel
} from '@/types/auth/types'

export type State = {
  user?: UserSelfModel,
  userShort?: string,
  isAdmin?: boolean,
  authenticated?: boolean
}

export const state: State = {
  isAdmin: false,
  authenticated: false
}
