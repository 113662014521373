
import { WmsLegendRuleSymbolizerPolygon } from '@/library/types/maps/wmsLegends'
import { defineComponent, PropType } from 'vue'
import InlineSvg from 'vue-inline-svg'
import imgPolygon from '@/library/assets/img/maps/symbols/polygon.svg'

const __default__ = defineComponent({
  components: { InlineSvg },
  props: {
    polygon: { type: Object as PropType<WmsLegendRuleSymbolizerPolygon>, required: true }
  },
  data () {
    return {
      imgPolygon
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4d7cf265": (_ctx.polygon.fill),
  "3365d4af": (_ctx.polygon["fill-opacity"]),
  "0e527acc": (_ctx.polygon.stroke),
  "c5dfce42": (_ctx.polygon["stroke-width"]),
  "12f0d664": (_ctx.polygon["stroke-opacity"]),
  "2d480de0": (_ctx.polygon["stroke-dasharray"])
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__