export default {
  "start-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать на сайт Цифрового двойника Байкальска"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это инновационная цифровая платформа, созданная в рамках стратегического мастер-плана города и программы его социально-экономического развития"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к карте"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация/авторизация"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти из профиля"])},
    "cards": {
      "city-info": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомься с максимально полной информацией о городе"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся информация обо всех городских объектах, инфраструктуре, окружающей среде - в одном месте."])}
      },
      "use-services": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользуйся удобными функциями и сервисами"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местный житель или турист, инвестор или простой обыватель – каждый найдет для себя полезный функционал и сервисы"])}
      },
      "change-world": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь в курсе изменений"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город живет и развивается, появляются новые проекты и инициативы - следи за изменениям и используй новые возможности."])}
      },
      "open-city": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открывай город по-новому"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На интерактивной карте можно найти все интересующее, а высокоточная съемка и 3D модель позволят в деталях изучить любой объект или местность"])}
      },
      "study-city": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изучай город любым удобным способом"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эко турист или автомобилист, лыжник или велосипедист найдет здесь полезную информацию обо всех маршрутах и достопримечательностях."])}
      },
      "start-study": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приступай к изучению и изменению города прямо сейчас"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрируйся, получи доступ к сервисам Цифрового двойника и меняй город вместе с нами!"])}
      }
    }
  },
  "baikalsk": {
    "app-header": {
      "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные"])},
      "services": {
        "select-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисы"])},
        "city-issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис городских предложений"])},
        "estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис жилой и коммерческой недвижимости"])},
        "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис городских вакансий"])},
        "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис городских проектов"])},
        "egrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис актуализации сведений из ЕГРН"])}
      },
      "user-menu": {
        "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])},
        "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрирование"])},
        "service-moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация сервисов"])},
        "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
        "admin-connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связь с администратором"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
      }
    },
    "personal-area": {
      "panel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])},
        "menu": {
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
          "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активности"])}
        }
      },
      "content": {
        "profile-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
        "activities-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активности"])},
        "activities-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Городские предложения"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])},
        "placeholders": {
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "inner-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите email"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
        },
        "errors": {
          "name-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенное значение не соответствует требованиям: имя может содержать только буквы латиницы или кириллицы и символ “-”"])},
          "surname-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенное значение не соответствует требованиям: фамилия может содержать только буквы латиницы или кириллицы и символ “-”"])},
          "error-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте корректность ввода"])},
          "error-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла ошибка при попытке обновления данных"])}
        },
        "success-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно обновлены"])},
        "logout-confirm": {
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения перед выходом?"])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все несохраненные изменения будут сброшены"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять"])}
        }
      }
    },
    "admin": {
      "panel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрирование"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
        "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы пользователей"])},
        "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пользователя"])},
        "reset-filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтр"])},
        "paginator-total-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователей"])},
        "table": {
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа пользователей"])},
          "search": {
            "placeholders": {
              "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
              "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
              "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа пользователей"])}
            }
          }
        },
        "item": {
          "common-not-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не указано"])},
          "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн"])},
          "last-seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Был в сети"])},
          "more-than-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["более недели назад"])}
        }
      },
      "create-user": {
        "title-create-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание пользователя"])},
        "title-edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование пользователя"])},
        "button-create-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пользователя"])},
        "button-edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
        "button-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "button-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить пользователя"])},
        "placeholders": {
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа пользователей"])}
        },
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание пользователя"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При создании пользователя, пароль будет сгенерирован и отправлен на электронную почту. Продолжить?"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
        }
      },
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы пользователей"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать группу"])},
        "paginator-total-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групп"])},
        "table": {
          "group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название группы"])},
          "open-manage-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список пользователей"])},
          "search": {
            "placeholders": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])}
            }
          }
        },
        "modal-manage": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список пользователей"])},
          "list-not-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не добавленные в группу"])},
          "list-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавленные в группу"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        },
        "users-list": {
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "search": {
            "placeholders": {
              "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
              "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
            }
          },
          "add-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для добавления пользователей перенесите их из таблицы слева"])}
        },
        "layers-access": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название слоя"])},
          "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтение"])},
          "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование"])},
          "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступа"])},
          "search": {
            "placeholders": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])}
            }
          }
        },
        "services-access": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название сервиса"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
          "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
          "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модератор"])},
          "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовый"])}
        },
        "cameras-access": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название камеры"])},
          "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтение"])},
          "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступа"])}
        },
        "toasts": {
          "remove": {
            "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить группу пользователей?"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
          },
          "quit": {
            "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При продолжения действия все внесенные изменения будут сброшены. Вы действительно хотите продолжить?"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])}
          }
        }
      },
      "create-group": {
        "title-create-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание группы"])},
        "title-edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование группы"])},
        "button-create-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать группу"])},
        "button-edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
        "button-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "button-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить группу"])},
        "placeholders": {
          "group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название группы"])},
          "inner-group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])}
        },
        "groups-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
        "layers-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к слоям"])},
        "services-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к сервисам"])},
        "list-not-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не добавленные в группу"])},
        "list-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавленные в группу"])},
        "notifications": {
          "errors": {
            "empty-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните поле"])},
            "wrong-symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название группы не соответствует требованиям: название может содержать только символы латиницы, кириллицы, цифры, знаки “-” и “_“"])}
          }
        }
      },
      "map": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрирование карты"])},
        "layers-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение максимального количества отображаемых слоев"])},
        "limit-apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
        "limit-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите максимальное число отображаемых слоев"])},
        "layers-drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премодерация изменений в слоях"])},
        "drafts-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название слоя"])},
        "drafts-apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместить данные в оригинал"])},
        "toast": {
          "update-limit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение количества видимых слоёв успешно сохранено"])},
          "update-limit-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка сохранения лимита слоёв"])},
          "apply-draft-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка сохранения черновика"])}
        }
      }
    },
    "moderation": {
      "panel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модерация"])},
        "services-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисы"])},
        "egrn-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление данных"])},
        "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Городские предложения"])},
        "estates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилая и коммерческая недвижимость"])},
        "vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Городские вакансии"])},
        "egrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕГРН"])}
      },
      "common": {
        "list-item": {
          "menu": {
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
            "show-on-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать на карте"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
          },
          "status": {
            "spare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите статус"])},
            "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликован"])},
            "unpublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не опубликован"])}
          }
        },
        "card": {
          "contacts-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты:"])},
          "menu-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
          "menu-show-on-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать на карте"])}
        }
      },
      "egrn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕГРН"])},
        "paginator-total-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записей"])},
        "button-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить данные"])},
        "button-smav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить запрос в СМЭВ"])},
        "list-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал загрузки"])},
        "header": {
          "columns": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
            "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата загрузки"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
          },
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "author-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])}
        },
        "list-item": {
          "size-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мб"])},
          "actions": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])}
          }
        },
        "filter": {
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтр"])},
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "size-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
          "status-selected-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрано: "])}
        },
        "toast": {
          "common-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка"])}
        }
      },
      "estates": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилая и коммерческая недвижимость"])},
        "paginator-total-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявлений"])},
        "header": {
          "columns": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
          },
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "author-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])}
        },
        "filter": {
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтр"])},
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "author-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])}
        },
        "delete-comfirmation": {
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить объявление?"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
        },
        "toast": {
          "delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление удалено"])},
          "delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при удалении Объявления"])},
          "common-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка"])}
        }
      },
      "vacancies": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Городские вакансии"])},
        "paginator-total-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансий"])},
        "header": {
          "columns": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
            "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
          },
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "author-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])}
        },
        "filter": {
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтр"])},
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "organization-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
          "author-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
          "status": {
            "spare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
            "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликован"])},
            "unpublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не опубликован"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все статусы"])}
          }
        },
        "delete-comfirmation": {
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить вакансию?"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
        },
        "toast": {
          "delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансия удалена"])},
          "delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при удалении вакансии"])},
          "common-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка"])}
        }
      },
      "suggestions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Городские предложения"])},
        "paginator-total-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложений"])},
        "header": {
          "columns": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
          },
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "author-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])}
        },
        "filter": {
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтр"])},
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "author-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])}
        },
        "delete-comfirmation": {
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить предложение?"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
        },
        "toast": {
          "delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложение удалено"])},
          "delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при удалении предложения"])},
          "common-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка"])}
        }
      }
    },
    "data": {
      "panel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные"])},
        "meteo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метеоданные"])},
        "atmo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мониторинг окружающей среды"])}
      },
      "atmo": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мониторинг воздуха"])},
        "aqi-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс AQI"])},
        "pm25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM 2.5"])},
        "pm10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM 10"])},
        "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержание CO"])},
        "o3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержание O3"])},
        "no2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержание NO2"])},
        "so2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержание SO2"])},
        "pm25-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мкг/м3"])},
        "pm10-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мкг/м3"])},
        "co-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мкг/м3"])},
        "o3-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мкг/м3"])},
        "no2-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мкг/м3"])},
        "so2-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мкг/м3"])}
      },
      "meteo": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогноз погоды"])},
        "graph-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метеосводка"])},
        "today": {
          "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас"])},
          "feelsLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ощущается как"])},
          "humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влажность"])},
          "pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Давление"])},
          "humidity-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
          "pressure-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мм.рт.ст."])},
          "wind-measure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["м/с"])},
          "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ветер"])},
          "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восход"])},
          "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закат"])},
          "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утро"])},
          "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
          "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вечер"])},
          "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ночь"])}
        }
      },
      "graph": {
        "average-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["среднее значение"])},
        "average-title-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cр.знач"])}
      }
    },
    "services": {
      "toast-common-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка"])},
      "city-projects": {
        "create-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать проект"])},
        "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])},
        "filter": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
          "btn-apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
        },
        "remove": {
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить проект?"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
        },
        "modal-create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание проекта"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип проекта"])},
          "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метка на карте*"])},
          "btn-set-mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указать"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
          "two-d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D"])},
          "three-d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D"])}
        },
        "modal-map": {
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите метку проекта на карте"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        },
        "toast": {
          "create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проект успешно создан"])},
          "create-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при создании проекта"])},
          "project-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проект успешно удален"])},
          "save-changes-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения сохранены"])},
          "project-delete-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при удалении проекта"])},
          "comment-load-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при загрузке комментариев"])},
          "comment-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий успешно удален"])}
        },
        "project-item": {
          "nav-root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Городские проекты"])},
          "menu": {
            "edit-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать страницу проекта"])},
            "edit-presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать презентацию проекта"])}
          },
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите имя проекта"])},
          "description-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите описание проекта"])},
          "publish-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата публикации: ", _interpolate(_named("n"))])},
          "publish-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать"])},
          "unpublish-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снять с публикации"])},
          "btn-presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Презентация"])},
          "btn-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть проект"])},
          "btn-send-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
          "textarea-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите текст комментария"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "cancel-confirm": {
            "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения перед выходом?"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все несохраненные изменения будут сброшены"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять"])}
          }
        },
        "comment": {
          "trunk-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать дальше"])},
          "trunk-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть"])},
          "total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Комментариев: ", _interpolate(_named("n"))])}
        },
        "presentation": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "infobox-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить инфобокс"])},
          "infobox-subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможные функции:"])},
          "2d-map-data": {
            "minX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minX:"])},
            "minY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minY:"])},
            "maxX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxX:"])},
            "maxY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxY:"])}
          },
          "3d-map-data": {
            "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["x:"])},
            "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y:"])},
            "z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z:"])},
            "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heading:"])},
            "pitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pitch:"])},
            "roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roll:"])}
          },
          "fix-extent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зафиксировать экстент"])},
          "animation-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анимация слайда"])},
          "animation-fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мгновенное переключение"])},
          "animation-slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плавный перелет"])},
          "slide-selecter-no-slides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет слайдов"])},
          "tabs": {
            "layers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление слоями"])},
            "infoboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление инфобоксами"])},
            "extent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положение экстента"])}
          },
          "infobox": {
            "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя инфобокса"])},
            "editing-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование инфобокса"])},
            "html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["html"])},
            "indent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отступ"])},
            "indent-x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["x:"])},
            "indent-y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y:"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ширина"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высота"])},
            "bind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка к углу карты"])},
            "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "btn-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
          },
          "toast-save-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Презентация проекта успешно сохранена"])},
          "toast-save-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла ошибка при сохранении презентации проекта"])},
          "toast-final-editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершите режим редактирования"])}
        }
      },
      "real-estate": {
        "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])},
        "nothing-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не найдено"])},
        "create-advert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить объявление"])},
        "filter": {
          "titles": {
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микрорайон"])},
            "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип объявления"])},
            "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество комнат"])},
            "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадь"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])}
          },
          "placeholders": {
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите микрорайон"])},
            "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрано: "])},
            "area-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
            "area-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
            "price-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
            "price-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])}
          },
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
          "btn-apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
        },
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить объявление"])},
          "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метка на карте*"])},
          "btn-set-mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указать"])},
          "titles": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название*"])},
            "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип недвижимости*"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип объявления*"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микрорайон"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица*"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер дома"])},
            "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество комнат*"])},
            "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадь*"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена*"])},
            "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение"])},
            "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вложенные файлы"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты*"])}
          },
          "placeholders": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название объявления"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название микрорайона"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер дома"])},
            "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кв.м"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите цену"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите описание объявления"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите контакты для связи"])}
          },
          "images-loader": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместите файл изображения"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество загружаемых изображений - 10 шт."])},
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
            "wrong-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат"])}
          },
          "attach-loader": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместите файл вложения"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество загружаемых вложений - 10 шт."])},
            "wrong-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат файла"])}
          },
          "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "btn-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])}
        },
        "add-success-note": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше объявление успешно создано и отправлено на проверку администратору"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После рассмотрения администратором, объявление будет опубликовано. Проверить статус объявления можно в личном кабинете в разделе “Мои активности”"])},
          "to-pesonal-area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в личный кабинет"])}
        },
        "cancel-confirmation": {
          "questiion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите выйти?"])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выходе объявление будет удалено"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
        },
        "panel-list-tabs": {
          "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилая"])},
          "commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коммерческая"])}
        },
        "toast": {
          "estate-common-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка"])}
        }
      },
      "city-vacancies": {
        "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])},
        "nothing-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не найдено"])},
        "create-advert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить вакансию"])},
        "filter": {
          "titles": {
            "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуемый опыт"])},
            "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образование"])},
            "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заработная плата"])}
          },
          "placeholders": {
            "salary-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
            "salary-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])}
          },
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
          "btn-apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
        },
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить вакансию"])},
          "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метка на карте*"])},
          "btn-set-mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указать"])},
          "titles": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название вакансии*"])},
            "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации*"])},
            "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуемый опыт"])},
            "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образование"])},
            "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заработная плата (до вычета НДФЛ)"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микрорайон"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица*"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер дома"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты*"])}
          },
          "placeholders": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название вакансии"])},
            "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
            "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуемый"])},
            "salary-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
            "salary-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название микрорайона"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер дома"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите описание вакансии"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите контакты для связи"])}
          },
          "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "btn-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])}
        },
        "add-success-note": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансия успешно создана и отправлена на проверку администратору"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После рассмотрения администратором, вакансия будет опубликована. Проверить статус вакансии можно в личном кабинете в разделе “Мои активности”"])},
          "to-pesonal-area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в личный кабинет"])}
        },
        "cancel-confirmation": {
          "questiion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите выйти?"])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выходе вакансия будет удалена"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
        },
        "card": {
          "experience-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуемый опыт:"])},
          "education-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образование:"])},
          "education": {
            "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["среднее"])},
            "vocational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["среднее профессиональное"])},
            "higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["высшее"])},
            "spare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не требуется"])}
          },
          "experience": {
            "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["без опыта"])},
            "up-to-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-3 года"])},
            "up-to-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-6 лет"])},
            "from-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["более 6 лет"])},
            "spare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не требуется"])}
          }
        },
        "item-common": {
          "salary-currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["руб."])},
          "salary-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до вычета"])},
          "salary-not-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не указано"])}
        }
      },
      "city-suggestions": {
        "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])},
        "nothing-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не найдено"])},
        "create-advert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить предложение"])},
        "filter": {
          "titles": {
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата публикации"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус предложения"])},
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])}
          },
          "placeholders": {
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])},
            "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДД.ММ.ГГГГ"])},
            "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДД.ММ.ГГГГ"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все статусы"])},
            "status-selected-word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусы: "])},
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес"])}
          },
          "btn-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
          "btn-apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
        },
        "create": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить предложение"])},
          "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метка на карте*"])},
          "btn-set-mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указать"])},
          "over-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышено максимально допустимое количество точек"])},
          "titles": {
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип*"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название*"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микрорайон"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица*"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер дома"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст предложения*"])},
            "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение"])},
            "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вложенные файлы"])}
          },
          "markers": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите метки предложения на карте"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
          },
          "placeholders": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название*"])},
            "name-inner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название предложения"])},
            "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название микрорайона"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица*"])},
            "street-inner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер дома"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])},
            "description-inner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
          },
          "images-loader": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместите файл изображения"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество загружаемых изображений - 10 шт."])},
            "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
            "wrong-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат"])}
          },
          "attach-loader": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместите файл вложения"])},
            "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество загружаемых вложений - 10 шт."])},
            "wrong-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат файла"])}
          },
          "validate-msg": {
            "field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно для заполнения"])},
            "has-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните необходимые поля"])}
          },
          "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "btn-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])}
        },
        "card": {
          "date-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата размещения: "])}
        },
        "add-success-note": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше предложение успешно создано и отправлено на проверку администратору."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" После рассмотрения администратором, предложение будет опубликовано. Проверить статус заявки можно в личном кабинете в разделе “Мой активности”"])},
          "to-pesonal-area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в личный кабинет"])}
        },
        "cancel-confirmation": {
          "questiion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите выйти?"])},
          "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выходе предложение будет удалено"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
        }
      },
      "video-streams": {
        "enter-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])},
        "create-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать группу камер"])},
        "create-camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать камеру"])},
        "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
        "download-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать записи"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн"])},
        "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офлайн"])},
        "set-mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите метку камеры на карте"])},
        "camera-viewer": {
          "coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координаты"])},
          "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать записи"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])}
        },
        "confirm": {
          "delete": {
            "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить камеру видеонаблюдения?"])},
            "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить группу камер видеонаблюдения?"])}
          },
          "exit": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите выйти?"])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выходе камера будет удалена"])}
          }
        },
        "modal": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать камеру видеонаблюдения"])},
          "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запись"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать камеру видеонаблюдения"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название камеры"])},
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])},
          "rtsp-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RTSP ссылка поток 1*"])},
          "rtsp-1-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите RTSP ссылка поток 1"])},
          "rtsp-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RTSP ссылка поток 2"])},
          "rtsp-2-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите RTSP ссылка поток 2"])},
          "microdistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микрорайон"])},
          "microdistrict-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название микрорайона"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
          "house-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер дома"])},
          "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
          "organization-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название организации"])},
          "coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координаты"])},
          "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метка на карте"])},
          "set-mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указать"])}
        },
        "download": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архив записей"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период"])},
          "tip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Допустимый период для скачивания с ", _interpolate(_named("n")), " по ", _interpolate(_named("m"))])},
          "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачивание"])}
        },
        "toasts": {
          "success": {
            "add-camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камера успешно добавлена"])},
            "edit-camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камера успешно отредактирована"])},
            "delete-camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камера успешно удалена"])},
            "delete-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа камер успешно удалена"])},
            "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачивание файлов завершено"])},
            "download-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачивание отменено"])}
          },
          "error": {
            "add-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при добавлении группы"])},
            "edit-camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при редактировании камеры"])},
            "last-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущее скачивание не завершено"])},
            "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка скачивания"])},
            "check-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте корректность данных"])},
            "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка воспроизведения"])}
          }
        }
      }
    },
    "auth": {
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в личный кабинет"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для получения персонального расширенного доступа к функционалу на коммерческой основе, направьте письменный запрос на официальном бланке организации на почту"])},
        "note-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info baikal.center"])},
        "placeholders": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "inner-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите email"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "inner-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])}
        },
        "forget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
        "ask-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пользователь?"])},
        "registration-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
        "btn-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])}
      },
      "registration": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
        "placeholders": {
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
          "inner-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите фамилию"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "inner-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите имя"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "inner-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите email"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "inner-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])},
          "password-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля"])},
          "inner-password-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])}
        },
        "have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть аккаунт?"])},
        "login-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
        "btn-reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])}
      },
      "change-password": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
        "pass-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен состоять из 8 символов, содержащих латинские буквы, минимум 1 заглавную букву, минимум 1 цифру"])},
        "input-headers": {
          "enter-old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите старый пароль"])},
          "enter-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите новый пароль"])},
          "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите новый пароль"])}
        },
        "input-placeholders": {
          "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль"])},
          "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
          "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])}
        },
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        }
      }
    },
    "shared": {
      "base-multiselect": {
        "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найдено"])}
      },
      "ofp": {
        "load-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки ОФП"])}
      },
      "base-input": {
        "max-length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимальное количество символов ", _interpolate(_named("n"))])}
      },
      "modal-confirmation": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнить?"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
      }
    }
  },
  "maps": {
    "measurement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измерения"])},
      "tools": {
        "ruler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линия"])},
        "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полигон"])},
        "circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окружность"])},
        "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить"])},
        "units": {
          "ga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["га"])},
          "km2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кв.км."])},
          "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кв.м."])},
          "km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["км"])},
          "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["м"])},
          "sub-m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["м²"])},
          "sub-km2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["км²"])}
        }
      },
      "measurementUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат измерения"])},
      "type": {
        "square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадь"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина"])},
        "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Радиус"])},
        "perimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Периметр"])}
      }
    },
    "location": {
      "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта"])},
      "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Долгота"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координаты"])},
      "switchDefine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определить координаты"])},
      "switchGoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти по координатам"])},
      "degressWithSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Градусы, минуты, секунды"])},
      "decimalDegress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Десятичные градусы"])},
      "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])},
      "pointLat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ш."])},
      "pointLon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["д."])},
      "copyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])}
    },
    "extent": {
      "actions": {
        "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
        "scaleArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увеличить до выделенного"])},
        "fullScale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домой"])},
        "mapExtent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экстент карты"])}
      },
      "btns": {
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать"])},
        "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано"])},
        "undoExtent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущему экстенту"])},
        "redoExtent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К следующему экстенту"])}
      },
      "copyExtentNotificationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка экстента карты скопирована в буфер обмена"])},
      "copyExtentNotificationSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед отправкой ссылки, предоставьте права на карту пользователю или сделайте ее общедоступной на странице “Мои карты”"])},
      "copyIframeNotificationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe скопирован в буфер обмена"])},
      "copyIframeNotificationSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед отправкой, предоставьте права на карту пользователю или сделайте ее общедоступной на странице “Мои карты”"])}
    },
    "layers": {
      "change-log": {
        "menu-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал изменения слоя"])}
      },
      "backups": {
        "menu-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервные копии"])},
        "menu-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать копию"])},
        "menu-restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить копию"])},
        "manage": {
          "window-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервные копии"])},
          "column-date-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
          "column-time-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
          "restore-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])}
        },
        "toast": {
          "create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервная копия успешно создана"])},
          "create-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при создании резервной копии"])},
          "load-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при загрузке резервных копий"])},
          "restore-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервная копия восстановлена"])},
          "restore-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при восстановлении резервной копии"])}
        }
      },
      "export": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
        "goemtryTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геометрия в WKT"])},
        "columns": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Псевдоним"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])}
        },
        "fields": {
          "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибуты для экспорта"])},
          "saveAliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить псевдонимы"])},
          "enableFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С учетом фильтра"])},
          "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат"])},
          "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя файла"])},
          "coordinateSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система координат"])}
        },
        "tooltip3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученный 3D слой при конвертации может не соответствовать исходному слою 2D. Для актуальности данных проведите повторную конвертацию в 3D"])}
      },
      "objects": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание объекта"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование объекта"])},
        "switch-options": {
          "attr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибуты объекта"])},
          "coord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координаты"])}
        },
        "wait-draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарисуйте объект на карте"])},
        "wait-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите объект на карте"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "msg": {
          "succes-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объект сохранен"])},
          "error-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить"])},
          "success-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объект удален"])},
          "error-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить объект"])},
          "error-save-msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Невозможно сохранить объект по причине ", _interpolate(_named("msg"))])},
          "error-save-filed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ошибка в поле ", _interpolate(_named("field")), ": ", _interpolate(_named("msg"))])}
        },
        "btns": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить объект"])},
          "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
          "flyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизить к выборке"])}
        },
        "removeObjectConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить объект?"])},
        "removeObjectConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить объект"])}
      },
      "downloadManager": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История загрузки"])},
        "columns": {
          "inData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные"])},
          "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип задачи"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
          "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
          "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
          "modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата завершения"])},
          "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вложение"])}
        },
        "statuses": {
          "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активен"])},
          "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создан"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружается"])},
          "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменен"])},
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершен"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удален"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружается"])}
        },
        "categories": {
          "layerImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импорт"])},
          "layerExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
          "layerConvert3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конвертация в 3D"])},
          "layerTransform3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка 3D"])}
        },
        "results": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружается"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно"])}
        },
        "downloadAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать файл со списком измененных атрибутов"])},
        "errors": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
          "time_limit_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышен лимит ожидания"])},
          "invalid_file_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неподдерживаемый файл"])},
          "geoserver_publication_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка создания слоя"])},
          "publication_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка операции"])},
          "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис недоступен"])},
          "unknown-layer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестный слой"])}
        },
        "warnings": {
          "invalidSld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить sld стиль"])},
          "attributesChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Были переименованы имена атрибутов в таблице"])},
          "invalidObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обнаружены невалидные объекты слоя"])},
          "attributesTrimmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество символов в атрибутах превышают допустимое количество символов. Значения были обрезаны до 1000 символов"])}
        },
        "downloadAttachmentImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать фаил со списком ошибок"])},
        "dayLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл доступен 24ч"])}
      },
      "sld-editor": {
        "confirms": {
          "rewrite": {
            "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий sld стиль будет перезаписан. Продолжить?"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])}
          },
          "reset": {
            "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены что хотите сбросить все внесенные изменения?"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
          },
          "save": {
            "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить внесенные изменения?"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять"])}
          }
        },
        "error": {
          "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер файла не должен превышать 20Мб"])},
          "xml-validator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В описанном XML есть ошибки"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла ошибка при применении стиля"])},
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки"])},
          "fileFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допускается загружать только файлы с форматом SLD"])}
        },
        "success": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стиль успешно применен"])}
        }
      },
      "styles": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование стиля"])},
        "geometryType": {
          "polygon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полигон"])},
          "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])},
          "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линия"])},
          "multipolygon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мультиполигон"])},
          "multipoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мультиточка"])},
          "multiline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мультилиния"])}
        },
        "base": {
          "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
          "nameInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "formatLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат стиля"])},
          "geometryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип геометрии"])},
          "attribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибут"])},
          "defaultName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стиль для слоя"])},
          "typeOptions": {
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стиль"])},
            "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])}
          },
          "formatOptions": {
            "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простой стиль"])},
            "editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать стиль по значениям"])}
          },
          "sizeOptions": {
            "standart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный размер"])},
            "byValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить размер по значению"])}
          },
          "attributeTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибутивная таблица"])},
          "attributeSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знак"])},
          "attributeValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])},
          "CSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS"])},
          "attributeSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])},
          "attributeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])}
        },
        "signature": {
          "showSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображать подпись объекта"])},
          "attribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибут"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
          "fontFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шрифт"])},
          "fontStyle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стиль"])},
          "fontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер шрифта"])},
          "symbolBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])},
          "symbolPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение символа"])},
          "fontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
          "fontColorOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность"])},
          "outlineSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер обводки"])},
          "outlineColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет обводки"])},
          "outlineColorOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность обводки"])},
          "positionBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положение подписи"])},
          "alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выравнивание"])},
          "relativeX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Относительно X"])},
          "relativeY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Относительно Y"])},
          "rotateAngle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угол вращения"])},
          "symbolsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество символов в строке"])},
          "scaleBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масштаб отображения"])},
          "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись"])},
          "newButtonTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить подпись"])},
          "symbolPositionOptions": {
            "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В начале подписи"])},
            "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В конце подписи"])}
          },
          "fontStyleOptions": {
            "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обычный"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полужирный"])},
            "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсив"])}
          },
          "previewText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["текст"])},
          "removeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
          "removeButtonTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить подпись"])},
          "signatureConflictResolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перекрытие"])},
          "linePosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение"])},
          "lineOffset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смещение"])},
          "needRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторять подпись"])},
          "lineRepeatInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервал"])}
        },
        "point": {
          "fillColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
          "fillType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип заливки"])},
          "strokeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контур"])},
          "strokeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет обводки"])},
          "strokeWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обводка"])},
          "clustering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кластеризация"])},
          "fillOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность"])},
          "strokeOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность обводки"])},
          "pointSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
          "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иконка"])},
          "iconSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер иконки"])},
          "iconChoiceSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать размер"])},
          "pointSymbolType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])}
        },
        "line": {
          "strokeWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толщина"])},
          "lineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип линии"])},
          "lineColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
          "lineOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность"])},
          "lineWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толщина"])},
          "lineTypeOptions": {
            "solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сплошная"])},
            "dotted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пунктирная"])},
            "dashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штриховая"])}
          },
          "fillOptions": {
            "solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без градиента"])},
            "byValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Градиент по значению"])}
          },
          "weightSizeOptions": {
            "standart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартный размер"])},
            "byValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить размер по значению"])}
          }
        },
        "polygon": {
          "fillColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет заливки"])},
          "fillType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип заливки"])},
          "strokeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контур"])},
          "strokeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет контура"])},
          "strokeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип контура"])},
          "strokeWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толщина контура"])},
          "gradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Градиент"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масштаб отображения"])},
          "fillOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность заливки"])},
          "strokeOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность контура"])},
          "fillOptions": {
            "solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сплошная"])},
            "dashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штриховая"])},
            "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без заливки"])},
            "byValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Градиент по значению"])}
          },
          "strokeOptions": {
            "solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сплошной"])},
            "dashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штриховой"])}
          }
        },
        "btns": {
          "uploadSld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить sld"])},
          "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
          "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить стиль"])},
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
        },
        "errors": {
          "fieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно для заполнения"])},
          "numberMinFieldLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Значение не должно быть меньше, чем ", _interpolate(_list(0))])},
          "numberMaxFieldLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Значение не должно превышать ", _interpolate(_list(0))])},
          "invalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение должно быть числом"])},
          "endLessStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение От должно быть меньше значения До"])},
          "getAttributesValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка получения значений"])},
          "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения"])},
          "save-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке сохранить данные"])}
        },
        "msg": {
          "save-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные сохранены"])}
        },
        "editorMode": {
          "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить вручную"])},
          "sld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактор sld"])}
        },
        "pointSymbolType": {
          "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простой"])},
          "svg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SVG"])}
        },
        "linePositionType": {
          "follow_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вдоль линии"])},
          "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Горизонтально"])}
        }
      },
      "tree": {
        "header": {
          "showHideLayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть слои"])},
          "showLayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать слои"])},
          "hideLayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть слои"])},
          "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новую группу"])},
          "importLayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортировать слой"])},
          "fromStorage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geohub.Storage"])},
          "fromPc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из компьютера"])},
          "fromUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес"])},
          "fromWms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS/WMTS"])},
          "fromWfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WFS"])},
          "fromTms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TMS"])},
          "expandCollapseTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть/Развернуть дерево"])},
          "expandTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернуть всё"])},
          "collapseTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть всё"])},
          "openUploadHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть историю операций"])},
          "enableMultipleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить множественное удаление"])},
          "create-layer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать слой"])},
          "confirmFromPC": {
            "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить файлы"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импорт файла"])},
            "documentationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При загрузке файла необходимо учитывать следующие ограничения:"])},
            "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые форматы файлов: Shapefile (ZIP-архив, содержащий все файлы шейп-файла), GeoJson, KML, GeoPackage, GeoTiff."])},
            "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимально допустимый размер файла - 5 ГБ."])},
            "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название файла не должно превышать 100 символов."])},
            "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибуты должны содержать не более 1000 символов в ячейке и не более 50 символов в названии."])},
            "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество атрибутов - 500."])},
            "description6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более подробную информацию вы можете найти в "])}
          },
          "group": {
            "msg": {
              "error": {
                "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать группу"])}
              }
            }
          },
          "enableMultipleMove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Множественное перемещение"])}
        },
        "item": {
          "disableLayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выключить слой"])},
          "enableLayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить слой"])},
          "applyOpacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить непрозрачность"])},
          "layerInfo": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о слое"])},
            "fields": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название слоя"])},
              "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название файла слоя"])},
              "fileFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат файла"])},
              "coordsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система координат"])},
              "geometryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геометрия"])},
              "fileEncoding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодировка"])},
              "spaceResolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пространственное разрешение"])},
              "compression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сжатие"])},
              "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время последнего обновления"])},
              "objectsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество объектов в слое"])},
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор слоя"])},
              "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
              "pixelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер пикселя"])},
              "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания слоя"])},
              "sourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник слоя"])}
            },
            "switch": {
              "layerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свойства слоя"])},
              "layerSourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свойства источника слоя"])}
            },
            "sourceType": {
              "fromPC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПК пользователя"])},
              "createdManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создан в системе"])}
            },
            "errors": {
              "commentUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при обновлении комментария"])}
            }
          },
          "enableGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить группу"])},
          "disableGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выключить группу"])}
        },
        "menu": {
          "remove-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить группу слоев"])},
          "edit-style": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стиль"])},
            "options": {
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать стиль"])},
              "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать стиль"])}
            }
          },
          "set-opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность"])},
          "fly-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизить к слою"])},
          "remove-layer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
          "remove-layer-confirm-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно ли удалить слой:"])},
          "remove-layer-confirm-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["из карты?"])},
          "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
          "openTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть таблицу"])},
          "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
          "edit-layer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
            "options": {
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать объект"])},
              "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать объект"])}
            }
          },
          "refreshGroupColorError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при обновлении цвета группы слоя"])},
          "removeGroupError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке удаления группы слоев"])},
          "removeLayerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке удаления слоя"])},
          "removeGroupQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить группу слоев?"])},
          "removeGroupCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа слоев будет удалена со всеми слоями и с подгруппами"])},
          "ungroupGroupQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы действительно хотите разгруппировать группу слоев ", _interpolate(_named("name")), " из проекта?"])},
          "ungroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разгруппировать"])},
          "ungroupError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось разгруппировать слой"])},
          "successGroupDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа успешно удалена"])},
          "successLayerDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слой успешно удален"])},
          "failLayerRename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось переименовать слой"])},
          "failGroupRename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось переименовать группу"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка слоя"])},
          "showInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть информацию"])},
          "exportSHP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHP (в ZIP)"])},
          "export-shp": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При экспорте слоя в SHP (.zip) названия атрибутов могут быть заменены из-за превышения лимита в 10 символов. Файл со списком замененных названий атрибутов можно найти и скачать в окне История операции"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать слой в SHP (в ZIP) файл?"])}
          },
          "setColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить цвет"])},
          "flyToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизить к группе"])},
          "convertTo3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать 3D слой"])},
          "3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D"])},
          "settings3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка 3D"])}
        },
        "successMultipleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранные слои успешно удалены"])},
        "errorMultipleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке удаления слоев"])},
        "multipleSelectTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите слои/группы слоев для удаления"])},
        "multipleDeleteDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно удалить выбранные элементы: ", _interpolate(_named("count")), " шт?"])},
        "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать всё"])},
        "moveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышен максимальный уровень вложенности"])},
        "layers-limit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто максимальное количество отображаемых слоев"])},
        "groupInfo": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о группе слоев"])},
          "layersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество слоев"])},
          "featuresCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество объектов во всех слоях"])},
          "totalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая площадь всех полигональных слоев - в кв.км"])},
          "totalLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая длина всех линейных слоев - в км"])},
          "totalGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все группы"])},
          "groupLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слои первого уровня"])},
          "modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время последнего обновления"])},
          "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор последнего обновления"])},
          "tips": {
            "typeSelectorTip": {
              "row1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все слои - отображается информация по всем слоям, входящие в рассматриваемую группу во вложенные группы, которые находятся в уровнях ниже текущей группы."])},
              "row2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слои первого уровня - отображается информация по слоям, входящие только в текущую группу слоя."])}
            },
            "totalAreaLength": {
              "lengthRow1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая длина объектов был расчитан на основании данных в следующих слоях:"])},
              "lengthRow2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В общую длину не включены данные из следующих слоев:"])},
              "areaRow1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая площадь объектов была расчитана на основании данных в следующих слоях:"])},
              "areaRow2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В общую площадь не включены данные из следующих слоев:"])}
            }
          }
        },
        "multipleSelectTipStyleCopy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выберите слои, группы слоев для применения в них стиль слоя ", _interpolate(_named("name"))])},
        "multipleStyleCopyDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Действительно применить для выбранных элементов стиль слоя ", _interpolate(_named("name")), ": ", _interpolate(_named("count")), " шт?"])},
        "multipleSelect": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "style": {
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
          }
        },
        "styleCopy": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стили применены"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось применить стили"])},
          "errorLayer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось применить  стиль для слоя ", _interpolate(_named("name"))])}
        }
      },
      "settings": {
        "title": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка слоя"])},
          "externalEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки слоя"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание слоя"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название слоя"])},
          "attrinbution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибуты"])},
          "geom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип геометрии"])},
          "name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])}
        },
        "columns": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Псевдоним"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
          "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])},
          "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица"])},
          "popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поп-ап"])}
        },
        "btns": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить атрибут"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
          "openDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в руководство пользователя"])}
        },
        "errors": {
          "msg-save-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось"])},
          "msg": {
            "patch": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось применить изменения"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки применены"])}
            },
            "not-unique-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение должно быть уникальным"])},
            "field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно для заполнения"])},
            "has-errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется исправить ошибки"])},
            "create": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слой успешно создан"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать слой"])}
            },
            "only-latin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только латинские буквы и цифры и нижнее подчеркивание"])},
            "field-one-digit-character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В поле должен содержаться хотя бы один символ латиницы, кириллицы или цифра"])},
            "is-system-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибут с таким названием невозможно создать"])}
          },
          "notSelectedLayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите слой для добавления на карту"])},
          "sourceConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удается подключиться к серверу"])}
        },
        "confirm": {
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения, внесенные в данные?"])},
          "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
          "btn-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
        },
        "geom": {
          "polygon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полигон"])},
          "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линия"])},
          "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])}
        },
        "empty-coluns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет атрибутов"])},
        "typeTips": {
          "row1": {
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["максимальное количество символов 1000"])}
          },
          "row4": {
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Большое число:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-9223372036854775808 .. +9223372036854775807"])}
          },
          "row2": {
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малое число:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-32768 .. +32767"])}
          },
          "row3": {
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целое число:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-2147483648 .. +2147483647"])}
          },
          "row5": {
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дробное число:"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["максимальное количество символов 16 (включая точку)"])}
          },
          "row6": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При попытке ввода в поле значения больше или меньше допустимого, в соответствии с выбранным типом, в поле автоматически будет установлено ближайшее допустимое значение в рамках выбранного типа."])}
          },
          "row7": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При экспорте слоя если формат выгружаемого файла поддерживает типы атрибута из слоя, то в выгружаемом файле тип атрибута будет соответствовать с типом атрибута из слоя. Если формат выгружаемого файла не поддерживает тип атрибута слоя, то неподдерживаемый тип атрибута будет конвертирован в строку."])}
          },
          "row8": {
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импорт"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В файлах типа SHP, GeoPackage, KML тип атрибута будет установлен в соответствии с типом из загружаемого файла."])}
          },
          "row9": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В файлах типа geoJson тип числовых атрибута будет определен путем соотношения значений с ограничениями типа атрибута."])}
          }
        },
        "notDefineRound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не задано"])},
        "saveConfirmQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При закрытии окна внесенные изменения будут потеряны. Сохранить изменения?"])},
        "successSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки слоя успешно изменены"])},
        "errorSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при сохранении настроек"])}
      },
      "convertor3d": {
        "title": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать 3D слой"])}
        },
        "btns": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])}
        },
        "height": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высота вытягивания, м"])}
        },
        "msg": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача создана"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать задачу"])},
          "field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно для заполнения"])},
          "has-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните необходимые поля"])}
        },
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполнить по атрибуту"])},
        "level": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высота над уровнем моря, м"])},
          "terrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По рельефу"])}
        },
        "multiple": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Множитель"])}
        },
        "lod": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOD"])},
          "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels of Detail — уровни детализации модели. Если активировать - объекты модели будут с упрощенной детализацией (на мелком масштабе), которая ускорит рендеринг."])},
          "tip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний- объекты будут упрощены по внешним точкам."])}
        },
        "lod-type": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упрощение"])},
          "tip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний- объекты будут упрощены по внутреннему контуру. "])},
          "tip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний- объекты будут упрощены по внешнему контуру."])},
          "internal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний"])},
          "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний"])}
        },
        "handle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполнить вручную"])}
      },
      "settings3d": {
        "msg": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки применены"])},
          "has-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исправьте ошибки"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось применить настройки"])}
        },
        "title": {
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить 3D модель"])}
        },
        "z": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещение модели по вертикали"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["метры"])}
        },
        "btns": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить изменения"])}
        },
        "x": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещение модели по оси X"])}
        },
        "y": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещение модели по оси Y"])}
        },
        "scale": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масштаб"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["множитель"])}
        }
      }
    },
    "legend": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
      "menu": {
        "layers": {
          "create-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новую группу"])},
          "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать слои"])},
          "set-opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрозрачность"])},
          "edit-style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стиль"])},
          "remove-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить группу слоев"])},
          "hide-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть слои"])},
          "add-storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geocode.Storage"])},
          "add-pc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из компьютера"])},
          "add-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес"])},
          "add-wms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS/WMTS"])},
          "add-wfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WFS"])},
          "add-tms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TMS"])},
          "item": {
            "fly-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизить к слою"])},
            "export": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])}
            },
            "remove-layer": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
              "confirmation": {
                "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно ли удалить данный слой из проекта?"])},
                "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалять"])},
                "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
              }
            },
            "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
            "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть таблицу"])},
            "edit-layer": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
              "options": {
                "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать объект"])},
                "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать объект"])}
              }
            }
          }
        },
        "filter": {
          "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернуть всё"])},
          "hide-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть всё"])}
        }
      }
    },
    "download": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгрузка карты"])},
      "options": {
        "size": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат"])},
          "options": {
            "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A2"])},
            "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A3"])},
            "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A4"])},
            "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A5"])}
          }
        },
        "layout": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ориентация"])},
          "options": {
            "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["книжная"])},
            "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["альбомная"])}
          }
        },
        "legend": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод легенды"])}
        },
        "format": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип файла"])},
          "options": {
            "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".pdf"])},
            "jpg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".jpg"])},
            "png": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".png"])}
          }
        }
      }
    },
    "view": {
      "tools": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать инструменты"])},
        "expandedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть инструменты"])}
      },
      "zoom": {
        "inTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увеличить масштаб"])},
        "outTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уменьшить масштаб"])}
      },
      "basemaps": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовые карты"])},
        "values": {
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без подложки"])},
          "bbm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовая подложка"])},
          "osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenStreetMap"])},
          "google-sputnik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google спутник"])},
          "open-topo-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenTopoMap"])}
        }
      },
      "legend": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легенда"])},
        "empty-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь будет отображена легенда для включенных слоев"])},
        "maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернуть"])},
        "minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть"])}
      },
      "minimap": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миникарта"])}
      },
      "bookmark": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки"])},
        "button": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить +"])}
        },
        "placeholder": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя закладки"])}
        },
        "menu": {
          "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
        },
        "errors": {
          "failedDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить закладку"])},
          "failedRename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось переименовать закладку"])},
          "failedAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось добавить закладку"])},
          "emptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название не должно быть пустым"])}
        },
        "newBookmarkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя закладки"])},
        "actions": {
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить закладку"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить закладку"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])}
        },
        "remove-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить закладку?"])}
      },
      "searh": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пространственный поиск"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пространственный поиск"])},
        "geom": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Область поиска"])},
          "options": {
            "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])},
            "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линия"])},
            "polygon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полигон"])},
            "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прямоугольник"])}
          }
        },
        "buffer": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Буфер"])}
        },
        "measture": {
          "options": {
            "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["м"])},
            "km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["км"])}
          }
        },
        "predicate": {
          "options": {
            "intersects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пересекает"])},
            "no-intersects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не пересекает"])},
            "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержит"])},
            "within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Находится внутри"])},
            "touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касается"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геометрический предикат"])}
        },
        "layers": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск применять"])},
          "options": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все слои"])},
            "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимые слои"])}
          }
        },
        "reset": {
          "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить настройки"])}
        },
        "actions": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])}
        },
        "msg": {
          "geometry-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо нарисовать объект"])},
          "area-to-length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Превышен максимальный размер площади области поиска (", _interpolate(_named("n")), " из ", _interpolate(_named("max")), " кв.км)"])},
          "error-common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при выполнении запроса"])},
          "empty-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По введенным параметрам объекты не найдены"])},
          "length-to-length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Превышен максимальный размер линии области поиска (", _interpolate(_named("n")), " из 1000 км)"])},
          "error-buffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось построить буффер"])}
        },
        "close-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выключении инструмента, результаты поиска будут сброшены"])},
        "close": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
        }
      },
      "searh-result": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат поиска"])},
        "actions": {
          "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизить"])}
        },
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдено объектов: "])}
      },
      "text-searh": {
        "options": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Область поиска"])},
          "map": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Область поиска"])},
            "allMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся карта"])},
            "extent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий экстент"])}
          },
          "layers": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слои"])},
            "allLayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все слои"])},
            "visibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимые слои"])}
          }
        },
        "msg": {
          "empty-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По введенным параметрам объекты не найдены"])},
          "error-common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при выполнении запроса"])}
        }
      }
    },
    "shared": {
      "modal": {
        "share-map": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники проекта"])},
          "btns": {
            "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Добавить пользователя"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
          },
          "text": {
            "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
            "access-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень доступа"])},
            "all-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступен всем"])},
            "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
            "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
            "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пользователя"])}
          },
          "deleteUserDialog": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить пользователя?"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не сможет редактировать/просматривать проект"])}
          },
          "notifications": {
            "no-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не найден"])},
            "finded-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь найден"])},
            "deleted-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь успешно удален"])},
            "exist-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь уже имеет доступ к проекту"])},
            "success-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к проекту успешно обновлен"])},
            "fail-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла ошибка при обновлении доступа к проекту"])}
          },
          "permissions": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["все уровни"])},
            "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтение"])},
            "readwrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["редактирование"])},
            "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["владелец"])}
          }
        },
        "base-confirm": {
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждаете действие?"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
        },
        "attributes-info": {
          "switcher-name-attrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атрибуты объекта"])},
          "switcher-name-coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координаты"])},
          "coordinates-select-decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Десятичные градусы"])},
          "coordinates-select-degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Градусы, минуты, секунды"])},
          "coordinates-list-lat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта"])},
          "coordinates-list-lon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Долгота"])},
          "action-button-move-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизить к объекту"])},
          "action-button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "attributes-types": {
            "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["строка"])},
            "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["число"])},
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дата"])},
            "datetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дата и время"])}
          },
          "label": {
            "lat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта"])},
            "lon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Долгота"])}
          },
          "nextLayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующий слой"])},
          "prevLayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущий слой"])},
          "errors": {
            "failSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке сохранить данные"])}
          },
          "msg": {
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])}
          },
          "attributeOrder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["из ", _interpolate(_named("totalCount")), " объектов"])}
        },
        "add-wms": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить WMS/WMTS слой"])},
          "btns": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])}
          },
          "layer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о слое"])},
            "name": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])}
            },
            "url": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес"])}
            },
            "auth": {
              "login": {
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
              },
              "password": {
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
              }
            }
          },
          "layers": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слои"])},
            "search": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти слой"])}
            }
          },
          "auth": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация"])}
          }
        },
        "add-wfs": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить WFS слой"])},
          "btns": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])}
          },
          "layer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о слое"])},
            "name": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])}
            },
            "url": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес"])}
            },
            "auth": {
              "login": {
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
              },
              "password": {
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
              }
            }
          },
          "layers": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слои"])},
            "search": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти слой"])}
            }
          },
          "auth": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация"])}
          }
        },
        "add-tms": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить TMS слой"])},
          "layer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о слое"])},
            "name": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])}
            },
            "url": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес"])}
            },
            "auth": {
              "login": {
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
              },
              "password": {
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
              }
            },
            "domains": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдомены"])}
            }
          },
          "auth": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация"])}
          },
          "btns": {
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
            "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])}
          }
        },
        "coordinate-item": {
          "msg": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["введите значение"])},
            "max90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение в диапазоне от -90 до 90"])},
            "max60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение в диапазоне от 0 до 60"])},
            "max180": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение в диапазоне от -180 до 180"])}
          }
        },
        "unsavedConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все несохраненные изменения будут сброшены. Сохранить изменения?"])},
        "external": {
          "layerNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слой не найден"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти слой"])}
        }
      },
      "attribution-table": {
        "selected-count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("selected")), " из ", _interpolate(_named("n")), " объектов"])},
        "total-count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " объектов"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состав колонок"])},
        "menu": {
          "showFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать фильтры"])},
          "hideFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть фильтры"])},
          "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
          "enableEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить редактирование"])},
          "disableEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выключить редактирование"])}
        },
        "btn": {
          "show-on-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить фильтр на карте"])},
          "fly-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизить к выборке"])},
          "clearSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить выборку"])},
          "allColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все столбцы"])},
          "state-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
          "state-forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
          "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        },
        "cb": {
          "filter-extent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фильтровать по экстенту"])}
        },
        "exportError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось экспортировать данные"])},
        "confirm-save": {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения, внесенные в атрибутивную таблицу?"])},
          "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
          "btn-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения, внесенные в атрибутивную таблицу?"])}
        },
        "selected-area": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["из ", _interpolate(_named("n"))])},
        "total-area": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n"))])},
        "measure-area": {
          "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кв.м"])},
          "km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кв.км"])},
          "ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["га"])}
        },
        "measure-length": {
          "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["м."])},
          "km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["км."])}
        },
        "editableTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В атрибутах с числовым типом при попытке ввода в поле значения больше или меньше допустимого, в соответствии с выбранным типом, в поле автоматически будет установлено ближайшее допустимое значение в рамках выбранного типа."])},
        "deleteTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить выбранные объекты"])},
        "confirm-delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы действительно хотите удалить выбранные объекты (", _interpolate(_named("count")), ")?"])},
        "deleteRows": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объекты удалены"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке удаления объектов"])}
        },
        "errors": {
          "decimalPlacesLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Число знаков после запятой не должно превыщать ", _interpolate(_list(0))])}
        }
      }
    },
    "list": {
      "new-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать проект"])},
      "tabs": {
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои карты"])},
        "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий доступ"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])},
      "btn": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать проект"])}
      },
      "types": {
        "d2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D Проекты"])},
        "d3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Проекты"])}
      },
      "modes": {
        "d2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D карта"])},
        "d3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D карта"])}
      },
      "remove-project-confirmation": {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно ли удалить этот проект?"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалять"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
      },
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таких карт нет"])},
      "item": {
        "modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновлено :"])},
        "options": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
          "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
          "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
          "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свойства"])},
          "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])}
        },
        "btn": {
          "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть"])},
          "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])}
        },
        "rename": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое название:"])}
        },
        "successMapDeleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Карта '", _interpolate(_named("name")), "' успешно удалена"])},
        "removeMapError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке удаления карты"])},
        "irrevocableDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить карту безвозвратно"])},
        "successMapRestored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Карта '", _interpolate(_named("name")), "' успешно восстановлена"])},
        "restoreMapError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при попытке восстановить карту"])},
        "enter-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните имя карты"])}
      },
      "empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для создания карты нажмите на кнопку “Создать карту”"])},
      "remove-project-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта будет безвозвратно удалена"])},
      "emptyShared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь пока нет карт доступных для работы"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карт"])},
      "sortOptions": {
        "nameAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по названию"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по названию"])},
        "createdAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сначала старые"])},
        "createdDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сначала новые"])}
      }
    },
    "geolocation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определить местоположение"])}
    }
  },
  "base": {
    "default-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестная ошибка"])},
    "requiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно для заполнения"])},
    "hasErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется исправить ошибки"])},
    "large-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка: Попытка отправить слишком большой запрос"])},
    "errorPage": {
      "serverErrorStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, обновите страницу"])},
      "serverErrorEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если ошибка повторилась - "])},
      "serverErrorLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напишите нам"])},
      "forbiddenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросите доступ у владельца карты"])}
    },
    "confirmBtns": {
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "notSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "notDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалять"])},
      "goto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "cancelShorted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
    },
    "fileUnits": {
      "byte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байт"])},
      "kb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КБ"])},
      "mb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МБ"])},
      "gb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ГБ"])},
      "tb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТБ"])}
    },
    "dbTypes": {
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["строка"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["число"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дата"])},
      "datetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дата и время"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["время"])},
      "geometry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["геометрия"])},
      "boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["логический"])}
    },
    "dropZone": {
      "defaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместите файлы для загрузки"])}
    },
    "sorting": {
      "asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По возрастанию"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По убыванию"])}
    },
    "alignment": {
      "top_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По верхнему левому краю"])},
      "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По центру сверху"])},
      "top_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По верхнему правому краю"])},
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По левому краю"])},
      "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По центру"])},
      "rigth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По правому краю"])},
      "bottom_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По нижнему левому краю"])},
      "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По центру снизу"])},
      "bottom_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По нижнему правому краю"])}
    },
    "dropdown-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опции"])}
    },
    "gradient-picker": {
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
      "btns": {
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "new-gradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить цвет"])},
        "delete-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить цвет"])}
      }
    },
    "limit-range": {
      "from-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
      "to-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])}
    },
    "empty-message": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных"])}
    },
    "input": {
      "copyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
      "searchIconTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])},
      "clearIconTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить"])},
      "max-width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто максимальное количество символов"])}
    },
    "table": {
      "searchIconTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить фильтр"])},
      "notData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных для отображения"])}
    },
    "layers": {
      "columns": {
        "dbtype": {
          "int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целое число"])},
          "bigint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Большое число"])},
          "smallint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малое число"])},
          "boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логическое"])},
          "float8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дробное число"])},
          "geometry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геометрия"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
          "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестно"])}
        }
      }
    },
    "expandTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать подсказку"])},
    "link": {
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переход по внешней ссылке"])},
      "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка может вести на небезопасный сайт. Перейти по ссылке?"])},
      "notShowConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показывать больше"])}
    },
    "booleanOptions": {
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["да"])},
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нет"])},
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нет значений"])}
    },
    "closeTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть подсказку"])},
    "svgLoader": {
      "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
      "listLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить иконки"])},
      "explorer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать символ"])}
      }
    },
    "window": {
      "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть"])},
      "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернуть"])}
    }
  },
  "errors": {
    "geometry": {
      "topology_validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topology Validation Error"])},
      "repeated_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated Point"])},
      "hole_lies_outside_shell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hole lies outside shell"])},
      "holes_are_nested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holes are nested"])},
      "interior_disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interior is disconnected"])},
      "self_intersection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-intersection"])},
      "ring_self_intersection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ring Self-intersection"])},
      "nested_shells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nested shells"])},
      "duplicate_rings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Rings"])},
      "few_points_in_geometry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too few points in geometry component"])},
      "invalid_coordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Coordinate"])},
      "ring_not_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ring not closed"])},
      "empty_geometry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty geometry"])},
      "invalid_geometry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid geometry"])}
    }
  },
  "tips": {
    "edit-layer": {
      "point": {
        "row1": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание нового объекта"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клик на произвольном месте на карте."])}
        },
        "row2": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещение объекта"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зажать и переместить вершину."])}
        }
      },
      "line": {
        "row1": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание первой вершины объекта"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клик на произвольном месте на карте."])}
        },
        "row2": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершение отрисовки объекта"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двойной клик на конечную точку."])}
        },
        "row3": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление вершины"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клик на необходимом месте на ребре."])}
        },
        "row4": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление вершины"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двойной клик по вершине."])}
        },
        "row5": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещение вершины"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зажать и переместить вершину."])}
        }
      },
      "polygon": {
        "row1": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание первой вершины объекта"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клик на произвольном месте на карте."])}
        },
        "row2": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершение отрисовки объекта"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клик на первую вершину или двойной клик на конечную точку."])}
        },
        "row3": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление вершины"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клик на необходимом месте на ребре."])}
        },
        "row4": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление вершины"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двойной клик по вершине."])}
        },
        "row5": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещение вершины"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зажать и переместить вершину."])}
        }
      }
    }
  },
  "storage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geohub.Storage"])},
    "titleLayerAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить слой из Geohub.Storage"])},
    "defaultStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите один или несколько файлов"])},
    "emptyStructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет файлов для отображения"])},
    "emptyLoadStructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет файлов для загрузки"])},
    "dropTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместите файлы со слоем для импорта"])},
    "directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папка"])},
    "defaultDirectoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая папка"])},
    "uploadManagerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка файлов"])},
    "tips": {
      "cancelUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить загрузку"])},
      "showUploadManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать панель загрузки"])},
      "hideUploadManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть панель загрузки"])},
      "restartUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить загрузку"])}
    },
    "columns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя файла"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])}
    },
    "confirm": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить выбранные файлы и папки?"])},
      "createDirectory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название для новой папки"])}
    },
    "btns": {
      "loadNewFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить новый файл"])},
      "createDirectory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать папку"])},
      "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать файл"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])}
    },
    "errors": {
      "createDirectory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать новую папку"])},
      "loadNewFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить файлы"])},
      "deleteRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить файлы"])},
      "downloadFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось скачать файлы"])},
      "importLayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось импортировать слой"])},
      "failedConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удается подключиться к хранилищу"])},
      "excessFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружаемый файл должен быть меньше 2 гигабайт"])},
      "unsupportedFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неподдерживаемые форматы: "])},
      "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки"])},
      "uploadCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка была отменена"])},
      "dublicateFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попытка одновременно загрузить один файл"])}
    }
  },
  "calendar": {
    "date-range": {
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
      "date-pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дд.мм.гггг"])},
      "week-days": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пн"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вт"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ср"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чт"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пт"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сб"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вс"])}
      ]
    }
  },
  "date-picker": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
    "week-days": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пн"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вт"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ср"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чт"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пт"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сб"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вс"])}
    ],
    "confirm": {
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])}
    },
    "pagination": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вперед"])}
    }
  }
}