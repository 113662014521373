
import i18n from '@/i18n'
import { defineComponent, inject, ref } from 'vue'
import { Map2DKey } from '../symbol'
import { OverviewMap } from 'ol/control'
import OSM from 'ol/source/OSM'
import TileLayer from 'ol/layer/Tile'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { ActionBottomRightKey } from './symbol'
import XYZ from 'ol/source/XYZ'
import { useStore } from '@/library/store'
import { BaseMapType } from '@/library/types/maps/enums'
import { IconType, SizeType } from '@/library/types/base/enums'
import imgMiniMap from '@/library/assets/img/icMiniMap.svg'

export default defineComponent({
  components: {
    BaseIcon
  },
  emits: ['close'],
  setup () {
    const olSurveyRef = ref<HTMLElement>()
    const map2D = inject(Map2DKey)

    const sourceOSM = new OSM()
    const sourceGoogle = new XYZ({ url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}' })
    const overviewMapControl = ref<OverviewMap | null>(null)

    const ActionBottomRight = inject(ActionBottomRightKey)

    return {
      olSurveyRef,
      map2D,
      SizeType,
      sourceOSM,
      sourceGoogle,
      overviewMapControl,
      ActionBottomRight,
      imgMiniMap,
      IconType
    }
  },
  computed: {
    basemap () {
      const store = useStore()
      return store.state.maps.basemap
    },
    isActive () {
      if (this.ActionBottomRight) {
        return this.ActionBottomRight.activeAction === 'survey'
      }
      return false
    }
  },
  watch: {
    isActive (newVal) {
      if (newVal) {
        this.enableSurveyMap()
      } else {
        this.disableSurveyMap()
      }
    }
  },
  methods: {
    $t: i18n.global.t,

    handleClick () {
      if (!this.ActionBottomRight) {
        return
      }
      if (this.isActive) {
        this.ActionBottomRight.activateAction(undefined)
      } else {
        this.ActionBottomRight.activateAction('survey')
      }
    },
    enableSurveyMap () {
      const curMap = this.map2D?.olMap?.map
      if (curMap) {
        this.overviewMapControl = new OverviewMap({
          target: this.olSurveyRef,
          layers: [
            new TileLayer({
              source: this.basemap === BaseMapType.OSM ? this.sourceOSM : this.basemap === BaseMapType.GOOGLE_SPUTNIK ? this.sourceGoogle : undefined
            })
          ],
          collapsed: false
        })
        curMap.addControl(this.overviewMapControl as OverviewMap)
      }
    },
    disableSurveyMap () {
      const curMap = this.map2D?.olMap?.map
      if (curMap && this.overviewMapControl) {
        curMap.removeControl(this.overviewMapControl as OverviewMap)
        this.overviewMapControl = null
      }
    }
  }
})
