import { GeoMap } from '@/library/types'
import { getLayerGeometryType } from '@/library/helpers'
import { getLayersList } from './layers'
import api from '../api'
import { StyleConverter } from '../maps/viewer/layers/styles/helpers/style_converter'
import { FeatureType } from '../types/maps/enums'

const converter = new StyleConverter()

const getFigureType = (geometryType?: FeatureType) => {
  if (geometryType) {
    if ([FeatureType.POINT, FeatureType.MULTI_POINT].includes(geometryType)) {
      return 'point'
    } else if ([FeatureType.LINE_STRING, FeatureType.MULTI_LINE_STRING].includes(geometryType)) {
      return 'line'
    }
  }
  return 'polygon'
}

export const migrationMboxToSld = async (geoMap: GeoMap): Promise<boolean> => {
  return new Promise((resolve) => {
    try {
      const layers = getLayersList(geoMap.groups)
      layers.forEach(async x => {
        if (x.mboxStyle && !x.style?.sldStyle) {
          const geometryType = getLayerGeometryType(x)
          const sldStyle = await converter.convertMapboxToSld(
            JSON.stringify(x.mboxStyle), getFigureType(geometryType), x.columns ?? []
          )
          await api.patch(`/layers/${x.id}/sld`, { sldStyle: sldStyle })
        }
      })
    } catch {
      //

    } finally {
      resolve(true)
    }
  })
}
