
import { defineComponent, inject } from 'vue'
import i18n from '@/i18n'
import { createEmpty, extend } from 'ol/extent'
// import { fromLonLat } from 'ol/proj'

import { OlMapKey } from './../olMap'
import BaseIcon from '@/library/base/BaseIcon.vue'
import imgExtent from '@/assets/svg/home-extent.svg'
import { GeoMapLayer } from '@/library/types'
import { IconType, SizeType } from '@/library/types/base/enums'
import { useStore } from '@/library/store'
import { useNearestExtent } from '@/library/helpers/hooks/main'

const store = useStore()

export default defineComponent({
  components: {
    BaseIcon
  },
  setup () {
    const OlMap = inject(OlMapKey)
    const { flyToNearestExtent } = useNearestExtent(OlMap)

    return {
      OlMap,
      IconType,
      SizeType,
      flyToNearestExtent
    }
  },
  data () {
    return {
      imgExtent
    }
  },
  computed: {
    layersWithExtent (): GeoMapLayer[] {
      return store.getters.getLayersList.filter(x => x.extent)
    }
  },
  methods: {
    $t: i18n.global.t,
    handleClick () {
      const fullExtent = createEmpty()
      /* Baykalsk defaults  */
      extend(fullExtent, [11578658.399468828, 6703353.253752559, 11605167.280677073, 6721097.332274366])
      /* this.layersWithExtent.forEach(layer => {
        if (!layer.extent) return
        const topLeft = fromLonLat([layer.extent.xMin, layer.extent.yMin])
        const bottomRigth = fromLonLat([layer.extent.xMax, layer.extent.yMax])
        extend(fullExtent, [...topLeft, ...bottomRigth])
      }
      ) */
      if (fullExtent.includes(Infinity) || fullExtent.includes(-Infinity)) {
        return
      }
      this.fitToExtent(fullExtent)
    },
    fitToExtent (layerExtent: number[]) {
      if (!this.OlMap?.map) return
      if (layerExtent && layerExtent[0] && layerExtent[0] !== Infinity) {
        this.flyToNearestExtent(layerExtent)
      }
    }
  }
})
