import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31b1b747"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "control-history" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (item, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_base_icon, {
        key: index,
        disabled: item.disabled,
        src: item.icon,
        size: _ctx.SizeType.LG,
        mirrored: item.mirrored,
        type: _ctx.IconType.SECONDARY,
        onClick: item.handler
      }, null, 8, ["disabled", "src", "size", "mirrored", "type", "onClick"])), [
        [_directive_tooltip, item.label]
      ])
    }), 128))
  ]))
}