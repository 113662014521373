import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_header = _resolveComponent("base-header")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-empty-message",
    style: _normalizeStyle({
      width: `${_ctx.width}px`,
      height: `${_ctx.height}px`,
      left: `calc(50% - ${_ctx.width / 2}px)`,
      top: `calc(50% - ${_ctx.height / 2}px)`
    })
  }, [
    (_ctx.showIcon)
      ? (_openBlock(), _createBlock(_component_base_icon, {
          key: 0,
          type: _ctx.IconType.STATIC,
          src: _ctx.icon,
          class: "base-empty-message__icon"
        }, null, 8, ["type", "src"]))
      : _createCommentVNode("", true),
    _createVNode(_component_base_header, {
      class: "base-empty-message__title",
      title: _ctx.title,
      "max-width": _ctx.width,
      "with-ellipsis": false,
      level: "4"
    }, null, 8, ["title", "max-width"]),
    (_ctx.description)
      ? (_openBlock(), _createBlock(_component_base_header, {
          key: 1,
          class: "base-empty-message__description",
          title: _ctx.description,
          "max-width": _ctx.width,
          "with-ellipsis": false,
          level: "5"
        }, null, 8, ["title", "max-width"]))
      : _createCommentVNode("", true)
  ], 4))
}