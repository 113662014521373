<template>
  <div
    class="modal-mask"
    @mousedown.stop
  >
    <div
      class="modal-overlay"
      @mousedown.stop
    >
      <div class="modal-overlay__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="less" scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-background);
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    background-color: var(--color-inverse);
    border-radius: var(--item-border-radius);
    z-index: 9999;

    &__content {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
