
import i18n from '@/i18n'
import { defineComponent, PropType } from 'vue'
import InlineSvg from 'vue-inline-svg'
import { signaturePreviewOptions } from '@/library/types'
import circleFigure from './../../../../../assets/img/geometry/icCircle.svg'
import stylePreview from './../components/StylePreview.vue'

export default defineComponent({
  components: {
    stylePreview,
    InlineSvg
  },

  props: {
    modelValue: {
      type: Object as PropType<signaturePreviewOptions>,
      required: true
    }
  },

  computed: {
    icon () {
      return circleFigure
    }
  },

  methods: {
    $t: i18n.global.t
  }
})
