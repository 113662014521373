import { InitUrlInfo, VisibleLayer } from '@/library/types'
import { Cartesian3 } from 'cesium'

export const getInitParams = (): InitUrlInfo => {
  const result: InitUrlInfo = {}
  const params = (new URL(String(document.location))).searchParams
  const savedZoom = Number(params.get('zoom'))
  const savedCenter = params.get('center')
  const savedDestination = params.get('destination')
  const savedOrientation = params.get('orientation')
  const savedVisibleLayers = params.get('visibleLayers')

  let centerCoords: number[] | undefined
  let visibleLayers: (VisibleLayer | number)[] | undefined

  if (savedCenter) {
    centerCoords = savedCenter.split('~').map(item => Number(item))
    if (Array.isArray(centerCoords) && centerCoords.length === 2) {
      result.center = centerCoords
    }
  }

  if (savedDestination && savedOrientation) {
    const dest = Cartesian3.fromArray(JSON.parse(savedDestination))
    const orient = JSON.parse(savedOrientation)
    result.position3d = {
      destination: dest,
      orientation: {
        heading: orient[0],
        pitch: orient[1],
        roll: orient[2]
      }
    }
  }

  if (savedVisibleLayers) {
    visibleLayers = savedVisibleLayers.split('~').map(item => JSON.parse(item))
    if (Array.isArray(visibleLayers) && visibleLayers.length) {
      result.visibleLayers = visibleLayers.map(item => {
        if (typeof item === 'number') {
          return {
            layerId: Number(item),
            filters: []
          }
        }
        return item
      })
    }
  }
  if (!Number.isNaN(savedZoom)) {
    result.zoom = savedZoom
  }
  return result
}

export const LOCAL_STORAGE_MAP_SORT_KEY = 'mapsSort'
