
import { defineComponent, inject } from 'vue'
import i18n from '@/i18n'
import { fromExtent } from 'ol/geom/Polygon'

import { OlMapKey } from './../olMap'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { ButtonAction } from '@/library/types'
import HistoryAction from '@/library/assets/img/icBack.svg'
import { ButtonType, IconType, SizeType } from '@/library/types/base/enums'

export default defineComponent({
  components: {
    BaseIcon
  },

  setup () {
    return {
      ButtonType,
      SizeType,
      IconType
    }
  },

  data () {
    return {
      OlMap: inject(OlMapKey),
      allowUndo: false,
      allowRedo: false
    }
  },

  computed: {
    actions (): ButtonAction[] {
      return [{
        label: this.$t('maps.extent.btns.undoExtent'),
        icon: HistoryAction,
        disabled: !this.allowUndo,
        handler: this.undo
      }, {
        label: this.$t('maps.extent.btns.redoExtent'),
        disabled: !this.allowRedo,
        icon: HistoryAction,
        mirrored: true,
        handler: this.redo
      }]
    }
  },

  watch: {
    'OlMap.extentPosition' () {
      const status = this.OlMap && this.OlMap.validateActions()
      if (status) {
        this.allowUndo = status.allowUndo
        this.allowRedo = status.allowRedo
      }
    }
  },

  methods: {
    $t: i18n.global.t,

    changePosition (diff: 1 | -1) {
      const currentMap = this.OlMap?.map
      if (this.OlMap && currentMap) {
        const currentExtent = this.OlMap.changeExtentPosition(diff)
        if (currentExtent) {
          currentMap.getView().fit(fromExtent(currentExtent), {
            size: currentMap.getSize(),
            duration: 300
          })
        }
      }
    },

    undo () {
      this.changePosition(-1)
    },

    redo () {
      this.changePosition(1)
    }
  }
})
