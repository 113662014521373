
import i18n from '@/i18n'
import { defineComponent } from 'vue'

import BaseButton from '@/library/base/BaseButton.vue'
import BaseModal from '@/library/base/modal/BaseModal.vue'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseLoading from '@/library/base/BaseLoading.vue'
import { ButtonAction } from '@/library/types'
import { ButtonType } from '@/library/types/base/enums'
import { wordEndingByCount } from '@/library/helpers'
import Storage from '@/library/storage/Storage.vue'
import { useStore } from '@/library/store'
import { MapsActionTypes } from '@/library/store/modules/maps/action-types'
import toast from '@/library/toast'

const store = useStore()

export default defineComponent({
  components: {
    BaseModal,
    BaseHeader,
    BaseButton,
    BaseLoading,
    Storage
  },

  emits: ['close', 'success'],

  data () {
    return {
      isLoading: false,
      selectedFiles: [] as string[]
    }
  },

  computed: {
    storageRef () {
      return this.$refs.storage as InstanceType<typeof Storage>
    },

    statusText (): string {
      const count = this.selectedFiles.length
      if (count > 0) {
        const prefix = wordEndingByCount(count, 'Выбран', ['', 'о', 'ы'])
        const postfix = wordEndingByCount(count, 'файл', ['', 'а', 'ов'])
        return `${prefix} ${count} ${postfix}`
      } else {
        return this.$t('storage.defaultStatus')
      }
    },

    footerButtons (): ButtonAction[] {
      return [{
        label: this.$t('storage.btns.cancel'),
        handler: this.close,
        type: ButtonType.SECONDARY
      }, {
        label: this.$t('storage.btns.add'),
        handler: this.addHandler,
        disabled: this.selectedFiles.length === 0 || this.isLoading,
        type: ButtonType.PRIMARY
      }]
    }
  },

  methods: {
    $t: i18n.global.t,

    close () {
      this.$emit('close')
    },

    async addHandler () {
      if (this.isLoading) return

      this.isLoading = true

      const asyncImports: Promise<void>[] = []
      try {
        this.isLoading = true
        this.selectedFiles.forEach(item => {
          asyncImports.push(
            store.dispatch(MapsActionTypes.LAYER_IMPORT, { path: item })
          )
        })
        await Promise.all(asyncImports)
        this.$emit('success')
      } catch {
        toast.error(this.$t('storage.errors.importLayers'))
      } finally {
        this.isLoading = false
      }
    }
  }
})
