
import { defineComponent, PropType } from 'vue'
import { useStore } from '@/store'
import { LayerPerm } from '@/types/baykalsk/enums'
import { AppMutationTypes } from '@/store/modules/baykalsk/mutation-types'

import LayerItem from './LayerItem.vue'
import BaseRadio from '@/components/base/BaseRadio.vue'
import InlineSvg from 'vue-inline-svg'
import i18n from '@/i18n'

import { GeoMapLayer, GeoMapLayergroup } from '@/library/types'
import { IconType, SizeType } from '@/library/types/base/enums'
import BaseHeader from '@/library/base/BaseHeader.vue'
import imgOpen from '@/library/assets/img/folder-opened.svg'
import imgClose from '@/library/assets/img/folder-closed.svg'

const store = useStore()

export default defineComponent({
  name: 'GroupItem',
  components: {
    BaseHeader,
    InlineSvg,
    LayerItem,
    BaseRadio
  },
  props: {
    list: {
      type: Object as PropType<Array<GeoMapLayer | GeoMapLayergroup>> | null,
      required: true
    },
    windowRect: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data () {
    const t = i18n.global.t

    return {
      imgOpen,
      imgClose,
      t,
      LayerPerm,
      IconType,
      SizeType
    }
  },
  computed: {
    readLayers () {
      return store.state.baykalsk.listOfRead
    },

    writeLayers () {
      return store.state.baykalsk.listOfWrite
    },

    denyLayers () {
      return store.state.baykalsk.listOfDeny
    },

    expandedGroups () {
      return store.state.baykalsk.visibleGroups
    },

    allItems () {
      return [...this.list].sort((a, b) => b.zIndex - a.zIndex)
    }
  },

  methods: {
    toggleAccess (state: LayerPerm, group: GeoMapLayergroup) {
      const layersIds = group.layers.map(layer => layer.id)
      group.groups.forEach(childGroup => {
        this.toggleAccess(state, childGroup)
      })
      switch (state) {
        case LayerPerm.READ: {
          const writeIncludes = this.writeLayers.filter(readId => {
            return layersIds.includes(readId)
          })
          if (writeIncludes.length) {
            const toSave = this.writeLayers.filter(readId => {
              return !writeIncludes.includes(readId)
            })
            store.commit(AppMutationTypes.SET_LIST_OF_WRITE, toSave)
          }

          const denyIncludes = this.denyLayers.filter(readId => {
            return layersIds.includes(readId)
          })
          if (denyIncludes.length) {
            const toSave = this.denyLayers.filter(readId => {
              return !denyIncludes.includes(readId)
            })
            store.commit(AppMutationTypes.SET_LIST_OF_DENY, toSave)
          }

          store.commit(AppMutationTypes.SET_LIST_OF_READ, [...store.state.baykalsk.listOfRead, ...layersIds])
          break
        }

        case LayerPerm.WRITE: {
          const readIncludes = this.readLayers.filter(readId => {
            return layersIds.includes(readId)
          })
          if (readIncludes.length) {
            const toSave = this.readLayers.filter(readId => {
              return !readIncludes.includes(readId)
            })
            store.commit(AppMutationTypes.SET_LIST_OF_READ, toSave)
          }

          const denyIncludes = this.denyLayers.filter(readId => {
            return layersIds.includes(readId)
          })
          if (denyIncludes.length) {
            const toSave = this.denyLayers.filter(readId => {
              return !denyIncludes.includes(readId)
            })
            store.commit(AppMutationTypes.SET_LIST_OF_DENY, toSave)
          }

          store.commit(AppMutationTypes.SET_LIST_OF_WRITE, [...store.state.baykalsk.listOfWrite, ...layersIds])
          break
        }

        case LayerPerm.DENY: {
          const readIncludes = this.readLayers.filter(readId => {
            return layersIds.includes(readId)
          })
          if (readIncludes.length) {
            const toSave = this.readLayers.filter(readId => {
              return !readIncludes.includes(readId)
            })
            store.commit(AppMutationTypes.SET_LIST_OF_READ, toSave)
          }

          const writeIncludes = this.writeLayers.filter(readId => {
            return layersIds.includes(readId)
          })
          if (writeIncludes.length) {
            const toSave = this.writeLayers.filter(readId => {
              return !writeIncludes.includes(readId)
            })
            store.commit(AppMutationTypes.SET_LIST_OF_WRITE, toSave)
          }

          store.commit(AppMutationTypes.SET_LIST_OF_DENY, [...store.state.baykalsk.listOfDeny, ...layersIds])
          break
        }
      }
    },

    access (group: GeoMapLayergroup) {
      const layersIds = group.layers.map(layer => layer.id)

      const writeLayers = this.writeLayers.filter(readId => {
        return layersIds.includes(readId)
      })
      let result = writeLayers.length > 0 ? LayerPerm.WRITE : false
      group.groups.forEach(childGroup => {
        if (!(writeLayers.length > 0)) {
          result = this.access(childGroup)
        }
      })
      if (result === LayerPerm.WRITE) return result

      const readLayers = this.readLayers.filter(readId => {
        return layersIds.includes(readId)
      })
      result = readLayers.length > 0 ? LayerPerm.READ : false
      group.groups.forEach(childGroup => {
        if (!(readLayers.length > 0)) {
          result = this.access(childGroup)
        }
      })
      if (result === LayerPerm.READ) return result

      const denyLayers = this.denyLayers.filter(readId => {
        return layersIds.includes(readId)
      })
      result = denyLayers.length > 0 ? LayerPerm.DENY : false
      group.groups.forEach(childGroup => {
        if (!(denyLayers.length > 0)) {
          result = this.access(childGroup)
        }
      })
      return result
    },

    setVisible (id: number) {
      if (!this.expandedGroups.includes(id)) {
        store.commit(AppMutationTypes.SET_MAP_GROUPS_VISIBLE, [...this.expandedGroups, id])
      } else if (this.expandedGroups.includes(id)) {
        store.commit(AppMutationTypes.SET_MAP_GROUPS_VISIBLE, this.expandedGroups.filter(x => x !== id))
      }
    },
    checkVisibility (id: number) {
      return this.expandedGroups.includes(id)
    },
    isLayer (inputObject : GeoMapLayer | GeoMapLayergroup) : inputObject is GeoMapLayer {
      return (inputObject as GeoMapLayer).url !== undefined
    }
  }
})
