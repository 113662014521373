import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_header = _resolveComponent("base-header")!

  return (Object.keys(_ctx.options).length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["base-text-switch", {
      disabled: _ctx.disabled,
      bordered: !_ctx.transparent,
      'full-width': _ctx.fullWidth,
      [_ctx.size]: _ctx.size
    }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (value, key) => {
          return (_openBlock(), _createBlock(_component_base_header, {
            key: key,
            class: _normalizeClass(["base-text-switch__option", {
        active: _ctx.modelValue === key,
        compact: _ctx.compact
      }]),
            title: value,
            level: "3",
            type: _ctx.TextType.BUTTON,
            style: _normalizeStyle(_ctx.fullWidth ? { width: `${100 / Object.keys(_ctx.options).length}%` } : {}),
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', key))
          }, null, 8, ["class", "title", "type", "style", "onClick"]))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}