export enum GeoMapLayerType {
  WMS = 'wms',
  WMTS = 'wmts',
  WFS = 'wfs',
  TMS = 'tms',
  TILESET_3D = 'tileset3d',
  HYBRID = 'hybrid',
  VECTOR_TILES = 'vectorTiles'
}

export enum GeoMapPermission {
  ALL = 'all',
  OWNER = 'owner',
  READ = 'read',
  READWRITE = 'readwrite'
}

export enum GeoMapAccessAction {
  IMPORT,
  EXPORT,
  LAYER_DELETE,
  LAYER_EDIT,
  LAYER_STYLE,
  GROUP_EDIT,
  WINDOW_TASKS,
  MAP_EDIT,
  MAP_DELETE,
  MAP_SHARING,
  MAP_DUBLICATE,
}

export enum BaseMapType {
  NONE = 'none',
  BBM = 'bbm',
  OSM = 'osm',
  OPEN_TOPO_MAP = 'openTopoMap',
  GOOGLE_SPUTNIK = 'googleSputnik'
}

export enum GeoMapType {
  D2 = '2d',
  D3 = '3d'
}

export enum LayerExportType {
  KML = 'kml',
  CSV = 'csv',
  SHP = 'shp',
  GEO_JSON = 'geoJSON',
  GEO_PACKAGE = 'geoPackage',
  GEO_TIFF = 'geoTIFF',
  XLSX = 'xlsx',
  TILESET = 'tileset'
}

export enum FeatureType {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
  MULTI_POINT = 'MultiPoint',
  MULTI_LINE_STRING = 'MultiLineString',
  MULTI_POLYGON = 'MultiPolygon',
  CIRCLE = 'Circle'
}

export enum DbGeometryType {
  POINT = 'POINT',
  POLYGON = 'POLYGON',
  LINESTRING = 'LINESTRING',
  MULTIPOINT = 'MULTIPOINT',
  MULTILINESTRING = 'MULTILINESTRING',
  MULTIPOLYGON = 'MULTIPOLYGON'
}

export enum LayerActiveToolType {
  MULTIPLE_SELECT = 'multiple_select',
  CLICK = 'mapToolClick',
  FEATURE_CREATE = 'mapToolFeatureCreate',
  FEATURE_EDITOR = 'mapToolFeatureEditor',
  ATTRIBUTE_TABLE = 'mapToolAttributeTable',
  STYLE = 'mapToolStyle',
  SLD_EDITOR = 'mapToolSldEditor',
  LAYER_EXTERNAL_ADD = 'mapToolLayerExternalAdd',
  LAYER_EXTERNAL_EDIT = 'mapToolLayerExternalEdit',
  LAYER_CREATE = 'mapToolLayerCreate',
  LAYER_EDIT = 'mapToolLayerEdit',
  CHANGE_LOG = 'changeLog',
  LAYER_BACKUP = 'layerBackup',
  LAYER_CONVERT_TO_3D = 'mapToolLayerConvertTo3d',
  LAYER_TRANSFORM_3D = 'mapToolLayerTransform3d'
}

export enum MapActiveToolType {
  MAP_GEOLOCATION = 'mapGeolocation',
  MAP_LOCATION = 'mapLocation',
  MAP_SCALE_AREA = 'mapScaleArea',
  MAP_MEASUREMENT = 'mapMeasurement',
  MAP_EXTENT_LINK_CREATOR = 'mapExtentLinkCreator',
  MAP_BOOKMARKS = 'mapBookmarks',
  MAP_SEARCH = 'mapSearch'
}

export enum LayerExternalMode {
  WMS = 'wms',
  WFS = 'wfs',
  TMS = 'tms'
}

export enum CoordsType {
  XY = 'xy',
  XYZ = 'xyz'
}

export enum GeoMapListSortMode {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_ASC = 'CREATED_ASC',
  CREATED_DESC = 'CREATED_DESC'
}

export enum LayerColumnShow {
  TABLE = 'table',
  POPUP = 'popup',
  EXPORT = 'export'
}

export enum LayerColumnDbType {
  GEOMETRY = 'geometry',
  DATE = 'date',
  TIMESTAMP = 'timestamp',
  TEXT = 'text',
  BOOLEAN = 'boolean',
  BIGINT = 'bigint',
  INT = 'int',
  SMALLINT = 'smallint',
  FLOAT8 = 'float8'
}

export enum CoordinateFormat {
  DECIMAL_DEGRESS = 'DECIMAL_DEGRESS',
  MSK38 = 'msk38',
  DEGREES_MINUTES_SECONDS = 'DEGREES_MINUTES_SECONDS'
}

export enum CoordinateType {
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude'
}

export enum CoordinateUnit {
  DECIMAL = 'decimal',
  DEGREES = 'degress',
  MINUTES = 'minutes',
  SECONDS = 'seconds'
}

export enum AreaUnit {
  M = 'm',
  M2 = 'm2',
  KM = 'km',
  KM2 = 'km2',
  GA = 'ga'
}

export enum UnitType {
  LENGTH = 'length',
  PERIMETER = 'perimeter',
  RADIUS = 'radius',
  AREA = 'area'
}

export enum MeasurementType {
  RULER = 'ruler',
  CIRCLE = 'circle',
  AREA = 'area'
}

export enum MapTab {
  MY = 'my',
  SHARED = 'shared',
  TRASH = 'trash'
}

export enum MapProjection {
  EPSG_4326 = 'EPSG:4326',
  EPSG_3857 = 'EPSG:3857'
}

export enum TreeSelectType {
  DELETE = 'delete',
  STYLE_COPY = 'style_copy',
  GROUPS_DRAG = 'groups_drag'
}

export enum LayerConvertGeometryOperations {
  EXTERNAL = 'simplify_polygon_hull_external',
  INTERNAL = 'simplify_polygon_hull_internal'
}

export enum GeometricPredicates {
  CONTAINS = 'contains',
  INTERSECTS = 'intersects',
  NO_INTERSECTS = 'no_intersects',
  WITHIN = 'within',
  TOUCH = 'touch'
}

export enum UnitMeasure {
  M = 'm',
  KM = 'km'
}

export enum GeoMapState {
  READY = 'ready',
  TRASH = 'in_trash',
  FROZEN = 'frozen'
}
