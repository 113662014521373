
import { defineComponent, PropType } from 'vue'

import { TextType } from '@/library/types/base/enums'

export default defineComponent({
  directives: {
    focus: {
      mounted: function (el: HTMLInputElement) {
        if (el && el.focus) {
          el.focus()
        }
      }
    }
  },
  props: {
    type: {
      type: String as PropType<TextType>,
      default: TextType.HEADER
    },
    level: {
      type: [String, Number],
      required: true,
      validator: (val: string | number) => {
        return ['0', '1', '2', '3', '4', '5', 'label'].includes(String(val))
      }
    },
    innerStyle: {
      type: Object,
      default: undefined
    },
    editable: {
      type: Boolean,
      default: false
    },
    blured: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    showWidthCounter: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: undefined
    },
    title: {
      type: String,
      required: true
    },
    showTip: { type: Boolean, default: false },
    withEllipsis: { type: Boolean, default: true }
  },
  emits: ['editTitle', 'unfocusInput', 'mouseoverText', 'mouseleaveText'],

  data () {
    return {
      editEnable: false,
      isTextHovered: false,
      editableNewHeader: this.title
    }
  },

  computed: {
    isValidMaxWidth () {
      return this.maxWidth !== undefined && !Number.isNaN(this.maxWidth)
    }
  },

  watch: {
    editable (state: boolean) {
      if (state) {
        this.handleClick()
      } else {
        this.editEnable = false
      }
    }
  },

  methods: {
    calculateClass (isInputTag?: boolean) {
      const result = ['base-header', `${this.type}-${this.level}`]
      if (isInputTag) {
        result.push('input')
      }
      if (this.editable && !this.blured) {
        result.push('unblured')
      }
      if (this.withEllipsis) {
        result.push('with-ellipsis')
      }
      return result.join(' ')
    },

    focusField () {
      this.editableNewHeader = this.title
    },
    blurField () {
      let newHeaderName = this.editableNewHeader.trim()
      if (!newHeaderName.length) {
        newHeaderName = this.title
        this.editableNewHeader = this.title
      }
      this.$emit('editTitle', newHeaderName)
      if (this.blured) {
        this.$emit('unfocusInput')
        this.editEnable = false
      }
    },
    handlerInput () {
      if (this.maxWidth && this.isValidMaxWidth && this.editableNewHeader.length > this.maxWidth) {
        this.editableNewHeader = this.editableNewHeader.slice(0, this.maxWidth)
      }
    },
    handleClick () {
      if (!this.editable) {
        return
      }

      this.editEnable = true
    }
  }
})
