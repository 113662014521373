
import { defineComponent, PropType } from 'vue'

import { SizeType, TextType } from '@/library/types/base/enums'
import BaseHeader from '@/library/base/BaseHeader.vue'

export default defineComponent({
  components: { BaseHeader },
  props: {
    modelValue: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    size: {
      type: String as PropType<SizeType>,
      default: SizeType.MD
    },
    options: { type: Object as PropType<Record<string, string>>, required: true }
  },
  emits: ['update:modelValue'],
  setup () {
    return {
      TextType
    }
  }
})
