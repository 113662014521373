import {
  ProjectStatus,
  ServicePerm,
  WeatherTabIconCode,
  EstatePurposeType,
  EstateAdevertType,
  CitySuggestionType,
  CitySuggestionStatus,
  EgrnStatus,
  VideoStreamsStatus
} from '@/types/baykalsk/enums'

import svg01d from '@/assets/svg/weather-01d.svg'
import svg01n from '@/assets/svg/weather-01n.svg'
import svg02d from '@/assets/svg/weather-02d.svg'
import svg02n from '@/assets/svg/weather-02n.svg'
import svg03d from '@/assets/svg/weather-03d.svg'
import svg04d from '@/assets/svg/weather-04d.svg'
import svg09d from '@/assets/svg/weather-09d.svg'
import svg10d from '@/assets/svg/weather-10d.svg'
import svg10n from '@/assets/svg/weather-10n.svg'
import svg11d from '@/assets/svg/weather-11d.svg'
import svg13d from '@/assets/svg/weather-13d.svg'
import svg50d from '@/assets/svg/weather-50d.svg'

import svgFTemp from '@/assets/svg/forecast-temperature.svg'
import svgFPrecip from '@/assets/svg/forecast-umbrella.svg'
import svgFPressure from '@/assets/svg/forecast-pressure.svg'
import svgFHumidity from '@/assets/svg/forecast-humidity.svg'
import svgFWind from '@/assets/svg/forecast-wind.svg'
import svgPM25 from '@/assets/svg/atmo-pm25.svg'
import svgPM10 from '@/assets/svg/atmo-pm10.svg'
import svgCO from '@/assets/svg/atmo-co.svg'
import svgO3 from '@/assets/svg/atmo-o3.svg'
import svgNO2 from '@/assets/svg/atmo-no2.svg'
import svgSO2 from '@/assets/svg/atmo-so2.svg'
import svgFSquare from '@/assets/svg/weather-square.svg'

import svgSuggestionIdea from '@/assets/svg/suggestion-idea.svg'
import svgMarkerOffline from '@/assets/svg/marker-offline.svg'
import svgMarkerOnline from '@/assets/svg/marker-online.svg'

import svgSuggestionProblem from '@/assets/svg/suggestion-problem.svg'

import svgSuggestionProblemInWork from '@/assets/svg/sug-problem-in-work.svg'
import svgSuggestionProblemCompleted from '@/assets/svg/sug-problem-completed.svg'
import svgSuggestionProblemConsideration from '@/assets/svg/sug-problem-consideration.svg'
import svgSuggestionProblemRejected from '@/assets/svg/sug-problem-rejected.svg'
import svgSuggestionProblemSuspended from '@/assets/svg/sug-problem-suspended.svg'

import svgSuggestionIdeaInWork from '@/assets/svg/sug-idea-in-work.svg'
import svgSuggestionIdeaCompleted from '@/assets/svg/sug-idea-completed.svg'
import svgSuggestionIdeaConsideration from '@/assets/svg/sug-idea-consideration.svg'
import svgSuggestionIdeaRejected from '@/assets/svg/sug-idea-rejected.svg'
import svgSuggestionIdeaSuspended from '@/assets/svg/sug-idea-suspended.svg'
import { VideoStreamsNode } from '@/types/baykalsk/types'
import proj4 from 'proj4'

export const getStatusName = (status: ProjectStatus): string => {
  switch (status) {
    case ProjectStatus.PUBLISH:
      return 'Опубликован'
    case ProjectStatus.UNPUBLISH:
      return 'Не опубликован'
    default:
      return status
  }
}

export const getVacancyStatusName = (status: ProjectStatus): string => {
  switch (status) {
    case ProjectStatus.PUBLISH:
      return 'Опубликовано'
    case ProjectStatus.UNPUBLISH:
      return 'Не опубликовано'
    default:
      return status
  }
}

export const checkAccess = (level: ServicePerm | undefined | null, target?: string): boolean => {
  if (!level) {
    return false
  }

  switch (level) {
    case ServicePerm.MODERATION:
      return true
    case ServicePerm.MODIFICATION:
      if (target && target === 'publish') return false
      else return true
    case ServicePerm.COMMENTING:
    case ServicePerm.DENY:
    case ServicePerm.READ:
      return false
  }
}

export const getForecastDayName = (weekday: number): string => {
  switch (weekday) {
    case 1:
      return 'Пн'
    case 2:
      return 'Вт'
    case 3:
      return 'Ср'
    case 4:
      return 'Чт'
    case 5:
      return 'Пт'
    case 6:
      return 'Сб'
    case 7:
      return 'Вс'
    default:
      return weekday.toString()
  }
}

export const getWeatherIcon = (iconCode: string): string => {
  /* TODO use enum */
  switch (iconCode) {
    case '01d':
      return svg01d
    case '01n':
      return svg01n
    case '02d':
      return svg02d
    case '02n':
      return svg02n
    case '03d':
    case '03n':
      return svg03d
    case '04d':
    case '04n':
      return svg04d
    case '09d':
    case '09n':
      return svg09d
    case '10d':
      return svg10d
    case '10n':
      return svg10n
    case '11d':
    case '11n':
      return svg11d
    case '13d':
    case '13n':
      return svg13d
    case '50d':
    case '50n':
      return svg50d
    default:
      return svg01n
  }
}

export const getWeatherTabIcon = (iconCode: string): string => {
  switch (iconCode) {
    case WeatherTabIconCode.TEMP:
      return svgFTemp
    case WeatherTabIconCode.PRESIP:
      return svgFPrecip
    case WeatherTabIconCode.PRESSURE:
      return svgFPressure
    case WeatherTabIconCode.HUMIDITY:
      return svgFHumidity
    case WeatherTabIconCode.WINDSPEED:
      return svgFWind
    case WeatherTabIconCode.PM25:
      return svgPM25
    case WeatherTabIconCode.PM10:
      return svgPM10
    case WeatherTabIconCode.CO:
      return svgCO
    case WeatherTabIconCode.O3:
      return svgO3
    case WeatherTabIconCode.NO2:
      return svgNO2
    case WeatherTabIconCode.SO2:
      return svgSO2
    default:
      return svgFSquare
  }
}

export const airConditionByAqi = (level: number): string => {
  switch (level) {
    case 1:
      return 'Хорошее качество воздуха'
    case 2:
      return 'Умеренное загрязнение воздуха'
    case 3:
      return 'Повышенный уровень загрязнения воздуха'
    case 4:
      return 'Вредный уровень загрязнения воздуха'
    case 5:
      return 'Очень вредный уровень загрязнения воздуха'
    case 6:
      return 'Очень опасный уровень загрязнения воздуха'
    default:
      return level.toString()
  }
}

export const getWeatherWindIconModifier = (level: number): string => {
  if (level >= 338 || level <= 22) return 'N'
  else if (level >= 292) return 'NW'
  else if (level >= 248) return 'W'
  else if (level >= 203) return 'SW'
  else if (level >= 158) return 'S'
  else if (level >= 113) return 'SE'
  else if (level >= 68) return 'E'
  else if (level >= 23) return 'NE'
  else return ''
}

export const getWeatherWindShortDirection = (level: number): string => {
  if (level >= 338 || level <= 22) return 'с'
  else if (level >= 292) return 'с/з'
  else if (level >= 248) return 'з'
  else if (level >= 203) return 'ю/з'
  else if (level >= 158) return 'ю'
  else if (level >= 113) return 'ю/в'
  else if (level >= 68) return 'в'
  else if (level >= 23) return 'с/в'
  else return ''
}

export const getDateBeforeCurrentForGraph = (daysBack?: number): string => {
  const dayBeforeCurrent = new Date(Date.now() - 86400000 * (daysBack ?? 1))
  const resDate = dayBeforeCurrent.getFullYear() + '-' + (dayBeforeCurrent.getMonth() + 1).toString().padStart(2, '0') + '-' + dayBeforeCurrent.getDate().toString().padStart(2, '0')
  return resDate
}

export const formatTemperatureSign = (temp: number): number | string => {
  switch (Math.sign(temp)) {
    case 1:
      return '+' + temp
    default:
      return temp
  }
}

export const getEstatePurposeName = (status: EstatePurposeType): string => {
  switch (status) {
    case EstatePurposeType.LIVE:
      return 'Жилая недвижимость'
    case EstatePurposeType.COMMERCIAL:
      return 'Коммерческая недвижимость'
    default:
      return status
  }
}

export const getEstateAdvertName = (status: EstateAdevertType): string => {
  switch (status) {
    case EstateAdevertType.RENT:
      return 'Аренда'
    case EstateAdevertType.SALE:
      return 'Продажа'
    default:
      return status
  }
}

export const getSuggestionTypeName = (type: CitySuggestionType): string => {
  switch (type) {
    case CitySuggestionType.IDEA:
      return 'Идея'
    case CitySuggestionType.PROBLEM:
      return 'Проблема'
    default:
      return type
  }
}

export const getSuggestionTypeIcon = (type: CitySuggestionType): string => {
  switch (type) {
    case CitySuggestionType.IDEA:
      return svgSuggestionIdea
    case CitySuggestionType.PROBLEM:
      return svgSuggestionProblem
    default:
      return svgSuggestionIdea
  }
}

export const getSuggestionStatusName = (status: CitySuggestionStatus): string => {
  switch (status) {
    case CitySuggestionStatus.COMPLETED:
      return 'Завершено'
    case CitySuggestionStatus.CONSIDERATION:
      return 'На рассмотрении'
    case CitySuggestionStatus.INWORK:
      return 'В работе'
    case CitySuggestionStatus.REJECTED:
      return 'Отклонено'
    case CitySuggestionStatus.SUSPENDED:
      return 'Приостановлено'
    default:
      return status
  }
}

export const getSuggestionIdeaStatusBasedIcon = (status: CitySuggestionStatus): string => {
  switch (status) {
    case CitySuggestionStatus.COMPLETED:
      return svgSuggestionIdeaCompleted
    case CitySuggestionStatus.CONSIDERATION:
      return svgSuggestionIdeaConsideration
    case CitySuggestionStatus.INWORK:
      return svgSuggestionIdeaInWork
    case CitySuggestionStatus.REJECTED:
      return svgSuggestionIdeaRejected
    case CitySuggestionStatus.SUSPENDED:
      return svgSuggestionIdeaSuspended
    default:
      return svgSuggestionIdea
  }
}

export const getSuggestionProblemStatusBasedIcon = (status: CitySuggestionStatus): string => {
  switch (status) {
    case CitySuggestionStatus.COMPLETED:
      return svgSuggestionProblemCompleted
    case CitySuggestionStatus.CONSIDERATION:
      return svgSuggestionProblemConsideration
    case CitySuggestionStatus.INWORK:
      return svgSuggestionProblemInWork
    case CitySuggestionStatus.REJECTED:
      return svgSuggestionProblemRejected
    case CitySuggestionStatus.SUSPENDED:
      return svgSuggestionProblemSuspended
    default:
      return svgSuggestionProblem
  }
}

export const getVideoStreamsIcon = (status: VideoStreamsStatus): string => {
  switch (status) {
    case VideoStreamsStatus.ONLINE:
      return svgMarkerOnline
    default:
      return svgMarkerOffline
  }
}

export const getEgrnStatusName = (status: EgrnStatus): string => {
  switch (status) {
    case EgrnStatus.SENT:
      return 'Запрос отправлен'
    case EgrnStatus.DONWLOAD:
      return 'Загрузка данных'
    case EgrnStatus.CANCELED:
      return 'Загрузка отменена'
    case EgrnStatus.REJECTED:
      return 'Получен отказ'
    case EgrnStatus.ERROR:
      return 'Ошибка загрузки'
    case EgrnStatus.UPDATED:
      return 'Данные обновлены'
    default:
      return status
  }
}

export const getCameraZoneAngle = (radians: number): number => {
  return -radians - 0.68 // 0.68 - корректировка дефолтного угла в радианах у svg-картинки
}

export const getStreamsTreeNode = (uuid: string, nodes: VideoStreamsNode[]): VideoStreamsNode | undefined => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].uuid === uuid) return nodes[i]
    const children = nodes[i].children
    if (children) {
      const child = getStreamsTreeNode(uuid, children)
      if (child) return child
    }
  }
}

export const getStreamsTreeParentNode = (uuid: string, nodes: VideoStreamsNode[]): VideoStreamsNode | null | undefined => {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].uuid === uuid) return null
    const children = nodes[i].children
    if (children) {
      const child = getStreamsTreeParentNode(uuid, children)
      if (child === null) return nodes[i]
    }
  }
}

export const msk38Converter = (isFromMsk38: boolean, coords: number[]): number[] => {
  const mainProjection = 'EPSG:4326'
  const Msk38Projection = '+proj=tmerc +lat_0=0 +lon_0=103.03333333333 +k=1 +x_0=3250000 +y_0=-5411057.63 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs'
  if (isFromMsk38) return proj4(Msk38Projection, mainProjection, coords)
  else return proj4(mainProjection, Msk38Projection, coords)
}
