import i18n from '@/i18n'

import { AreaUnit } from '@/library/types/maps/enums'
import { roundAfterDecimalPoint } from '@/library/helpers'

const $t = i18n.global.t

export const LINE_WIDTH = 2
export const CIRCLE_RADIUS = 5
export const DISPLAY_PRECISION = 2

export const CONVERSATION_FACTORS: Record<string, Record<string, number>> = {
  [AreaUnit.M]: {
    [AreaUnit.KM]: 0.001
  },
  [AreaUnit.KM]: {
    [AreaUnit.M]: 1000,
    [AreaUnit.M2]: 1000000,
    [AreaUnit.GA]: 100
  },
  [AreaUnit.M2]: {
    [AreaUnit.KM2]: 0.000001,
    [AreaUnit.GA]: 0.0001
  },
  [AreaUnit.KM2]: {
    [AreaUnit.M2]: 1000000,
    [AreaUnit.GA]: 100
  },
  [AreaUnit.GA]: {
    [AreaUnit.M2]: 10000,
    [AreaUnit.KM2]: 0.01
  }
}

export const UNIT_LABELS = {
  [AreaUnit.M]: $t('maps.measurement.tools.units.m'),
  [AreaUnit.KM]: $t('maps.measurement.tools.units.km'),
  [AreaUnit.M2]: $t('maps.measurement.tools.units.sub-m2'),
  [AreaUnit.KM2]: $t('maps.measurement.tools.units.sub-km2'),
  [AreaUnit.GA]: $t('maps.measurement.tools.units.ga')
}

export const convertUnitValue = (
  value: number, oldUnit: AreaUnit, newUnit: AreaUnit
): number => {
  if (oldUnit === newUnit) {
    return value
  }
  return value * CONVERSATION_FACTORS[oldUnit][newUnit]
}

export const convertRoundedUnitValue = (value: number, oldUnit: AreaUnit, newUnit: AreaUnit): number => {
  const convertedValue = convertUnitValue(value, oldUnit, newUnit)
  return roundAfterDecimalPoint(convertedValue, DISPLAY_PRECISION)
}

export const AREA_UNITS = [{
  key: AreaUnit.KM2,
  value: $t('maps.measurement.tools.units.km2')
}, {
  key: AreaUnit.M2,
  value: $t('maps.measurement.tools.units.m2')
}, {
  key: AreaUnit.GA,
  value: $t('maps.measurement.tools.units.ga')
}]

export const DISTANCE_UNITS = [{
  key: AreaUnit.KM,
  value: $t('maps.measurement.tools.units.km')
}, {
  key: AreaUnit.M,
  value: $t('maps.measurement.tools.units.m')
}]
