
import { defineComponent, inject, computed, PropType } from 'vue'
import {
  PointCloudShading,
  Cesium3DTileset,
  Resource,
  CustomShader,
  Axis,
  Matrix4,
  ShadowMode,
  Ellipsoid,
  ClippingPlaneCollection,
  Cartesian2,
  Cartesian3,
  ClassificationType,
  SplitDirection,
  ImageBasedLighting,
  Cesium3DTileStyle,
  Color
} from 'cesium'

import { useStore } from '@/library/store'
import { ExpressionCondition, GeoMapLayer, MapBoxExpression } from '@/library/types'
import { CViewerKey } from './../symbol'
import { getGeometrySymbolOptions } from '@/library/helpers'

const store = useStore()

export default defineComponent({
  name: 'CPrimitiveTileset',

  props: {
    url: {
      type: Object as PropType<Resource>,
      required: true
    },
    geoLayer: {
      type: Object as PropType<GeoMapLayer>,
      required: true
    },
    show: {
      type: [Boolean, Object, Function] as PropType<boolean>,
      default: true
    },
    modelMatrix: {
      type: Object as PropType<Matrix4>,
      default: undefined
    },
    shadows: {
      type: Number,
      default: ShadowMode.ENABLED
    },
    maximumScreenSpaceError: {
      type: Number,
      default: 2
    },
    maximumMemoryUsage: {
      type: Number,
      default: 512
    },
    cullWithChildrenBounds: {
      type: Boolean,
      default: true
    },
    cullRequestsWhileMoving: {
      type: Boolean,
      default: true
    },
    cullRequestsWhileMovingMultiplier: {
      type: Number,
      default: 60.0
    },
    preloadWhenHidden: {
      type: Boolean,
      default: false
    },
    preloadFlightDestinations: {
      type: Boolean,
      default: true
    },
    preferLeaves: {
      type: Boolean,
      default: false
    },
    dynamicScreenSpaceError: {
      type: Boolean,
      default: false
    },
    dynamicScreenSpaceErrorDensity: {
      type: Number,
      default: 0.00278
    },
    dynamicScreenSpaceErrorFactor: {
      type: Number,
      default: 4.0
    },
    dynamicScreenSpaceErrorHeightFalloff: {
      type: Number,
      default: 0.25
    },
    progressiveResolutionHeightFraction: {
      type: Number,
      default: 0.3
    },
    foveatedScreenSpaceError: {
      type: Boolean,
      default: true
    },
    foveatedConeSize: {
      type: Number,
      default: 0.1
    },
    foveatedMinimumScreenSpaceErrorRelaxation: {
      type: Number,
      default: 0.0
    },
    foveatedInterpolationCallback: {
      type: Function as PropType<Cesium3DTileset.foveatedInterpolationCallback>,
      default: undefined
    },
    foveatedTimeDelay: {
      type: Number,
      default: 0.2
    },
    skipLevelOfDetail: {
      type: Boolean,
      default: true
    },
    baseScreenSpaceError: {
      type: Number,
      default: 1024
    },
    skipScreenSpaceErrorFactor: {
      type: Number,
      default: 16
    },
    skipLevels: {
      type: Number,
      default: 3
    },
    immediatelyLoadDesiredLevelOfDetail: {
      type: Boolean,
      default: true
    },
    loadSiblings: {
      type: Boolean,
      default: true
    },
    clippingPlanes: {
      type: [Object, Function] as PropType<ClippingPlaneCollection>,
      default: undefined
    },
    classificationType: {
      type: Object as PropType<ClassificationType>,
      default: undefined
    },
    ellipsoid: {
      type: Object as PropType<Ellipsoid>,
      default: undefined
    },
    pointCloudShading: {
      type: Object as PropType<PointCloudShading>,
      default: undefined
    },
    imageBasedLightingFactor: {
      type: [Object, Array, Function] as PropType<Cartesian2>,
      default: () => [1.0, 1.0]
    },
    lightColor: {
      type: Object as PropType<Cartesian3>,
      default: undefined
    },
    luminanceAtZenith: {
      type: Number,
      default: 0.2
    },
    specularEnvironmentMaps: {
      type: String,
      default: undefined
    },
    backFaceCulling: {
      type: Boolean,
      default: true
    },
    showOutline: {
      type: Boolean,
      default: true
    },
    vectorClassificationOnly: {
      type: Boolean,
      default: false
    },
    vectorKeepDecodedPositions: {
      type: Boolean,
      default: false
    },
    featureIdIndex: {
      type: Number,
      default: 0
    },
    instanceFeatureIdIndex: {
      type: Number,
      default: 0
    },
    showCreditsOnScreen: {
      type: Boolean,
      default: false
    },
    debugHeatmapTilePropertyName: {
      type: String,
      default: undefined
    },
    debugFreezeFrame: {
      type: Boolean,
      default: false
    },
    debugColorizeTiles: {
      type: Boolean,
      default: false
    },
    debugWireframe: {
      type: Boolean,
      default: false
    },
    debugShowBoundingVolume: {
      type: Boolean,
      default: false
    },
    debugShowContentBoundingVolume: {
      type: Boolean,
      default: false
    },
    debugShowViewerRequestVolume: {
      type: Boolean,
      default: false
    },
    debugShowGeometricError: {
      type: Boolean,
      default: false
    },
    debugShowRenderingStatistics: {
      type: Boolean,
      default: false
    },
    debugShowMemoryUsage: {
      type: Boolean,
      default: false
    },
    debugShowUrl: {
      type: Boolean,
      default: false
    },
    enableMouseEvent: {
      type: Boolean,
      default: true
    },
    enableModelExperimental: {
      type: Boolean,
      default: false
    },
    enableShowOutline: {
      type: Boolean,
      default: false
    },
    enableDebugWireframe: {
      type: Boolean,
      default: false
    },
    projectTo2D: {
      type: Boolean,
      default: false
    },
    customShader: {
      type: Object as PropType<CustomShader>,
      default: undefined
    },
    properties: {
      type: Array as PropType<
      Array<{
        key: string
        keyValue: any
        propertyName: string
        propertyValue: any
      }>
    >,
      default: undefined
    },
    fragmentShader: {
      type: String,
      default: undefined
    },
    replaceFS: Boolean,
    modelUpAxis: {
      type: Number,
      default: Axis.Y
    },
    modelForwardAxis: {
      type: Number,
      default: Axis.X
    },
    outlineColor: {
      type: Object as PropType<Color>,
      default: undefined
    },
    featureIdLabel: {
      type: String,
      default: undefined
    },
    splitDirection: {
      type: Number,
      default: SplitDirection.NONE
    },
    instanceFeatureIdLabel: {
      type: String,
      default: undefined
    },
    imageBasedLighting: {
      type: Object as PropType<ImageBasedLighting>,
      default: undefined
    }
  },

  emits: ['ready'],

  setup () {
    const viewer = inject(CViewerKey)
    return {
      viewer: computed(() => viewer?.value)
    }
  },

  data () {
    const tileset = new Cesium3DTileset({
      url: this.url,
      show: this.show,
      modelMatrix: this.modelMatrix,
      modelUpAxis: this.modelUpAxis,
      modelForwardAxis: this.modelForwardAxis,
      foveatedInterpolationCallback: this.foveatedInterpolationCallback,
      imageBasedLighting: this.imageBasedLighting,
      enableShowOutline: this.enableShowOutline,
      outlineColor: this.outlineColor,
      featureIdLabel: this.featureIdLabel,
      instanceFeatureIdLabel: this.instanceFeatureIdLabel,
      splitDirection: this.splitDirection,
      projectTo2D: this.projectTo2D,
      enableDebugWireframe: this.enableDebugWireframe,
      shadows: this.shadows,
      maximumScreenSpaceError: this.maximumScreenSpaceError,
      maximumMemoryUsage: this.maximumMemoryUsage,
      cullWithChildrenBounds: this.cullWithChildrenBounds,
      cullRequestsWhileMoving: this.cullRequestsWhileMoving,
      cullRequestsWhileMovingMultiplier: this.cullRequestsWhileMovingMultiplier,
      preloadWhenHidden: this.preloadWhenHidden,
      preloadFlightDestinations: this.preloadFlightDestinations,
      preferLeaves: this.preferLeaves,
      dynamicScreenSpaceError: this.dynamicScreenSpaceError,
      dynamicScreenSpaceErrorDensity: this.dynamicScreenSpaceErrorDensity,
      dynamicScreenSpaceErrorFactor: this.dynamicScreenSpaceErrorFactor,
      dynamicScreenSpaceErrorHeightFalloff: this.dynamicScreenSpaceErrorHeightFalloff,
      progressiveResolutionHeightFraction: this.progressiveResolutionHeightFraction,
      foveatedScreenSpaceError: this.foveatedScreenSpaceError,
      foveatedConeSize: this.foveatedConeSize,
      foveatedMinimumScreenSpaceErrorRelaxation: this.foveatedMinimumScreenSpaceErrorRelaxation,
      foveatedTimeDelay: this.foveatedTimeDelay,
      skipLevelOfDetail: this.skipLevelOfDetail,
      baseScreenSpaceError: this.baseScreenSpaceError,
      skipScreenSpaceErrorFactor: this.skipScreenSpaceErrorFactor,
      skipLevels: this.skipLevels,
      immediatelyLoadDesiredLevelOfDetail: this.immediatelyLoadDesiredLevelOfDetail,
      loadSiblings: this.loadSiblings,
      clippingPlanes: this.clippingPlanes,
      classificationType: this.classificationType,
      ellipsoid: this.ellipsoid,
      pointCloudShading: this.pointCloudShading,
      lightColor: this.lightColor,
      backFaceCulling: this.backFaceCulling,
      showOutline: this.showOutline,
      vectorClassificationOnly: this.vectorClassificationOnly,
      vectorKeepDecodedPositions: this.vectorKeepDecodedPositions,
      showCreditsOnScreen: this.showCreditsOnScreen,
      debugHeatmapTilePropertyName: this.debugHeatmapTilePropertyName,
      debugFreezeFrame: this.debugFreezeFrame,
      debugColorizeTiles: this.debugColorizeTiles,
      debugWireframe: this.debugWireframe,
      debugShowBoundingVolume: this.debugShowBoundingVolume,
      debugShowContentBoundingVolume: this.debugShowContentBoundingVolume,
      debugShowViewerRequestVolume: this.debugShowViewerRequestVolume,
      debugShowGeometricError: this.debugShowGeometricError,
      debugShowRenderingStatistics: this.debugShowRenderingStatistics,
      debugShowMemoryUsage: this.debugShowMemoryUsage,
      debugShowUrl: this.debugShowUrl
    })

    return {
      tileset
    }
  },

  computed: {
    alpha () {
      return store.state.maps.layersOpacity[this.geoLayer.id] ?? 1
    }
  },

  watch: {
    viewer () {
      this.addLayer()
    },

    alpha () {
      this.applyStyle()
    },

    'geoLayer.style.mboxStyle' () {
      this.applyStyle()
    }
  },

  mounted () {
    this.applyStyle()
    this.addLayer()
  },

  beforeUnmount () {
    if (this.viewer && !this.viewer.isDestroyed()) {
      this.viewer.scene.primitives.remove(this.tileset)
    }
  },

  methods: {
    addLayer () {
      if (this.viewer) {
        this.tileset.readyPromise.then(() => {
          this.viewer.scene.primitives.add(this.tileset)
          this.$emit('ready', this.tileset)
        })
      }
    },

    async applyStyle () {
      const style = this.geoLayer.style?.mboxStyle
      if (style) {
        const options = getGeometrySymbolOptions(style)

        if (Array.isArray(options.fill)) {
          const conditions: [string, string][] = []

          const color = options.fill as MapBoxExpression
          const startPosition = color[0] === 'case' ? 1 : 2
          const stepMode = color[0] === 'step'
          const attributeName: string = Array.isArray(color[1]) ? String(color[1][1]) ?? '' : ''
          // const attributeType = this.geoLayer.columns?.find(x => x.name === attributeName)?.dbType
          // let isString = true
          // if (attributeType) {
          //   isString = ![LayerColumnDbType.TEXT, LayerColumnDbType.DATE, LayerColumnDbType.].indexOf(attributeType)
          // }

          for (let i = startPosition; i < color.length - 2; i += 2) {
            const colorValue = stepMode ? String(color[i + 2]) : String(color[i + 1])
            let attributeValue: string | boolean | number
            const condition = (stepMode ? color[i + 1] : color[i]) as ExpressionCondition
            if (Array.isArray(condition) && condition.length > 1 && condition[1].length === 3) {
              attributeValue = condition[1][2] as string | boolean | number
            } else {
              attributeValue = (stepMode ? color[i + 1] : color[i]) as string | boolean | number
            }

            const fValue = (typeof attributeValue === 'string') ? `'${attributeValue}'` : attributeValue

            conditions.push(['$' + `{${attributeName}} === ` + `${fValue}`, `color("${colorValue}", ${(options.fillOpacity ?? 1) * this.alpha})`])
          }

          this.tileset.style = new Cesium3DTileStyle({
            color: {
              conditions: conditions
            }
          })
        } else {
          this.tileset.style = new Cesium3DTileStyle({
            color: {
              conditions: [
                ['true', `color("${options.fill}", ${(options.fillOpacity ?? 1) * this.alpha})`]
              ]
            }
          })
        }
      } else {
        this.tileset.style = new Cesium3DTileStyle({
          color: `rgba(255, 255, 255, ${this.alpha})`
        })
      }
    }
  }
})
