
import { defineComponent, inject, computed, PropType } from 'vue'
import {
  GeoJsonDataSource,
  Color,
  Resource,
  BillboardGraphics,
  HeightReference,
  JulianDate,
  PolygonGraphics,
  PolylineGraphics,
  CustomDataSource,
  PolylineDashMaterialProperty,
  HorizontalOrigin,
  VerticalOrigin,
  LabelGraphics,
  BoundingSphere,
  Ellipsoid,
  ConstantPositionProperty,
  Cartesian2
} from 'cesium'

import { CViewerKey } from './../symbol'
import { AttributeInfo, GeoMapLayer, MapBoxExpression, MapBoxStyleLayer } from '@/library/types'
import { DEFAULT_FILL_OPACITY, DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR, DEFAULT_STROKE_OPACITY, DEFAULT_STROKE_WIDTH, getPointSymbol } from '../../layers/styles/helpers/main'
import { useStore } from '@/library/store'
import { applyComplexStyle, getGeometrySymbolOptions, getLayerGeometryType, prepareGeometryStyle } from '@/library/helpers'
import { ComplexStyleType } from '@/library/types/styles_editor/enums'
import { FeatureType } from '@/library/types/maps/enums'

const store = useStore()
const COMMON_PROPERTY = '__common__'

export default defineComponent({
  name: 'CDatasourceGeojson',

  props: {
    markerSize: { type: Number, default: 40 },
    markerColor: { type: String, default: DEFAULT_PRIMARY_COLOR },
    markerSymbol: { type: String, default: 'circle-stroked' },
    data: { type: Object as PropType<Resource | string>, required: true },
    fill: { type: String, default: DEFAULT_PRIMARY_COLOR },
    stroke: { type: String, default: DEFAULT_PRIMARY_COLOR },
    clampToGround: { type: Boolean, default: true },
    strokeWidth: { type: Number, default: DEFAULT_STROKE_WIDTH },
    geoLayer: { type: Object as PropType<GeoMapLayer | undefined>, required: false, default: null }
  },

  emits: ['ready'],

  setup () {
    const viewer = inject(CViewerKey)
    return {
      viewer: computed(() => viewer?.value)
    }
  },

  data () {
    return {
      source: null as Promise<GeoJsonDataSource> | null,
      borders: null as CustomDataSource | null
    }
  },

  computed: {
    alpha () {
      return store.state.maps.layersOpacity[this.geoLayer?.id ?? -1] ?? 1
    }
  },

  watch: {
    viewer () {
      this.removeLayer()
      this.createLayer()
    },

    alpha () {
      this.applyStyle()
    },

    'geoLayer.style.mboxStyle' () {
      this.applyStyle()
    }
  },

  mounted () {
    this.createLayer()
  },

  beforeUnmount () {
    this.removeLayer()
  },

  methods: {
    async removeLayer () {
      if (this.viewer && this.source && !this.viewer.isDestroyed()) {
        this.viewer.dataSources.remove(await this.source, true)
      }

      if (this.viewer && this.borders && !this.viewer.isDestroyed()) {
        this.viewer.dataSources.remove(this.borders, true)
      }
    },

    async createLayer () {
      if (this.viewer) {
        const featureType = getLayerGeometryType(this.geoLayer)
        if (featureType === FeatureType.MULTI_POLYGON || featureType === FeatureType.POLYGON) {
          this.borders = new CustomDataSource()
          this.viewer.dataSources.add(this.borders)
        }
        const options: GeoJsonDataSource.LoadOptions = {
          markerSize: this.markerSize,
          strokeWidth: this.strokeWidth,
          markerSymbol: this.markerSymbol,
          clampToGround: this.clampToGround
        }
        if (this.stroke) {
          options.stroke = Color.fromCssColorString(this.stroke)
        }
        if (this.fill) {
          options.fill = Color.fromCssColorString(this.fill).withAlpha(0.4)
        }
        if (this.markerColor) {
          options.markerColor = Color.fromCssColorString(this.markerColor)
        }
        this.source = GeoJsonDataSource.load(this.data, options)
        this.$emit('ready', this.source)

        this.viewer.dataSources.add(await this.source)

        this.source.then(() => this.applyStyle())
      }
    },

    async refreshData () {
      if (this.source) {
        (await this.source).load(this.data)
      }
    },

    getHorizontalOrientation (orientation: string | undefined) {
      switch (orientation) {
        case 'top_left':
        case 'left':
        case 'bottom_left':
          return HorizontalOrigin.LEFT
        case 'top_right':
        case 'right':
        case 'bottom_right':
          return HorizontalOrigin.RIGHT
        case 'top':
        case 'bottom':
        case 'center':
        default:
          return HorizontalOrigin.CENTER
      }
    },

    getVerticalOrientation (orientation: string | undefined) {
      switch (orientation) {
        case 'top':
        case 'top_right':
        case 'top_left':
          return VerticalOrigin.TOP
        case 'bottom_left':
        case 'bottom':
        case 'bottom_right':
          return VerticalOrigin.BOTTOM
        case 'left':
        case 'right':
        case 'center':
        default:
          return VerticalOrigin.CENTER
      }
    },

    getLabel (eProps: Record<string, string>, styleLayer?: MapBoxStyleLayer): undefined | LabelGraphics {
      if (!styleLayer) {
        return undefined
      }

      let field = styleLayer.layout?.['text-field'] as string ?? ''
      field = field.slice(1, field.length - 1)

      const label = new LabelGraphics({
        horizontalOrigin: this.getHorizontalOrientation(styleLayer.layout?.['text-anchor']),
        verticalOrigin: this.getVerticalOrientation(styleLayer.layout?.['text-anchor']),
        text: String(eProps[field]),
        font: `${styleLayer.layout?.['text-size']}px "${styleLayer.layout?.['text-font']?.[0]}"`,
        fillColor: Color.fromCssColorString(String(styleLayer.paint?.['text-color'])).withAlpha(Number(styleLayer.paint?.['text-opacity'])),
        outlineWidth: styleLayer.paint?.['text-halo-width'] ? Number(styleLayer.paint?.['text-halo-width']) : 1,
        outlineColor: styleLayer.paint?.['text-halo-color'] ? Color.fromCssColorString(String(styleLayer.paint?.['text-halo-color'])) : undefined,
        pixelOffset: styleLayer.paint?.['text-translate']?.[0] !== undefined ? new Cartesian2(styleLayer.paint?.['text-translate'][0], styleLayer.paint?.['text-translate'][1]) : undefined,
        heightReference: HeightReference.RELATIVE_TO_GROUND
      })

      return label
    },

    getLayerAttriburtes () {
      const style = this.geoLayer?.style?.mboxStyle
      const featureType = getLayerGeometryType(this.geoLayer)

      const layersAttributes = {
        [COMMON_PROPERTY]: {
          style: getGeometrySymbolOptions(style),
          visible: true
        }
      } as Record<string, AttributeInfo>

      if (!style || !featureType) {
        return
      }

      const styleOptions = getGeometrySymbolOptions(style)
      if (Array.isArray(styleOptions.fill)) {
        applyComplexStyle(
          ComplexStyleType.FILL, false, featureType, styleOptions,
          layersAttributes, undefined, true
        )
      } else if (Array.isArray(styleOptions.stroke)) {
        applyComplexStyle(
          ComplexStyleType.STROKE, false, featureType, styleOptions,
          layersAttributes, undefined, true
        )
      } else if (Array.isArray(styleOptions.icon)) {
        applyComplexStyle(
          ComplexStyleType.ICON, false, featureType, styleOptions,
          layersAttributes, undefined, true
        )
      } else {
        const defaultStyle = prepareGeometryStyle(featureType, styleOptions)
        if (defaultStyle) {
          layersAttributes[COMMON_PROPERTY].style = defaultStyle
        }
      }

      return layersAttributes
    },

    async applyStyle () {
      if (this.borders) {
        this.borders.entities.removeAll()
      }
      const layersAttributes = this.getLayerAttriburtes()
      if (!layersAttributes) {
        return
      }

      const ds = await this.source
      if (!ds) {
        return
      }

      const keys = Object.keys(layersAttributes)

      const options = getGeometrySymbolOptions(this.geoLayer?.style?.mboxStyle)
      const color = options.fill || options.stroke || options.icon as MapBoxExpression
      const attributeName: string = Array.isArray(color[1]) ? String(color[1][1]) ?? '' : ''
      const featureType = getLayerGeometryType(this.geoLayer)

      const signature = this.geoLayer?.style?.mboxStyle.layers.find(x => x.id.startsWith('signature'))
      ds.entities.values.forEach((e) => {
        const eProps = e.properties?.getValue(new JulianDate())
        const defaultStyle = layersAttributes[COMMON_PROPERTY].style
        const style = keys.length === 1 && keys[0] === COMMON_PROPERTY ? defaultStyle : layersAttributes[eProps[attributeName]]?.style

        switch (featureType) {
          case FeatureType.POINT:
          case FeatureType.MULTI_POINT: {
            const graphic = style?.Point?.graphics?.[0]

            if (graphic?.icon?.length) {
              const bb = new BillboardGraphics({
                image: graphic.icon,
                heightReference: HeightReference.CLAMP_TO_GROUND,
                width: Number(options.radius) * 2,
                height: Number(options.radius) * 2
              })
              e.billboard = bb
            } else if (graphic) {
              const pointSymbol = getPointSymbol(
                Number(options.radius) * 2,
                graphic?.fill,
                options.fillOpacity,
                Number(options.strokeWidth),
                graphic?.stroke,
                options.strokeOpacity
              )
              const icon = 'data:image/svg+xml;base64,' + window.btoa(pointSymbol)
              const bb = new BillboardGraphics({
                image: icon,
                heightReference: HeightReference.CLAMP_TO_GROUND,
                width: Number(options.radius) * 2,
                height: Number(options.radius) * 2
              })
              e.billboard = bb
            } else {
              const pointSymbol = getPointSymbol(
                10,
                DEFAULT_SECONDARY_COLOR,
                0.4,
                1,
                DEFAULT_PRIMARY_COLOR,
                1
              )

              const bb = new BillboardGraphics({
                image: 'data:image/svg+xml;base64,' + window.btoa(pointSymbol),
                heightReference: HeightReference.CLAMP_TO_GROUND,
                width: 10,
                height: 10
              })

              e.billboard = bb
            }
            break
          }
          case FeatureType.POLYGON:
          case FeatureType.MULTI_POLYGON: {
            if (style?.Polygon && e.polygon) {
              e.polygon = new PolygonGraphics({
                hierarchy: e.polygon.hierarchy,
                material: Color.fromCssColorString(style.Polygon.fill ?? '').withAlpha(Number(style.Polygon['fill-opacity']))
              })
              if (signature && e.polygon.hierarchy) {
                const positions = e.polygon.hierarchy.getValue(new JulianDate()).positions
                const center = BoundingSphere.fromPoints(positions).center
                Ellipsoid.WGS84.scaleToGeodeticSurface(center, center)
                e.position = new ConstantPositionProperty(center)
              }
              if (this.borders) {
                const colorLine = Color.fromCssColorString(style.Polygon.stroke ?? '').withAlpha(Number(style.Polygon['stroke-opacity']))
                const dash : number[] | undefined = options.strokeDasharray?.split(' ').map(x => Number(x))
                this.borders.entities.add({
                  polyline: {
                    positions: e.polygon.hierarchy?.getValue(new JulianDate()).positions,
                    material: !dash || dash.length !== 2
                      ? colorLine
                      : new PolylineDashMaterialProperty({ color: colorLine, dashLength: dash[1] * (typeof options.strokeWidth === 'number' ? options.strokeWidth : DEFAULT_STROKE_WIDTH) }),
                    width: typeof options.strokeWidth === 'number' ? options.strokeWidth : DEFAULT_STROKE_WIDTH,
                    clampToGround: true
                  }
                })
              }
            } else if (e.polygon) {
              e.polygon = new PolygonGraphics({
                hierarchy: e.polygon.hierarchy,
                material: Color.fromCssColorString(DEFAULT_SECONDARY_COLOR).withAlpha(Number(DEFAULT_FILL_OPACITY / 100))
              })
              if (this.borders) {
                this.borders.entities.add({
                  polyline: {
                    positions: e.polygon.hierarchy?.getValue(new JulianDate()).positions,
                    material: Color.fromCssColorString(DEFAULT_PRIMARY_COLOR).withAlpha(Number(DEFAULT_STROKE_OPACITY / 100)),
                    width: DEFAULT_STROKE_WIDTH,
                    clampToGround: true
                  }
                })
              }
            }
            break
          }
          case FeatureType.LINE_STRING:
          case FeatureType.MULTI_LINE_STRING: {
            if (style?.Line && e.polyline) {
              const colorLine = Color.fromCssColorString(style.Line.stroke ?? '').withAlpha(Number(style.Line['stroke-opacity']))
              const dash : number[] | undefined = options.strokeDasharray?.split(' ').map(x => Number(x))
              e.polyline = new PolylineGraphics({
                positions: e.polyline.positions,
                material: !dash || dash.length !== 2
                  ? colorLine
                  : new PolylineDashMaterialProperty({ color: colorLine, dashLength: dash[1] * (typeof options.strokeWidth === 'number' ? options.strokeWidth : DEFAULT_STROKE_WIDTH) }),
                width: typeof options.strokeWidth === 'number' ? options.strokeWidth : DEFAULT_STROKE_WIDTH,
                clampToGround: true
              })

              if (signature && e.polyline) {
                const positions = e.polyline.positions?.getValue(new JulianDate())
                const center = BoundingSphere.fromPoints(positions).center
                Ellipsoid.WGS84.scaleToGeodeticSurface(center, center)
                e.position = new ConstantPositionProperty(center)
              }
            } else if (e.polyline) {
              e.polyline = new PolylineGraphics({
                positions: e.polyline.positions,
                material: Color.fromCssColorString(DEFAULT_PRIMARY_COLOR).withAlpha(Number(DEFAULT_STROKE_OPACITY / 100)),
                width: DEFAULT_STROKE_WIDTH,
                clampToGround: true
              })
            }
          }
        }

        e.label = this.getLabel(eProps, signature)
      })
    }
  }
})
