import { withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-373b9a43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-gradient-picker" }
const _hoisted_2 = {
  key: 0,
  class: "gradient-settings__list"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "gradient-settings__content" }
const _hoisted_5 = { class: "color-block__label" }
const _hoisted_6 = { class: "gradient-settings__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_color_picker = _resolveComponent("base-color-picker")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_button = _resolveComponent("base-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "base-gradient-picker__view",
      style: _normalizeStyle({ background: _ctx.currentGradient }),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openPicker && _ctx.openPicker(...args)), ["stop"]))
    }, null, 4),
    (_ctx.isOpenedPicker)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(_ctx.pickerLocation),
          class: "gradient-settings",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          (_ctx.mode === 'defaultSets')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.defaultGradients, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "base-gradient-picker__view",
                    style: _normalizeStyle({ background: item.gradient }),
                    onClick: ($event: any) => (_ctx.applyGradient(item.colorStops))
                  }, null, 12, _hoisted_3))
                }), 128))
              ]))
            : (_ctx.mode === 'newGradient')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "color-block"
                      }, [
                        _createVNode(_component_base_color_picker, {
                          modelValue: item.color,
                          "onUpdate:modelValue": ($event: any) => ((item.color) = $event),
                          class: "color-block__color"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('base.gradient-picker.color')) + " " + _toDisplayString(index + 1), 1),
                        (_ctx.colors.length > 1)
                          ? _withDirectives((_openBlock(), _createBlock(_component_base_icon, {
                              key: 0,
                              type: _ctx.IconType.CLEAR_DESTRUCTIVE,
                              src: _ctx.imgClose,
                              size: _ctx.SizeType.XS,
                              onClick: ($event: any) => (_ctx.deleteColor(index))
                            }, null, 8, ["type", "src", "size", "onClick"])), [
                              [_directive_tooltip, _ctx.$t('base.gradient-picker.btns.delete-color')]
                            ])
                          : _createCommentVNode("", true)
                      ]))
                    }), 128)),
                    (_ctx.colors.length <= 11)
                      ? (_openBlock(), _createBlock(_component_base_button, {
                          key: 0,
                          class: "color-block__add",
                          onClick: _ctx.addColor
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" + " + _toDisplayString(_ctx.$t('base.gradient-picker.color')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", {
                    class: "base-gradient-picker__view",
                    style: _normalizeStyle({ background: _ctx.gradient })
                  }, null, 4)
                ], 64))
              : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerActions, (item) => {
              return (_openBlock(), _createBlock(_component_base_button, {
                key: item.label,
                type: item.type,
                onClick: item.handler
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["type", "onClick"]))
            }), 128))
          ])
        ], 4))
      : _createCommentVNode("", true)
  ]))
}