import { ColorField, MapBoxStyle } from '@/library/types'
import {
  MIN_ALLOWABLE_ZOOM,
  MAX_ALLOWABLE_ZOOM,
  findClosestValue
} from '@/library/helpers'

export const WHITE_VARIANTS = ['#fff', '#ffffff', 'white']
export const ZERO_VARIANTS = [0.01, 0]
export const RGB_COLORS = ['#ff0000', '#00ff00', '#0000ff']
export const DEFAULT_STROKE_WIDTH = 1.25
export const LAZY_LIMIT_SIZE = 100

export const isStrictTransparent = (color: string, opacity?: number): boolean => {
  return (
    typeof color === 'string' &&
    WHITE_VARIANTS.includes(color) &&
    typeof opacity === 'number' && !Number.isNaN(opacity) && ZERO_VARIANTS.includes(opacity)
  )
}

export const parseExpressionAttributeName = (color?: ColorField): string | undefined => {
  let attribute: string | undefined
  if (Array.isArray(color)) {
    color.every(item => {
      if (Array.isArray(item)) {
        if (item.length === 2) {
          attribute = String(item[1])
        } else if (item.length === 3) {
          const variantConditions = item[1]
          if (Array.isArray(variantConditions) && Array.isArray(variantConditions[1])) {
            if (typeof variantConditions[1][1] === 'string') {
              attribute = variantConditions[1][1]
            }
          }
        }
      }
      return attribute === undefined
    })
  }
  return attribute
}

const SCALES_ZOOM_ASSOCIATION: Record<number, number> = {
  0: 559082264,
  1: 279541132,
  2: 139770566,
  3: 69885283,
  4: 34942641,
  5: 17471321,
  6: 8735660,
  7: 4367830,
  8: 2183915,
  9: 1091957,
  10: 545978,
  11: 272989,
  12: 136494,
  13: 68247,
  14: 34123,
  15: 17061,
  16: 8530,
  17: 4265,
  18: 2132,
  19: 1066,
  20: 533,
  21: 266,
  22: 133,
  23: 66,
  24: 33
}

export const getScaleByZoom = (zoom: number): number => {
  if (zoom < MIN_ALLOWABLE_ZOOM) {
    zoom = MIN_ALLOWABLE_ZOOM
  } else if (zoom > MAX_ALLOWABLE_ZOOM) {
    zoom = MAX_ALLOWABLE_ZOOM
  }
  if (!Number.isInteger(zoom)) {
    const leftBorder = Math.floor(zoom)
    const rightBorder = Math.ceil(zoom)
    const leftValue = SCALES_ZOOM_ASSOCIATION[leftBorder]
    const rightValue = SCALES_ZOOM_ASSOCIATION[rightBorder]
    const difference = rightValue - leftValue
    const ratio = zoom - leftBorder
    return leftValue + (difference * ratio)
  }
  return SCALES_ZOOM_ASSOCIATION[zoom]
}

export const getZoomByScale = (scale: number): number => {
  const scales = Object.values(SCALES_ZOOM_ASSOCIATION)
  const maxScale = Math.max(...scales)
  const minScale = Math.min(...scales)
  if (scale < minScale) {
    scale = minScale
  } else if (scale > maxScale) {
    scale = maxScale
  }
  const closestScale = findClosestValue(scales, scale)
  const findedIndex = scales.findIndex(item => item === closestScale)
  return Number(Object.keys(SCALES_ZOOM_ASSOCIATION)[findedIndex])
}

export const getOlLineWidthByMBox = (mBoxLineWidth: number): number => {
  return mBoxLineWidth < 3 ? mBoxLineWidth * 2 : mBoxLineWidth * 1.2
}

export const getDefaultStyles = () => {
  return {
    polygon: {
      strokeWidth: 0
    },
    point: {
      strokeWidth: 0,
      radius: 5
    },
    line: {
      strokeWidth: 1
    }
  }
}

export const getMapboxImageCallback = (layer: unknown, url: string): string => {
  return url
}

export const loadUsedIconsFromStyle = (style: MapBoxStyle): Promise<boolean[]> => {
  const urls = new Set<string>()
  const asyncDownloads: Promise<boolean>[] = []
  style.layers.forEach(layer => {
    const iconImage = layer.layout?.['icon-image']
    if (Array.isArray(iconImage)) {
      iconImage.forEach(item => {
        if (typeof item === 'string' && item.startsWith('https://')) {
          urls.add(item)
        }
      })
    } else if (typeof iconImage === 'string') {
      urls.add(iconImage)
    }
  })
  Array.from(urls).filter(url => {
    return url.startsWith('https://')
  }).forEach(url => {
    asyncDownloads.push(
      new Promise(resolve => {
        const img = new Image()
        img.src = url
        img.onload = () => {
          resolve(true)
        }
        img.onabort = () => {
          resolve(false)
        }
      })
    )
  })
  return Promise.all(asyncDownloads)
}
