
import { defineComponent, PropType, ref } from 'vue'
import InlineSvg from 'vue-inline-svg'

import BaseIcon from '@/library/base/BaseIcon.vue'
import BaseMenuGroup from '@/library/base/menu/BaseMenuGroup.vue'
import { IconType, SizeType, MenuTriggerType } from '@/library/types/base/enums'
import { MenuTreeItem, MenuSequenceInfo } from '@/library/types'

import imgMenu from '@/library/assets/img/icMenu.svg'

export default defineComponent({
  components: { BaseIcon, BaseMenuGroup, InlineSvg },
  props: {
    items: {
      type: Array as PropType<MenuTreeItem[]>,
      default: () => []
    },
    trigger: {
      type: String as PropType<MenuTriggerType>,
      default: 'click',
      validator: (val: MenuTriggerType) => {
        return [MenuTriggerType.CLICK, MenuTriggerType.HOVER].indexOf(val) !== -1
      }
    },
    iconTip: {
      type: String,
      default: null
    },
    iconType: {
      type: String as PropType<IconType>,
      default: IconType.SECONDARY
    },
    iconSize: {
      type: String as PropType<SizeType>,
      default: SizeType.SM
    },
    iconSrc: {
      type: String as PropType<string>,
      default: imgMenu
    },
    iconRotateAngle: {
      type: Number,
      default: 0
    },
    iconRounded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const menuIcon = ref<HTMLDivElement>()
    const containerRef = ref<HTMLDivElement | null>(null)
    return {
      menuIcon,
      containerRef,
      IconType
    }
  },

  data () {
    return {
      topPosition: undefined as number | undefined,
      leftPosition: undefined as number | undefined,
      openedSequence: [] as MenuSequenceInfo[],
      isOpen: false
    }
  },

  computed: {
    totalWidth () {
      let result = 0
      this.openedSequence.forEach(item => {
        if (item.width && item.state) {
          result += item.width
        }
      })
      return result
    }
  },

  watch: {
    isOpen (state: boolean) {
      this.$nextTick(() => {
        this.updateSequence({
          level: 0,
          state: state,
          width: this.containerRef?.clientWidth
        })
      })
    },

    totalWidth () {
      this.calculatePosition()
    }
  },

  mounted () {
    window.addEventListener('click', this.handleClick)
    window.addEventListener('mousedown', this.handleClick)
    window.addEventListener('dragover', this.close)
    const parent = this.$parent?.$el as HTMLElement
    if (parent) {
      const root = this.$parent?.$parent?.$el as HTMLElement
      parent.addEventListener('wheel', this.close)
      root && root.addEventListener('wheel', this.close)
    }
    window.addEventListener('wheel', this.close)
    if (this.trigger === MenuTriggerType.HOVER && this.menuIcon) {
      this.menuIcon.addEventListener('mouseover', this.handleHover)
      this.$el.addEventListener('mouseleave', this.close)
    }
  },
  beforeUnmount () {
    window.removeEventListener('click', this.handleClick)
    window.removeEventListener('mousedown', this.handleClick)
    window.removeEventListener('dragover', this.close)
    window.removeEventListener('wheel', this.close)
    const parent = this.$parent?.$el as HTMLElement
    if (parent) {
      const root = this.$parent?.$parent?.$el as HTMLElement
      parent.removeEventListener('wheel', this.close)
      root && root.removeEventListener('wheel', this.close)
    }
  },

  methods: {
    updateSequence (info: MenuSequenceInfo) {
      if (info.level < this.openedSequence.length) {
        this.openedSequence.splice(info.level, this.openedSequence.length - info.level)
      }
      this.openedSequence[info.level] = info
    },
    calculatePosition () {
      const rect = this.menuIcon?.getBoundingClientRect()
      this.$nextTick(() => {
        if (rect && this.containerRef) {
          const originY = rect.y + rect.height
          if (originY + this.containerRef.clientHeight > window.innerHeight) {
            this.topPosition = rect.y - this.containerRef.clientHeight - 10
          } else {
            this.topPosition = originY
          }
          const originWidth = rect.x + this.totalWidth + 10
          if (originWidth > window.innerWidth) {
            this.leftPosition = rect.x - (originWidth - window.innerWidth)
          } else {
            this.leftPosition = rect.x
          }
        }
      })
    },
    handleClick (evt: MouseEvent) {
      if (this.isOpen && evt.target !== null && this.menuIcon) {
        if (!this.menuIcon.contains(evt.target as HTMLElement)) {
          this.isOpen = false
        }
      }
    },
    handleHover () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    }
  }
})
