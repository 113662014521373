import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_map_download = _resolveComponent("map-download")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_base_icon, {
      modelValue: _ctx.isActive,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActive) = $event)),
      class: "actions__icons-btn",
      src: _ctx.MapDownloadSvg,
      size: _ctx.SizeType.LG,
      type: _ctx.IconType.SECONDARY
    }, null, 8, ["modelValue", "src", "size", "type"]), [
      [_directive_tooltip, _ctx.$t('maps.download.title')]
    ]),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "draggableContainerRef",
          class: "map-download",
          draggable: "true",
          style: _normalizeStyle(_ctx.style),
          onDragstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragMouseDown && _ctx.dragMouseDown(...args)))
        }, [
          _createVNode(_Transition, { name: "slide" }, {
            default: _withCtx(() => [
              _createVNode(_component_map_download, {
                onDownloadMap: _ctx.printMap,
                onClose: _ctx.mapDownloadClose
              }, null, 8, ["onDownloadMap", "onClose"])
            ]),
            _: 1
          })
        ], 36))
      : _createCommentVNode("", true)
  ]))
}