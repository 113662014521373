
import { defineComponent } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import InlineSvg from 'vue-inline-svg'
import BaseIcon from '@/library/base/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseInput,
    InlineSvg,
    BaseIcon
  },
  props: {
    password: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Password'
    },
    innerPlaceholder: {
      type: String,
      default: ''
    },
    isError: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:password'],
  data () {
    return {
      innerPassword: this.password ? this.password : '',
      isVisible: false
    }
  },
  watch: {
    innerPassword (newValue) {
      this.$emit('update:password', newValue)
    }
  }
})
