
import { defineComponent, inject, computed, PropType } from 'vue'
import {
  ImageryLayer,
  WebMapServiceImageryProvider,
  GetFeatureInfoFormat,
  Rectangle,
  GeographicTilingScheme,
  WebMercatorTilingScheme,
  Ellipsoid,
  Credit,
  TimeIntervalCollection,
  Clock,
  Resource
} from 'cesium'

import { CViewerKey } from './../symbol'
import { GeoMapLayer } from '@/library/types'
import { useStore } from '@/library/store'

const store = useStore()

export default defineComponent({
  name: 'CWmsProvider',

  props: {
    url: {
      type: String,
      required: true
    },
    parameters: {
      type: Object,
      default: undefined
    },
    layers: {
      type: String,
      default: undefined
    },
    getFeatureInfoParameters: {
      type: Object,
      default: undefined
    },
    enablePickFeatures: {
      type: Boolean,
      default: true
    },
    getFeatureInfoFormats: {
      type: Array as PropType<Array<GetFeatureInfoFormat>>,
      default: undefined
    },
    rectangle: {
      type: [Object, Array] as PropType<Rectangle>,
      default: undefined
    },
    tilingScheme: {
      type: Object as PropType<GeographicTilingScheme | WebMercatorTilingScheme>,
      default: undefined
    },
    ellipsoid: {
      type: Object as PropType<Ellipsoid>,
      default: undefined
    },
    tileWidth: {
      type: Number,
      default: 256
    },
    tileHeight: {
      type: Number,
      default: 256
    },
    minimumLevel: {
      type: Number,
      default: 0
    },
    maximumLevel: {
      type: Number,
      default: undefined
    },
    crs: {
      type: String,
      default: undefined
    },
    srs: {
      type: String,
      default: undefined
    },
    credit: {
      type: [String, Object] as PropType<string | Credit>,
      default: ''
    },
    subdomains: {
      type: [String, Array] as PropType<string | Array<string>>,
      default: undefined
    },
    clock: {
      type: Object as PropType<Clock>,
      default: undefined
    },
    times: {
      type: Object as PropType<TimeIntervalCollection>,
      default: undefined
    },
    getFeatureInfoUrl: {
      type: [String, Object] as PropType<string | Resource>,
      default: undefined
    },
    geoLayer: {
      type: Object as PropType<GeoMapLayer | null>,
      required: false,
      default: null
    },
    isOfp: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const viewer = inject(CViewerKey)
    return {
      viewer: computed(() => viewer?.value)
    }
  },

  data: () => ({
    layer: null as ImageryLayer | null
  }),

  computed: {
    alpha () {
      return store.state.maps.layersOpacity[this.geoLayer?.id ?? -1] ?? 1
    }
  },

  watch: {
    viewer () {
      this.reloadLayer()
    },

    alpha (newVal) {
      if (this.layer) {
        this.layer.alpha = newVal
      }
    }
  },

  mounted () {
    this.reloadLayer()
  },

  beforeUnmount () {
    if (this.viewer && this.layer && !this.viewer.isDestroyed()) {
      this.viewer.scene.imageryLayers.remove(this.layer as ImageryLayer)
    }
  },

  methods: {
    reloadLayer () {
      if (this.viewer) {
        const urlObj = new URL(this.url)
        urlObj.searchParams.delete('bbox')
        if (urlObj.searchParams.has('srs')) {
          urlObj.searchParams.delete('srs')
        }
        urlObj.searchParams.append('srs', 'EPSG:4326')

        this.layer = this.viewer.scene.imageryLayers.addImageryProvider(
          new WebMapServiceImageryProvider({
            url: urlObj.toString(),
            parameters: this.parameters,
            layers: this.layers || '',
            maximumLevel: this.maximumLevel,
            minimumLevel: this.minimumLevel,
            getFeatureInfoParameters: this.getFeatureInfoParameters,
            enablePickFeatures: this.enablePickFeatures,
            getFeatureInfoFormats: this.getFeatureInfoFormats,
            rectangle: this.rectangle,
            tilingScheme: this.tilingScheme,
            ellipsoid: this.ellipsoid,
            tileWidth: this.tileWidth,
            tileHeight: this.tileHeight,
            crs: this.crs,
            srs: this.srs,
            credit: this.credit,
            subdomains: this.subdomains
          })
        )
        this.layer.alpha = this.isOfp ? 1 : 0.5
      }
    }
  }
})
