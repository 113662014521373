import i18n from '@/i18n'
import { LayerColumnDbType } from '../types/maps/enums'

const t = i18n.global.t

export const COLUMN_ID = 'id_geocore'
export const COLUMN_AREA = 'area_geocore'
export const COLUMN_LENGTH = 'length_geocore'
export const COLUMN_GEOMETRY = 'geometry'

export const COLUMNS_SYSTEM = [COLUMN_ID, COLUMN_AREA, COLUMN_LENGTH, COLUMN_GEOMETRY]
export const COLUMNS_NUMBER_TYPES = [LayerColumnDbType.BIGINT, LayerColumnDbType.INT, LayerColumnDbType.SMALLINT, LayerColumnDbType.FLOAT8]

export const getLayersColumnsList = () : { value: string, label: string, index: number, isAvailableForCreate: boolean }[] => {
  const list = Object.values(LayerColumnDbType).map<{ value: string, label: string, index: number, isAvailableForCreate: boolean }>(x => {
    return {
      value: x,
      label: getColumnDbTypeLabel(x),
      index: getColumnDbTypeIndex(x),
      isAvailableForCreate: getColumnDbTypeAvailableForCreate(x)
    }
  })
  list.sort((a, b) => a.index - b.index)
  return list
}

export const getColumnDbTypeLabel = (type: LayerColumnDbType): string => {
  switch (type) {
    case LayerColumnDbType.BIGINT:
      return t('base.layers.columns.dbtype.bigint')
    case LayerColumnDbType.INT:
      return t('base.layers.columns.dbtype.int')
    case LayerColumnDbType.SMALLINT:
      return t('base.layers.columns.dbtype.smallint')
    case LayerColumnDbType.BOOLEAN:
      return t('base.layers.columns.dbtype.boolean')
    case LayerColumnDbType.FLOAT8:
      return t('base.layers.columns.dbtype.float8')
    case LayerColumnDbType.GEOMETRY:
      return t('base.layers.columns.dbtype.geometry')
    case LayerColumnDbType.TEXT:
      return t('base.layers.columns.dbtype.text')
    case LayerColumnDbType.DATE:
      return t('base.layers.columns.dbtype.date')
    case LayerColumnDbType.TIMESTAMP:
      return t('base.layers.columns.dbtype.timestamp')
    default:
      return t('base.layers.columns.dbtype.unknown')
  }
}

export const getColumnDbTypeInput = (type: LayerColumnDbType): string => {
  switch (type) {
    case LayerColumnDbType.BIGINT:
    case LayerColumnDbType.INT:
    case LayerColumnDbType.SMALLINT:
    case LayerColumnDbType.FLOAT8:
      return 'number'
    case LayerColumnDbType.BOOLEAN:
      return 'checkbox'
    case LayerColumnDbType.GEOMETRY:
      return 'none'
    case LayerColumnDbType.DATE:
      return 'date'
    case LayerColumnDbType.TIMESTAMP:
      return 'datetime-local'
    case LayerColumnDbType.TEXT:
    default:
      return 'text'
  }
}

export const getColumnDbInputMax = (type: LayerColumnDbType): number | bigint | undefined => {
  switch (type) {
    case LayerColumnDbType.BIGINT:
      return 9223372036854775807
    case LayerColumnDbType.INT:
      return 2147483647
    case LayerColumnDbType.SMALLINT:
      return 32767
    case LayerColumnDbType.TEXT:
      return 1000
    default:
      return undefined
  }
}

export const getColumnDbInputMin = (type: LayerColumnDbType): number | bigint | undefined => {
  switch (type) {
    case LayerColumnDbType.BIGINT:
      return -9223372036854775808
    case LayerColumnDbType.INT:
      return -2147483648
    case LayerColumnDbType.SMALLINT:
      return -32768
    default:
      return undefined
  }
}

export const getColumnDbTypeIndex = (type: LayerColumnDbType): number => {
  switch (type) {
    case LayerColumnDbType.BIGINT:
      return 2
    case LayerColumnDbType.INT:
      return 1
    case LayerColumnDbType.SMALLINT:
      return 3
    case LayerColumnDbType.BOOLEAN:
      return 8
    case LayerColumnDbType.FLOAT8:
      return 4
    case LayerColumnDbType.GEOMETRY:
      return 9
    case LayerColumnDbType.TEXT:
      return 5
    case LayerColumnDbType.DATE:
      return 6
    case LayerColumnDbType.TIMESTAMP:
      return 7
    default:
      return 10
  }
}

export const getColumnDbTypeAvailableForCreate = (type: LayerColumnDbType): boolean => {
  switch (type) {
    case LayerColumnDbType.BIGINT:
    case LayerColumnDbType.SMALLINT:
    case LayerColumnDbType.GEOMETRY:
      return false
    default:
      return true
  }
}
