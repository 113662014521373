
import { defineComponent, inject, ref } from 'vue'
import i18n from '@/i18n'
import { OlMapKey } from '@/library/maps/viewer/ol-map/olMap'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { IconType, SizeType } from '@/library/types/base/enums'
// import fullscreenIcon from '@/library/assets/img/full-screen.svg'
import fullscreenIcon from '@/assets/svg/home-extent.svg'
import { useStore as usestoreApp } from '@/store'

export default defineComponent({
  components: {
    BaseIcon
  },
  setup () {
    return {
      IconType,
      SizeType
    }
  },
  data () {
    return {
      OlMap: inject(OlMapKey),
      fullscreenIcon
    }
  },
  methods: {
    $t: i18n.global.t,
    handleClick () {
      this.fitToExtent()
    },
    fitToExtent () {
      const curSlideExt = ref<number[]>([])
      const baykalskStore = usestoreApp()
      if (!baykalskStore.state.baykalsk?.activeCPSSlideExtent.length) {
        curSlideExt.value = [11578658.399468828, 6703353.253752559, 11605167.280677073, 6721097.332274366]
      } else curSlideExt.value = baykalskStore.state.baykalsk.activeCPSSlideExtent

      if (!this.OlMap?.map) return
      if (curSlideExt.value && curSlideExt.value[0] && curSlideExt.value[0] !== Infinity) {
        this.OlMap.map.getView().fit(curSlideExt.value, {
          duration: 1000,
          padding: [50, 50, 50, 50]
        })
      }
    }
  }
})
