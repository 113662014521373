
import i18n from '@/i18n'
import { defineComponent } from 'vue'

import imgFolder from '@/library/assets/img/icFolder2.svg'
import BaseHeader from '@/library/base/BaseHeader.vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { IconType } from '@/library/types/base/enums'

export default defineComponent({
  components: {
    BaseHeader,
    BaseIcon
  },

  props: {
    title: { type: String, default: i18n.global.t('base.empty-message.title') },
    description: { type: String, default: undefined },
    icon: { type: String, default: imgFolder },
    showIcon: { type: Boolean, default: true },
    width: { type: Number, default: 200 },
    height: { type: Number, default: 120 }
  },

  setup () {
    return {
      IconType
    }
  }
})
