
import BaseIcon from '@/library/base/BaseIcon.vue'
import InlineSvg from 'vue-inline-svg'

import { defineComponent } from 'vue'

import imgPoly from '@/library/assets/img/maps/symbols/polygon-multi.svg'
import imgLine from '@/library/assets/img/maps/symbols/line-multi.svg'
import imgPoint from '@/library/assets/img/maps/symbols/point-multi.svg'
import imgRaster from '@/library/assets/img/maps/symbols/raster.svg'
import { IconType } from '@/library/types/base/enums'

export default defineComponent({
  components: { InlineSvg, BaseIcon },
  props: {
    type: { type: String, required: true }
  },
  setup () {
    return {
      IconType,
      imgPoly,
      imgLine,
      imgPoint,
      imgRaster
    }
  }
})
