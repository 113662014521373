
import BaseInput from '@/library/base/BaseInput.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    BaseInput
  },

  props: {
    modelValue: { type: [Number, String], default: null },
    minValue: { type: Number, default: 0 },
    maxValue: { type: Number, default: 100 },
    step: { type: Number, default: 1 },
    unit: { type: String, default: undefined },
    width: { type: String, default: 'auto' },
    withLabels: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  emits: ['update:modelValue'],

  data: () => ({
    selectedValue: 0
  }),

  computed: {
    inputWidth () {
      return `${String(this.maxValue).length * 10}px`
    }
  },

  watch: {
    modelValue (newValue) {
      const numberValue = Number(newValue)
      if (!Number.isNaN(numberValue)) {
        this.selectedValue = numberValue
      }
    }
  },

  mounted () {
    const numberValue = Number(this.modelValue)
    if (!Number.isNaN(numberValue)) {
      this.selectedValue = numberValue
    }
  },

  methods: {
    handlerInput (event: Event | string) {
      if (event instanceof Event) {
        const target = event.target as HTMLInputElement
        this.$emit('update:modelValue', target.value)
      } else {
        this.$emit('update:modelValue', event)
      }
    }
  }
})
