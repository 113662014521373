export enum MapBoxLayerType {
  FILL= 'fill',
  LINE= 'line',
  CIRCLE= 'circle',
  SYMBOL= 'symbol'
}

export enum WindowStyleMode {
  DEFAULT = 'default',
  RASTER = 'raster'
}

export enum StyleEditorMode {
  STYLE_EDITOR = 'style-editor',
  SLD_EDITOR = 'sld-editor'
}

export enum PointSymbolType {
  SIMPLE = 'simple',
  SVG = 'svg'
}

export enum SvgIconLoaderType {
  ICON = 'icon',
  BUTTON = 'button'
}

export enum LinePositionType {
  FOLLOW_LINE = 'follow_line',
  HORIZONTAL = 'horizontal'
}

export enum ComplexStyleType {
  STROKE = 'stroke',
  ICON = 'icon',
  FILL = 'fill'
}
