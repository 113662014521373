const domainsCount = process.env.VUE_APP_SUB_DOMAINS_COUNT ? parseInt(process.env.VUE_APP_SUB_DOMAINS_COUNT) : 10

const alphabet = new Array(26).fill(1).map((_, i) => String.fromCharCode(97 + i))
const availableAlphabet = alphabet.slice(0, domainsCount)

/* export used for videostreams */
export const appDomain = window.location.hostname !== 'localhost' ? window.location.host : (process.env.VUE_APP_DEV_DOMAIN || 'geocode2-dev.geocode.tech')

let iterationIndex = 0

export const domainGetter = {
  next (): string {
    if (!availableAlphabet.length) {
      return `https://${appDomain}`
    }

    if (iterationIndex >= availableAlphabet.length) {
      iterationIndex = 0
    }
    return `https://${availableAlphabet[iterationIndex++]}.${appDomain}`
  }
}

export const domainTemplate = availableAlphabet.length
  ? `https://{${availableAlphabet[0]}-${availableAlphabet[availableAlphabet.length - 1]}}.${appDomain}`
  : `https://${appDomain}`
