import { Client, BucketItemFromList, BucketItem } from 'minio'
import axios from 'axios'

import { UploadExcessFileSizeError } from './errors'
import { UploadNotificationItem, StorageConnectionInfo } from '@/library/types'
import { BaseTaskStatus } from '@/library/types/base/enums'
import auth from '@/library/auth'

export class MinioClient {
  private client: Client
  private structure: Record<string, BucketItem[]> = {}
  uploads: Record<string, UploadNotificationItem> = {}

  constructor () {
    this.client = new Client({
      endPoint: process.env.VUE_APP_MINIO_URL ?? 'minio-dev.geocode.tech',
      useSSL: true,
      accessKey: auth.keycloak.tokenParsed?.s3_access,
      secretKey: auth.keycloak.tokenParsed?.s3_secret
    })
  }

  clearUploads (): void {
    for (const pathPrefix in this.uploads) {
      if (this.uploads[pathPrefix]) {
        this.uploads[pathPrefix].isHidden = true
      }
    }
  }

  getConnectionInfo (): StorageConnectionInfo {
    return {
      endpoint: process.env.VUE_APP_MINIO_URL ?? 'minio-dev.geocode.tech',
      accessKey: auth.keycloak.tokenParsed?.s3_access,
      secretKey: auth.keycloak.tokenParsed?.s3_secret
    }
  }

  getBuckets (): Promise<BucketItemFromList[]> {
    return new Promise((resolve, reject) => {
      try {
        this.client.listBuckets((err, buckets) => {
          if (err) {
            reject(err)
          } else {
            buckets.forEach(item => {
              this.structure[item.name] = []
            })
            resolve(buckets)
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  getBucketFiles (bucketName: string, pathPrefix: string): Promise<BucketItem[]> {
    return new Promise((resolve, reject) => {
      try {
        if (Array.isArray(this.structure[bucketName])) {
          this.structure[bucketName].splice(0, this.structure[bucketName].length)
          const stream = this.client.listObjects(bucketName, pathPrefix)
          stream.on('data', (obj) => {
            const existObject = this.structure[bucketName].find(item => {
              return (
                (item.prefix && item.prefix === obj.prefix) ||
                (item.name && item.name === obj.name)
              )
            })
            if (!existObject) {
              this.structure[bucketName].push(obj)
            }
          })
          stream.on('end', () => {
            resolve(this.structure[bucketName])
          })
          stream.on('error', (err) => {
            reject(err)
          })
        } else {
          reject(new Error('unknown bucketName'))
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  downloadFile (bucketName: string, filePath: string): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const expiredSeconds = Number(process.env.VUE_APP_MINIO_DOWNLOAD_LINK_EXPIRED_SECONDS) || 5
        this.client.presignedGetObject(bucketName, filePath, expiredSeconds, (error, presignedUrl) => {
          if (error) {
            reject(error)
          }
          resolve(presignedUrl)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  private _getFileNameWithoutExtension (fileName: string) {
    const pointParts = fileName.split('.')
    if (pointParts.length > 1) {
      return pointParts.slice(0, pointParts.length - 1).join('.')
    }
    return fileName
  }

  private _getFileExtension (fileName: string) {
    return String(fileName.split('.').pop())
  }

  private _getFileNameWithoutCounter (fileName: string) {
    const fileNameWithoutExtension = this._getFileNameWithoutExtension(fileName)
    const bracketsParts = fileNameWithoutExtension.split(' (')
    if (bracketsParts.length > 1) {
      return bracketsParts.slice(0, bracketsParts.length - 1).join(' (')
    }
    return fileNameWithoutExtension
  }

  private _recalcPathPrefix (bucketName: string, pathPrefix: string, fileName: string) {
    let copiesCount = 0
    const currentFileWithoutExtension = this._getFileNameWithoutExtension(fileName)
    const currentFileExtension = this._getFileExtension(fileName)
    Object.values(this.uploads).filter(item => {
      return ![BaseTaskStatus.DELETED, BaseTaskStatus.DONE].includes(item.status)
    }).forEach(item => {
      if (item.file.name === fileName) {
        copiesCount += 1
      }
    })
    this.structure[bucketName].filter(item => {
      return item.name
    }).forEach(item => {
      const existFileName = String(item.name.split('/').pop())
      const baseFileName = this._getFileNameWithoutCounter(existFileName)
      const existExtension = this._getFileExtension(existFileName)
      if (
        (baseFileName === currentFileWithoutExtension) &&
        (existExtension === currentFileExtension)
      ) {
        copiesCount += 1
      }
    })
    if (copiesCount) {
      const pathParts = pathPrefix.split('.')
      const fileExtension = String(pathParts.pop())
      pathPrefix = `${pathParts.join('.')} (${copiesCount}).${fileExtension}`
    }
    return pathPrefix
  }

  loadNewFile (bucketName: string, pathPrefix: string, file: File, restarted?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const maxSize = Number(process.env.VUE_APP_STORAGE_UPLOAD_MAX_BYTES) || Number(0x7fffffff)
        if (file.size < maxSize) {
          if (!restarted) {
            pathPrefix = this._recalcPathPrefix(bucketName, pathPrefix, file.name)
          }
          file.arrayBuffer().then((arrayBuffer) => {
            const buffer = Buffer.from(arrayBuffer)
            this.uploads[pathPrefix] = {
              pathPrefix: pathPrefix,
              fileName: String(pathPrefix.split('/').pop()),
              progress: 0,
              file: file,
              lastModified: 0,
              status: BaseTaskStatus.STARTED,
              controller: new AbortController()
            }
            this.client.presignedPutObject(bucketName, pathPrefix, async (error, url) => {
              if (error) {
                this.uploads[pathPrefix].status = BaseTaskStatus.ERROR
                reject(error)
              }
              axios.put(url, buffer, {
                signal: this.uploads[pathPrefix].controller.signal,
                onUploadProgress: (event: ProgressEvent) => {
                  const percent = Math.round((event.loaded * 100) / file.size)
                  if (this.uploads[pathPrefix]) {
                    this.uploads[pathPrefix].progress = percent
                  }
                }
              }).then(() => {
                this.uploads[pathPrefix].status = BaseTaskStatus.DONE
                resolve()
              }).catch(error => {
                if (error.message === BaseTaskStatus.CANCELED) {
                  this.uploads[pathPrefix].status = BaseTaskStatus.CANCELED
                } else {
                  this.uploads[pathPrefix].status = BaseTaskStatus.ERROR
                  reject(error)
                }
              })
            })
          })
        } else {
          reject(new UploadExcessFileSizeError())
        }
      } catch (error) {
        this.uploads[pathPrefix].status = BaseTaskStatus.ERROR
        reject(error)
      }
    })
  }

  createDirectory (bucketName: string, pathPrefix: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.client.putObject(bucketName, pathPrefix, '', (error) => {
          if (error) {
            reject(error)
          }
          resolve()
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  deleteObject (bucketName: string, pathPrefix: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.client.removeObject(bucketName, pathPrefix, (error) => {
          if (error) {
            reject(error)
          }
          if (this.uploads[pathPrefix]) {
            this.uploads[pathPrefix].status = BaseTaskStatus.DELETED
          }
          resolve()
        })
      } catch (error) {
        reject(error)
      }
    })
  }
}
