
import { defineComponent, PropType, StyleValue } from 'vue'
import BaseHeader from './BaseHeader.vue'
import BaseIcon from './BaseIcon.vue'
import imgClose from '@/library/assets/img/icCrossedClose.svg'
import { IconType, SizeType } from '@/library/types/base/enums'

export default defineComponent({
  components: { BaseHeader, BaseIcon },
  props: {
    title: { type: String, default: undefined },
    hideClose: { type: Boolean, default: false },
    showSeparator: { type: Boolean, default: true },
    asNotification: { type: Boolean, default: false },
    headerLevel: { type: String as PropType<'2' | '4'>, default: '4' },
    headerWithEllipsis: { type: Boolean, default: true },
    customContentStyles: {
      type: Object as PropType<StyleValue>,
      default: () => ({})
    }
  },
  emits: [
    'close'
  ],
  setup () {
    return {
      IconType,
      SizeType
    }
  },
  data () {
    return {
      hidden: false,
      imgClose
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
})
