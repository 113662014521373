
import i18n from '@/i18n'
import { defineComponent, inject } from 'vue'
import BaseIcon from '@/library/base/BaseIcon.vue'
import { ActionBottomRightKey } from './symbol'
import { IconType, SizeType } from '@/library/types/base/enums'
import BaseMapSelect from './BaseMapSelect.vue'

import imgBasemap from '@/library/assets/img/icTransp.svg'

export default defineComponent({
  components: {
    BaseIcon,
    BaseMapSelect
  },
  emits: ['close'],
  setup () {
    const ActionBottomRight = inject(ActionBottomRightKey)

    return {
      ActionBottomRight,
      imgBasemap,
      SizeType,
      IconType
    }
  },
  computed: {
    isActive () {
      if (this.ActionBottomRight) {
        return this.ActionBottomRight.activeAction === 'baseMap'
      }
      return false
    }
  },
  methods: {
    $t: i18n.global.t,

    handleClick () {
      if (!this.ActionBottomRight) {
        return
      }
      if (this.isActive) {
        this.ActionBottomRight.activateAction(undefined)
      } else {
        this.ActionBottomRight.activateAction('baseMap')
      }
    }
  }
})
