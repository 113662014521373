
import i18n from '@/i18n'
import { defineComponent, inject } from 'vue'
import { DragBoxEvent } from 'ol/interaction/DragBox'
import { DragBox } from 'ol/interaction'
import { mouseOnly } from 'ol/events/condition'
import { fromExtent } from 'ol/geom/Polygon'

import BaseIcon from '@/library/base/BaseIcon.vue'
import { MapActiveToolType } from '@/library/types/maps/enums'
import { OlMapKey } from './../../olMap'
import { ActionTopRightKey } from './../../../actions/symbol'
import { IconType, SizeType } from '@/library/types/base/enums'

import imgScale from '@/library/assets/img/icScale.svg'

export default defineComponent({
  components: {
    BaseIcon
  },

  setup () {
    const ActionTopRight = inject(ActionTopRightKey)
    return {
      ActionTopRight,
      imgScale,
      SizeType,
      IconType
    }
  },

  data () {
    return {
      OlMap: inject(OlMapKey),
      dragBox: undefined as DragBox | undefined,
      startCoords: undefined as number[] | undefined
    }
  },

  computed: {
    isActive (): boolean {
      if (this.ActionTopRight) {
        return this.ActionTopRight.activeAction === MapActiveToolType.MAP_SCALE_AREA
      }
      return false
    }
  },

  watch: {
    isActive (state) {
      state ? this.activateTool() : this.deactivateTool()
    }
  },

  mounted () {
    if (this.isActive) {
      this.activateTool()
    }
  },

  beforeUnmount () {
    this.deactivateTool()
  },

  methods: {
    $t: i18n.global.t,

    boxstartHandler (event: DragBoxEvent) {
      this.startCoords = Array.from(event.coordinate)
    },

    boxendHandler (event: DragBoxEvent) {
      const currentMap = this.OlMap?.map
      if (currentMap && Array.isArray(this.startCoords)) {
        const endCoords = Array.from(event.coordinate)
        const newExtent = [
          Math.min(this.startCoords[0], endCoords[0]),
          Math.min(this.startCoords[1], endCoords[1]),
          Math.max(this.startCoords[0], endCoords[0]),
          Math.max(this.startCoords[1], endCoords[1])
        ]
        currentMap.getView().fit(fromExtent(newExtent))
      }
    },

    deactivateTool () {
      const currentMap = this.OlMap?.map
      if (currentMap && this.dragBox) {
        this.dragBox.un('boxstart', this.boxstartHandler)
        this.dragBox.un('boxend', this.boxendHandler)
        currentMap.removeInteraction(this.dragBox as DragBox)
      }
      this.dragBox = undefined
      this.startCoords = undefined
    },

    activateTool () {
      this.dragBox = new DragBox({
        condition: mouseOnly,
        className: 'scale-dragbox'
      })
      this.dragBox.on('boxstart', this.boxstartHandler)
      this.dragBox.on('boxend', this.boxendHandler)
      const currentMap = this.OlMap?.map
      if (currentMap) {
        currentMap.addInteraction(this.dragBox as DragBox)
      }
    },

    handleClick () {
      if (!this.ActionTopRight) {
        return
      }
      if (this.isActive) {
        this.ActionTopRight.activateAction(null)
      } else {
        this.ActionTopRight.activateAction(MapActiveToolType.MAP_SCALE_AREA)
      }
    }
  }
})
