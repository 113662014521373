
import { WmsLegendRuleSymbolizerRaster } from '@/library/types/maps/wmsLegends'
import { defineComponent, PropType } from 'vue'
import InlineSvg from 'vue-inline-svg'
import imgRaster from '@/library/assets/img/maps/symbols/raster.svg'

const __default__ = defineComponent({
  components: { InlineSvg },
  props: {
    raster: { type: Object as PropType<WmsLegendRuleSymbolizerRaster>, default: null }
  },
  data () {
    return {
      imgRaster
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "39ac4e9b": (_ctx.raster.stroke)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__