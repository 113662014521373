
import { defineComponent, ref } from 'vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  props: {
    modelValue: { type: String, required: true },
    placeholder: { type: String, required: true },
    required: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    innerPlaceholder: { type: String, default: 'Введите..' },
    max: { type: Number, default: undefined },
    isError: { type: Boolean, default: false }
  },
  emits: [
    'update:modelValue'
  ],
  setup () {
    var elInput = ref<HTMLInputElement>()
    const toast = useToast()
    return {
      elInput,
      toast
    }
  },
  data () {
    return {
      innerValue: this.modelValue
    }
  },
  computed: {
    hasValue () {
      return this.modelValue != null && this.modelValue.length > 0
    }
  },
  watch: {
    innerValue: function (val) {
      this.$emit('update:modelValue', val)
    }
  },
  methods: {
    focusInput () {
      if (this.elInput) {
        this.elInput.focus()
      }
    },
    handleKeyUp () {
      if (this.$props.max) {
        if (this.innerValue.length >= this.$props.max + (this.$props.type.toLowerCase() === 'number' ? 1 : 0)) {
          this.toast.clear()
          this.toast.error(this.$t('baikalsk.shared.base-input.max-length', { n: this.$props.max }))
          if (this.$props.type.toLowerCase() === 'number') this.innerValue = this.innerValue.slice(0, -1)
        } else this.toast.clear()
      }
    }
  }
})
