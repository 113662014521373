import { GeoMapLayer } from '@/library/types'
import { DbGeometryType, FeatureType, LayerColumnDbType } from '../types/maps/enums'

export const NUMBER_DB_TYPES = [LayerColumnDbType.BIGINT, LayerColumnDbType.INT, LayerColumnDbType.SMALLINT, LayerColumnDbType.FLOAT8]

export const dbGeometryTypeToFeatureType = (geometryType: DbGeometryType): FeatureType | null => {
  switch (geometryType) {
    case DbGeometryType.POINT:
      return FeatureType.POINT
    case DbGeometryType.LINESTRING:
      return FeatureType.LINE_STRING
    case DbGeometryType.POLYGON:
      return FeatureType.POLYGON
    case DbGeometryType.MULTIPOINT:
      return FeatureType.MULTI_POINT
    case DbGeometryType.MULTILINESTRING:
      return FeatureType.MULTI_LINE_STRING
    case DbGeometryType.MULTIPOLYGON:
      return FeatureType.MULTI_POLYGON
    default:
      return null
  }
}

export const getLayerGeometryType = (layer?: GeoMapLayer): FeatureType | undefined => {
  let result: FeatureType | undefined
  if (layer?.style?.mboxStyle.__geometryType__) {
    return layer.style.mboxStyle.__geometryType__
  }
  const geometryColumn = layer?.columns?.find(column => {
    return column.dbType === LayerColumnDbType.GEOMETRY
  })

  if (!geometryColumn) {
    return
  }

  const layerTypes = geometryColumn.geometryTypes
  if (Array.isArray(layerTypes) && layerTypes.length) {
    const fType = dbGeometryTypeToFeatureType(layerTypes[0])
    if (fType) {
      result = fType
    }
  }

  return result
}
