import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7246f59b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 3,
  class: "base-confirm__separator"
}
const _hoisted_2 = {
  key: 5,
  class: "base-confirm__separator"
}
const _hoisted_3 = { class: "base-confirm__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_header = _resolveComponent("base-header")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-confirm", { compacted: _ctx.isCompacted }]),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeAfterClick ? _ctx.$emit('cancel') : false))
  }, [
    _createElementVNode("div", {
      class: "base-confirm__wrapper",
      style: _normalizeStyle(_ctx.width ? `width: ${_ctx.width}` : '')
    }, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_base_loading, {
            key: 0,
            overlay: true
          }))
        : _createCommentVNode("", true),
      (_ctx.showClose)
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 1,
            src: _ctx.imgClose,
            class: "base-confirm__close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      (!_ctx.isCompacted)
        ? (_openBlock(), _createBlock(_component_base_icon, {
            key: 2,
            src: _ctx.imgQuestion,
            type: _ctx.IconType.STATIC,
            "custom-size": 75,
            rounded: "",
            class: "base-confirm__question"
          }, null, 8, ["src", "type"]))
        : _createCommentVNode("", true),
      _createVNode(_component_base_header, {
        class: "base-confirm__title",
        title: _ctx.questionText,
        "with-ellipsis": false,
        level: "2",
        type: _ctx.TextType.HEADER
      }, null, 8, ["title", "type"]),
      (_ctx.withSeparators)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.captionText)
        ? (_openBlock(), _createBlock(_component_base_header, {
            key: 4,
            class: "base-confirm__caption",
            title: _ctx.captionText,
            "with-ellipsis": false,
            type: _ctx.TextType.HEADER,
            level: "4"
          }, null, 8, ["title", "type"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.withSeparators)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_base_button, {
          type: _ctx.ButtonType.SECONDARY,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isDelete ? _ctx.cancelDeleteText : _ctx.cancelText), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createVNode(_component_base_button, {
          type: _ctx.isDelete ? _ctx.ButtonType.DESTRUCTIVE : _ctx.ButtonType.PRIMARY,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isDelete ? _ctx.confirmDeleteText : _ctx.confirmText), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ])
    ], 4)
  ], 2))
}